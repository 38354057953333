<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                <!-- Title -->
              </h1>
              <p class="text-lead text-white">
                <!-- Text -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-white border-0 mb-0">

            <div class="card-body px-lg-4 py-lg-4">
              <div class="text-center text-muted mb-4">
                <small>
                </small>
              </div>
              <form @submit.prevent="onSubmit">
                <div class="d-flex flex-column">
                  <div class="w-100">
                    <label for="email" class="text-start">
                      <h5>Email</h5>
                    </label>
                    <InputText name="email" type="text" v-model="user.email" class="w-100" />
                    <span class="text-start text-danger" v-if="errorMessage">{{ errorMessage }}</span>
                  </div>
                  <div class="row" v-for="message in validationErrors['email']" :key="message.index">
                    <span class="text-danger d-flex flex-column mt-4 ml-3">
                      {{ message }}
                    </span>
                  </div>
                  <div v-if="emailError">
                    <span class="text-danger d-flex flex-column mt-4 ml-3">
                      {{ emailError }}
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column mt-4">
                  <div class="w-100">
                    <label for="password" class="text-start">
                      <h5>Password</h5>
                    </label>

                    <Password v-model="user.password" inputClass="w-100" class="w-100" toggleMask :feedback="false">
                    </Password>
                  </div>
                  <span class="text-start text-danger" v-if="errorMessage">{{ errorMessage }}</span>
                  <!-- </Field> -->
                  <div class="row w-100" v-for="message in validationErrors['password']" :key="message.index">
                    <span class="text-danger d-flex flex-column mt-4 ml-3">
                      {{ message }}
                    </span>
                  </div>
                  <div v-if="passError">
                    <span class="text-danger d-flex flex-column mt-4 ml-3">
                      {{ passError }}
                    </span>
                  </div>
                  <div class="row w-100" v-for="message in validationErrors['error']" :key="message.index">
                    <span class="text-danger d-flex flex-column mt-4 ml-3">
                      {{ message }}
                    </span>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <Button label="Sign in" type="submit" class="p-primary" />
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link :to="{ name: 'ForgotPassword' }" class="text-primary"><small>Forgot password?</small>
              </router-link>
            </div>
            <div class="col-6 text-right">
              <router-link :to="{ name: 'Register' }" class="text-primary"><small>Create new account</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuth } from "@/composables/auth";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import { onMounted, reactive, watch, ref } from "vue";


export default {
  components: {
    InputText,
    Password,
  },
  setup() {

    const { loginForm, validationErrors, processing, submitLogin } = useAuth();

    const user = reactive({
      email: "",
      password: "",
    });

    const emailError = ref(null);
    const passError = ref(null);

    const isValidEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const validateForm = () => {
      if (!user.email || !isValidEmail(user.email)) {
        console.error("Invalid email");
        emailError.value = "Enter A valid Email";
        return false;
      }
      if (!user.password) {
        console.error("Password is required");
        passError.value = "Enter A valid Password";

        return false;
      }
      passError.value =null;
      emailError.value =null;
      return true;
    };




    const onSubmit = (values) => {
      if (validateForm()) {
        loginForm.email = user.email;
        loginForm.password = user.password;
        submitLogin();
      }
    }

    return {
      loginForm,
      validationErrors,
      processing,
      submitLogin,
      onSubmit,
      user,
      emailError,
      passError
    };
  },
};
</script>
