<template>
  <div>
    <div class="profile-pic-wrapper">
      <div
        class="d-flex flex-column"
        :class="{
          'pic-holder-avatar': upType == 'avatar',
          'pic-holder-other': upType == 'other',
        }"
      >
        <!-- uploaded pic shown here -->
        <img
          class="pic profilePic m-2"
          :class="{
            'border rounded-circle ': upType == 'avatar',
            '': upType == 'other',
          }"
          style="object-fit: cover"
          :src="
            modelValue && modelValue[0]?.original_url
              ? modelValue[0]?.original_url
              : placeholder
          "
        />

        <input
          class="uploadProfileInput"
          type="file"
          @change="updateValue"
          :name="uid"
          :id="uid"
          accept="image/*"
          style="display: none"
        />
        <label :for="uid" class="upload-file-block">
          <div class="text-center">
            <div class="mb-2">
              <!-- <i class="fa fa-camera fa-2x"></i> -->
              <img src="img/upload-icon-white.svg" alt="upload" />
            </div>
            <div class="text-uppercase">Upload Photo</div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import axiosClient from "../axiosClient";
export default {
  name: "UploadFiles",
  props: {
    modelValue: {
      type: Array,
    },
    url: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "img/upload-icon.svg",
    },
    upType: {
      type: String,
      default: "other",
    },
    collection_name: {
      type: String,
    },
    size: {
      type: Number,
    },
    model_id: {
      type: Number,
    },
    uid: {
      type: String,
      default: "uploader",
    },
  },
  setup(props, context) {
    const swal = inject("$swal");
    const files = reactive({
      file: null,
      collection_name: props.collection_name,
      size: props.size,
      model_id: props.model_id,
    });

    function updateValue(event) {
      // Get a reference to the file
      const file = event.target.files[0];
      files.file = file;

      let serializedPost = new FormData();
      for (let item in files) {
        if (files.hasOwnProperty(item)) {
          serializedPost.append(item, files[item]);
        }
      }
      axiosClient
        .post(props.url, serializedPost)
        .then((res) => {
          // document.getElementById("profilePic").src = res.data.original_url;
          const data = [];
          data.push(res.data);
          context.emit("update:modelValue", data);
        })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Upload error",
            text: error.response.data.message,
          });
        })
        .finally(() => {
          //
        });
    }

    return {
      updateValue,
    };
  },
};
</script>

<style>
.shadow {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
}
.profile-pic-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pic-holder-avatar {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pic-holder-other {
  text-align: center;
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pic-holder-avatar .pic {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder-other .pic {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.pic-holder-avatar .upload-file-block,
.pic-holder-avatar .upload-loader,
.pic-holder-other .upload-file-block,
.pic-holder-other .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.3);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder-avatar .upload-file-block,
.pic-holder-other .upload-file-block {
  cursor: pointer;
}

.pic-holder-avatar:hover .upload-file-block,
.pic-holder-other:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.pic-holder-avatar.uploadInProgress .upload-file-block,
.pic-holder-other.uploadInProgress .upload-file-block {
  display: none;
}

.pic-holder-avatar.uploadInProgress .upload-loader,
.pic-holder-other.uploadInProgress .upload-loader {
  opacity: 1;
}

/* Snackbar css */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 14px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
