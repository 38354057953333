<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5">
      <p class="mb-2 header-1-p">How it works?</p>
      <p class="header-3-s">
        Here’s exactly how PXL works, from a creator or designer point of view:
      </p>

      <div class="row mt-5">
        <div class="col-12">
          <Image class="how-it-works-img" src="/img/how-it-works.png"></Image>
        </div>
      </div>

      <p class="header-3-s mt-5">
        Then this happens (the customers point of view):
      </p>

      <div class="row mt-5">
        <div class="col-12">
          <Image class="how-it-works-img" src="/img/how-it-works2.png"></Image>
        </div>
      </div>
      <!-- <ul class="mt-5 text-3-s">
        <li class="mb-4 mt-3">
          You sign up for a PXL account and upload your artwork.
        </li>
        <li class="mb-4">
          You decide what products to list that artwork on – including deciding
          on the colors, position etc.
        </li>
        <li class="mb-4">
          You give your design a title, a description, and include as many
          relevant tags (keywords) as possible.
        </li>
        <li class="mb-4">
          You publish your product: it is then available for sale on PXL.shop
        </li>
      </ul>

      <p class="mt-5 mb-5 header-3-s">
        Then this happens (the customers point of view):
      </p>

      <ul class="mt-5 text-3-s">
        <li class="mb-4 mt-3">
          A customer searches for their favorite thing on PXL and sees your
          design.
        </li>
        <li class="mb-4">
          The customer likes your design, so he purchases your design on a
          T-Shirt.
        </li>
        <li class="mb-4">
          PXL prints your artwork onto the T-Shirt and ships it to the customer.
        </li>
        <li class="mb-4">
          PXL credits your account with a percentage of the sale price – and
          once a month PXL pays out your Profit to your PayPal or bank account.
        </li>
      </ul>

      <p class="mt-5 mb-5 header-3-s">
        Of course, there’s a lot more detail we could go into, but that’s the
        basic process.
      </p>

      <h4 class="mt-5 mb-2 header-3-s">Categories: (Themes)</h4>

      <ul class="nav flex-column mt-4 mb-5 nav-pills themes">
        <li class="nav-item">
          <a class="nav-link">General</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Movies & TV Shows</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Anime</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Sport</a>
        </li>

        <li class="nav-item">
          <a class="nav-link">Music </a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Vintage</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Calligraphy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Games</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Animals</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Kids</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Cars</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Memes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"> Relationship</a>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
