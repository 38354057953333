<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container py-5">
      <div class="row">
        <div
          class="col-md-6"
          v-for="product in artwork.products"
          :key="product.index"
        >
          <div class="drawingArea">
            {{ product.name_en }}
            <canvas :id="product.name_en" width="530" height="630"></canvas>
          </div>
          <div
            v-for="variation in product.variations.filter(
              (variations) => variations.name_en.toLowerCase() == 'color'
            )"
            :key="variation.index"
            class="variation-plate"
          >
            <button
              v-for="option in variation.variation_options"
              :key="option.index"
              class="btn color-option my-2 mx-1"
              :style="'background-color: ' + option.name_en"
              @click="
                changeColor(product.name_en, option.name_en, artwork.initial)
              "
            >
              &nbsp;
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <form
                @submit.prevent="
                  storeArtworkInitial(artwork.id, artwork.initial)
                "
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12"></div>
                  <div class="col-md-12 mt-4 d-flex justify-content-end">
                    <base-button native-type="submit" size="m" type="success"
                      >Save</base-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import useArtworks from "@/composables/Artworks";
// import useAuth from "@/composables/auth";
import useMockupEditor from "@/composables/MockupEditor";

import { forEach } from "lodash";

export default {
  components: {
  },
  props: {
    data: {
      required: true,
    },
  },
  setup(props) {
    const {
      storeArtwork,
      storeArtworkInitial,
      validationErrors,
      isLoading,
    } = useArtworks();
    // const { logedinUser } = useAuth();
    const {
      setUpListMockupEditor,
      addImage,
      changeColor,
      saveDesgin,
    } = useMockupEditor();
    const router = useRouter();

    const artwork = ref({});

    onMounted(async () => {
      artwork.value = JSON.parse(props.data);
      if (!artwork.value.initial) {
        artwork.value.initial = {};

        forEach(artwork.value.products, (product) => {
          artwork.value.initial[product.name_en] = {
            positions: {
              top: 0,
              left: 0,
            },
            scale: {
              x: 0,
              y: 0,
            },
            color: "",
          };
        });
      }
      setUpListMockupEditor(artwork);
    });

    return {
      router,
      storeArtwork,
      storeArtworkInitial,
      validationErrors,
      isLoading,
      addImage,
      changeColor,
      saveDesgin,
      artwork,
    };
  },
};
</script>
<style>
.mockup {
  width: 100%;
  /* background-repeat: no-repeat;
  background-size: auto; */
}
.canvas-container {
  margin: 0 !important;
}
.drawingArea canvas {
  mix-blend-mode: multiply !important;
}
</style>
