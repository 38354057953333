import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useCountries() {
  const countries = ref({});
  const country = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getCountries = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/countries?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        countries.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getCountry = async (id) => {
    axiosClient.get("/countries/" + id).then((response) => {
      country.value = response.data.data;
    });
  };

  const storeCountry = async (country) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    // let serializedCountry = new FormData();
    // for (let item in country) {
    //   if (country.hasOwnProperty(item)) {
    //     serializedCountry.append(item, country[item]);
    //   }
    // }
    let params = serialize(country, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/countries", params)
      .then((response) => {
        router.push({ name: "countries.index" });
        swal({
          icon: "success",
          title: "Country saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateCountry = async (country) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/countries/" + country.id, country)
      .then((response) => {
        router.push({ name: "countries.index" });
        swal({
          icon: "success",
          title: "Country saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteCountry = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/countries/" + id)
          .then((response) => {
            getCountries();
            router.push({ name: "countries.index" });
            swal({
              icon: "success",
              title: "Country deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    countries,
    country,
    getCountries,
    getCountry,
    storeCountry,
    validationErrors,
    isLoading,
    updateCountry,
    deleteCountry,
  };
}
