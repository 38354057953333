import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useCurrencies() {
  const currencies = ref({});
  const currency = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const currencyProcessingFiles = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const getCurrencies = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_code = "",
    search_country = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/currencies?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_code=" +
          search_code +
          "&search_country=" +
          search_country +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        currencies.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getCurrency = async (id) => {
    await axiosClient.get("/currencies/" + id).then((response) => {
      currency.value = response.data.data;
    });
  };

  const storeCurrency = async (currency) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(currency, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/currencies", params)
      .then((response) => {
        router.push({ name: "currencies.index" });
        swal({
          icon: "success",
          title: "Currency saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateCurrency = async (currency) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/currencies/" + currency.id, currency)
      .then((response) => {
        router.push({ name: "currencies.index" });
        swal({
          icon: "success",
          title: "Currency saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteCurrency = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/currencies/" + id)
          .then((response) => {
            getCurrencies();
            router.push({ name: "currencies.index" });
            swal({
              icon: "success",
              title: "Currency deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };


  const storeCurrencyMedia = async (files) => {
    let serializedCurrency = new FormData();
    for (let item in files) {
      if (files.hasOwnProperty(item)) {
        serializedCurrency.append(item, files[item]);
      }
    }

    axiosClient
      .post("/currencies/media", serializedCurrency)
      .then((response) => {})
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };


  const storeCurrencyFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (currencyProcessingFiles.value[collection_name]) return;

    currencyProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedUser = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedUser.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/currencies/media", serializedUser)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (currencyProcessingFiles.value[collection_name] = false));
    });
  };

  return {
    currencies,
    currency,
    getCurrencies,
    getCurrency,
    storeCurrency,
    validationErrors,
    isLoading,
    updateCurrency,
    deleteCurrency,
    storeCurrencyMedia,
    storeCurrencyFiles,
    currencyProcessingFiles,
  };
}
