<template>
    <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-6 col-lg-2" v-for="number in 6" :key="number">
            <div class="card-custom bg-custom text-white border-0 m-0 mb-2">
                <img src="/img/brand/white-logo.png" class="tst-img" alt=" ">
            </div>
        </div>
    </div>
</template>


<style scoped>
.card-img {
    height: 100%;
}

.bg-custom {
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}

.card-img .card-custom {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.card:hover {
    cursor: pointer;
}

.card-custom {
    width: 180px;
    height: 180px;
}

.tst-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    animation: cardImageOpacity 2s infinite !important;
}

@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #f7f7f7;
    }

    50% {
        background-position: 100% 0;
        background-color: #f7f7f7;
    }

    100% {
        background-position: 200% 0;
        background-color: #f7f7f7;
    }
}

@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 20%;
    }

    50% {
        background-position: 100% 0;
        opacity: 60%;
    }


    100% {
        background-position: 200% 0;
        opacity: 90%;
    }
}
</style>