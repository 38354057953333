// import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/Inputs/BaseCheckbox";
import BaseDropdown from "../components/BaseDropdown";
import BaseHeader from "../components/BaseHeader";
import BaseInput from "../components/Inputs/BaseInput";
import BaseNav from "../components/Navbar/BaseNav";
import BasePagination from "../components/BasePagination";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "../components/Inputs/BaseRadio";
import BaseSwitch from "../components/BaseSwitch";
import Card from "../components/Cards/Card";
import StatsCard from "../components/Cards/StatsCard";
import { ElTooltip, ElPopover, ElLoading } from "element-plus";

import CurrencySwitcher from "../components/Currency/Switcher.vue";

import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Badge from 'primevue/badge';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';
import Image from 'primevue/image';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import md from "@/components/md";

const GlobalComponents = {
  install(app) {
    app.component("badge", Badge);
    app.component("base-button", BaseButton);
    app.component("base-checkbox", BaseCheckbox);
    app.component("base-dropdown", BaseDropdown);
    app.component("base-input", BaseInput);
    app.component("base-nav", BaseNav);
    app.component("base-header", BaseHeader);
    app.component("base-pagination", BasePagination);
    app.component("base-progress", BaseProgress);
    app.component("base-radio", BaseRadio);
    app.component("base-switch", BaseSwitch);
    app.component("card", Card);
    app.component("stats-card", StatsCard);
    app.use(ElTooltip);
    app.use(ElPopover);
    app.use(ElLoading);
    app.component("FileUpload", FileUpload);
    app.component("ProgressBar", ProgressBar);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Badge", Badge);
    app.component("Dropdown", Dropdown);
    app.component("MultiSelect", MultiSelect);
    app.component("Button", Button);
    app.component("InputText", InputText);
    app.component("Password", Password);
    app.component("Image", Image);
    app.component("Checkbox", Checkbox);
    app.component("Calendar", Calendar);
    app.component("InputNumber", InputNumber);
    app.component("OverlayPanel", OverlayPanel);
    app.component("Dialog", Dialog);
    app.component("DataTable ", DataTable);
    app.component("Column", Column);
    app.component("md", md);
    app.component("CurrencySwitcher", CurrencySwitcher);
  },
};

export default GlobalComponents;
