<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_name_en" class="form-control" placeholder="Search name" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_code" class="form-control" placeholder="Search code" type="text" />
                  </div>

                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page"
                    :options="per_page_options" optionLabel="text" optionValue="value" />
                </div>
                <div>
                  <Button v-if="can('currency_create')" @click.prevent="
                    router.push({
                      name: 'currencies.create',
                    })
                  " label="New" class="p-button-success" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'asc' &&
                                orderColumn === 'id',
                            }"><i :class="{
  'c-info':
    orderDirection === 'asc' &&
    orderColumn === 'id',
}" class="fas fa-long-arrow-alt-up"></i></span>
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'desc' &&
                                orderColumn === 'id',
                            }"><i :class="{
  'c-info':
    orderDirection === 'desc' &&
    orderColumn === 'id',
}" class="fas fa-long-arrow-alt-down"></i></span>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('name_en')">
                          <div :class="{
                            '': orderColumn === 'name_en',
                          }">
                            Name
                          </div>
                          <div role="button" class="select-none">
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'asc' &&
                                orderColumn === 'name_en',
                            }"><i :class="{
  'c-info':
    orderDirection === 'asc' &&
    orderColumn === 'name_en',
}" class="fas fa-long-arrow-alt-up"></i></span>
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'desc' &&
                                orderColumn === 'name_en',
                            }"><i :class="{
  'c-info':
    orderDirection === 'desc' &&
    orderColumn === 'name_en',
}" class="fas fa-long-arrow-alt-down"></i></span>
                          </div>
                        </div>
                      </th>
                      <!-- <th>
                        <div
                          class="d-flex justify-content-between"
                          @click="updateOrdering('name_ar')"
                        >
                          <div
                            :class="{
                              '': orderColumn === 'name_ar',
                            }"
                          >
                            Name Ar
                          </div>
                          <div role="button" class="select-none">
                            <span
                              :class="{
                                'd-none':
                                  orderDirection !== '' &&
                                  orderDirection !== 'asc' &&
                                  orderColumn === 'name_ar',
                              }"
                              ><i
                                :class="{
                                  'c-info':
                                    orderDirection === 'asc' &&
                                    orderColumn === 'name_ar',
                                }"
                                class="fas fa-long-arrow-alt-up"
                              ></i
                            ></span>
                            <span
                              :class="{
                                'd-none':
                                  orderDirection !== '' &&
                                  orderDirection !== 'desc' &&
                                  orderColumn === 'name_ar',
                              }"
                              ><i
                                :class="{
                                  'c-info':
                                    orderDirection === 'desc' &&
                                    orderColumn === 'name_ar',
                                }"
                                class="fas fa-long-arrow-alt-down"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </th> -->
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('code')">
                          <div :class="{
                            '': orderColumn === 'code',
                          }">
                            Code
                          </div>
                          <div role="button" class="select-none">
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'asc' &&
                                orderColumn === 'code',
                            }"><i :class="{
  'c-info':
    orderDirection === 'asc' &&
    orderColumn === 'code',
}" class="fas fa-long-arrow-alt-up"></i></span>
                            <span :class="{
                              'd-none':
                                orderDirection !== '' &&
                                orderDirection !== 'desc' &&
                                orderColumn === 'code',
                            }"><i :class="{
  'c-info':
    orderDirection === 'desc' &&
    orderColumn === 'code',
}" class="fas fa-long-arrow-alt-down"></i></span>
                          </div>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="currency in currencies.data" :key="currency.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ currency.id }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ currency.name_en }}</span>
                      </td>
                      <!-- <td class="text-sm font-weight-normal">
                        <span>{{ currency.name_ar }}</span>
                      </td> -->
                      <td class="text-sm font-weight-normal">
                        <span>{{ currency.code }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button v-if="can('currency_show')" @click.prevent="
                          router.push({
                            name: 'currencies.show',
                            params: { id: currency.id },
                          })
                        " class="p-button-info" icon="fas fa-eye" />
                        <Button v-if="can('currency_edit')" @click.prevent="
                          router.push({
                            name: 'currencies.edit',
                            params: { id: currency.id },
                          })
                        " class="p-button-warning" icon="fas fa-pen-fancy" />
                        <Button v-if="can('currency_delete')" @click.prevent="deleteCurrency(currency.id)" class="p-button-danger"
                          icon="fas fa-trash" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ currencies?.meta?.from }} to
                    {{ currencies?.meta?.to }} of
                    {{ currencies?.meta?.total }}
                  </p>
                </div>
                <div>
                  <pagination :data="currencies" :limit="1" @pagination-change-page="
  (page) =>
    getCurrencies(
      page,
      search_id,
      search_name_en,
      search_name_ar,
      search_code,
      search_global,
      orderColumn,
      orderDirection,
      per_page
    )
                  " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import useCurrencies from "@/composables/Currencies";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";


export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = 1;
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_code = ref("");
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");

    const router = useRouter();
    const { currencies, getCurrencies, deleteCurrency } = useCurrencies();
    const { can } = useAbility();
    onMounted(async () => {
      await getCurrencies();
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getCurrencies(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_code.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(search_id, (current) => {
      getCurrencies(
        1,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_code.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_en, (current) => {
      getCurrencies(
        1,
        search_id.value,
        current,
        search_name_ar.value,
        search_code.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_ar, (current) => {
      getCurrencies(
        1,
        search_id.value,
        search_name_en.value,
        current,
        search_code.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_code, (current) => {
      getCurrencies(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_global, (current) => {
      getCurrencies(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_code.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getCurrencies(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_code.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });

    return {
      router,
      currencies,
      getCurrencies,
      deleteCurrency,
      search_id,
      search_name_en,
      search_name_ar,
      search_code,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
