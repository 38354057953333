<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Avatar</label>
                    <div v-if="user?.avatar && user?.avatar.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in user?.avatar" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                          alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Name</label>
                    <input v-model="user.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">username</label>
                    <input v-model="user.username" class="form-control" id="username" type="text" name="username" />
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Email</label>
                    <input v-model="user.email" class="form-control" id="email" type="text" name="email" />
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Mobile</label>
                    <div class="mobile-input-container">
                      <Dropdown v-model="user.mobilecode" :filter="true" :options="userMeta?.country"
                        optionLabel="mobilecode" optionValue="mobilecode" placeholder="Code" style="height: 2.87rem;" />
                      <input v-model="user.mobile" class="form-control mobile-input" id="mobile" type="tel"
                        pattern="[0-9]{8,10}" name="mobile" maxlength="10"/>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Bio</label>
                    <textarea v-model="user.about_en" class="form-control" name="about" id="about" rows="5"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
            <div class="card">
              <div class="card-header d-flex flex-row">
                <h4>Social media</h4>
              </div>
              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Link</th>
                      <th>Type</th>
                    </tr>
                    <tr v-for="(social, index) in user?.socials" :key="index">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        <input v-model="social.link" class="form-control" id="social-link" type="url"
                          name="social-link" />
                      </td>
                      <td>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="social.type_id"
                          :options="userMeta?.social_types" optionLabel="name_en" optionValue="id" />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="card">
              <div class="card-header d-flex flex-row">
                <h4>Address</h4>
              </div>
              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Villa</th>
                      <th>Flat</th>
                      <th>Road</th>
                      <th>Block</th>
                      <th>Post Code</th>
                      <th>Country</th>
                      <th>City</th>
                    </tr>
                    <tr v-for="(address, index) in user?.address" :key="index">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        <input v-model="address.villa" class="form-control" id="address-villa" type="text"
                          name="address-villa" />
                      </td>
                      <td>
                        <input v-model="address.flat" class="form-control" id="address-flat" type="text"
                          name="address-flat" />
                      </td>
                      <td>
                        <input v-model="address.road" class="form-control" id="address-road" type="text"
                          name="address-road" />
                      </td>
                      <td>
                        <input v-model="address.block" class="form-control" id="address-block" type="text"
                          name="address-block" />
                      </td>
                      <td>
                        <input v-model="address.post_code" class="form-control" id="address-post_code" type="text"
                          name="address-post_code" />
                      </td>
                      <td>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="address.country_id"
                          :options="userMeta?.country" optionLabel="name_en" optionValue="id" />
                      </td>
                      <td>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="address.city_id" :options="userMeta?.city.filter(
                          (city) =>
                            city?.country_id == address?.country_id
                        )" optionLabel="name_en" optionValue="id" />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h4>Financial</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">IBAN</label>
                    <input v-model="user.iban" class="form-control" id="iban" type="text" name="iban" />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Commsion</label>
                    <input v-model="user.commission" class="form-control" id="commission" type="number"
                      name="commission" />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Rate</label>
                    <input v-model="user.rate" class="form-control" id="rate" type="number" name="rate" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h4>Settings</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="binary">Is Featured</label>
                      <Checkbox name="Is Featured" v-model="user.is_featured" :binary="true" />
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="binary">Is Artist</label>
                      <Checkbox name="Is Artist" v-model="user.is_artist" :binary="true" />
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="status_id">Status</label>
                      <Dropdown class="w-100" :filter="true" :showClear="true" v-model="user.status_id"
                        :options="userMeta.status" optionLabel="name_en" optionValue="id" />
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="type_id">Type</label>
                      <Dropdown class="w-100" :filter="true" :showClear="true" v-model="user.type_id"
                        :options="userMeta.type" optionLabel="name_en" optionValue="id" />
                    </div>
                  </div>

                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="roles">Roles</label>
                      <MultiSelect v-model="user.roles" :filter="true" :showClear="true" :options="userMeta?.roles"
                        optionLabel="name_en" optionValue="id" display="chip" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button label="Cancel" @click.prevent="
            router.push({
              name: 'users.index',
            })
            " class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useUsers from "@/composables/Users";

import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import UploadFiles from "@/components/UploadFiles";


export default {
  components: {
    UploadFiles,
    DropzoneFileUpload,
  },
  setup() {
    const swal = inject("$swal");
    const {
      getUser,
      user,
      userMeta,
      deleteUserAddress,
      deleteUserSocial,
      updateUser,
      validationErrors,
      isLoading,
    } = useUsers();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();

    onMounted(async () => {
      await getUser(route.params.id);
    });

    const addAddress = async (address) => {
      if (address.length < 5) {
        address.push({
          villa: "",
          flat: "",
          road: "",
          block: "",
          post_code: "",
          country: "",
          city: "",
        });
      } else {
        swal({
          icon: "warning",
          title: "You reached the maximum number of addresses",
        });
      }
    };

    const addSocial = async (social) => {
      if (social.length < 5) {
        social.push({
          link: "",
          type_id: null,
        });
      } else {
        swal({
          icon: "warning",
          title: "You reached the maximum number of social media",
        });
      }
    };

    return {
      router,
      user,
      userMeta,
      validationErrors,
      isLoading,
      updateUser,
      deleteUserAddress,
      deleteUserSocial,
      can,
      addAddress,
      addSocial,
    };
  },
};
</script>

<style scoped>
.mobile-input-container {
  display: flex;
  align-items: center;
}

.mobile-input {
  flex-grow: 1;
  /* Allow the first input to grow and take the available space */
  margin-right: 0.5rem;
  /* Add some spacing between the inputs */
}
</style>
