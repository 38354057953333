<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="storeGlobalSetting(globalSetting)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Key <md /></label>
                      <input v-model="globalSetting.s_key" class="form-control" id="s_key" type="text" name="s_key" />
                      <div class="row" v-for="message in validationErrors['s_key']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label class="form-control-label" for="s_type">Type <md /></label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="globalSetting.s_type"
                          :options="globalSettingMeta?.s_types" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['s_types']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Label <md /></label>
                      <input v-model="globalSetting.s_label" class="form-control" id="s_label" type="text"
                        name="s_label" />
                      <div class="row" v-for="message in validationErrors['s_label']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Value <md /></label>
                      <input v-model="globalSetting.s_value" class="form-control" id="s_value" type="text"
                        name="s_value" />
                      <div class="row" v-for="message in validationErrors['s_value']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Order <md /></label>
                      <input v-model="globalSetting.s_order" class="form-control" id="s_order" type="text"
                        name="s_order" />
                      <div class="row" v-for="message in validationErrors['s_order']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button @click.prevent="
  router.push({
    name: 'globalSettings.index',
  })
            " label="Cancel" class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import useGlobalSettings from "@/composables/GlobalSettings";



export default {
  components: {
  },
  setup() {
    const globalSetting = reactive({
      s_key: "",
      s_type: "",
      s_label: "",
      s_value: "",
      s_order: "",
    });

    const { storeGlobalSetting, validationErrors, isLoading, getGlobalSettingMeta, globalSettingMeta } = useGlobalSettings();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(async () => {
      await getGlobalSettingMeta();
    });

    return {
      router,
      globalSetting,
      storeGlobalSetting,
      validationErrors,
      isLoading,
      globalSettingMeta,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>

