<template>
    <Galleria :value="images" containerStyle="width: 640px; height:434px" :showThumbnails="false"
        :responsiveOptions="responsiveOptions">
        <template #item="slotProps">
            <div class="thumbnail-container">
                <div class="thumbnail-wrapper">
                    <div>
                        <span style="display:none">{{ }}</span>
                        <div id="artwork-image-container" class="col-md-12">
                            <div class="row w-100 m-0">
                                <div class="col-12 m-0 p-0">
                                    <div class="mockup bg-custom">
                                        <img class="mockup-image img-animation" :src="slotProps.item" alt="">
                                        <div class="drawing-area">
                                            <div class="canvas-container">
                                                <canvas class="mockup-canvas"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Galleria>
</template>


<script setup>
import Galleria from 'primevue/galleria';

const images = ["/img/brand/45logo-light.png", "/img/brand/45logo-light.png", "/img/brand/45logo-light.png"]
const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
    }
]
</script>
    
   



<style scoped>
/* start */
.thumbnail-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    max-width: 350px;
}

.thumbnail-wrapper {
    text-align: center;
}






@media (max-width: 767px) {
    .thumbnail-container {
        flex-wrap: wrap;
        gap: 5px;
    }

}

.img-animation {
    animation: cardImageOpacity 2s infinite !important;
}

@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 65%;
    }

    70% {
        background-position: 100% 0;
        opacity: 80%;
    }


    100% {
        background-position: 200% 0;
        opacity: 100%;
    }
}



@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #f7f7f7;
    }

    50% {
        background-position: 100% 0;
        background-color: #f7f7f7;
    }

    100% {
        background-position: 200% 0;
        background-color: #f7f7f7;
    }
}

.bg-custom {
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}




/* end */
</style>