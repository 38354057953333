<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div v-if="isLoading == false" class="container py-5">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 mb-5 d-block d-lg-none">
              <div class="d-flex justify-content-between w-100">
                <div>
                  <p class="header-1-p">{{ artwork?.name_en }}</p>
                  <div>
                    <span class="text-3-g"> by </span>
                    <span class="header-3-s">{{ artwork?.user?.name_en }} </span>
                  </div>
                </div>
                <div>
                  <div class="btn-fav border rounded-circle pointer">
                    <img @click.prevent="addToWishlist(artwork.id, isLoggedIn)" :src="wishlistIDs.includes(artwork.id)
                      ? 'img/favorite.svg'
                      : 'img/non-favorite.svg'
                      " />
                  </div>
                </div>
              </div>
            </div>
            <!-- canvas -->
            <!-- <div id="artwork-image-container" class="col-md-12">
              <div v-if="artwork?.products" class="row w-100 m-0">
                <div
                  v-for="(product, productIndex) in artwork?.products?.filter((product) => product.name_en == selectedOptions?.options?.product)"
                  :key="productIndex" class="col-12 m-0 p-0">
                  <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-mockup'" class="mockup">
                    <img ref="imageList" :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-image'"
                      class="mockup-image" :src="product?.front_image[0]?.original_url" alt="">
                    <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-drawing-area'" class="drawing-area">
                      <div ref="canvasContainer"
                        :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas-container'"
                        class="canvas-container">
                        <canvas :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas'"
                          class="mockup-canvas"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- end of canvas -->

            <div class="mt-3 mockup mb-0">
              <div v-if="isLoadingData">
                <ArtworkSkeleton />
              </div>
              <Galleria v-else :value="imageUrls" :responsiveOptions="responsiveOptions" :numVisible="5"
                containerStyle="width: 640px; height:434px" :showThumbnails="false" :showIndicators="true"
                onSlideChange="setUpShow" :changeItemOnIndicatorHover="true">
                <template #item="slotProps">
                  <div class="thumbnail-container">
                    <div class="thumbnail-wrapper">
                      <div v-if="slotProps?.item == 'firstItem'">
                        <span style="display:none">{{ readyToshow() }}</span>
                        <div id="artwork-image-container" class="col-md-12">
                          <div v-if="artwork?.products" class="row w-100 m-0">
                            <div
                              v-for="(product, productIndex) in artwork?.products?.filter((product) => product.name_en == selectedOptions?.options?.product)"
                              :key="productIndex" class="col-12 m-0 p-0">
                              <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-mockup'" class="mockup">
                                <img ref="imageList" :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-image'"
                                  class="mockup-image" :src="product?.front_image[0]?.webp" alt="">
                                <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-drawing-area'"
                                  class="drawing-area">
                                  <div ref="canvasContainer"
                                    :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas-container'"
                                    class="canvas-container">
                                    <canvas :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas'"
                                      class="mockup-canvas"></canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <inner-image-zoom className="mockup-image" :src="slotProps?.item" :zoomSrc="slotProps?.item"
                          zoomType="hover" fadeDuration="150" />
                        <!-- <img class="mockup-image" :src="slotProps?.item" alt="" style="object-fit: scale-down;"/> -->
                      </div>
                    </div>
                  </div>
                </template>
              </Galleria>
            </div>
            <!-- end of new gallery -->

          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 d-none d-lg-block">
              <div class="d-flex justify-content-between w-100">
                <div>
                  <p class="header-1-p">{{ artwork?.name_en }}</p>
                  <div>
                    <span class="text-3-g"> by </span>
                    <span class="header-3-s">{{ artwork?.user?.name_en }} </span>
                  </div>
                </div>
                <div>
                  <div class="btn-fav border rounded-circle pointer">
                    <img @click.prevent="addToWishlist(artwork.id, isLoggedIn)" :src="wishlistIDs.includes(artwork.id)
                      ? 'img/favorite.svg'
                      : 'img/non-favorite.svg'
                      " />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="(isMobile || isTablet)">
              <div v-for="(product, productIndex) in artwork.products?.filter(
                (product) =>
                  product.name_en == selectedOptions?.options?.product
              )" :key="product.id" class="col-md-12">
                <div v-for="variation in product.variations" :key="variation.index" class="mt-3">
                  <p class="text-m-2-s pb-2">{{ variation.name_en }}</p>
                  <div class="d-flex justify-content-start">
                    <div v-for="option in variation.variation_options" :key="option.index" class="mr-2">
                      <div v-if="variation.name_en.toLowerCase() == 'color'">

                        <span class="dot d-flex align-items-center justify-content-center" :class="{
                          selected: selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] == option.name_en,
                        }" :style="[{ 'background-color': option.name_en }]"
                          @click="selectVariations(variation.name_en.toLowerCase(), option.name_en, product);">
                          <b v-if="selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] == option.name_en
                          " style="color: black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="7.411"
                              viewBox="0 0 10.828 7.411">
                              <path id="Path_281" data-name="Path 281" d="M17.486,10.707l-5,5-3-2.994"
                                transform="translate(-8.072 -9.293)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                            </svg>
                          </b>
                        </span>
                      </div>
                      <div v-else>
                        <!-- <base-button
                        @click="
                          selectVariations(
                            variation.name_en.toLowerCase(),
                            option.name_en,
                            product
                          )
                        "
                        :outline="
                          selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] != option.name_en
                        "
                        type="secondary"
                      >
                        <div class="pl-2 pr-2">{{ option.name_en }}</div>
                      </base-button> -->
                        <button class="btn-custom-light" @click="
                          selectVariations(
                            variation.name_en.toLowerCase(),
                            option.name_en,
                            product
                          )
                          " :class="{
                            active:
                              selectedOptions['options'][
                              variation.name_en.toLowerCase()
                              ] == option.name_en,
                          }" style="width: 48px">
                          {{ option.name_en }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-12 mt-3">
              <span class="header-1-s" :class="{ 'text-c': selectedOptions.discount > 0 }">{{ currency?.code }} {{
                Number(selectedOptions.price * currency?.exchange_rate).toFixed(3) }}</span>
              <span v-if="selectedOptions.discount > 0" class="header-1-p pl-2">
                {{ currency?.code }} {{ Number((selectedOptions.price -
                  selectedOptions.discount) * currency?.exchange_rate).toFixed(3)
                }}
              </span>

              <p class="text-2-s mt-3">
                {{ artwork?.description_en }}
              </p>
            </div>
            <div class="col-md-12 mt-3">
              <p class="text-m-2-s pb-2">Product</p>
              <button class="btn-custom-light mr-2" v-for="(product, productIndex) in artwork.products"
                :key="productIndex" @click.prevent="
                  selectVariations('product', product.name_en, product)
                  " :class="{
                    active: product.name_en == selectedOptions?.options?.product,
                  }">
                {{ product.name_en }}
              </button>
            </div>
            <div v-if="!(isMobile || isTablet)">
              <div v-for="(product, productIndex) in artwork.products?.filter(
                (product) =>
                  product.name_en == selectedOptions?.options?.product
              )" :key="product.id" class="col-md-12">
                <div v-for="variation in product.variations" :key="variation.index" class="mt-3">
                  <p class="text-m-2-s pb-2">{{ variation.name_en }}</p>
                  <div class="d-flex justify-content-start">
                    <div v-for="option in variation.variation_options" :key="option.index" class="mr-2">
                      <div v-if="variation.name_en.toLowerCase() == 'color'">

                        <span class="dot d-flex align-items-center justify-content-center" :class="{
                          selected: selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] == option.name_en,
                        }" :style="[{ 'background-color': option.name_en }]"
                          @click="selectVariations(variation.name_en.toLowerCase(), option.name_en, product);">
                          <b v-if="selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] == option.name_en
                          " style="color: black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="7.411"
                              viewBox="0 0 10.828 7.411">
                              <path id="Path_281" data-name="Path 281" d="M17.486,10.707l-5,5-3-2.994"
                                transform="translate(-8.072 -9.293)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                            </svg>
                          </b>
                        </span>
                      </div>
                      <div v-else>
                        <!-- <base-button
                        @click="
                          selectVariations(
                            variation.name_en.toLowerCase(),
                            option.name_en,
                            product
                          )
                        "
                        :outline="
                          selectedOptions['options'][
                            variation.name_en.toLowerCase()
                          ] != option.name_en
                        "
                        type="secondary"
                      >
                        <div class="pl-2 pr-2">{{ option.name_en }}</div>
                      </base-button> -->
                        <button class="btn-custom-light" @click="
                          selectVariations(
                            variation.name_en.toLowerCase(),
                            option.name_en,
                            product
                          )
                          " :class="{
                            active:
                              selectedOptions['options'][
                              variation.name_en.toLowerCase()
                              ] == option.name_en,
                          }" style="width: 48px">
                          {{ option.name_en }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-md-12 mt-3">
              <p class="text-m-2-s pb-2">Quantity</p>
            </div>
            <div class="col-md-12">
              <div class="
                                      col-4
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                      btn-quantity
                                    ">
                <span class="btn-q" @click.prevent="dcreaseQty()">
                  <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                      <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                        stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                    <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                  </svg>
                </span>
                <span class="text-quantity font-weight-bold">{{
                  selectedOptions.quantity
                }}</span>
                <span class="btn-q" @click.prevent="increaseQty()">
                  <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                      <line id="Line_20" data-name="Line 20" y2="9" transform="translate(14.5 10)" fill="none"
                        stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                      <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                        stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                    <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div v-if="cartMessage" class="text-danger">{{ cartMessage }}</div>
              <base-button @click="addToCart(isLoggedIn, logedinUser.id)" block type="primary"
                :disabled="!checkCartValidity">Add to cart</base-button>
            </div>
            <div class="col-md-12 mt-4">
              <div class="row">
                <div class="col-md-12">
                  <div class="accordion-tabs">
                    <div @click.prevent="activeAccordionTab('Shipping')" class="accordion-tab"
                      :class="{ active: activeTab == 'Shipping' }">
                      <div class="
                                              accordion-tab-header
                                              d-flex
                                              justify-content-between
                                              align-items-center
                                            ">
                        <div class="
                                                accordion-tab-details
                                                d-flex
                                                justify-content-between
                                                align-items-center
                                                pt-3
                                                pb-3
                                              ">
                          <div class="details-icon">
                            <svg id="Delivery_Truck_Fast" data-name="Delivery, Truck, Fast"
                              xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                              <g id="Group_479" data-name="Group 479" transform="translate(2.333 4.667)">
                                <path id="Path_290" data-name="Path 290"
                                  d="M19.155,18.373a1.848,1.848,0,1,1-2.613,0,1.847,1.847,0,0,1,2.613,0"
                                  transform="translate(0.333 -1.695)" fill="none" stroke="#1a1e1d"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_291" data-name="Path 291"
                                  d="M8.155,18.373a1.848,1.848,0,1,1-2.613,0,1.847,1.847,0,0,1,2.613,0"
                                  transform="translate(-1.5 -1.695)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_292" data-name="Path 292"
                                  d="M11.333,4H16a1.167,1.167,0,0,1,1.167,1.167V16.833H2" transform="translate(-2 -4)"
                                  fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2" />
                                <path id="Path_293" data-name="Path 293"
                                  d="M5.5,20.485H3.167A1.167,1.167,0,0,1,2,19.319V13" transform="translate(-2 -2.5)"
                                  fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2" />
                                <path id="Path_294" data-name="Path 294"
                                  d="M15,7h5.043a1.165,1.165,0,0,1,1.083.734L23,12.417a2.341,2.341,0,0,1,.167.866v6.939A1.167,1.167,0,0,1,22,21.389H19.864"
                                  transform="translate(0.167 -3.5)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_295" data-name="Path 295" d="M17.305,19.42H8.17"
                                  transform="translate(-0.972 -1.43)" fill="none" stroke="#1a1e1d"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_296" data-name="Path 296" d="M22.667,14.667H18V10h3.733"
                                  transform="translate(0.667 -3)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2" />
                                <line id="Line_22" data-name="Line 22" x2="5.833" fill="none" stroke="#1a1e1d"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <line id="Line_23" data-name="Line 23" x2="3.5" transform="translate(0 3.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <line id="Line_24" data-name="Line 24" x1="1.167" transform="translate(0 7)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                              </g>
                              <path id="Path_297" data-name="Path 297" d="M0,0H28V28H0Z" fill="none" />
                            </svg>
                          </div>
                          <div class="details-title pl-3">
                            <span class="header-2-s">Shipping</span>
                          </div>
                        </div>
                        <div class="accordion-tab-icon">
                          <svg id="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Path_231" data-name="Path 231" d="M0,0H24V24H0Z" fill="none" />
                            <path id="Path_232" data-name="Path 232" d="M8,10l4.8,4.8L17.6,10"
                              transform="translate(-0.8 -0.4)" fill="none" stroke="#323232" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                          </svg>
                        </div>
                      </div>
                      <div class="accordion-tab-content pb-3">
                        <span class="text-3-g" v-html="settingsList['shipping-information']">
                        </span>
                      </div>
                    </div>
                    <div @click.prevent="activeAccordionTab('Size chart')" class="accordion-tab"
                      :class="{ active: activeTab == 'Size chart' }">
                      <div class="
                                              accordion-tab-header
                                              d-flex
                                              justify-content-between
                                              align-items-center
                                            ">
                        <div class="
                                                accordion-tab-details
                                                d-flex
                                                justify-content-between
                                                align-items-center
                                                pt-3
                                                pb-3
                                              ">
                          <div class="details-icon">
                            <svg id="pencil-ruler" xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                              viewBox="0 0 28 28">
                              <rect id="Rectangle_92" data-name="Rectangle 92" width="28" height="28" fill="none" />
                              <rect id="Rectangle_93" data-name="Rectangle 93" width="28" height="28" fill="none" />
                              <path id="Path_301" data-name="Path 301"
                                d="M6.5,11.167,3.966,8.633a3.3,3.3,0,0,1,0-4.667h0a3.3,3.3,0,0,1,4.667,0L11.167,6.5"
                                transform="translate(0.5 0.5)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <line id="Line_25" data-name="Line 25" y1="4.667" x2="4.667"
                                transform="translate(5.833 5.833)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <path id="Path_302" data-name="Path 302"
                                d="M18.667,14l1.994,1.994a2.333,2.333,0,0,1,.638,1.192l.856,4.283a.583.583,0,0,1-.686.686L17.186,21.3a2.333,2.333,0,0,1-1.192-.638L14,18.667"
                                transform="translate(2.333 2.333)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <path id="Path_303" data-name="Path 303"
                                d="M20.22,12.053l-11.6,11.6a1.2,1.2,0,0,1-1.692,0L3.35,20.068a1.2,1.2,0,0,1,0-1.692L18.377,3.35a1.2,1.2,0,0,1,1.692,0L23.65,6.932a1.2,1.2,0,0,1,0,1.692Z"
                                transform="translate(0.5 0.5)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <line id="Line_26" data-name="Line 26" x1="1.328" y1="1.328"
                                transform="translate(16.313 6.414)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <line id="Line_27" data-name="Line 27" x1="1.912" y1="1.912"
                                transform="translate(13.013 9.713)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <line id="Line_28" data-name="Line 28" x1="1.328" y1="1.328"
                                transform="translate(9.713 13.013)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                              <line id="Line_29" data-name="Line 29" x1="1.912" y1="1.912"
                                transform="translate(6.414 16.313)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                            </svg>
                          </div>
                          <div class="details-title pl-3">
                            <span class="header-2-s">Size chart</span>
                          </div>
                        </div>
                        <div class="accordion-tab-icon">
                          <svg id="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Path_231" data-name="Path 231" d="M0,0H24V24H0Z" fill="none" />
                            <path id="Path_232" data-name="Path 232" d="M8,10l4.8,4.8L17.6,10"
                              transform="translate(-0.8 -0.4)" fill="none" stroke="#323232" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                          </svg>
                        </div>
                      </div>
                      <div class="accordion-tab-content pb-3">
                        <span class="text-3-g" v-html="settingsList['size-chart']">
                        </span>
                      </div>
                    </div>
                    <div @click.prevent="activeAccordionTab('Tags')" class="accordion-tab"
                      :class="{ active: activeTab == 'Tags' }">
                      <div class="
                                              accordion-tab-header
                                              d-flex
                                              justify-content-between
                                              align-items-center
                                            ">
                        <div class="
                                                accordion-tab-details
                                                d-flex
                                                justify-content-between
                                                align-items-center
                                                pt-3
                                                pb-3
                                              ">
                          <div class="details-icon">
                            <svg id="bookmarks-label-tag-double" xmlns="http://www.w3.org/2000/svg" width="28"
                              height="28" viewBox="0 0 28 28">
                              <path id="Path_304" data-name="Path 304" d="M28,0H0V28H28Z" fill="none" />
                              <path id="Path_305" data-name="Path 305" d="M28,0H0V28H28Z" fill="none" />
                              <path id="Path_306" data-name="Path 306"
                                d="M9.269,10.163a.428.428,0,1,1-.606,0,.428.428,0,0,1,.606,0"
                                transform="translate(1.423 1.673)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2.5" />
                              <path id="Path_307" data-name="Path 307"
                                d="M12.912,6.338l8.412,8.412a2.307,2.307,0,0,1,0,3.262l-4.811,4.811a2.307,2.307,0,0,1-3.262,0L4.838,14.412A1.155,1.155,0,0,1,4.5,13.6V7.154A1.154,1.154,0,0,1,5.654,6H12.1a1.148,1.148,0,0,1,.814.338Z"
                                transform="translate(0.75 1)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2.5" />
                              <path id="Path_308" data-name="Path 308"
                                d="M21.5,11.167,13.91,3.673A2.332,2.332,0,0,0,12.27,3H7.5"
                                transform="translate(1.25 0.5)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2.5" />
                            </svg>
                          </div>
                          <div class="details-title pl-3">
                            <span class="header-2-s">Tags</span>
                          </div>
                        </div>
                        <div class="accordion-tab-icon">
                          <svg id="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Path_231" data-name="Path 231" d="M0,0H24V24H0Z" fill="none" />
                            <path id="Path_232" data-name="Path 232" d="M8,10l4.8,4.8L17.6,10"
                              transform="translate(-0.8 -0.4)" fill="none" stroke="#323232" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                          </svg>
                        </div>
                      </div>
                      <div class="accordion-tab-content pb-3">
                        <div v-if="artwork?.tags">
                          <span v-for="artworkTag in artwork?.tags" :key="artworkTag.index"
                            class="badge badge-info badge-lg mr-2">
                            {{ artworkTag.name_en }}
                          </span>
                        </div>
                        <div v-else>
                          <span class="text-3-g">
                            No tags for this artwork
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="artwork && artwork.user" class="col-12 col-lg-6 mt-3">
          <h1 class="header-1-p">Designs, you may like!</h1>
          <span class="text-2-s"></span>
          <div class="media align-items-center mt-3 pointer" @click.prevent="router.push({
            name: 'Artist',
            params: { slug: artwork?.user?.username },
          })">
            <span class="avatar avatar-xl rounded-circle mb-3">
              <img alt="Avatar placeholder" style="height: 100%; width: 100%; object-fit: cover;" :src="artwork.user && artwork?.user?.avatar.length > 0
                ? artwork?.user?.avatar[0]?.webp
                : '/img/placeholders/block.png'
                " />
            </span>
          </div>
          <p class="text-bold-2-s pointer" @click.prevent="router.push({
            name: 'Artist',
            params: { slug: artwork?.user?.username },
          })">
            {{ artwork?.user?.name_en }} <i v-if="artwork?.user?.type_id == 1"
              class="primary-pxl fas fa-certificate"></i>
          </p>
          <p class="text-3-g">
            {{ artwork?.user?.about_en }}
          </p>
          <div class="social-media">
            <div class="d-flex justify-content-start" v-if="artwork.user?.socials">
              <div v-for="social in artwork.user.socials" :key="social.index" style="width: 30px; height: 30px;">
                <a :href="social.link" target="_blank" style="width: 30px; height: 30px;">
                  <img style="object-fit: contain; width: 100%; height: 100%; padding-right: 0.5rem;" :src="social.type.icon && social?.type?.icon[0]?.webp
                    ? social?.type?.icon[0]?.webp
                    : '/img/placeholders/block.png'
                    " alt="" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 col-lg-4" v-for="userArtwork in artwork.user.artworks" :key="userArtwork.index">
              <div @click.prevent="
                router.replace({
                  name: 'Artist',
                  params: { slug: artwork?.user?.username },
                })
                ">
                <div class="
                                                  card card-custom
                                                  bg-dark
                                                  text-white
                                                  border-0
                                                  m-0
                                                  mb-3
                                                  mx-auto
                                                ">
                  <img class="card-img" style="object-fit: cover"
                    :style="`${Object.keys(userArtwork.initial).length > 0 && userArtwork.initial[Object.keys(userArtwork.initial)[0]]?.color ? 'background-color: ' + userArtwork.initial[Object.keys(userArtwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                    :src="getArtworkImage(userArtwork)" alt="Card image" />
                  <div class="
                                                    card-img-overlay
                                                    d-flex
                                                    align-items-end
                                                    flex-column
                                                    p-2
                                                  "></div>
                </div>
                <div class="d-flex align-items-start flex-column mx-auto card-custom-info">
                  <span class="header-3-s one-line-text">
                    {{ userArtwork?.name_en }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container py-5">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <ProgressSpinner aria-label="Basic ProgressSpinner" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import useArtworks from "@/composables/Artworks";
import useMockupEditor from "@/composables/MockupEditor";
import { useShopping } from "@/composables/Shopping";
import { isLoggedIn, logedinUser, settingsList } from "@/composables/auth";
import Galleria from 'primevue/galleria';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import ArtworkSkeleton from "../../components/ArtworkSkeleton.vue";

import { useCurrency } from "@/composables/GlobalState";


const { artwork, getArtwork, getArtworkBySlug, isLoading } = useArtworks();
const { setUpShow, changeShowColor } = useMockupEditor();
const {
  selectedOptions,
  setDefualtVariations,
  selectVariations,
  increaseQty,
  dcreaseQty,
  addToCart,
  addToWishlist,
  wishlistIDs,
} = useShopping();

const productQuantity = ref(0);
const productBackorders = ref("");

const productChecker = ref([]);
const checkCartValidity = ref(false);
const { currency } = useCurrency();
const cartMessage = ref("");

const isLoadingData = ref(true);
const activeTab = ref("");

const router = useRouter();
const route = useRoute();

const canvasContainer = ref([]);
const imageList = ref([]);
const galleryList = ref([]);
const count = ref(0);

const isMobile = ref(false);
const isTablet = ref(false);

const screenWidth = ref(window.innerWidth);

watch(screenWidth, (newWidth) => {
  if (newWidth <= 650) {
    isMobile.value = true;
    isTablet.value = false;
  } else if (newWidth <= 991) {
    isMobile.value = false;
    isTablet.value = true;
  } else {
    isMobile.value = false;
    isTablet.value = false;
  }
});



watch(
  () => currency,
  (current) => {
    console.log('currency.value', currency.value)
  },
  { deep: true }
)

window.addEventListener('resize', () => {
  screenWidth.value = window.innerWidth;
});


onMounted(async () => {
  await getArtworkBySlug(route.params.id);
  await setDefualtVariations(artwork);
  await readyToshow();
  fetchGallery();
  setTimeout(() => {
    isLoadingData.value = false;
  }, 1500);

});

const readyToshow = async () => {
  if (!selectedOptions.value.options.product) {
    selectedOptions.value.options.product = artwork.value.products[0].name_en;
    selectedOptions.value.price = artwork.value.products[0].price;
    selectedOptions.value.discount = artwork.value.products[0].discount;
    productQuantity.value = artwork.value.products[0]?.quantity;
    productBackorders.value = artwork.value.products[0]?.backorders;

    artwork.value.products[0].children.forEach((child) => {
      const color = child.variations
        .find((v) => v.name_en === "Color")
        ?.variation_options[0]?.name_en;

      const size = child.variations
        .find((v) => v.name_en === "Size")
        ?.variation_options[0]?.name_en;

      productChecker.value.push({
        quantity: child.quantity,
        color: color || null,
        size: size || null
      });
    });
    // console.log("children array check", JSON.stringify(productChecker.value));
    // console.log(productBackorders == "do not allow");


  }

  if (selectedOptions.value.options.product) {


    // selectedOptions.value.quantity == productChild qunatity
    // selectedOptions.value.options == productChild.selectedOption
    // {"product":"itWorks","size":"L","color":"#FF6357"}
    let childCompareObj = {
      "quantity": selectedOptions.value.quantity,
      "color": selectedOptions.value.options.color,
      "size": selectedOptions.value.options.size
    };

    const matchingProduct = productChecker.value.find(item =>
      item.color === childCompareObj.color && item.size === childCompareObj.size
    );

    if (productBackorders.value == "do not allow") {
      if (matchingProduct) {
        if (childCompareObj.quantity <= matchingProduct.quantity) {
          checkCartValidity.value = true;
          cartMessage.value = "";
        } else {
          checkCartValidity.value = false;
          cartMessage.value = "The item is out of stock or the selected quantity exceeds available stock.";
        }
      } else {
        checkCartValidity.value = false;
        cartMessage.value = "Select color and size";
      }
    } else {
      checkCartValidity.value = true;
      cartMessage.value = "";
    }


    console.log("backorder is = ", JSON.stringify(productBackorders.value));
    // console.log("selectedOPtions", JSON.stringify(selectedOptions.value.options));
    // console.log("cart validty ", checkCartValidity.value);
    setUpShow(artwork, selectedOptions, true, canvasContainer, imageList);
  }
}

async function checkForGallery() {

  return new Promise((resolve, reject) => {
    if (artwork.value && artwork.value.products && artwork.value.products[0] !== null) {
      resolve(artwork.value.products[0]);
    } else {
      reject(new Error('No product gallery found'));
    }
  });
}

const productGallery = ref(null);
const imageUrls = ref([]);

async function fetchGallery() {
  try {
    await getArtworkBySlug(route.params.id);
    productGallery.value = await checkForGallery();
    imageUrls.value = productGallery.value.product_gallery.map(item => item.webp);
    imageUrls.value.unshift("firstItem");

  } catch (error) {
    console.error(error);
  }
}

watch(
  () => selectedOptions.value,
  async () => {
    await setDefualtVariations(artwork);
    setTimeout(() => {
      setUpShow(artwork, selectedOptions, true, canvasContainer, imageList);
    }, 0);
  },
  { deep: true }
);

const activeAccordionTab = async (accordionTab) => {
  if (activeTab.value == accordionTab) {
    activeTab.value = "";
  } else {
    activeTab.value = accordionTab;
  }
};


const fullScreen = ref(false);
const fullScreenImage = ref('');



const selectedImage = ref(null);







const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 3,
    numScroll: 3
  },
  {
    breakpoint: '768px',
    numVisible: 2,
    numScroll: 2
  },
  {
    breakpoint: '560px',
    numVisible: 1,
    numScroll: 1
  }
  // end of it

];


function getArtworkImage(artwork) {
  if (artwork?.cover_image && artwork?.cover_image[0]?.webp) {
    return artwork.cover_image[0].webp;
  } else if (artwork?.image[0]?.webp) {
    return artwork.image[0].webp;
  } else if (artwork?.image[0]?.original_url) {
    return artwork.image[0].original_url;
  } else {
    return '/img/placeholders/block.png';
  }
}

</script>
<style scoped>
.card.card-custom:hover {
  cursor: pointer;
}

.card-custom {
  width: 180px;
  height: 180px;
  margin-bottom: 0.5rem;
}

.card-custom .card-img {
  height: 100%;
}

.dot {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.selected {
  border: 1px solid var(--primary-pxl);
}


/* start */
.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 350px;
}

.thumbnail-wrapper {
  text-align: center;
}

.thumbnail {
  width: 19.5rem;
  height: 19.5rem;
  /* width: 350px;
  height: 350px; */
  /* cursor: pointer; */
}

.carousel-width {
  width: 450px !important;
}


.carousel .p-carousel-prev,
.carousel .p-carousel-next {
  display: none;
  /* Hide the carousel arrows */
}

@media (max-width: 767px) {
  .thumbnail-container {
    flex-wrap: wrap;
    gap: 5px;
  }

  .thumbnail {
    width: 19.5rem;
    height: 19.5rem;
  }

}

@media screen and (max-width: 600px) {
  .thumbnail {
    width: 19.5rem;
    height: 19.5rem;
  }
}

/* end */
</style>
