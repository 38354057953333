<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form name="information" id="information" @submit.prevent="storeUser(user)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Info</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Avatar
                        <md />
                      </label>
                      <FileUpload name="avatar[]" :auto="true" :customUpload="true"
                        @uploader="storeUserFiles($event, false, 'user_avatar', 30, 0, user.avatar, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div v-if="!userProcessingFiles['user_avatar'] && user?.avatar && user?.avatar.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in user?.avatar" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(user?.avatar, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="userProcessingFiles['user_avatar']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['avatar']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Name
                        <md />
                      </label>
                      <input v-model="user.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                      <div class="row" v-for="message in validationErrors['name_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">username
                        <md />
                      </label>
                      <input v-model="user.username" class="form-control" id="username" type="text" name="username" />
                      <div class="row" v-for="message in validationErrors['username']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Email
                        <md />
                      </label>
                      <input v-model="user.email" class="form-control" id="email" type="text" name="email" />
                      <div class="row" v-for="message in validationErrors['email']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Mobile
                        <md />
                      </label>

                      <div class="mobile-input-container">
                        <Dropdown v-model="user.mobilecode" :options="userMeta?.country" :filter="true"
                          optionLabel="mobilecode" optionValue="mobilecode" placeholder="Code" style="height: 3rem;" />
                        <input v-model="user.mobile" class="form-control" id="mobile" type="tel" pattern="[0-9]{8,10}"
                          maxlength="10" name="mobile" />
                      </div>
                      <div class="row" v-for="message in validationErrors['mobile']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Bio</label>
                      <textarea v-model="user.about_en" class="form-control" name="about" id="about"
                        rows="5"></textarea>
                      <div class="row" v-for="message in validationErrors['about_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between"></div>
              </div>
              <div class="card">
                <div class="card-header d-flex flex-row">
                  <h4>Social media</h4>
                  <button @click="addSocial(user?.socials)" type="button"
                    class="btn btn-sm base-button btn-outline-secondary ml-2">
                    Add
                  </button>
                </div>
                <div class="table-responsive">
                  <table class="table table-flush table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Link</th>
                        <th>Type</th>
                        <th>Action</th>
                      </tr>
                      <tr v-for="(social, index) in user?.socials" :key="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          <input v-model="social.link" class="form-control" id="social-link" type="url"
                            name="social-link" />
                        </td>
                        <td>
                          <Dropdown class="w-100" :filter="true" :showClear="true" v-model="social.type_id"
                            :options="userMeta?.social_types" optionLabel="name_en" optionValue="id" />
                        </td>
                        <td>
                          <Button class="p-button-danger" icon="fas fa-trash" @click.prevent="deleteSocial(index)" />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="card">
                <div class="card-header d-flex flex-row">
                  <h4>Address</h4>
                  <button @click="addAddress(user?.address)" type="button"
                    class="btn btn-sm base-button btn-outline-secondary ml-2">
                    Add
                  </button>
                </div>
                <div class="table-responsive">
                  <table class="table table-flush table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Villa</th>
                        <th>Flat</th>
                        <th>Road</th>
                        <th>Block</th>
                        <th>Post Code</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Action</th>
                      </tr>
                      <tr v-for="(address, index) in user?.address" :key="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          <input v-model="address.villa" class="form-control" id="address-villa" type="text"
                            name="address-villa" />
                        </td>
                        <td>
                          <input v-model="address.flat" class="form-control" id="address-flat" type="text"
                            name="address-flat" />
                        </td>
                        <td>
                          <input v-model="address.road" class="form-control" id="address-road" type="text"
                            name="address-road" />
                        </td>
                        <td>
                          <input v-model="address.block" class="form-control" id="address-block" type="text"
                            name="address-block" />
                        </td>
                        <td>
                          <input v-model="address.post_code" class="form-control" id="address-post_code" type="text"
                            name="address-post_code" />
                        </td>
                        <td>
                          <Dropdown class="w-100" :filter="true" :showClear="true" v-model="address.country_id"
                            :options="userMeta?.country" optionLabel="name_en" optionValue="id"
                            @change="countryCurrentIndex(index)" />
                        </td>
                        <td>
                          <!-- <Dropdown class="w-100" :filter="true" :showClear="true" v-model="address.city_id" :options="userMeta?.city.filter(
                            (city) =>
                              city?.country_id == address?.country_id
                          )" optionLabel="name_en" optionValue="id" /> -->
                          <Dropdown class="w-100" :filter="true" :showClear="true" v-model="address.city_id"
                            :options="userMeta?.city[index] || []" optionLabel="name_en" optionValue="id" />
                        </td>
                        <td>
                          <Button class="p-button-danger" icon="fas fa-trash"
                            @click.prevent="deleteUserAddress(index)" />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Financial</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">IBAN</label>
                      <input v-model="user.iban" class="form-control" id="iban" type="text" name="iban" />
                      <div class="row" v-for="message in validationErrors['iban']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Commsion
                        <md />
                      </label>
                      <input v-model="user.commission" class="form-control" id="commission" type="number" step="any"
                        name="commission" />
                      <div class="row" v-for="message in validationErrors['commission']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Rate</label>
                      <input v-model="user.rate" class="form-control" id="rate" type="number" name="rate" />
                      <div class="row" v-for="message in validationErrors['rate']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Settings</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="binary">Is Featured</label>
                        <Checkbox name="Is Featured" v-model="user.is_featured" :binary="true" />
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="binary">Is Artist</label>
                        <Checkbox name="Is Artist" v-model="user.is_artist" :binary="true" />
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="status_id">Status
                          <md />
                        </label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="user.status_id"
                          :options="userMeta.status" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['status']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="type_id">Type
                          <md />
                        </label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="user.type_id"
                          :options="userMeta.type" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['type']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="roles">Roles
                          <md />
                        </label>
                        <MultiSelect v-model="user.roles" :filter="true" :showClear="true" :options="userMeta?.roles"
                          optionLabel="name_en" optionValue="id" display="chip" />
                        <div class="row" v-for="message in validationErrors['roles']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label class="form-control-label">Password
                          <md />
                        </label>
                        <Password v-model="user.password" id="password" name="password" inputClass="w-100"
                          :feedback="false" toggleMask />
                        <div class="row" v-for="message in validationErrors['password']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
              router.push({
                name: 'users.index',
              })
              " class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";
import useCities from "@/composables/Cities";
import useUsers from "@/composables/Users";
import { removeFromArray } from "@/helpers/Functions";

export default {
  components: {
  },
  setup() {
    const swal = inject("$swal");
    const user = reactive({
      avatar: ref([]),
      address: [],
      about_en: "",
      about_ar: "",
      socials: [],
      name_en: "",
      name_ar: "",
      username: "",
      email: "",
      iban: "",
      commission: "",
      rate: 0,
      status_id: "",
      type_id: "",
      is_featured: 0,
      is_artist: 0,
      roles: [],
      password: "",
      mobilecode: "",
    });
    const countryIndex = ref("");
    const countryCurrentIndex = (index) => {
      countryIndex.value = index;
    }


    const {
      userMeta,
      getUserMeta,
      storeUser,
      validationErrors,
      isLoading,
      userProcessingFiles,
      storeUserFiles,
    } = useUsers();
    const { cities, getCities } = useCities();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(async () => {
      getUserMeta();

      if (userMeta) {
        userMeta.value.city = null;
      }
    })

    const addAddress = async (address) => {
      if (address.length < 5) {
        address.push({
          villa: "",
          flat: "",
          road: "",
          block: "",
          post_code: "",
          country: "",
          city: "",
        });
      } else {
        swal({
          icon: "warning",
          title: "You reached the maximum number of addresses",
        });
      }
    };

    const country_array = ref([]);
    let isDeletion = false;


    watch(() => user?.address[countryIndex.value]?.country_id, async (newCountryId, oldCountryId) => {


      isDeletion = false;
      getCities(
        1,
        "",
        "",
        "",
        newCountryId,
        "",
        "created_at",
        "desc",
        50000
      );
    }, {
      deep: true,
    });
    watch(cities, async () => {
      if (cities.value?.data) {
        if (!isDeletion) {
          country_array.value[countryIndex.value] = cities.value.data;
        }
        userMeta.value.city = country_array.value;
      }
    }, {
      deep: true,
    });


    const deleteUserAddress = (index) => {
      isDeletion = true;
      if (index < country_array.value.length) {
        country_array.value.splice(index, 1);
        userMeta.value.city = [...country_array.value];
      }
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#ef4444",
        timer: 20000,
        timerProgressBar: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            user.address.splice(index, 1);
            swal({
              icon: "success",
              title: "User address deleted successfully",
            });
          } catch (error) {
            swal({
              icon: "error",
              title: "Something went wrong",
              text: error,
            });
          }
        }
      });
    };

    const addSocial = async (social) => {
      if (social.length < 5) {
        social.push({
          link: "",
          type_id: null,
        });
      } else {
        swal({
          icon: "warning",
          title: "You reached the maximum number of social media",
        });
      }
    };





    const deleteSocial = (index) => {
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#ef4444",
        timer: 20000,
        timerProgressBar: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            user.socials.splice(index, 1);
            swal({
              icon: "success",
              title: "User social deleted successfully",
            });
          } catch (error) {
            swal({
              icon: "error",
              title: "Something went wrong",
              text: error,
            });
          }
        }
      });
    };

    return {
      router,
      user,
      userMeta,
      validationErrors,
      isLoading,
      storeUser,
      can,
      addAddress,
      deleteUserAddress,
      addSocial,
      deleteSocial,
      userProcessingFiles,
      storeUserFiles,
      removeFromArray,
      country_array,
      countryCurrentIndex,
    };
  },
  methods: {
  },
  mounted() { },
};
</script>

<style scoped>
.mobile-input-container {
  display: flex;
  align-items: center;
}

.mobile-input {
  flex-grow: 1;
  /* Allow the first input to grow and take the available space */
  margin-right: 0.5rem;
  /* Add some spacing between the inputs */
}
</style>
