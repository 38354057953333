<template>
  <label @click="onClick($event)" class="custom-toggle" :class="switchClass">
    <input
      :id="inputId"
      ref="input"
      type="checkbox"
      role="switch"
      :checked="checked"
      :disabled="disabled"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
      v-bind="inputProps"
    />
    <span
      class="custom-toggle-slider rounded-circle"
      :data-label-off="offText"
      :data-label-on="onText"
    >
    </span>
  </label>
</template>
<script>
export default {
  name: "base-switch",
  emits: ["click", "update:modelValue", "change", "input", "focus", "blur"],
  props: {
    modelValue: {
      type: null,
      default: false,
    },
    trueValue: {
      type: null,
      default: true,
    },
    falseValue: {
      type: null,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: null,
    inputProps: null,
    type: String,
    onText: {
      type: String,
      default: "",
    },
    offText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    switchClass() {
      let baseClass = "custom-toggle-";
      if (this.type) {
        return baseClass + this.type;
      }
      return "";
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {
        this.$emit("input", modelValue);
      },
    },
    checked() {
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        const newValue = this.checked ? this.falseValue : this.trueValue;
        this.$emit("click", event);
        this.$emit("update:modelValue", newValue);
        this.$emit("change", event);
        this.$emit("input", newValue);
        this.$refs.input.focus();
      }
      event.preventDefault();
    },
    onFocus(event) {
      this.focused = true;
      this.$emit("focus", event);
    },
    onBlur(event) {
      this.focused = false;
      this.$emit("blur", event);
    },
  },
};
</script>
<style></style>
