<template>
    <div class="card card-custom bg-custom  border-0 m-0 ml-0 mr-0 mb-3 mx-auto mx-md-0">
        <div class="centered-content">
            <img src="/img/brand/white-logo.png" class="tst-img" alt=" ">
        </div>
    </div>
</template>

<style scoped>
.card.card-custom:hover {
    cursor: pointer;
}

.card-custom {
    width: 180px;
    height: 180px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-custom {
    height: 100%;
}

.card-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.tst-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    animation: cardImageOpacity 2s infinite !important;
}


.centered-content {
    width: 60%;
    height: 60%;
}

@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #f7f7f7;
    }

    50% {
        background-position: 100% 0;
        background-color: #f7f7f7;
    }

    100% {
        background-position: 200% 0;
        background-color: #f7f7f7;
    }
}

@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 20%;
    }

    50% {
        background-position: 100% 0;
        opacity: 60%;
    }


    100% {
        background-position: 200% 0;
        opacity: 90%;
    }
}

.bg-custom {
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}
</style>

