<template>
    <div style="display: inline;">
        &nbsp;<span class="text-danger">*</span>
    </div>
</template>
  
<script setup lang="ts">
</script>
  
<style scoped>

</style>