import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useProducts() {
  const products = ref({});
  const product = ref({});
  const meta = ref({});
  const files = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const productProcessingFiles = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const getProducts = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/products?" +
        "page=" +
        page +
        "&search_id=" +
        search_id +
        "&search_name_en=" +
        search_name_en +
        "&search_name_ar=" +
        search_name_ar +
        "&search_global=" +
        search_global +
        "&order_column=" +
        order_column +
        "&order_direction=" +
        order_direction +
        "&per_page=" +
        per_page
      )
      .then((response) => {
        products.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getProductMeta = async () => {
    axiosClient.get("/products/meta").then((response) => {
      meta.value = response.data.meta;
    });
  };

  const getProduct = async (id) => {
    axiosClient.get("/products/" + id).then((response) => {
      product.value = response.data.data;
      meta.value = response.data.meta;
    });
  };

  const storeProduct = async (product) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedProduct = new FormData();
    for (let item in product) {
      if (product.hasOwnProperty(item)) {
        serializedProduct.append(item, product[item]);
      }
    }

    let params = serialize(product, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/products", params)
      .then((response) => {
        router.push({ name: "products.index" });
        swal({
          icon: "success",
          title: "Product saved successfully",
        });
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Product error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeProductMedia = async (files) => {
    let serializedProduct = new FormData();
    for (let item in files) {
      if (files.hasOwnProperty(item)) {
        serializedProduct.append(item, files[item]);
      }
    }

    axiosClient
      .post("/products/media", serializedProduct)
      .then((response) => {
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateProduct = async (product) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/products/" + product.id, product)
      .then((response) => {
        router.push({ name: "products.index" });
        swal({
          icon: "success",
          title: "Product saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteProduct = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/products/" + id)
          .then((response) => {
            getProducts();
            router.push({ name: "products.index" });
            swal({
              icon: "success",
              title: "Product deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const storeProductFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (productProcessingFiles.value[collection_name]) return;

    productProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedProduct = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedProduct.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/products/media", serializedProduct)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (productProcessingFiles.value[collection_name] = false));
    });
  };


  const deleteVariation = async (parent_id,product_id) => {

    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete(`/products/${parent_id}/${product_id}`)
          .then((response) => {
            getProducts();
            router.push({ name: "products.index" });
            swal({
              icon: "success",
              title: "Variation deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });

  };

  return {
    products,
    product,
    meta,
    files,
    getProductMeta,
    getProducts,
    getProduct,
    storeProduct,
    validationErrors,
    isLoading,
    updateProduct,
    deleteProduct,
    storeProductMedia,
    productProcessingFiles,
    storeProductFiles,
    deleteVariation,
  };
}
