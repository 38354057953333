import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useSliders() {
  const allSliders = ref({});
  const sliders = ref({});
  const slider = ref({});
  const sliderMeta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const sliderProcessingFiles = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const getSliders = async (
    page = 1,
    search_id = "",
    search_title_en = "",
    search_title_ar = "",
    search_location = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/sliders?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_title_en=" +
          search_title_en +
          "&search_title_ar=" +
          search_title_ar +
          "&search_location=" +
          search_location +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        sliders.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getAllSliders = async () => {
    axiosClient
      .get("/sliders?all=true")
      .then((response) => {
        allSliders.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getSlider = async (id) => {
    axiosClient.get("/sliders/" + id).then((response) => {
      response.data.data.permissionData = response.data.data.permissions;
      response.data.data.permissions = response.data.permissions;
      slider.value = response.data.data;
      sliderMeta.value = response.data.meta;
    });
  };

  const storeSlider = async (slider) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(slider, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/sliders", params)
      .then((response) => {
        router.push({ name: "sliders.index" });
        swal({
          icon: "success",
          title: "Slider saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateSlider = async (slider) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/sliders/" + slider.id, slider)
      .then((response) => {
        router.push({ name: "sliders.index" });
        swal({
          icon: "success",
          title: "Slider saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteSlider = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/sliders/" + id)
          .then((response) => {
            getSliders();
            router.push({ name: "sliders.index" });
            swal({
              icon: "success",
              title: "Slider deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const storeSliderFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (sliderProcessingFiles.value[collection_name]) return;

    sliderProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedUser = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedUser.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/sliders/media", serializedUser)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (sliderProcessingFiles.value[collection_name] = false));
    });
  };

  return {
    allSliders,
    sliders,
    slider,
    getAllSliders,
    getSliders,
    getSlider,
    storeSlider,
    validationErrors,
    isLoading,
    updateSlider,
    deleteSlider,
    sliderProcessingFiles,
    storeSliderFiles,
  };
}
