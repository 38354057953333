<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="storeCoupon(coupon)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="is_active">Is Active</label>
                        <Checkbox name="is_active" v-model="coupon.is_active" :binary="true" />
                      </div>
                      <div class="row" v-for="message in validationErrors['is_active']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name</label>
                      <input v-model="coupon.name" class="form-control" id="name" type="text" name="name" />
                      <div class="row" v-for="message in validationErrors['name']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">code
                        <md />
                      </label>
                      <input v-model="coupon.code" class="form-control" id="code" type="text" name="code" />
                      <div class="row" v-for="message in validationErrors['code']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="type">Type
                          <md />
                        </label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="coupon.type"
                          :options="couponTypes" optionLabel="name" optionValue="name" />
                        <div class="row" v-for="message in validationErrors['type']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Value</label>
                      <input v-model="coupon.value" class="form-control" id="value" type="text" name="value" />
                      <div class="row" v-for="message in validationErrors['value']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Maximum Usage</label>
                      <input v-model="coupon.maximum_usage" class="form-control" id="maximum_usage" type="number" name="maximum_usage" />
                      <div class="row" v-for="message in validationErrors['maximum_usage']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Expires At</label>
                      <Calendar id="expires_at" class="w-100" v-model="coupon.expires_at" :manualInput="false" :showTime="true"
                        dateFormat="dd-mm-yy" hourFormat="12" />
                      <div class="row" v-for="message in validationErrors['expires_at']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
              router.push({
                name: 'coupons.index',
              })
            " class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import {useCoupons} from "@/composables/Coupons";


export default {
  components: {
  },
  setup() {
    const coupon = ref({
      name: "",
      code: "",
      type: "fixed",
      value: null,
      maximum_usage: 0,
      is_active: true,
      expires_at: null,
    });
    const couponTypes = ref([
      {
        name: "percent"
      },
      {
        name: 'fixed'
      }
    ])

    const {
      couponMeta,
      getCouponMeta,
      storeCoupon,
      validationErrors,
      isLoading,
    } = useCoupons();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(async () => {
      getCouponMeta();
    });

    return {
      router,
      couponMeta,
      coupon,
      validationErrors,
      isLoading,
      storeCoupon,
      can,
      couponTypes,
    };
  },
  methods: {},
  mounted() { },
};
</script>

