<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5">
      <div class="row" v-if="cartItems && cartItems.length > 0">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <p class="header-1-p">Cart</p>
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr v-for="(item, index) in cartItems" :key="item.index">
                      <td class="col-3 p-0">
                        <img class="" style="object-fit: cover; width: 90px;" :style="`${item?.artwork?.initial ?
        Object.keys(item.artwork.initial).length > 0 && item.artwork.initial[Object.keys(item.artwork.initial)[0]]?.color ?
          'background-color: ' + item?.artwork?.initial[Object.keys(item.artwork.initial)[0]]?.color :
          'background-color: #fff;' : 'background-color: #fff;'}`" :src="item.artwork &&
        item?.artwork?.cover_image[0]?.webp
        ? item?.artwork?.cover_image[0]?.webp
        : item.artwork &&
          item?.artwork?.image[0]?.webp
          ? item?.artwork?.image[0]?.webp
          : '/img/placeholders/block.png'
        " alt="" loading="lazy" />
                      </td>
                      <td class="col-5 align-middle">
                        <div class="header-2-s">
                          {{ item?.artwork?.name_en }}
                        </div>
                        <div v-if="item.options" class="art-details d-flex justify-content-start">
                          <div v-for="(option, index) in item.options" :key="option.index" class="
                                    pr-2
                                    text-gray text-capitalize
                                    d-flex
                                    align-items-center
                                  ">
                            <span>{{ index }}: &nbsp;</span>
                            <span v-if="index == 'color'" class="dot" :style="[{ 'background-color': option }]">
                            </span>
                            <span v-else class="text-light">
                              {{ option }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="col-2 align-middle">
                        <div class="art-price">
                          <span class="header-3-s" :class="{ 'text-c': item?.product?.discount > 0 }">
                            {{ currency?.code }} {{ Number(item?.product?.price * currency?.exchange_rate).toFixed(3) }}
                          </span>
                          <span v-if="item?.product?.discount > 0" class="header-3-p pl-2">
                            {{ currency?.code }} {{ Number((item?.product?.price - item?.product?.discount) *
        currency?.exchange_rate).toFixed(3) }}
                          </span>
                        </div>
                      </td>
                      <td class="col-2 align-middle">
                        <div class="
                                  col-12
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  btn-quantity
                                ">
                          <span class="btn-q" @click.prevent="
        updateCartItemQuantity(
          isLoggedIn,
          logedinUser.id,
          item,
          -1
        )
        ">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                          <span class="header-3-s">{{ item?.quantity }}</span>
                          <span class="btn-q" @click.prevent="
        updateCartItemQuantity(
          isLoggedIn,
          logedinUser.id,
          item,
          1
        )
        ">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_20" data-name="Line 20" y2="9" transform="translate(14.5 10)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                        </div>
                      </td>
                      <td class="col-1 pr-0 align-middle">
                        <button class="btn-custom-light" @click.prevent="
        deleteCartItem(
          item.id,
          isLoggedIn,
          logedinUser.id,
          index
        )
        ">
                          <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <path id="Path_315" data-name="Path 315"
                              d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                              fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                            <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24" fill="none" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <h3 class="header-1-p">Summary</h3>
            </div>
            <div v-if="cartAmount && cartAmount > 0" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Amount</span>
              <span class="text-2-g">
                {{ currency?.code }} {{ Number(cartAmount * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div v-if="cartDiscount && cartDiscount > 0" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Discount</span>
              <span class="text-2-g">
                - {{ currency?.code }} {{ Number(cartDiscount * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div v-if="cartTotal && cartTotal > 0" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Total</span>
              <span class="text-2-g">
                {{ currency?.code }} {{ Number(cartTotal * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div class="col-md-12 mt-3">
              <base-button @click.prevent="
        router.push({
          name: 'Checkout',
        })
        " block type="primary">Checkout</base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <p class="header-1-p">Cart</p>
          <p class="text-2-s">Need inspiration?</p>
        </div>
        <div class="col-12">
          <div class="w-100 d-flex justify-content-center">
            <img src="/img/undraw_empty_cart_co35.png" class="w-50" alt="undraw_empty_cart_co35">
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-3 text-left">
        <div class="col-md-12 mx-auto">
          <p class="header-1-p">Designs</p>
          <p class="text-2-s">You may like</p>
        </div>
      </div>
      <div v-if="isLoading">
        <ArtistCartDesignSkeleton />
      </div>
      <div v-else class="row mb-4">
        <div v-for="artwork in artworks.data" :key="artwork.index" class="col-12 col-sm-6 col-md-6 col-lg-2">
          <div>
            <div class="card card-custom bg-dark text-white border-0 m-0 mb-2">
              <img class="card-img" style="object-fit: cover" :style="`${item?.artwork?.initial ?
        Object.keys(item.artwork.initial).length > 0 && item.artwork.initial[Object.keys(item.artwork.initial)[0]]?.color ?
          'background-color: ' + item?.artwork?.initial[Object.keys(item.artwork.initial)[0]]?.color :
          'background-color: #fff;' : 'background-color: #fff;'}`" :src="artwork.cover_image && artwork?.cover_image[0]?.webp
        ? artwork?.cover_image[0]?.webp
        : artwork?.image[0]?.webp
          ? artwork?.image[0]?.webp
          : '/img/placeholders/block.png'
        " alt="Card image" @click.prevent="
        router.push({
          name: 'Artwork',
          params: { id: artwork.slug },
        })
        " />
              <div class="btn-fav">
                <img @click.prevent="addToWishlist(artwork.id, isLoggedIn)" :src="wishlistIDs.includes(artwork.id)
        ? 'img/favorite.svg'
        : 'img/non-favorite.svg'
        " />
              </div>
              <div class="img-footer">
                <span v-if="formatDateYYYYMMDD(artwork.created_at) ==
        formatDateYYYYMMDD(Date.now())
        " class="badge badge-secondary p-2">
                  New
                </span>
              </div>
            </div>
            <div @click.prevent="
        router.push({
          name: 'Artwork',
          params: { id: artwork.slug },
        })
        " class="d-flex align-items-start flex-column m-pointer">
              <span class="text-lable-semi-bold-secondary">
                {{ artwork.name_en }}
              </span>
              <span class="text-lable-span">
                {{ artwork.user.name_en }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { cartItems, useShopping } from "@/composables/Shopping";
import { isLoggedIn, logedinUser } from "@/composables/auth";
import useArtworks from "@/composables/Artworks";
import ArtistCartDesignSkeleton from "../../components/ArtistCartDesignSkeleton.vue";
import { useCurrency } from "@/composables/GlobalState";


export default {
  components: { ArtistCartDesignSkeleton },
  setup() {
    const { artworks, getArtworks } = useArtworks();
    const {
      getUserCartItems,
      updateCartItemQuantity,
      deleteCartItem,
      cartTotal,
      cartDiscount,
      cartAmount,
      addToWishlist,
      wishlistIDs,
    } = useShopping();
    const router = useRouter();
    const route = useRoute();

    const { currency } = useCurrency()

    onMounted(async () => {
      getUserCartItems(isLoggedIn.value, logedinUser.id);
      getArtworks(1, "", "", "", "", "", "", "", "", "id", "desc", 6, 1);
      setTimeout(() => {
        isLoading.value = false;
      }, 1500);
    });

    const isLoading = ref(true);

    const formatDateYYYYMMDD = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    };

    return {
      isLoggedIn,
      logedinUser,
      artworks,
      cartItems,
      cartTotal,
      cartDiscount,
      cartAmount,
      updateCartItemQuantity,
      deleteCartItem,
      router,
      route,
      formatDateYYYYMMDD,
      addToWishlist,
      wishlistIDs,
      isLoading,
      currency,
    };
  },
};
</script>
<style scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.card:hover {
  cursor: pointer;
}

.card-custom {
  width: 180px;
  height: 180px;
}

.card-custom .card-img {
  height: 100%;
}
</style>
