<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form name="information" id="information" @submit.prevent="storeSlider(slider)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Desktop Image</label>
                      <FileUpload name="slider[]" :auto="true" :customUpload="true"
                        @uploader="storeSliderFiles($event, false, 'slider_desktop_image', 30, 0, slider.desktop_image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!sliderProcessingFiles['slider_desktop_image'] && slider?.desktop_image && slider?.desktop_image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in slider?.desktop_image" :key="index"
                              class="p-2 position-relative" style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(slider?.desktop_image, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="sliderProcessingFiles['slider_desktop_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['desktop_image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Mobile Image</label>
                      <FileUpload name="slider[]" :auto="true" :customUpload="true"
                        @uploader="storeSliderFiles($event, false, 'slider_mobile_image', 30, 0, slider.mobile_image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!sliderProcessingFiles['slider_mobile_image'] && slider?.mobile_image && slider?.mobile_image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in slider?.mobile_image" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(slider?.mobile_image, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="sliderProcessingFiles['slider_mobile_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['mobile_image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <!-- Tablet Image -->
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Tablet Image</label>
                      <FileUpload name="slider[]" :auto="true" :customUpload="true"
                        @uploader="storeSliderFiles($event, false, 'slider_tablet_image', 30, 0, slider.tablet_image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!sliderProcessingFiles['slider_tablet_image'] && slider?.tablet_image && slider?.tablet_image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in slider?.tablet_image" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(slider?.tablet_image, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="sliderProcessingFiles['slider_tablet_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['tablet_image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <!-- End of Tablet Image -->
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Title</label>
                      <input v-model="slider.title_en" class="form-control" id="title_en" type="text" name="title_en" />
                      <div class="row" v-for="message in validationErrors['title_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Location</label>
                      <input v-model="slider.location" class="form-control" id="location" type="text" name="location" />
                      <div class="row" v-for="message in validationErrors['location']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">link</label>
                      <input v-model="slider.link" class="form-control" id="link" type="text" name="link" />
                      <div class="row" v-for="message in validationErrors['link']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Order</label>
                      <input v-model="slider.order" class="form-control" id="order" type="number" name="order" />
                      <div class="row" v-for="message in validationErrors['order']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
              router.push({
                name: 'sliders.index',
              })
              " class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import useSliders from "@/composables/Sliders";
import { removeFromArray } from "@/helpers/Functions";


export default {
  components: {
  },
  setup() {
    const slider = reactive({
      desktop_image: [],
      mobile_image: [],
      tablet_image:[],
      title_ar: "",
      title_en: "",
      location: "",
      link: "",
      order: 0,
    });

    const {
      storeSlider,
      validationErrors,
      isLoading,
      sliderProcessingFiles,
      storeSliderFiles
    } = useSliders();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(() => { });

    return {
      router,
      slider,
      storeSlider,
      validationErrors,
      isLoading,
      can,
      sliderProcessingFiles,
      storeSliderFiles,
      removeFromArray,
    };
  },
};
</script>

<style></style>
