<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Name</label>
                    <input
                      v-model="messageType.name_en"
                      class="form-control"
                      id="name_en"
                      type="text"
                      name="name_en"
                      readonly
                    />
                  </div>
                  <!-- <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name Ar</label>
                      <input
                        v-model="messageType.name_ar"
                        class="form-control"
                        id="name_ar"
                        type="text"
                        name="name_ar"
                      />
                    </div> -->
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button @click.prevent="
              router.push({
                name: 'message-types.index',
              })
            " label="Cancel" class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useMessageTypes from "@/composables/MessageTypes";



export default {
  components: {
  },
  setup() {
    const { messageType, getMessageType } = useMessageTypes();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getMessageType(route.params.id);
    });

    return {
      router,
      messageType,
      can,
    };
  },
  methods: {},
  mounted() {},
};
</script>
