import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useMessageTypes() {
  const messageTypes = ref({});
  const messageType = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getMessageTypes = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/message-types?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        messageTypes.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getMessageType = async (id) => {
    axiosClient.get("/message-types/" + id).then((response) => {
      messageType.value = response.data.data;
    });
  };

  const storeMessageType = async (messageType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedMessageType = new FormData();
    for (let item in messageType) {
      if (messageType.hasOwnProperty(item)) {
        serializedMessageType.append(item, messageType[item]);
      }
    }

    axiosClient
      .post("/message-types", serializedMessageType)
      .then((response) => {
        router.push({ name: "message-types.index" });
        swal({
          icon: "success",
          title: "message type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateMessageType = async (messageType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/message-types/" + messageType.id, messageType)
      .then((response) => {
        router.push({ name: "message-types.index" });
        swal({
          icon: "success",
          title: "Message type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteMessageType = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/message-types/" + id)
          .then((response) => {
            getMessageTypes();
            router.push({ name: "message-types.index" });
            swal({
              icon: "success",
              title: "Message type deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    messageTypes,
    messageType,
    getMessageTypes,
    getMessageType,
    storeMessageType,
    validationErrors,
    isLoading,
    updateMessageType,
    deleteMessageType,
  };
}
