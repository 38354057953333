<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Rest your password</h1>
              <!-- <p class="text-lead text-white">Welcome to PXL.</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <form
            name="register"
            id="register"
            @submit.prevent="resetPassword(password, password_confirmation, token)"
          >
            <div class="card border-0">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="text-center text-muted mb-4">
                  <small>Enter your new password</small>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Password</label>
                    <input
                      v-model="password"
                      class="form-control"
                      id="password"
                      type="password"
                      name="password"
                    />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Confirm Password</label>
                    <input
                      v-model="password_confirmation"
                      class="form-control"
                      id="password_confirmation"
                      type="password"
                      name="password_confirmation"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-between">
                <base-button
                  @click.prevent="
                    router.push({
                      name: 'Login',
                    })
                  "
                  size="m"
                  type="secondary"
                  >Cancel</base-button
                >
                <base-button native-type="submit" size="m" type="success"
                  >Submit</base-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter, useRoute } from "vue-router";

import {useAuth} from "@/composables/auth";

import { ElSelect, ElOption } from "element-plus";
export default {
  name: "register",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  setup() {

    const { resetPassword } = useAuth();

    const router = useRouter();
    const route = useRoute();

    const token = route.params.token;

    return {
      router,
      resetPassword,
      token,
    };
  },
};
</script>
<style></style>
