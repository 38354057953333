<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container">
      <div class="row w-100 m-0">
        <!-- <div class="col-md-12">
          {{ artworkCategories }}
        </div> -->

        <div class="col-md-12 mb-4 mt-6 p-0">
          <div class="d-flex flex-row justify-content-start align-items-center"></div>
          <div class="breadcrumb-custom">Home

            <svg xmlns="http://www.w3.org/2000/svg" width="4.811" height="8.121" viewBox="0 0 4.811 8.121"
              class="ml-1 mr-1">
              <path id="Path_258" data-name="Path 258" d="M10,14l3-3L10,8" transform="translate(-8.939 -6.939)"
                fill="none" stroke="#837f7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
            Themes
          </div>
          <div class="current-page">Themes</div>
          <!-- <div class="current-page-text">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh
          </div> -->
        </div>
        <div class="col-md-12 p-0">
          <hr class="m-0" />
        </div>
        <div class="
                                                  col-md-6
                                                  d-flex
                                                  justify-content-start
                                                  align-items-center
                                                  mb-5
                                                  mt-5
                                                  p-0
                                                ">
          <div v-if="artworkCategories.data" class="text-lable-gray">
            {{ artworkCategories.meta.total }} Results
          </div>
        </div>
        <div class="
                                                  col-md-6
                                                  d-flex
                                                  justify-content-end
                                                  align-items-center
                                                  mb-5
                                                  mt-5
                                                  p-0
                                                ">
          <div v-if="artworkCategories.data" class="text-lable-semi-bold-primary mr-3">
            Sort by
          </div>
          <div v-if="artworkCategories.data">
            <Dropdown v-model="orderColumn" :options="orderColumnOptions" optionLabel="text" optionValue="value"
              placeholder="Sort by" />
          </div>
        </div>
        <div v-if="isLoading">
          <ThemesSkeleton />
        </div>
        <div v-else class="col-md-12 p-0">
          <div class="row">
            <div v-for="category in artworkCategories.data" :key="category.index" class="col-md-4 p-0 pl-2 pr-2 pb-3">
              <!-- <div
                @click.prevent="
                  router.push({
                    name: 'Theme',
                    params: {
                      slug: category.slug,
                      parent_id: category.id,
                      parent_name_en: category.name_en,
                    },
                  })
                "
                class="card theme-card bg-dark text-white border-0 m-0 m-pointer"
              > -->
              <div @click.prevent="
                saveCategorieNextState(category)
                " class="card theme-card bg-dark text-white border-0 m-0 m-pointer">
                <img class="card-img" style="object-fit: cover" :src="getCategoriesImage(category)" alt="Card image" />
                <div class="card-img-overlay d-flex align-items-end p-3">
                  <div>
                    <!-- <h5 class="h2 card-title text-white mb-2">Card title</h5>
                <p class="card-text">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p> -->
                    <p class="card-title text-sm font-weight-bold m-0">
                      {{ category.name_en }}
                    </p>
                    <!-- <p v-if="category.children" class="card-text text-sm m-0">
                      {{ category.children.length }} theme
                    </p> -->
                    <p v-if="category.artworks" class="card-text text-sm m-0">
                      {{ category.artworks.length }} artwork
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-4 mt-4 p-0">
          <div class="d-flex justify-content-end">
            <pagination :data="artworkCategories" :limit="1" @pagination-change-page="(page) =>
              getArtworkCategories(
                page,
                search_id,
                search_name_en,
                search_name_ar,
                search_parent,
                search_global,
                orderColumn,
                orderDirection,
                per_page
              )
              " />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import useArtworkCategories from "@/composables/ArtworkCategories";
import { useRouter } from "vue-router";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
import ThemesSkeleton from "@/components/ThemesSkeleton.vue";
export default {
  components: {
    Pagination: LaravelVuePagination,
    ThemesSkeleton: ThemesSkeleton,
  },
  setup() {
    const page = ref(1);
    const per_page = ref(9);
    const per_page_options = ref([]);
    const orderColumnOptions = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_parent = ref("");
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("asc");
    const isLoading = ref(true);
    const router = useRouter();
    const { artworkCategories, getArtworkCategories, deleteArtworkCategory } =
      useArtworkCategories();
    onMounted(async () => {
      localStorage.removeItem('ThemeArtwork');

      await getArtworkCategories(
        page.value,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
      orderColumnOptions.value = [
        { text: "Default", value: "id" },
        { text: "Date", value: "created_at" },
        { text: "Alphabetically", value: "name_en" },
      ];
      setTimeout(() => {
        isLoading.value = false;
      }, 1500);
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    };
    watch(search_id, (current) => {
      getArtworkCategories(
        1,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    });
    watch(search_name_en, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        current,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    });
    watch(search_name_ar, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        current,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    });
    watch(search_parent, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    });
    watch(search_global, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        1
      );
    });
    watch(per_page, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current,
        1
      );
    });
    watch(orderColumn, (current) => {
      getArtworkCategories(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_parent.value,
        search_global.value,
        current,
        orderDirection.value,
        per_page.value,
        1
      );
    });


    const saveCategorieNextState = async (category) => {
      // await store.dispatch('saveThemeData', category);
      router.push({
        name: 'ThemeArtworks',
        params: {
          slug: category.slug,
          // subslug: category.slug,
          // parent_name_en: category.name_en,
          category_id: category.id,
          category_name_en: category.name_en,
        },
      });
      localStorage.setItem('ThemeArtwork', JSON.stringify(category))
    };


    function getCategoriesImage(category) {
      if (category.cover && category.cover[0]?.webp) {
        return category.cover[0].webp;
      } else if (category.cover && category?.cover[0]?.original_url) {
        return category.cover[0].original_url
      } else {
        return '/img/placeholders/block.png';
      }
    }

    return {
      router,
      artworkCategories,
      getArtworkCategories,
      deleteArtworkCategory,
      search_id,
      search_name_en,
      search_name_ar,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      getCategoriesImage,
      per_page_options,
      per_page,
      page,
      orderColumnOptions,
      search_parent,
      isLoading,
      saveCategorieNextState,
    };
  },
};
</script>
<style scoped>
.theme-card {
  width: 100%;
  height: 223px;
}

.theme-card .card-img {
  height: 100%;
}

.theme-card .card-img-overlay {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.35057773109243695) 0%,
      rgba(0, 212, 255, 0) 35%);
}

.theme-card .card-title {
  font-family: "Poppins-Bold";
}

.breadcrumb-custom {
  font-family: "Poppins";
  font-size: 12px;
  color: var(--grayer);
}
</style>
