import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useShippings() {
  const shippings = ref({});
  const shipping = ref({});
  const shippingMeta = ref({});
  const files = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getShippings = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/shippings?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        shippings.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getShippingMeta = async () => {
    axiosClient.get("/shippings/meta").then((response) => {
      shippingMeta.value = response.data.meta;
    });
  };

  const getShipping = async (id) => {
    axiosClient.get("/shippings/" + id).then((response) => {
      response.data.data.regionsData = response.data.data.regions;
      response.data.data.regions = response.data.regions;
      response.data.data.shippingMethodsData =
        response.data.data.shipping_methods;
      response.data.data.shippingMethods = response.data.shippingMethods;
      shipping.value = response.data.data;
      shippingMeta.value = response.data.meta;
    });
  };

  const storeShipping = async (shipping) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedShipping = new FormData();
    for (let item in shipping) {
      if (shipping.hasOwnProperty(item)) {
        serializedShipping.append(item, shipping[item]);
      }
    }

    let params = serialize(shipping, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/shippings", params)
      .then((response) => {
        router.push({ name: "shippings.index" });
        swal({
          icon: "success",
          title: "Shipping saved successfully",
        });
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Shipping error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeShippingMedia = async (files) => {
    let serializedShipping = new FormData();
    for (let item in files) {
      if (files.hasOwnProperty(item)) {
        serializedShipping.append(item, files[item]);
      }
    }

    axiosClient
      .post("/shippings/media", serializedShipping)
      .then((response) => {
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateShipping = async (shipping) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/shippings/" + shipping.id, shipping)
      .then((response) => {
        router.push({ name: "shippings.index" });
        swal({
          icon: "success",
          title: "Shipping saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteShipping = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/shippings/" + id)
          .then((response) => {
            getShippings();
            router.push({ name: "shippings.index" });
            swal({
              icon: "success",
              title: "Shipping deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    shippings,
    shipping,
    shippingMeta,
    files,
    getShippingMeta,
    getShippings,
    getShipping,
    storeShipping,
    validationErrors,
    isLoading,
    updateShipping,
    deleteShipping,
    storeShippingMedia,
  };
}
