import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useUserStatuses() {
  const userStatuses = ref({});
  const userStatus = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getUserStatuses = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/user-statuses?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        userStatuses.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getUserStatus = async (id) => {
    axiosClient.get("/user-statuses/" + id).then((response) => {
      userStatus.value = response.data.data;
    });
  };

  const storeUserStatus = async (userStatus) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedUserStatus = new FormData();
    for (let item in userStatus) {
      if (userStatus.hasOwnProperty(item)) {
        serializedUserStatus.append(item, userStatus[item]);
      }
    }

    axiosClient
      .post("/user-statuses", serializedUserStatus)
      .then((response) => {
        router.push({ name: "user-statuses.index" });
        swal({
          icon: "success",
          title: "user status saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateUserStatus = async (userStatus) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/user-statuses/" + userStatus.id, userStatus)
      .then((response) => {
        router.push({ name: "user-statuses.index" });
        swal({
          icon: "success",
          title: "User status saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteUserStatus = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/user-statuses/" + id)
          .then((response) => {
            getUserStatuses();
            router.push({ name: "user-statuses.index" });
            swal({
              icon: "success",
              title: "User status deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    userStatuses,
    userStatus,
    getUserStatuses,
    getUserStatus,
    storeUserStatus,
    validationErrors,
    isLoading,
    updateUserStatus,
    deleteUserStatus,
  };
}
