<template>
  <div>
    <!-- Page content -->
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="row">
            <div class="col-md-12">
              <div class="card-wrapper">
                <div v-if="page == 1" class="card shadow-none">
                  <div class="card-header">
                    <!-- <button id="tut1" ref="tut1" @click="start" style="display:hidden;">Start Tutorial !</button> -->
                    <h4>Step 1 - Information</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 d-none">
                        <label class="form-control-label">Cover Image
                          <md />
                        </label>
                        <FileUpload name="cover_image[]" :auto="true" :customUpload="true"
                          @uploader="storeArtworkFiles($event, false, 'artwork_cover_image', 30, 0, artwork.cover_image, false)"
                          :multiple="true" accept="image/*">
                          <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                            <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                              <div class="d-flex gap-2">
                                <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                                <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                  class="p-button-rounded p-button-success"
                                  :disabled="!files || files.length === 0"></Button>
                                <Button @click="clearCallback()" icon="pi pi-times"
                                  class="p-button-rounded p-button-danger"
                                  :disabled="!files || files.length === 0"></Button>
                              </div>
                            </div>
                          </template>
                          <template #empty>
                            <div
                              v-if="!artworkProcessingFiles['artwork_cover_image'] && artwork?.cover_image && artwork?.cover_image.length > 0"
                              class="d-flex justify-content-start align-items-center flex-wrap">
                              <div v-for="(image, index) in artwork?.cover_image" :key="index"
                                class="p-2 position-relative" style="height:150px; width: 150px;">
                                <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                  alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                                <div
                                  class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                  style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                  @click.prevent="
                  removeFromArray(artwork?.cover_image, index)
                  ">
                                  <i class="pi pi-times"></i>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="artworkProcessingFiles['artwork_cover_image']"
                              class="d-flex justify-content-center align-items-center">
                              <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                            </div>
                            <div v-else class="d-flex align-items-center justify-content-center flex-column">
                              <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                              <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                            </div>
                          </template>
                        </FileUpload>
                        <div class="row" v-for="message in validationErrors['cover_image']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="form-control-label">Image
                          <md />
                        </label>
                        <!-- here on boarding wrapper 2 -->
                        <!-- <VOnboardingWrapper ref="wrapper" :steps="steps">
                          <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
                            <VOnboardingStep>
                              <div class="bg-white shadow sm:rounded-lg">
                                <div class="px-4 py-5 sm:p-6">
                                  <div class="sm:flex sm:items-center sm:justify-between">
                                    <div v-if="step.content">
                                      <h3 v-if="step.content.title" class="text-lg font-medium leading-6 text-gray-900">
                                        {{ step.content.title }}
                                      </h3>
                                      <div v-if="step.content.description" class="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>{{ step.content.description }}</p>
                                      </div>
                                    </div>
                                    <div
                                      class="mt-5 space-x-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center relative">
                                      <span
                                        class="absolute right-0 bottom-full mb-2 mr-2 text-gray-600 font-medium text-xs">{{
                  `${index + 1}/${steps.length}` }}</span>
                                      <template v-if="!isFirst">
                                        <button @click="previous" type="button"
                                          class="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 font-medium text-yellow-700 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm">
                                          Previous
                                        </button>
                                      </template>

                                      <button v-if="!isLast" @click="next" type="button"
                                        :style="{ backgroundColor: '#52ab64', borderColor: '#52ab64' }"
                                        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">
                                        {{ "Next" }}
                                      </button>
                                      <button v-else @click="setOffTutorial1()" type="button"
                                        :style="{ backgroundColor: '#52ab64', borderColor: '#52ab64' }"
                                        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">
                                        {{ "Finish" }}
                                      </button>
                                      <button @click="setOffTutorial1()" type="button"
                                        :style="{ backgroundColor: '#ff5252', borderColor: '#ff5252' }"
                                        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm hover-effect">
                                         Skip
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </VOnboardingStep>
                          </template>
                        </VOnboardingWrapper> -->
                        <FileUpload id="pageOneImage" name="image[]" :auto="true" :customUpload="true"
                          @uploader="storeArtworkFiles($event, false, 'artwork_image', 30, 0, artwork.image, false)"
                          :multiple="true" accept="image/*">
                          <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                            <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                              <div class="d-flex gap-2">
                                <Button id="uploadFileButton" ref="uploadFileButton" @click="chooseCallback()"
                                  icon="pi pi-images" class="p-button-rounded"></Button>
                                <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                    class="p-button-rounded p-button-success"
                                    :disabled="!files || files.length === 0"></Button>
                                  <Button @click="clearCallback()" icon="pi pi-times"
                                    class="p-button-rounded p-button-danger"
                                    :disabled="!files || files.length === 0"></Button> -->
                              </div>
                            </div>
                          </template>
                          <template #empty>
                            <div
                              v-if="!artworkProcessingFiles['artwork_image'] && artwork?.image && artwork?.image.length > 0"
                              class="d-flex justify-content-start align-items-center flex-wrap">
                              <div v-for="(image, index) in artwork?.image" :key="index" class="p-2 position-relative"
                                style="height:150px; width: 150px;">
                                <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                  alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                                <div
                                  class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                  style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                  @click.prevent="
                  removeFromArray(artwork?.image, index)
                  ">
                                  <i class="pi pi-times"></i>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="artworkProcessingFiles['artwork_image']"
                              class="d-flex justify-content-center align-items-center">
                              <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                            </div>
                            <div @click="triggerButtonClick" v-else
                              class="d-flex align-items-center justify-content-center flex-column">
                              <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                              <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                            </div>
                          </template>
                        </FileUpload>
                        <div class="row" v-for="message in validationErrors['image']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <label id="" class="form-control-label">Name
                          <md />
                        </label>
                        <input v-model="artwork.name_en" class="form-control" id="pageOneName" type="text"
                          name="name_en" />
                        <div class="row" v-for="message in validationErrors['name_en']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4 d-none">
                        <label class="form-control-label">Slug
                          <md />
                        </label>
                        <input v-model="artwork.slug" class="form-control" id="slug" type="text" name="slug" />
                        <div class="row" v-for="message in validationErrors['slug']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                      <div v-if="artworkMeta?.products" class="col-md-12 mt-4">
                        <div class="form-group d-flex flex-column justify-content-start">
                          <label for="products">Product
                            <md /> <span v-if="!artwork?.image || artwork?.image.length == 0" class="text-danger">(
                              Please
                              upload the artwork image )</span>
                          </label>
                          <MultiSelect id="pageOneSelect" v-model="artwork.products" :selectionLimit="1" :filter="true"
                            :showClear="true" :disabled="!artwork?.image || artwork?.image.length == 0"
                            :options="artworkMeta?.products" optionLabel="name_en" display="chip" />
                          <div class="row" v-for="message in validationErrors['products']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <p class="text-m-2-s pb-2">Quantity</p>
                        <div id="pageOneQunatitiy" class="
                              col-4
                              d-flex
                              justify-content-between
                              align-items-center
                              btn-quantity
                            ">
                          <span class="btn-q" @click.prevent="dcreaseQty()">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                          <span class="text-quantity font-weight-bold">{{
                  selectedOptions.quantity
                }}</span>
                          <span class="btn-q" @click.prevent="increaseQty()">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_20" data-name="Line 20" y2="9" transform="translate(14.5 10)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="page == 2" class="card shadow-none">
                  <div class="card-header">
                    <h4>Step 2 - Setup <span class="text-danger">( Please select color & size )</span></h4>
                  </div>
                  <!-- <button @click="start">Start Tutorial 2!</button> -->
                   <!-- here on boarding wrapper -->
                  <!-- <VOnboardingWrapper ref="wrapper" :steps="steps1">
                    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
                      <VOnboardingStep>
                        <div class="bg-white shadow sm:rounded-lg">
                          <div class="px-4 py-5 sm:p-6 rounded-sm">
                            <div class="sm:flex sm:items-center sm:justify-between">
                              <div v-if="step.content">
                                <h3 v-if="step.content.title" class="text-lg font-medium leading-6 text-gray-900">
                                  {{ step.content.title }}
                                </h3>
                                <div v-if="step.content.description" class="mt-2 max-w-xl text-sm text-gray-500">
                                  <p>{{ step.content.description }}</p>
                                </div>
                              </div>
                              <div
                                class="mt-5 space-x-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center relative">
                                <span
                                  class="absolute right-0 bottom-full mb-2 mr-2 text-gray-600 font-medium text-xs">{{
                  `${index + 1}/${steps.length}` }}</span>
                                <template v-if="!isFirst">
                                  <button @click="previous" type="button"
                                    class="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 font-medium text-yellow-700 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm">
                                    Previous
                                  </button>
                                </template>

                                <button v-if="!isLast" @click="next" type="button"
                                  :style="{ backgroundColor: '#52ab64', borderColor: '#52ab64' }"
                                  class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">
                                  {{ "Next" }}
                                </button>
                                <button v-else @click="setOffTutorial2()" type="button"
                                  :style="{ backgroundColor: '#52ab64', borderColor: '#52ab64' }"
                                  class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">
                                  {{ "Finish" }}
                                </button>
                                <button @click="setOffTutorial2()" type="button"
                                  :style="{ backgroundColor: '#ff5252', borderColor: '#ff5252' }"
                                  class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 ml-2 font-medium text-white shadow-sm hover:#5e8f32 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm hover-effect">
                                   Skip
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </VOnboardingStep>
                    </template>
                  </VOnboardingWrapper> -->
                  <div class="card-body">
                    <div v-if="artwork.products" class="row">
                      <div v-for="(product, productIndex) in artwork.products" :key="productIndex"
                        class="col-12 col-lg-6">
                        <div id="pageTwoImageResizing"
                          :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-mockup'" class="mockup">
                          <img ref="imageList" :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-image'"
                            class="mockup-image" :src="product?.front_image[0]?.webp" alt="">
                          <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-drawing-area'"
                            class="drawing-area">
                            <div ref="canvasContainer"
                              :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas-container'"
                              class="canvas-container">
                              <canvas :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas'"
                                class="mockup-canvas"></canvas>
                            </div>
                          </div>
                        </div>
                        <div v-if="product?.variations" class="d-flex flex-column">
                          <div id="pageTwoColor" v-for="variation in product?.variations" :key="variation.index"
                            class="d-flex justify-content-start py-2">
                            <div class="color-options d-flex justify-content-start flex-wrap w-100"
                              v-if="variation?.variation_options && variation.name_en.toLowerCase() == 'color'">
                              <Button v-for="option in variation.variation_options" :key="option.index"
                                :style="'width: 40px; height: 40px;' + 'background-color: ' + option.name_en + ';' + 'border: 1px solid ' + option.name_en + ';'"
                                :icon="artwork?.initial[product?.name_en]?.color == option.name_en ? 'pi pi-check' : ''"
                                @click.prevent="setIC(product?.name_en, option.name_en, artwork?.initial, productIndex); selectVariations(
                  variation.name_en.toLowerCase(),
                  option.name_en,
                  product
                );" class="p-button-rounded rounded-circle pxl-shadow" />
                            </div>
                            <div id="pageTwoSize" v-else-if="variation?.variation_options"
                              class="d-flex justify-content-start flex-wrap w-100">
                              <div class="other-options">
                                <button v-for="option in variation.variation_options" :key="option.index"
                                  class="btn-custom-light" @click.prevent="
                  selectVariations(
                    variation.name_en.toLowerCase(),
                    option.name_en,
                    product
                  )
                  " :class="{
                  active:
                    selectedOptions['options'][
                    variation.name_en.toLowerCase()
                    ] == option.name_en,
                }" style="width: 48px">
                                  {{ option.name_en }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="pageTwoImageSize" class="d-flex flex-column">
                    <p class="trigger" @click.prevent="showSizeChart = !showSizeChart">
                      <i class="fas fa-ruler"></i>
                      Size Chart
                    </p>
                    <span v-if="showSizeChart" class="text-3-g" v-html="settingsList['size-chart']">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
              <Button v-if="page == 1" @click.prevent="
                  router.push({
                    name: 'Account',
                  })
                  " label="Cancel" class="p-button-secondary" />
              <Button v-if="page == 1"
                :disabled="!artwork?.user_id || !artwork?.products || artwork?.products.length == 0 || !artwork?.name_en || !artwork?.slug"
                @click.prevent="goToPage(2)" label="Next" class="p-button-success" />

              <Button v-if="page == 2" @click.prevent="goToPage(1)" label="Back" class="p-button-secondary" />
              <Button v-if="page == 2" :disabled="!selectedOptions?.options?.color || !selectedOptions?.options?.size"
                @click.prevent="handleStore()" class="p-button-success">
                <p class="text-bold-3-s w-100 text-white text-center">
                  <span v-if="isLoading == false">Add to cart</span>
                  <ProgressSpinner v-else-if="isLoading == true" style="width: 20px; height: 20px;" strokeWidth="5" />
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import useArtworks from "@/composables/Artworks";
import useMockupEditor from "@/composables/MockupEditor";
import { useShopping } from "@/composables/Shopping";
import { logedinUser, isLoggedIn, settingsList } from "@/composables/auth";
import { removeFromArray, getArrayOfField } from "@/helpers/Functions";


// import {
//   VOnboardingWrapper,
//   VOnboardingStep,
//   useVOnboarding,
// } from "v-onboarding";

// const tutorial1 = localStorage.getItem('tutorial1');
// const tutorial2 = localStorage.getItem('tutorial2');

// const wrapper = ref(null);
// const { start, goToStep, finish } = useVOnboarding(wrapper);
// const steps = [
//   {
//     attachTo: { element: "#pageOneImage" },
//     title: "Welcome!",
//     content: { description: "Here press on the middle or top left corner to upload an image of your art, width >=800 height >= 800 transparent image" },
//   },
//   {
//     attachTo: { element: "#pageOneName" },
//     content: {
//       description:
//         "Write the display name of your art",
//     },
//   },
//   {
//     attachTo: { element: "#pageOneSelect" },
//     content: {
//       description:
//         "Select the products type that you want your art to be on!",
//     },
//   },
//   {
//     attachTo: { element: "#pageOneQunatitiy" },
//     content: {
//       description:
//         "If you want more than one shirt you can increase the quantity here",
//     },
//   },
// ];
// const steps1 = [
//   {
//     attachTo: { element: "#pageTwoImageResizing" },
//     content: {
//       description:
//         "Here you will scale the size of the image by pulling the edges of the image, and you will drag it to where you want the image to be on the shirt, do not go outside of the red box",
//     },
//   },
//   {
//     attachTo: { element: "#pageTwoColor" },
//     content: {
//       description:
//         "Select the Color of the shirt",
//     },
//   },
//   {
//     attachTo: { element: "#pageTwoSize" },
//     content: {
//       description:
//         "Select the size of the shirt",
//     },
//   },
//   {
//     attachTo: { element: "#pageTwoImageSize" },
//     content: {
//       description:
//         "based on those sizes you will select the size of the shirt",
//     },
//   },
// ];


// function setOffTutorial1() {
//   localStorage.setItem('tutorial1', '1');
//   finish();
// }

// function setOffTutorial2() {
//   localStorage.setItem('tutorial2', '1');
//   finish();
// }

const {
  getArtworkMeta,
  artworkMeta,
  storeArtwork,
  validationErrors,
  isLoading,
  artworkProcessingFiles,
  storeArtworkFiles,
} = useArtworks();

const showSizeChart = ref(true)

const {
  selectedOptions,
  setDefualtVariations,
  selectVariations,
  increaseQty,
  dcreaseQty,
  addToCart,
} = useShopping();

const {
  setUpEditor,
  setInitialColor,
} = useMockupEditor();

const artwork = ref({
  image: [],
  cover_image: [],
  description_ar: "",
  description_en: "",
  name_ar: "",
  name_en: "",
  slug: "",
  status_id: 1,
  user_id: 2,
  products: [],
  categories: [],
  tags: [],
  positions: [],
  initial: {},
  is_admin: false,
  direct: 1
});

const page = ref(1);

const uploadFileButton = ref(null);
function triggerButtonClick() {
  uploadFileButton.value.$el.click();
}

const tut1 = ref(null);

const canvasContainer = ref([]);
const imageList = ref([]);

const router = useRouter();
const userType = ref('guest');
onMounted(async () => {
  // if (tutorial1 == 0) {
  //   setTimeout(() => {
  //     start();
  //   }, 800);
  // }
  getArtworkMeta();
  if (isLoggedIn.value == true) {
    artwork.value.user_id = logedinUser?.id;
    if (logedinUser?.id == 1) {
      userType.value = 'admin';
    }
    else {
      userType.value = 'user';
    }
  }
});

watch(
  () => artwork.value.name_en,
  async (current) => {
    artwork.value.slug = artwork.value.name_en
      .toLowerCase()
      .replace("&", "and")
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  },
  { deep: true }
);

watch(
  () => artwork.value.products,
  async (current) => {
    selectedOptions.value.options.product = artwork.value.products[0]?.name_en;
    selectedOptions.value.product_id = artwork.value.products[0]?.id;
    selectedOptions.value.product = artwork.value.products[0];
  },
  { deep: true }
);

const goToPage = (pageNo) => {
  if (pageNo == 2) {
    var productsList = getArrayOfField(artwork.value.products, 'name_en');
    var initial = Object.keys(artwork.value.initial);
    initial.forEach((item, index) => {
      if (!productsList.includes(item)) {
        delete artwork.value.initial[item];
      } else {
        selectedOptions.value.options.product = item;
      }
    });
    setDefualtVariations(artwork);
    setTimeout(() => {
      setUpEditor(artwork, canvasContainer, imageList)
    }, 1000);

    // if (tutorial2 == 0) {
    //   setTimeout(() => {
    //     start();
    //   }, 800);
    // }
  }
  page.value = pageNo;
}

const setIC = (productName, optionName, artworkInitial, productIndex) => {
  setInitialColor(productName, optionName, artworkInitial, productIndex, imageList)
}

const handleStore = () => {
  storeArtwork(artwork.value, userType.value).then(async (response) => {
    if (response != null) {
      selectedOptions.value.artwork = response;
      selectedOptions.value.artwork_id = response?.id;
      router.push({ name: "Home" });
      addToCart(isLoggedIn.value, logedinUser?.id);
    }
  })
}
</script>
<style>
.mockup {
  width: 100%;
  /* background-repeat: no-repeat;
  background-size: auto; */
}

.canvas-container {
  margin: 0 !important;
}

.drawingArea canvas {
  mix-blend-mode: multiply !important;
}

.trigger {
  cursor: pointer;
  color: var(--primary-pxl);
}

.trigger:hover {
  color: var(--secondary-pxl);
  text-decoration-line: underline;
}

.hover-effect:hover {
  background-color: #ff7b7b;
  border-color: #ff7b7b;
}



[data-v-onboarding-wrapper] [data-popper-arrow]::before {
  content: "";
  background: var(--v-onboarding-step-arrow-background, #000);
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out, visibility 0.3s ease-out;
  visibility: visible;
  transform: translateX(0px) rotate(45deg);
  transform-origin: center;
  width: var(--v-onboarding-step-arrow-size, 10px);
  height: var(--v-onboarding-step-arrow-size, 10px);
  position: absolute;
  z-index: -1;
}

[data-v-onboarding-wrapper] [data-popper-placement^="top"]>[data-popper-arrow] {
  bottom: 1px;
}

[data-v-onboarding-wrapper] [data-popper-placement^="right"]>[data-popper-arrow] {
  left: -4px;
}

[data-v-onboarding-wrapper] [data-popper-placement^="bottom"]>[data-popper-arrow] {
  top: -4px;
}

[data-v-onboarding-wrapper] [data-popper-placement^="left"]>[data-popper-arrow] {
  right: -4px;
}
</style>
