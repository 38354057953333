/* eslint-disable no-unused-vars */
import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useOrders() {
  const orders = ref({});
  const order = ref({});
  const meta = ref({});
  const files = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getOrders = async (
    page = 1,
    search_id = "",
    search_date = "",
    search_reference = "",
    search_name_en = "",
    search_name_ar = "",
    search_vendor = "",
    search_status = "",
    search_parent = 1,
    search_children = 0,
    search_noguest = 0,
    search_global = "",
    order_column = "id",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/orders?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_date=" +
          search_date +
          "&search_reference=" +
          search_reference +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_vendor=" +
          search_vendor +
          "&search_status=" +
          search_status +
          "&search_parent=" +
          search_parent +
          "&search_children=" +
          search_children +
          "&search_noguest=" +
          search_noguest +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        orders.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getOrderMeta = async () => {
    axiosClient.get("/orders/meta").then((response) => {
      meta.value = response.data.meta;
    });
  };

  const getOrder = async (id) => {
    axiosClient.get("/orders/" + id).then((response) => {
      response.data.data.productData = response.data.data.product;
      response.data.data.product = response.data.products;
      order.value = response.data.data;
      meta.value = response.data.meta;
    });
  };

  const storeOrder = async (order) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    // let serializedOrder = new FormData();
    // for (let item in order) {
    //   if (order.hasOwnProperty(item)) {
    //     serializedOrder.append(item, order[item]);
    //   }
    // }

    let params = serialize(order, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/orders", params)
      .then((response) => {
        router.push({ name: "orders.index" });
        swal({
          icon: "success",
          title: "Order saved successfully",
        });
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Order error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeOrderMedia = async (files) => {
    let serializedOrder = new FormData();
    for (let item in files) {
      if (files.hasOwnProperty(item)) {
        serializedOrder.append(item, files[item]);
      }
    }

    axiosClient
      .post("/orders/media", serializedOrder)
      .then((response) => {
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateOrder = async (order) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/orders/" + order.id, order)
      .then((response) => {
        // router.push({ name: "orders.index" });
        swal({
          icon: "success",
          title: "Order saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteOrder = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/orders/" + id)
          .then((response) => {
            getOrders();
            router.push({ name: "orders.index" });
            swal({
              icon: "success",
              title: "Order deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const updatePaid = async (order) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(order, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/orders/updatePaid", params)
      .then((response) => {
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Order error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  return {
    orders,
    order,
    meta,
    files,
    getOrderMeta,
    getOrders,
    getOrder,
    storeOrder,
    validationErrors,
    isLoading,
    updateOrder,
    deleteOrder,
    storeOrderMedia,
    updatePaid,
  };
}
