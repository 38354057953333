<template>
  <div>
    <div class="container-fluid" style="padding: 0 !important; margin: 0 !important; height: 600px">
      <div v-if="sliders?.data" style="height: 600px">
        <!-- <Carousel id="main-slider" class="main-slider h-100"
          :value="sliders.data.filter((slider) => slider.location == 'home-main')" :showNavigators="false"
          :showIndicators="true" :autoplayInterval="10000" :page="0" :numVisible="1" :numScroll="1"
          :responsiveOptions="mainResponsiveOptions" contentClass="main-slider-content"
          containerClass="main-slider-container" indicatorsContentClass="main-slider-indicators">
          <template #item="slotProps">
            <div class="h-100 w-100">
              <img :src="slotProps.data.desktop_image && slotProps.data.mobile_image
              ? isMobile && slotProps.data.mobile_image[0]?.original_url
                ? slotProps.data.mobile_image[0]?.original_url
                : slotProps.data.desktop_image[0]?.original_url
              : 'img/user-defualt.svg'" alt="" class="h-100 w-100" style="object-fit: cover; object-position: center;">
            </div>
          </template>
</Carousel> -->
        <vCarousel id="slide-vCarousel" :items="sliders.data.filter((slider) => slider.location == 'home-main')"
          :navigation="false" :pagination="true" :startAutoPlay="true" :timeout="10000" class="carousel"
          v-slot="{ item }">
          <div class="h-100 w-100">
            <a :href="item?.link">
              <!-- <img :src="item.desktop_image && item.mobile_image
                ? isMobile && item.mobile_image[0]?.original_url
                  ? item.mobile_image[0]?.original_url
                  : item.desktop_image[0]?.original_url
                : 'img/user-defualt.svg'" alt="" class="h-100 w-100 tst"
                style="object-fit: cover; object-position: center;"> -->
              <img :src="item.desktop_image && item.mobile_image && item.tablet_image
                ? isTablet && item.tablet_image[0]?.webp
                  ? item.tablet_image[0]?.webp
                  : isMobile && item.mobile_image[0]?.webp
                    ? item.mobile_image[0]?.webp
                    : item.desktop_image[0]?.webp
                : 'img/user-default.svg'" alt="" class="h-100 w-100 tst"
                style="object-fit: cover; object-position: center;">

            </a>
          </div>
        </vCarousel>
      </div>
      <div v-else class="row h-100">&nbsp;</div>
    </div>
    <div v-if="isLoading">
      <SliderImageSkeleton />
    </div>
    <div v-else class="container-fluid bg-secondary bb-p p-0 pl-0 pr-0"
      style="width: 100%; min-height: 185px; overflow: hidden;">
      <carousel class="h-100 w-100" v-if="artworkCategories?.data" :items-to-show="8.5" :itemsToScroll="1"
        :autoplay="5000" :wrap-around="true" :breakpoints="carouselBreakpoints" snapAlign="center">
        <slide v-for="item in artworkCategories?.data" :key="item.index">
          <div @click.prevent="
            router.push({
              name: 'ThemeArtworks',
              params: {
                slug: item?.slug,
                // subslug: item?.slug,
                parent_name_en: item?.name_en,
                category_id: item?.id,
                category_name_en: item?.name_en,
              },
            })">
            <div class="
                                      card
                                      shadow-none
                                      theme-card
                                      bg-dark
                                      text-white
                                      border-0
                                      m-0
                                      px-1
                                      py-2
                                      m-pointer
                                      h-100
                                    " style="overflow: hidden;">
              <div class="image-wrapper">
                <picture>
                  <!-- WebP source - preferential format for web performance -->
                  <source v-if="item?.cover && item?.cover[0]?.webp" :srcset="item.cover[0].webp" type="image/webp" />

                  <!-- Fallback image -->
                  <img class="card-img h-100 w-100" style="object-fit: cover" :src="'/img/placeholders/block.png'"
                    alt="Card image" />
                </picture>

              </div>
              <div class="card-img-overlay d-flex align-items-end p-3">
                <div>
                  <p class="card-title text-sm font-weight-bold m-0">
                    {{ item?.name_en }}
                  </p>

                  <p v-if="item?.artworks" class="card-text text-sm m-0">
                    {{ item?.artworks.length }} artwork
                  </p>
                </div>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation class="themes" />
        </template>
      </carousel>


      <!-- <Carousel v-if="artworkCategories?.data" id="themes-slider" class="themes-slider h-100" :value="artworkCategories?.data" :showNavigators="true"
        :showIndicators="false" :autoplayInterval="7000" :circular="true" :page="0" :numVisible="5" :numScroll="3"
        :responsiveOptions="themesResponsiveOptions" contentClass="themes-slider-content"
        containerClass="themes-slider-container" indicatorsContentClass="themes-slider-indicators">
        <template #item="slotProps">
          <div class="h-100 w-100" @click.prevent="
          router.push({
            name: 'ThemeArtworks',
            params: {
              slug: slotProps?.data?.slug,
              subslug: slotProps?.data?.slug,
              parent_name_en: slotProps?.data?.name_en,
              category_id: slotProps?.data?.id,
              category_name_en: slotProps?.data?.name_en,
            },
          })">
            <div class="
                card
                shadow-none
                theme-card
                bg-dark
                text-white
                border-0
                m-0
                px-1
                py-2
                m-pointer
                h-100
              ">
              <img class="card-img h-100 w-100" style="object-fit: cover" :src="
                slotProps?.data?.cover && slotProps.data?.cover[0]?.url
                  ? slotProps.data?.cover[0]?.url
                  : '/img/placeholders/block.png'
              " alt="Card image" />
              <div class="card-img-overlay d-flex align-items-end p-3">
                <div>
                  <p class="card-title text-sm font-weight-bold m-0">
                    {{ slotProps?.data?.name_en }}
                  </p>
                  <p v-if="slotProps?.data?.children" class="card-text text-sm m-0">
                    {{ slotProps?.data?.children.length }} theme
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Carousel> -->
      <div v-else class="row h-100">&nbsp;</div>
    </div>
    <!-- Page content -->
    <div class="container mt-5">
      <div class="row mb-5 mx-2 mx-md-0 text-left">
        <div class="col-md-12 p-0 mx-auto">
          <p class="header-1-p">Designs</p>
          <p class="text-2-s">Recently listed designs</p>
        </div>
      </div>
      <div v-if="isLoading" class="row mb-5 mx-2 mx-md-0 justify-content-start">
        <div v-for="number in 12" :key="number" class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 mb-3">
          <ImageCardSkeleton />
        </div>
      </div>
      <div class="row mb-5 mx-2 mx-md-0 justify-content-start" v-else>
        <div v-for="artwork in artworks?.data" :key="artwork.index"
          class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 mb-3">
          <div>
            <div class="card card-custom bg-dark text-white border-0 m-0 ml-0 mr-0 mb-3 mx-auto mx-md-0">
              <img class="card-img" style="object-fit: cover"
                :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                :src="getArtworkImage(artwork)" alt="Card image" @click.prevent="
                  router.push({
                    name: 'Artwork',
                    params: { id: artwork?.slug },
                  })
                  " />
              <div class="btn-fav">
                <img @click.prevent="addToWishlist(artwork?.id, isLoggedIn)" :src="wishlistIDs.includes(artwork?.id)
                  ? 'img/favorite.svg'
                  : 'img/non-favorite.svg'
                  " />
              </div>
              <div class="img-footer">
                <span v-if="formatDateYYYYMMDD(artwork?.created_at) ==
                  formatDateYYYYMMDD(Date.now())
                " class="badge badge-secondary p-2">
                  New
                </span>
              </div>
            </div>
            <div @click.prevent="
              router.push({
                name: 'Artwork',
                params: { id: artwork?.slug },
              })
              " class="d-flex align-items-start flex-column m-pointer mx-auto mx-md-0 card-custom-info">
              <span class="header-3-s one-line-text">
                {{ artwork?.name_en }}
              </span>
              <span class="text-4-g one-line-text">
                {{ artwork?.user?.name_en }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5 text-center">
        <div class="col-md-8 mx-auto">
          <button class="btn-custom-p" type="button" @click.prevent="router.push({ name: 'Themes' })">
            <span class="btn-inner-text">View All</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 mb-5" style="padding: 0 !important" v-if="sliders?.data">
      <img v-for="(slider, index) in sliders?.data.filter(
        (slider) => slider.location == 'home-ads'
      )" :key="index" class="w-100" style="object-fit: cover" :src="slider.desktop_image && slider.mobile_image
        ? isMobile && slider.mobile_image[0]?.webp
          ? `${slider.mobile_image[0]?.webp}`
          : `${slider.desktop_image[0]?.webp}`
        : `img/user-defualt.svg`
        " :alt="slider.title_en" />
    </div>
    <div v-if="featuredUsers?.data && featuredUsers?.data?.length > 0" class="container">
      <div class="row text-left mx-2 mx-md-0">
        <div class="col-md-12 p-0 mx-auto">
          <p class="header-1-p">Featured artists</p>
          <p class="text-2-s">Artists to keep an eye on!</p>
        </div>
      </div>
    </div>
    <div v-if="featuredUsers?.data && featuredUsers?.data?.length > 0" class="container-fluid px-0 py-4">
      <div class="featuredUsers">
        <vCarousel id="featuredUsers-vCarousel" :items="featuredUsers.data" :navigation="false" :pagination="true"
          :startAutoPlay="true" :timeout="10000" class="carousel" v-slot="{ item }">
          <div class="container">
            <div class="card" style="min-height: 330px; border-radius: 16px;">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <div class="artiest-details d-flex flex-column">
                      <div class="media align-items-center mt-3 pointer" @click.prevent="router.push({
                        name: 'Artist',
                        params: { slug: item?.username },
                      })">
                        <span class="avatar avatar-xl rounded-circle">
                          <img alt="Avatar placeholder" style="object-fit: cover" class="h-100 w-100" :src="item.avatar && item?.avatar[0]?.url
                            ? item?.avatar[0]?.url
                            : 'img/user-defualt.svg'
                            " />
                        </span>
                      </div>
                      <span class="text-bold-2-s text-left mt-3 pointer" @click.prevent="router.push({
                        name: 'Artist',
                        params: { slug: item?.username },
                      })">
                        {{ item?.name_en }} <i v-if="item?.type_id == 1" class="primary-pxl fas fa-certificate"></i>
                      </span>
                      <span class="text-3-g text-left">
                        {{ item?.about_en }}
                      </span>
                    </div>
                    <div class="social-media mt-lg-3">
                      <div class="d-flex justify-content-start" v-if="item?.socials">
                        <div v-for="social in item.socials" :key="social.index" style="width: 30px; height: 30px;">
                          <a :href="social.link" target="_blank" style="width: 30px; height: 30px;">
                            <img style="object-fit: contain; width: 100%; height: 100%; padding-right: 0.5rem;" :src="social.type.icon && social?.type?.icon[0]?.url
                              ? social?.type?.icon[0]?.url
                              : '/img/placeholders/block.png'
                              " alt="" loading="lazy" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-7">
                    <div class="row m-0 mb-5 mt-3 w-100" v-if="item.artworks">
                      <div v-for="artwork in item.artworks
                        .filter(artwork => artwork.is_active)
                        .filter((artwork, index) => isMobile ? index < 2 : index < 3)" :key="artwork.index" class="col-6 col-lg-4 p-0">
                        <div @click.prevent="
                          router.push({
                            name: 'Artwork',
                            params: { id: artwork?.slug },
                          })">
                          <div class="card card-custom bg-dark text-white border-0 m-0 mb-3 mx-auto">
                            <img class="card-img" style="object-fit: contain"
                              :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                              :src="getArtworkImage(artwork)" alt="Card image" />
                            <div class="card-img-overlay d-flex align-items-end flex-column p-2"></div>
                          </div>
                          <div class="d-flex align-items-start flex-column mx-auto card-custom-info">
                            <span class="header-3-s one-line-text">
                              {{ artwork?.name_en }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vCarousel>
      </div>
      <!-- End of Carousel -->
      <div v-if="featuredUsers?.data && featuredUsers?.data?.length > 0"
        class="mb-5 text-center justify-center marginTop10Rem">
        <div class="col-md-8 mx-auto">
          <button class="btn-custom-p" type="button" @click.prevent="router.push({ name: 'Artists' })">
            <span class="btn-inner-text">View Artists</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mb-5 mx-2 mx-md-0 text-left">
        <div class="col-md-12 p-0 mx-auto">
          <img class="w-100" style="object-fit: cover" :src="isMobile ? 'img/how-to-mobile.png' : 'img/how-to.png'"
            alt="how-to" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { isLoggedIn } from "@/composables/auth";
import { useShopping } from "@/composables/Shopping";
import useUsers from "@/composables/Users";
import useArtworks from "@/composables/Artworks";
import useArtworkCategories from "@/composables/ArtworkCategories";
import useSliders from "@/composables/Sliders";
import ScrollPanel from 'primevue/scrollpanel';
// import Carousel from 'primevue/carousel';
// import Carouselx1 from "@/components/Carouselx1";
// import Carouselx8 from "@/components/Carouselx8";
// import Carouselx2 from "@/components/Carouselx2";

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import vCarousel from "@/components/vCarousel";
import vSlide from "@/components/vSlide";
import vSlider from "@/components/vSlider";
import ImageCardSkeleton from "@/components/ImageCardSkeleton";
import SliderImageSkeleton from "@/components/SliderImageSkeleton";

export default {
  components: {
    // Carouselx1,
    // Carouselx8,
    // Carouselx2,
    // Carousel,
    Carousel,
    Slide,
    Navigation,
    vCarousel,
    vSlide,
    vSlider,
    ScrollPanel,
    ImageCardSkeleton,
    SliderImageSkeleton,
  },
  setup() {
    const isMobile = ref(false);
    const isTablet = ref(false);
    const { addToWishlist, wishlistIDs } = useShopping();
    const { getFeaturedUsers, featuredUsers } = useUsers();
    const { getSliders, sliders } = useSliders();
    const { artworks, getArtworks } = useArtworks();
    const { artworkCategories, getArtworkCategories } = useArtworkCategories();
    const router = useRouter();

    const isLoading = ref(true);

    const screenWidth = ref(window.innerWidth);
    if (screenWidth.value <= 1140 && screenWidth.value > 650) {
      isTablet.value = true;
      isMobile.value = false;
    } else if (screenWidth.value <= 650) {
      isTablet.value = false;
      isMobile.value = true;
    } else {
      isTablet.value = false;
      isMobile.value = false;
    }

    onMounted(async () => {
      await getSliders(1, "", "", "", "home-main,home-ads", "", "order", "asc", 10);
      await getArtworks(1, "", "", "", "", "", "", "", "", "id", "desc", 24, 1, 0, 1);
      await getArtworkCategories(1, "", "", "", "", "", "id", "asc", 24, 1);
      await getFeaturedUsers();
      window.onresize = () => {
        screenWidth.value = window.innerWidth;
        if (screenWidth.value <= 1140 && screenWidth.value > 650) {
          isTablet.value = true;
          isMobile.value = false;
        } else if (screenWidth.value <= 650) {
          isTablet.value = false;
          isMobile.value = true;
        } else {
          isTablet.value = false;
          isMobile.value = false;
        }
      };
      setTimeout(() => {
        isLoading.value = false;
      }, 1500);
    });
    const mainResponsiveOptions = ref([
      {
        breakpoint: "992px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "576px",
        numVisible: 1,
        numScroll: 1,
      },
    ]);
    const themesResponsiveOptions = ref([
      {
        breakpoint: "992px",
        numVisible: 5,
        numScroll: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "576px",
        numVisible: 2,
        numScroll: 2,
      },
    ]);

    const carouselBreakpoints = ref({
      1: {
        itemsToShow: 1.5,
        snapAlign: 'center',
      },
      577: {
        itemsToShow: 2.7,
        snapAlign: 'center',
      },
      768: {
        itemsToShow: 4.5,
        snapAlign: 'center',
      },
      993: {
        itemsToShow: 5.5,
        snapAlign: 'center',
      },
      1201: {
        itemsToShow: 8.2,
        snapAlign: 'center',
      },
    });

    const formatDateYYYYMMDD = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    };

    function getArtworkImage(artwork) {
      if (artwork?.cover_image && artwork?.cover_image[0]?.webp) {
        return artwork.cover_image[0].webp;
      } else if (artwork?.image[0]?.webp) {
        return artwork.image[0].webp;
      } else if (artwork?.image[0]?.original_url) {
        return artwork.image[0].original_url;
      } else {
        return '/img/placeholders/block.png';
      }
    }



    return {
      isLoggedIn,
      artworks,
      artworkCategories,
      router,
      mainResponsiveOptions,
      themesResponsiveOptions,
      formatDateYYYYMMDD,
      featuredUsers,
      sliders,
      isMobile,
      isTablet,
      addToWishlist,
      wishlistIDs,
      carouselBreakpoints,
      isLoading,
      getArtworkImage,
    };
  },
  created() {
    if (!localStorage.getItem('tutorial1')) {
      localStorage.setItem('tutorial1', '0');
    }
    if (!localStorage.getItem('tutorial2')) {
      localStorage.setItem('tutorial2', '0');
    }
  },
};
</script>
<style scoped>
.carousel__prev.themes,
.carousel__next.themes {
  background: #fff !important;
}

.theme-card {
  width: 100%;
  height: 223px;
}

.theme-card .card-img {
  height: 100%;
}

.theme-card .card-img-overlay {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.35057773109243695) 0%,
      rgba(0, 212, 255, 0) 35%);
}

.theme-card .card-title {
  font-family: "Poppins-Bold";
}

.card.card-custom:hover {
  cursor: pointer;
}

.card-custom {
  width: 180px;
  height: 180px;
  margin-bottom: 0.5rem;
}

.card-custom .card-img {
  height: 100%;
}

.home-carousel .carousel-inner .carousel-item {
  width: 100%;
  height: 60vh !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}

.carousel-featured-artists .carousel-inner .carousel-item {
  width: 100%;
  height: auto !important;
}

.carousel-featured-artists .carousel-inner .carousel-item .card {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 16px;
}

.main-slider-content {
  position: relative;
  height: 100% !important;
}

.main-slider-container {
  height: 100% !important;
}

.main-slider-container>*>.p-carousel-items-container {
  height: 100% !important;
}



.main-slider-indicators {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.p-carousel.main-slider .p-carousel-indicators.main-slider-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--secondary-pxl);
  border: 1px solid var(--secondary-pxl);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.p-carousel.main-slider .p-carousel-indicators.main-slider-indicators .p-carousel-indicator button {
  background-color: transparent;
  border: 1px solid var(--secondary-pxl);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}


.themes-slider-content {
  position: relative;
  height: 100% !important;
}

.themes-slider-container {
  height: 100% !important;
}

.themes-slider-container>*>.p-carousel-items-container {
  height: 100% !important;
}

.themes-slider-indicators {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.p-carousel.themes-slider .p-carousel-indicators.themes-slider-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--secondary-pxl);
  border: 1px solid var(--secondary-pxl);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.p-carousel.themes-slider .p-carousel-indicators.themes-slider-indicators .p-carousel-indicator button {
  background-color: transparent;
  border: 1px solid var(--secondary-pxl);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.featuredUsers {
  height: 400px;
}

@media screen and (max-width: 991px) {
  .featuredUsers {
    height: 600px;
  }
}

@media (min-width: 768px) {

  .main-content .container-fluid,
  .main-content .container-sm,
  .main-content .container-md,
  .main-content .container-lg,
  .main-content .container-xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.tst {
  overflow: hidden;
  position: relative;
  display: block;
}

.image-wrapper {
  position: relative;
  max-width: 300px;
  overflow: hidden;
  max-height: 300px;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.card-img {
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 651px) and (max-width: 991px) {
  ::v-deep(.pagination) {
    position: absolute !important;
    top: 700px !important;
  }

  .marginTop10Rem {
    margin-top: 10rem;
  }
}
</style>
