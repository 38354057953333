<template>
  <div class="carousel">
    <div class="inner" ref="inner" :style="innerStyles">
      <div class="slidercard" v-for="item in items" :key="item.index">
        <slot :item="item" />
      </div>
    </div>
  </div>
  <button @click="prev">prev</button>
  <button @click="next">next</button>
</template>

<script>
export default {
  props: ["vitems", "vinnerStyles", "vstep", "vtransitioning"],
  data() {
    return {
      items: this.vitems,
      innerStyles: this.vinnerStyles,
      step: this.vstep,
      transitioning: this.vtransitioning,
    }
  },
  mounted() {
    this.setStep()
    this.resetTranslate()
  },
  methods: {
    setStep() {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.items.length
      this.step = `${innerWidth / totalCards}px`
    },

    next() {
      if (this.transitioning) return

      this.transitioning = true

      this.moveLeft()

      this.afterTransition(() => {
        const slidercard = this.items.shift()
        this.items.push(slidercard)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    prev() {
      if (this.transitioning) return

      this.transitioning = true

      this.moveRight()

      this.afterTransition(() => {
        const slidercard = this.items.pop()
        this.items.unshift(slidercard)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    moveLeft() {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                    translateX(-${this.step})`
      }
    },

    moveRight() {
      this.innerStyles = {
        transform: `translateX(${this.step})
                    translateX(-${this.step})`
      }
    },

    afterTransition(callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },

    resetTranslate() {
      this.innerStyles = {
        transition: 'none',
        transform: `translateX(-${this.step})`
      }
    }
  }
}
</script>

<style scoped>
.carousel {
  width: 100%;
  overflow: hidden;
}

.inner {
  transition: transform 0.2s;
  white-space: nowrap;
}

.slidercard {
  width: 243px;
  display: inline-flex;

  /* optional */
  height: 100%;
  background-color: #39b1bd;
  color: white;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

/* optional */
button {
  margin-right: 5px;
  margin-top: 10px;
}
</style>
