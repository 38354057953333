<template>
  <router-view v-if="is_token"></router-view>
  <div v-else style="height: 100vh; width: 100vw; display: flex; justify-content: center; align-items: center;">
    <img src="/img/brand/default-logo.png" class="loading-logo" alt="is loading...">
  </div>

</template>

<script>
import { onMounted, ref } from "vue";
import {useAuth} from "./composables/auth";

export default {
  setup() {
    const { getPublicToken, getSettingsList } = useAuth();
    const is_token = ref(false);
    onMounted(async () => {
      await getPublicToken().then(async () => {
        await getSettingsList();
        setTimeout(() => {
          is_token.value = true
        }, 2000);
      });
    });

    return {
      is_token,
    };
  },
};
</script>
