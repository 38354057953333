<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_name_en" class="form-control" placeholder="Search name" type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page"
                    :options="per_page_options" optionLabel="text" optionValue="value" />
                </div>
                <div>
                  <Button v-if="can('artwork_create')" label="New" @click.prevent="
                    router.push({
                      name: 'artworks.create',
                    })
                    " class="p-button-success" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
                              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Art</th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('name_en')">
                          <div :class="{
                            '': orderColumn === 'name_en',
                          }">
                            Name
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'name_en' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'name_en' },
                              { 'pi pi-sort-alt': orderColumn != 'name_en' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Status</th>
                      <th>Custom Print</th>
                      <th>Is Featured </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="artwork in artworks.data" :key="artwork.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ artwork.id }}</span>
                      </td>
                      <td>
                        <img id="artwork-image" class="img-fluid" style="object-fit: cover; width: 150px;" :src="artwork.image && artwork?.image[0]?.url
                          ? artwork?.image[0]?.url
                          : '/img/placeholders/block.png'
                          " alt="" loading="lazy" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ artwork.name_en }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="artwork.status" :class="{
                          'badge-warning': artwork.status_id == 1,
                          'badge-success': artwork.status_id == 2,
                          'badge-danger': artwork.status_id == 3,
                        }" class="badge badge-lg mr-2" style="width: 84px">
                          {{ artwork.status.name_en }}
                          <svg v-if="artwork.status_id == 3" id="warning" xmlns="http://www.w3.org/2000/svg" width="15"
                            height="15" viewBox="0 0 15 15">
                            <path id="Path_319" data-name="Path 319" d="M0,0H15V15H0Z" fill="none" />
                            <path id="Path_320" data-name="Path 320"
                              d="M8.625,3h0A5.625,5.625,0,0,1,14.25,8.625h0A5.625,5.625,0,0,1,8.625,14.25h0A5.625,5.625,0,0,1,3,8.625H3A5.625,5.625,0,0,1,8.625,3Z"
                              transform="translate(-1.125 -1.125)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="1.5" />
                            <path id="Path_321" data-name="Path 321" d="M12,10.625V7.5"
                              transform="translate(-4.5 -2.813)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="1.5" />
                            <path id="Path_322" data-name="Path 322"
                              d="M11.906,16a.156.156,0,1,0,.157.156A.155.155,0,0,0,11.906,16"
                              transform="translate(-4.406 -6)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="1.5" />
                          </svg>
                        </span>
                        <span :class="{
                          'badge-success': artwork.is_active == 1,
                          'badge-danger': artwork.is_active == 0,
                        }" class="badge badge-lg mr-2">
                          <span v-if="artwork.is_active == 1">
                            {{ 'Activated' }}
                          </span>
                          <span v-else>
                            {{ 'not Activated' }}
                          </span>


                        </span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="artwork.is_direct">Yes</span>
                        <span v-else>No</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="artwork.is_featured">Yes</span>
                        <span v-else>No</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button class="p-button-info" icon="fas fa-eye" v-if="can('artwork_show')" @click.prevent="
                          router.push({
                            name: 'artworks.show',
                            params: { id: artwork.id },
                          })
                          " />
                        <Button class="p-button-warning" icon="fas fa-pen-fancy" v-if="can('artwork_edit')"
                          @click.prevent="
                            router.push({
                              name: 'artworks.edit',
                              params: { id: artwork.id },
                            })
                            " />
                        <Button class="p-button-danger" icon="fas fa-trash" v-if="can('artwork_delete')"
                          @click.prevent="deleteArtwork(artwork.id, 'admin')" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ artworks?.meta?.from }} to
                    {{ artworks?.meta?.to }} of
                    {{ artworks?.meta?.total }}
                  </p>
                </div>
                <div>
                  <!-- <pagination :data="artworks" :limit="1" @pagination-change-page="(page) =>
              getArtworks(
                page,
                search_id,
                search_name_en,
                search_name_ar,
                search_categories,
                search_products,
                search_tags,
                search_variations,
                search_global,
                orderColumn,
                orderDirection,
                per_page,
                inactive,
                is_direct,
                is_featured
              )
              " /> -->
                  <pagination :data="artworks" :limit="1" @pagination-change-page="(page) => { }" />

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import useArtworks from "@/composables/Artworks";
import { isLoggedIn, logedinUser } from "@/composables/auth";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
import TriStateCheckbox from 'primevue/tristatecheckbox';

export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
    TriStateCheckbox,
  },
  setup() {
    const page = ref(1);
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_categories = ref([]);
    const search_products = ref([]);
    const search_tags = ref([]);
    const search_variations = ref([]);
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderColumnOptions = ref([]);
    const orderDirection = ref("desc");
    const orderDirectionOptions = ref([]);
    const inactive = ref(0);
    const is_direct = ref(0);
    const is_featured = ref(0);
    const route = useRoute();

    const router = useRouter();
    const { artworks, getArtworks, deleteArtwork } = useArtworks();
    const { can } = useAbility();
    onMounted(async () => {
      const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
      page.value = pageNumber;
      await getArtworks(
        page.value,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "id",
        "desc",
        10,
        0,
        0,
        0,
      );
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
      orderDirectionOptions.value = [
        { text: "ASC", value: "asc" },
        { text: "DESC", value: "desc" },
      ];
      orderColumnOptions.value = [
        { text: "Default", value: "id" },
        { text: "Date", value: "created_at" },
        { text: "Name", value: "name_en" },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    };

    watch(search_id, (current) => {
      getArtworks(
        1,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });
    watch(search_name_en, (current) => {
      getArtworks(
        1,
        search_id.value,
        current,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });
    watch(search_name_ar, (current) => {
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        current,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });
    watch(search_categories, (current) => {
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });

    watch(
      () => search_products.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
          is_featured.value,
        );
      },
      { deep: true }
    );

    watch(
      () => search_tags.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
          is_featured.value,
        );
      },
      { deep: true }
    );

    watch(
      () => search_variations.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
          is_featured.value,
        );
      },
      { deep: true }
    );
    watch(search_global, (current) => {
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });
    watch(per_page, (current) => {
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current,
        inactive.value,
        is_direct.value,
        is_featured.value,
      );
    });

    watch(() => route.query.page, (current) => {
      const pageNumber = current ? parseInt(current, 10) : 1;
      page.value = pageNumber;
    });

    watch(() => page.value, (current) => {
      getArtworks(
        page.value,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
        is_featured.value
      );
    })

    return {
      route,
      router,
      artworks,
      getArtworks,
      deleteArtwork,
      search_id,
      search_name_en,
      search_name_ar,
      search_categories,
      search_products,
      search_tags,
      search_variations,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
      isLoggedIn,
      logedinUser,
      is_direct,
      is_featured,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
