<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5 mb-5">
      <div v-if="wishlistItems.data && wishlistItems.data.length > 0" class="row">
        <div class="col-md-12">
          <p class="header-1-p">My favorite artworks</p>
        </div>
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr v-for="(item, index) in wishlistItems.data" :key="item.index">
                  <td class="col-2 pl-0">
                    <img class="img-fluid m-pointer" style="object-fit: cover" :src="
                      item && item?.cover_image[0]?.webp
                        ? item?.cover_image[0]?.webp
                        : item?.image[0]?.webp
                          ? item?.image[0]?.webp
                          : '/img/placeholders/block.png'
                    " @click.prevent="
  router.push({
    name: 'Artwork',
    params: { id: item.slug },
  })
" alt="" loading="lazy" />
                  </td>
                  <td class="col-9 align-middle">
                    <div class="m-pointer" @click.prevent="
                      router.push({
                        name: 'Artwork',
                        params: { id: item.slug },
                      })
                    ">
                      <div class="header-2-s">
                        {{ item?.name_en }}
                      </div>
                      <div class="text-3-s">
                        {{ item?.description_en }}
                      </div>
                    </div>
                  </td>
                  <td class="col-1 pr-0 align-middle">
                    <button class="btn-custom-light" @click.prevent="deleteWishlistItem(item.id, isLoggedIn)">
                      <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path id="Path_315" data-name="Path 315"
                          d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                          fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" />
                        <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24" fill="none" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="wishlistItems" class="d-flex justify-content-end">
            <pagination :data="wishlistItems" :limit="1"
              @pagination-change-page="(page) => getWishlistItems(page, isLoggedIn)" />
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <p class="header-1-p">Wishlist</p>
          <p class="text-2-s">Need inspiration?</p>
        </div>
        <div class="col-12">
          <div class="w-100 d-flex justify-content-center">
            <img src="/img/undraw_Wishlist_re_m7tv.png" class="w-50" alt="undraw_Wishlist_re_m7tv">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
import { isLoggedIn } from "@/composables/auth";
import { useShopping } from "@/composables/Shopping";
import { useRouter } from "vue-router";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
export default {
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const { getWishlistItems, wishlistItems, deleteWishlistItem } =
      useShopping();
    const router = useRouter();
    onMounted(async () => {
      getWishlistItems(1, isLoggedIn);
    });
    return {
      wishlistItems,
      deleteWishlistItem,
      getWishlistItems,
      isLoggedIn,
      router,
    };
  },
};
</script>
<style>

</style>
