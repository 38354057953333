import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useSocialTypes() {
  const socialTypes = ref({});
  const socialType = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getSocialTypes = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/social-types?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        socialTypes.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getSocialType = async (id) => {
    axiosClient.get("/social-types/" + id).then((response) => {
      socialType.value = response.data.data;
    });
  };

  const storeSocialType = async (socialType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};


    let params = serialize(socialType, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/social-types", params)
      .then((response) => {
        router.push({ name: "social-types.index" });
        swal({
          icon: "success",
          title: "social type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateSocialType = async (socialType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(socialType, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .put("/social-types/" + socialType.id, socialType)
      .then((response) => {
        router.push({ name: "social-types.index" });
        swal({
          icon: "success",
          title: "Social type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteSocialType = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/social-types/" + id)
          .then((response) => {
            getSocialTypes();
            router.push({ name: "social-types.index" });
            swal({
              icon: "success",
              title: "Social type deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    socialTypes,
    socialType,
    getSocialTypes,
    getSocialType,
    storeSocialType,
    validationErrors,
    isLoading,
    updateSocialType,
    deleteSocialType,
  };
}
