<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_title_en" class="form-control" placeholder="Search title" type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page" :options="per_page_options"
                    optionLabel="text" optionValue="value" />
                </div>
                <div>
                  <Button v-if="can('slider_create')" label="New" @click.prevent="
                    router.push({
                      name: 'sliders.create',
                    })
                    " class="p-button-success" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div slider="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
                              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Desktop Image</th>
                      <th>Mobile Image</th>
                      <th>Tablet Image</th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('title_en')">
                          <div :class="{
                            '': orderColumn === 'title_en',
                          }">
                            Title
                          </div>
                          <div slider="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'title_en' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'title_en' },
                              { 'pi pi-sort-alt': orderColumn != 'title_en' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('location')">
                          <div :class="{
                            '': orderColumn === 'location',
                          }">
                            Location
                          </div>
                          <div slider="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'location' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'location' },
                              { 'pi pi-sort-alt': orderColumn != 'location' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Order</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="slider in sliders.data" :key="slider.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ slider.id }}</span>
                      </td>
                      <td>
                        <img id="artwork-image" class="img-fluid" style="object-fit: cover; height: 150px" :src="slider.desktop_image &&
                            slider?.desktop_image[0]?.url
                            ? slider?.desktop_image[0]?.url
                            : '/img/placeholders/block.png'
                          " alt="" loading="lazy" />
                      </td>
                      <td>
                        <img id="artwork-image" class="img-fluid" style="object-fit: cover; height: 150px" :src="slider.mobile_image && slider?.mobile_image[0]?.url
                            ? slider?.mobile_image[0]?.url
                            : '/img/placeholders/block.png'
                          " alt="" loading="lazy" />
                      </td>
                      <!-- Tablet Image -->
                      <td>
                        <img id="artwork-image" class="img-fluid" style="object-fit: cover; height: 150px" :src="slider.tablet_image && slider?.tablet_image[0]?.url
                            ? slider?.tablet_image[0]?.url
                            : '/img/placeholders/block.png'
                          " alt="" loading="lazy" />
                      </td>
                      <!-- End of Tablet Image -->
                      <td class="text-sm font-weight-normal">
                        <span>{{ slider.title_en }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ slider.location }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ slider.order }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button class="p-button-info" icon="fas fa-eye" v-if="can('slider_show')" @click.prevent="
                          router.push({
                            name: 'sliders.show',
                            params: { id: slider.id },
                          })
                          " />
                        <Button class="p-button-warning" icon="fas fa-pen-fancy" v-if="can('slider_edit')" @click.prevent="
                          router.push({
                            name: 'sliders.edit',
                            params: { id: slider.id },
                          })
                          " />
                        <Button class="p-button-danger" icon="fas fa-trash" v-if="can('slider_delete')"
                          @click.prevent="deleteSlider(slider.id)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ sliders?.meta?.from }} to
                    {{ sliders?.meta?.to }} of
                    {{ sliders?.meta?.total }}
                  </p>
                </div>
                <div>
                  <pagination :data="sliders" :limit="1" @pagination-change-page="(page) =>
                      getSliders(
                        page,
                        search_id,
                        search_title_en,
                        search_title_ar,
                        search_global,
                        orderColumn,
                        orderDirection,
                        per_page
                      )
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import useSliders from "@/composables/Sliders";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";


export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = 1;
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_title_en = ref("");
    const search_title_ar = ref("");
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");

    const router = useRouter();
    const { sliders, getSliders, deleteSlider } = useSliders();
    const { can } = useAbility();
    onMounted(async () => {
      await getSliders();
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getSliders(
        1,
        search_id.value,
        search_title_en.value,
        search_title_ar.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(search_id, (current) => {
      getSliders(
        1,
        current,
        search_title_en.value,
        search_title_ar.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_title_en, (current) => {
      getSliders(
        1,
        search_id.value,
        current,
        search_title_ar.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_title_ar, (current) => {
      getSliders(
        1,
        search_id.value,
        search_title_en.value,
        current,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_global, (current) => {
      getSliders(
        1,
        search_id.value,
        search_title_en.value,
        search_title_ar.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getSliders(
        1,
        search_id.value,
        search_title_en.value,
        search_title_ar.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });

    return {
      router,
      sliders,
      getSliders,
      deleteSlider,
      search_id,
      search_title_en,
      search_title_ar,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
