<template>
  <div class="container-fluid px-5 mb-5 mt-n20">
    <form @submit.prevent="updateSiteSettings(siteSettings)">
      <div class="card p-0 mt-3" style="min-height: 50vh;">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <h5 class="m-0">{{ "Site Settings" }}</h5>
            </div>
            <div class="d-flex align-items-center">
              <!-- <Button
                :label="'Add'"
                class="p-button-success"
                @click.prevent=""
              /> -->
            </div>
          </div>
          <hr />
          <div class="row">
            <div v-for="siteSetting in siteSettings" :key="siteSetting.index" class="col-md-12 mt-4">
              <div class="d-flex flex-column">
                <label class="text-capitalize" for="s_label">{{ siteSetting.s_label }} <span
                    v-if="siteSetting.s_type == 5">%</span></label>
                <!-- text -->
                <InputText :id="siteSetting.s_key" :name="siteSetting.s_key" v-model="siteSetting.s_value" type="text"
                  v-if="siteSetting.s_type == 1" />
                <!-- textarea -->
                <Textarea v-model="siteSetting.s_value" :autoResize="true" rows="5" cols="30"
                  v-if="siteSetting.s_type == 2" />
                <!-- texteditor -->
                <Editor v-model="siteSetting.s_value" editorStyle="height: 320px" :id="siteSetting.s_key"
                  :name="siteSetting.s_key" v-if="siteSetting.s_type == 3" />
                <!-- number -->
                <InputNumber :inputId="siteSetting.s_key" v-model="siteSetting.s_value" showButtons
                  buttonLayout="horizontal" :step="1" :min="0" decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus" v-if="siteSetting.s_type == 4" />
                <!-- percentage -->
                <InputNumber :inputId="siteSetting.s_key" :min="0" :minFractionDigits="2" v-model="siteSetting.s_value"
                  v-if="siteSetting.s_type == 5" />
                <!-- currency -->
                <InputNumber :inputId="siteSetting.s_key" v-model="siteSetting.s_value" showButtons
                  buttonLayout="horizontal" :step="1" :min="0" decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus" mode="currency" currency="BHD" v-if="siteSetting.s_type == 6" />
                <!-- image -->
                <!-- <div v-if="siteSetting.s_type == 7">
                  <FileUpload :name="siteSetting.s_key + '-image[]'" :multiple="false" :fileLimit="1" :auto="true"
                    accept="image/*" :customUpload="true" @uploader="
                      storeSettingFiles(
                        $event,
                        false,
                        'setting_image',
                        30,
                        siteSetting.id,
                        siteSetting,
                        true
                      )
                    ">
                    <template #empty>
                      <div v-if="siteSetting?.s_value" class="p-2">
                        <Image :src="siteSetting.s_value ? siteSetting.s_value : '/imgs/placeholder1x1.png'" alt="Image"
                          width="100" preview />
                      </div>
                      <p v-else>{{ "Drag and drop files to here to upload." }}</p>
                    </template>
                  </FileUpload>
                </div> -->
                <!-- gallery -->
                <!-- <div v-if="siteSetting.s_type == 8">
                  <FileUpload :name="siteSetting.s_key + '-gallery[]'" :multiple="true" :fileLimit="10" :auto="true"
                    accept="image/*"  :customUpload="true" @uploader="
                      storeSettingFiles(
                        $event,
                        true,
                        'setting_image',
                        30,
                        siteSetting.id,
                        siteSetting,
                        true
                      )
                    ">
                    <template #empty>
                      <div v-if="siteSetting?.s_value" class="d-flex justify-content-start flex-wrap">
                        <div v-for="(settinggallery, index) in siteSetting?.s_value" :key="index"
                          class="p-2 position-relative">
                          <div class="position-absolute top-0 end-0 pointer"
                            @click.prevent="removeFromArray(siteSetting.s_value, index)">
                            <i class="fa-solid fa-circle-xmark"></i>
                          </div>
                          <Image :src="settinggallery ? settinggallery : '/imgs/placeholder1x1.png'" alt="Image"
                            width="100" preview />
                        </div>
                      </div>
                      <p v-else>{{ "Drag and drop files to here to upload." }}</p>
                    </template>
                  </FileUpload>
                </div> -->
                <div class="row" v-for="message in validationErrors['s_value']" :key="message.index">
                  <span class="text-danger">
                    {{ message }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div>
          <!-- <Button :label="'Cancel'" @click.prevent="
            router.push({
              name: 'global.setting.index',
            })
          " /> -->
        </div>
        <div>
          <Button type="submit" :label="'Save'" class="p-button-success" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Editor from 'primevue/editor'
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";
import useGlobalSettings from "../../composables/GlobalSettings";
import { removeFromArray } from "../../helpers/Functions";
export default {
    components: { Editor },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    const {
      siteSettings,
      globalSettingMeta,
      getSiteSettings,
      isLoading,
      updateSiteSettings,
      validationErrors,
    } = useGlobalSettings();

    onMounted(async () => {
      await getSiteSettings();
    });

    return {
      router,
      can,
      globalSettingMeta,
      isLoading,
      siteSettings,
      updateSiteSettings,
      validationErrors,
      removeFromArray,
    };
  },
};
</script>
<style scoped>

</style>
