import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useArtworkTags() {
  const artworkTags = ref({});
  const artworkTag = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getArtworkTags = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/artwork-tags?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        artworkTags.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getArtworkTag = async (id) => {
    axiosClient.get("/artwork-tags/" + id).then((response) => {
      artworkTag.value = response.data.data;
    });
  };

  const storeArtworkTag = async (artworkTag) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedArtworkTag = new FormData();
    for (let item in artworkTag) {
      if (artworkTag.hasOwnProperty(item)) {
        serializedArtworkTag.append(item, artworkTag[item]);
      }
    }

    axiosClient
      .post("/artwork-tags", serializedArtworkTag)
      .then((response) => {
        router.push({ name: "artwork-tags.index" });
        swal({
          icon: "success",
          title: "Tag saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateArtworkTag = async (artworkTag) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/artwork-tags/" + artworkTag.id, artworkTag)
      .then((response) => {
        router.push({ name: "artwork-tags.index" });
        swal({
          icon: "success",
          title: "Tag saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteArtworkTag = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/artwork-tags/" + id)
          .then((response) => {
            getArtworkTags();
            router.push({ name: "artwork-tags.index" });
            swal({
              icon: "success",
              title: "Tag deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    artworkTags,
    artworkTag,
    getArtworkTags,
    getArtworkTag,
    storeArtworkTag,
    validationErrors,
    isLoading,
    updateArtworkTag,
    deleteArtworkTag,
  };
}
