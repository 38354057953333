<template>
    <div class="row mb-5 mx-2 mx-md-0 justify-content-start">
        <div v-for="number in 8" :key="number" class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-0 mb-3">
            <div>
                <div class="card-custom bg-custom text-white border-0 m-0 mb-2 mx-auto text-left">
                    <img src="/img/brand/white-logo.png" class="tst-img" alt=" ">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-custom:hover {
    cursor: pointer;
}

.card-custom {
    width: 180px;
    height: 180px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-custom {
    height: 100%;
}

.card-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.tst-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    animation: cardImageOpacity 2s infinite !important;
}

@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #f7f7f7;
    }

    50% {
        background-position: 100% 0;
        background-color: #f7f7f7;
    }

    100% {
        background-position: 200% 0;
        background-color: #f7f7f7;
    }
}

@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 20%;
    }

    50% {
        background-position: 100% 0;
        opacity: 60%;
    }


    100% {
        background-position: 200% 0;
        opacity: 90%;
    }
}

.bg-custom {
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}

.card-custom {
    width: 180px;
    height: 180px;
}

.card-custom .card-img {
    height: 100%;
}

.card-custom {
    font-family: "Poppins-Bold";
}
</style>