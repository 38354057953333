<template>
  <div>
    <!-- Page content -->
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12 p-0">
          <form name="information" id="information" @submit.prevent="handleUpdateArtwork(artwork, userType)">
            <div class="row">
              <div class="col-md-12">
                <div class="card-wrapper">
                  <div v-if="page == 1" class="card shadow-none">
                    <div class="card-header">
                      <h4>Step 1 - Information</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="form-control-label">Cover Image
                            <md />
                          </label>
                          <FileUpload v-if="![2].includes(artwork.status_id)" name="cover_image[]" :auto="true"
                            :customUpload="true"
                            @uploader="storeArtworkFiles($event, false, 'artwork_cover_image', 30, 0, artwork.cover_image, false)"
                            :multiple="true" accept="image/*">
                            <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                              <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                                <div class="d-flex gap-2">
                                  <Button @click="chooseCallback()" icon="pi pi-images"
                                    class="p-button-rounded"></Button>
                                  <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                                </div>
                              </div>
                            </template>
                            <template #empty>
                              <div
                                v-if="!artworkProcessingFiles['artwork_cover_image'] && artwork?.cover_image && artwork?.cover_image.length > 0"
                                class="d-flex justify-content-start align-items-center flex-wrap">
                                <div v-for="(image, index) in artwork?.cover_image" :key="index"
                                  class="p-2 position-relative" style="height:150px; width: 150px;">
                                  <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                    alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                                  <div
                                    class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                    style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                    @click.prevent="
                                      removeFromArray(artwork?.cover_image, index)
                                    ">
                                    <i class="pi pi-times"></i>
                                  </div>
                                </div>
                              </div>
                              <div v-else-if="artworkProcessingFiles['artwork_cover_image']"
                                class="d-flex justify-content-center align-items-center">
                                <ProgressSpinner style="width:20px;height:20px" strokeWidth="4"
                                  animationDuration=".5s" />
                              </div>
                              <div v-else class="d-flex align-items-center justify-content-center flex-column">
                                <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                              </div>
                            </template>
                          </FileUpload>
                          <img v-else id="artwork-image" class="img-fluid" style="object-fit: cover"
                            :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                            :src="
                              artwork.cover_image && artwork?.cover_image[0]?.original_url
                                ? artwork?.cover_image[0]?.original_url
                                : '/img/placeholders/block.png'
                            " alt="" loading="lazy" />
                          <div class="row" v-for="message in validationErrors['cover_image']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Image
                            <md />
                          </label>
                          <FileUpload v-if="![2].includes(artwork.status_id)" name="image[]" :auto="true"
                            :customUpload="true"
                            @uploader="storeArtworkFiles($event, false, 'artwork_image', 30, 0, artwork.image, false)"
                            :multiple="true" accept="image/*">
                            <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                              <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                                <div class="d-flex gap-2">
                                  <Button @click="chooseCallback()" icon="pi pi-images"
                                    class="p-button-rounded"></Button>
                                  <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                                </div>
                              </div>
                            </template>
                            <template #empty>
                              <div
                                v-if="!artworkProcessingFiles['artwork_image'] && artwork?.image && artwork?.image.length > 0"
                                class="d-flex justify-content-start align-items-center flex-wrap">
                                <div v-for="(image, index) in artwork?.image" :key="index" class="p-2 position-relative"
                                  style="height:150px; width: 150px;">
                                  <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                    alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                                  <div
                                    class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                    style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                    @click.prevent="
                                      removeFromArray(artwork?.image, index)
                                    ">
                                    <i class="pi pi-times"></i>
                                  </div>
                                </div>
                              </div>
                              <div v-else-if="artworkProcessingFiles['artwork_image']"
                                class="d-flex justify-content-center align-items-center">
                                <ProgressSpinner style="width:20px;height:20px" strokeWidth="4"
                                  animationDuration=".5s" />
                              </div>
                              <div v-else class="d-flex align-items-center justify-content-center flex-column">
                                <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                              </div>
                            </template>
                          </FileUpload>
                          <img v-else id="artwork-image" class="img-fluid" style="object-fit: cover"
                            :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                            :src="
                              artwork.image && artwork?.image[0]?.original_url
                                ? artwork?.image[0]?.original_url
                                : '/img/placeholders/block.png'
                            " alt="" loading="lazy" />
                          <div class="row" v-for="message in validationErrors['image']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <label class="form-control-label">Name
                            <md />
                          </label>
                          <input v-model="artwork.name_en" class="form-control" id="name_en" type="text"
                            name="name_en" />
                          <div class="row" v-for="message in validationErrors['name_en']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <label class="form-control-label">Slug
                            <md />
                          </label>
                          <input v-model="artwork.slug" class="form-control" id="slug" type="text" name="slug" />
                          <div class="row" v-for="message in validationErrors['slug']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <label class="form-control-label">Description
                          </label>
                          <textarea v-model="artwork.description_en" class="form-control" name="description_en"
                            id="description_en" rows="5"></textarea>
                          <div class="row" v-for="message in validationErrors['description_en']" :key="message.index">
                            <span class="text-danger">
                              {{ message }}
                            </span>
                          </div>
                        </div>
                        <div v-if="artworkMeta?.categories" class="col-md-12 mt-4">
                          <div class="form-group d-flex flex-column justify-content-start">
                            <label for="categories">Themes
                              <md />
                            </label>
                            <MultiSelect v-model="artwork.categories" :filter="true" :showClear="true"
                              :options="artworkMeta?.categories" optionLabel="name_en" display="chip" />
                            <div class="row" v-for="message in validationErrors['categories']" :key="message.index">
                              <span class="text-danger">
                                {{ message }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="artworkMeta?.tags" class="col-md-12 mt-4">
                          <div class="form-group d-flex flex-column justify-content-start">
                            <label for="tags">Tags</label>
                            <MultiSelect v-model="artwork.tags" :selectionLimit="5" :filter="true" :showClear="true"
                              :options="artworkMeta?.tags" optionLabel="name_en" display="chip" />
                            <div class="row" v-for="message in validationErrors['tags']" :key="message.index">
                              <span class="text-danger">
                                {{ message }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="artworkMeta?.products" class="col-md-12 mt-4">
                          <div class="form-group d-flex flex-column justify-content-start">
                            <label for="products">Product
                              <md /> <span v-if="!artwork?.image || artwork?.image.length == 0" class="text-danger">(
                                Please
                                upload the artwork image )</span>
                            </label>
                            <MultiSelect v-model="artwork.products" :filter="true" :showClear="true"
                              :disabled="!artwork?.image || artwork?.image.length == 0" :options="artworkMeta?.products"
                              optionLabel="name_en" display="chip" />
                            <div class="row" v-for="message in validationErrors['products']" :key="message.index">
                              <span class="text-danger">
                                {{ message }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="page == 2" class="card shadow-none">
                    <div class="card-header">
                      <h4>Step 2 - Setup</h4>
                    </div>
                    <div class="card-body">
                      <div v-if="artwork.products" class="row">
                        <div v-for="(product, productIndex) in artwork.products" :key="productIndex"
                          class="col-12 col-lg-6">
                          <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-mockup'" class="mockup">
                            <img ref="imageList" :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-image'"
                              class="mockup-image" :src="product?.front_image[0]?.webp" alt="">
                            <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-drawing-area'"
                              class="drawing-area">
                              <div ref="canvasContainer"
                                :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas-container'"
                                class="canvas-container">
                                <canvas :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas'"
                                  class="mockup-canvas"></canvas>
                              </div>
                            </div>
                          </div>
                          <div v-if="product?.variations" class="row w-100">
                            <div v-for="variation in product?.variations.filter(
                              (variations) => variations.name_en.toLowerCase() == 'color'
                            )" :key="variation.index" class="py-2 row w-100">
                              <div class="color-options d-flex justify-content-start flex-wrap"
                                v-if="variation?.variation_options">
                                <Button v-for="option in variation.variation_options" :key="option.index"
                                  :style="'width: 40px; height: 40px;' + 'background-color: ' + option.name_en + ';' + 'border: 1px solid ' + option.name_en + ';'"
                                  :icon="artwork?.initial[product?.name_en]?.color == option.name_en ? 'pi pi-check' : ''"
                                  @click.prevent="setIC(product?.name_en, option.name_en, artwork?.initial, productIndex)"
                                  class="p-button-rounded rounded-circle pxl-shadow" />
                              </div>
                            </div>
                            <div class="row w-100" v-if="issetColorsErrors[product.name_en]">
                              <span class="text-danger">{{ issetColorsErrors[product.name_en] }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-between">
                <Button v-if="page == 1" @click.prevent="
                  router.push({
                    name: 'Account',
                  })
                " label="Cancel" class="p-button-secondary" />
                <Button v-if="page == 1"
                  :disabled="!artwork?.user_id || !artwork?.products || artwork?.products.length == 0 || !artwork?.name_en || !artwork?.slug || !artwork?.categories || artwork?.categories.length == 0"
                  @click.prevent="goToPage(2)" label="Next" class="p-button-success" />

                <Button v-if="page == 2" @click.prevent="goToPage(1)" label="Back" class="p-button-secondary" />
                <Button :disabled="isLoading == true" v-if="page == 2" type="submit" class="p-button-success" >
                  <p class="text-bold-3-s w-100 text-white text-center">
                    <span v-if="isLoading == false">Save</span>
                    <ProgressSpinner v-else-if="isLoading == true" style="width: 20px; height: 20px;" strokeWidth="5"/>
                  </p>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";
import useArtworks from "@/composables/Artworks";
import useMockupEditor from "@/composables/MockupEditor";
import { logedinUser } from "@/composables/auth";
import { removeFromArray, getArrayOfField } from "@/helpers/Functions";

const {
  artwork,
  getArtworkBySlug,
  artworkMeta,
  updateArtwork,
  validationErrors,
  isLoading,
  artworkProcessingFiles,
  storeArtworkFiles,
} = useArtworks();

const {
  setUpEditor,
  setInitialColor,
} = useMockupEditor();


const page = ref(1);

const canvasContainer = ref([]);
const imageList = ref([]);

const issetColorsErrors = ref({});

const userType = ref(logedinUser?.data?.type_id == 1 ? "verifyUser" : "user")

const router = useRouter();
const route = useRoute();
const { can } = useAbility();
onMounted(async () => {
  await getArtworkBySlug(route.params.slug);
});


const goToPage = (pageNo) => {
  if (pageNo == 2) {
    var productsList = getArrayOfField(artwork.value.products, 'name_en');
    var initial = Object.keys(artwork.value.initial);
    initial.forEach((item, index) => {
      if (!productsList.includes(item)) {
        delete artwork.value.initial[item];
      }
    });
    setTimeout(() => {
      setUpEditor(artwork, canvasContainer, imageList)
    }, 1000);
  }
  page.value = pageNo;
}

const setIC = (productName, optionName, artworkInitial, productIndex) => {
  setInitialColor(productName, optionName, artworkInitial, productIndex, imageList)
}

const handleUpdateArtwork = (artworkData, submitType) => {
  let issetColors = true;
  artworkData.products.forEach(product => {
    issetColorsErrors.value[product.name_en] = ""
    if(!artworkData.initial[product.name_en]?.color) {
      issetColors = false;
      issetColorsErrors.value[product.name_en] = "Please Select the defult color"
    }
  });

  if(issetColors == false) return

  updateArtwork(artworkData, submitType)
}
</script>
<style>
.mockup {
  width: 100%;
}

.canvas-container {
  margin: 0 !important;
}

.drawingArea canvas {
  mix-blend-mode: multiply !important;
}
</style>
