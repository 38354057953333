<template>
    <div class="container-fluid bg-secondary bb-p p-0 pl-0 pr-0" style="width: 100%; min-height: 185px; overflow: hidden;">
        <carousel class="h-100 w-100" :items-to-show="8.5" :itemsToScroll="1" :autoplay="5000" :wrap-around="true"
            :breakpoints="carouselBreakpoints" snapAlign="center">
            <slide v-for="number in 12" :key="number">
                <div style="margin-left: 0.2rem;">
                    <div
                        class="d-flex justify-content-center card-custom shadow-none theme-card border-0 m-0 px-1 py-2 m-pointer h-100">
                        <img class="h-100 w-100 tst-img" style="object-fit: cover" alt="Card image"
                            src="/img/brand/45logosmall-light.png" />
                        <div class="card-img-overlay d-flex align-items-end p-3">
                            <div>
                                <p></p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>

            </slide>
            <template #addons>
                <navigation class="themes" />
            </template>
        </carousel>
    </div>
</template>

<script setup>
import { ref } from "vue";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

const carouselBreakpoints = ref({
    1: {
        itemsToShow: 2.4,
        snapAlign: 'center',
    },
    577: {
        itemsToShow: 4.5,
        snapAlign: 'center',
    },
    768: {
        itemsToShow: 4.8,
        snapAlign: 'center',
    },
    993: {
        itemsToShow: 5.5,
        snapAlign: 'center',
    },
    1201: {
        itemsToShow: 8.2,
        snapAlign: 'center',
    },
});

</script>

<style scoped>
.theme-card {
    width: 100%;
    height: 223px;
}

.theme-card .card-img {
    height: 100%;
    width: 100%;
}

.tst-img {
    height: 100%;
    width: 100%;
    animation: cardImageOpacity 2s infinite !important;
}

.card-custom {
    margin-right: 0.5rem;
    /* Adjust the margin to add space between images */
    margin-left: 0.5rem;
    /* Adjust the margin to add space between images */
    display: flex;
    align-items: center;
    justify-content: center;
}

.theme-card {
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}

.theme-card .card-title {
    font-family: "Poppins-Bold";
}


@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 40%;
    }

    70% {
        background-position: 100% 0;
        opacity: 75%;
    }


    100% {
        background-position: 200% 0;
        opacity: 90%;
    }
}

@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #a9a9a9;
    }

    50% {
        background-position: 100% 0;
        background-color: #989898;
    }

    100% {
        background-position: 200% 0;
        background-color: #767676;
    }
}
</style>
