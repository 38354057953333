<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_reference" class="form-control" placeholder="Search reference" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_name_en" class="form-control" placeholder="Search name" type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page"
                    :options="per_page_options" optionLabel="text" optionValue="value" />
                </div>
                <!-- <Button v-if="can('order_create')" label="New" @click.prevent="
                    router.push({
                      name: 'orders.create',
                    })
                  " class="p-button-success" /> -->
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
                              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('date')">
                          <div :class="{
                            '': orderColumn === 'date',
                          }">
                            Date
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'date' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'date' },
                              { 'pi pi-sort-alt': orderColumn != 'date' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('reference')">
                          <div :class="{
                            '': orderColumn === 'reference',
                          }">
                            Reference
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'reference' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'reference' },
                              { 'pi pi-sort-alt': orderColumn != 'reference' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('name_en')">
                          <div :class="{
                            '': orderColumn === 'name_en',
                          }">
                            Name
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'name_en' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'name_en' },
                              { 'pi pi-sort-alt': orderColumn != 'name_en' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('status_id')">
                          <div :class="{
                            '': orderColumn === 'status_id',
                          }">
                            Status
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'status_id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'status_id' },
                              { 'pi pi-sort-alt': orderColumn != 'status_id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="orders.data">
                    <tr v-for="order in orders.data" :key="order.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.id }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.date }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.reference }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="order.delivery_address">{{
                          order.delivery_address.name_en
                        }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="order.status" class="badge badge-primary">{{
                          order.status.name_en
                        }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button class="p-button-info" icon="fas fa-eye" v-if="can('order_show')" @click.prevent="
                          router.push({
                            name: 'orders.show',
                            params: { id: order.id },
                          })
                          " />
                        <!-- <Button class="p-button-warning" icon="fas fa-pen-fancy" v-if="can('order_edit')"
                          @click.prevent="
                            router.push({
                              name: 'orders.edit',
                              params: { id: order.id },
                            })
                          " /> -->
                        <Button class="p-button-danger" icon="fas fa-trash" v-if="can('order_delete')"
                          @click.prevent="deleteOrder(order.id)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ orders?.meta?.from }} to
                    {{ orders?.meta?.to }} of
                    {{ orders?.meta?.total }}
                  </p>
                </div>
                <div>
                  <!-- <pagination :data="orders" :limit="1" @pagination-change-page="(page) =>
                      getOrders(
                        page,
                        search_id,
                        search_date,
                        search_reference,
                        search_name_en,
                        search_name_ar,
                        search_vendor,
                        search_status,
                        search_parent,
                        search_children,
                        search_noguest,
                        search_global,
                        orderColumn,
                        orderDirection,
                        per_page
                      )
                    " /> -->
                  <pagination :data="orders" :limit="1" @pagination-change-page="(page) => { }" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import useOrders from "@/composables/Orders";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";


export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = ref(1);
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_date = ref("");
    const search_reference = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_vendor = ref("");
    const search_status = ref("");
    const search_parent = ref(1);
    const search_children = ref(0);
    const search_noguest = ref(0);
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");
    const route = useRoute();

    const router = useRouter();
    const { orders, getOrders, deleteOrder } = useOrders();
    const { can } = useAbility();
    onMounted(async () => {

      const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
      page.value = pageNumber;
      await getOrders(
        page.value,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(search_id, (current) => {
      getOrders(
        1,
        current,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_date, (current) => {
      getOrders(
        1,
        search_id.value,
        current,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_reference, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_en, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        current,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_ar, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        current,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_status, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_status, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        current,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_global, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });

    watch(() => route.query.page, (current) => {
      const pageNumber = current ? parseInt(current, 10) : 1;
      page.value = pageNumber;
    });



    watch(() => page.value, (current) => {
      getOrders(
        page.value,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      )
    })
    return {
      route,
      router,
      orders,
      getOrders,
      deleteOrder,
      search_id,
      search_date,
      search_reference,
      search_name_en,
      search_name_ar,
      search_vendor,
      search_status,
      search_parent,
      search_children,
      search_noguest,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
