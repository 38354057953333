<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item :link="{
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'fas fa-tachometer-alt text-primary',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Users',
          path: '/users',
          icon: 'ni ni-single-02 text-orange',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Products',
          path: '/products',
          icon: 'fas fa-boxes text-info',
        }"></sidebar-item>

        <!-- <sidebar-item :link="{
          name: 'Artworks',
          path: '/artworks',
          icon: 'fas fa-palette text-danger',
        }"></sidebar-item> -->

        <sidebar-item :isMenu="true" :link="{
          name: 'Artwork-Lists',
          icon: 'fas fa-palette text-danger',
        }">
          <!-- <sidebar-item :link="{ name: 'Global Settings', path: '/global-settings' }"></sidebar-item> -->
          <sidebar-item :link="{ name: 'Artworks', path: '/artworks' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Custom Artworks', path: '/custom-artworks' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Featured Artworks', path: '/featured-artworks' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{
          name: 'Notifications',
          path: '/notifications',
          icon: 'fas fa-bell text-success',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Orders',
          path: '/orders',
          icon: 'fas fa-dolly-flatbed text-info',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Due payments',
          path: '/due-payments',
          icon: 'fas fa-money-bill',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Coupons',
          path: '/coupons',
          icon: 'fas fa-gift text-info',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Sliders',
          path: '/sliders',
          icon: 'fas fa-window-restore text-warning',
        }"></sidebar-item>

        <sidebar-item :link="{
          name: 'Settings',
          path: '/settings',
          icon: 'ni ni-settings-gear-65 text-info',
        }"></sidebar-item>

        <sidebar-item :isMenu="true" :link="{
          name: 'System-Lists',
          icon: 'ni ni-settings-gear-65',
        }">
          <!-- <sidebar-item :link="{ name: 'Global Settings', path: '/global-settings' }"></sidebar-item> -->
          <sidebar-item :link="{ name: 'Countries', path: '/countries' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Cities', path: '/cities' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Currencies', path: '/currencies' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Shippings', path: '/shippings' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Shipping Methods', path: '/shipping-methods' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Permissions', path: '/permissions' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Roles', path: '/roles' }"></sidebar-item>
          <sidebar-item :link="{ name: 'user-statuses', path: '/user-statuses' }"></sidebar-item>
          <sidebar-item :link="{ name: 'user-types', path: '/user-types' }"></sidebar-item>
          <sidebar-item :link="{ name: 'message-types', path: '/message-types' }"></sidebar-item>
          <sidebar-item :link="{ name: 'notification-types', path: '/notification-types' }"></sidebar-item>
          <sidebar-item :link="{ name: 'payment-methods', path: '/payment-methods' }"></sidebar-item>
          <sidebar-item :link="{ name: 'artwork-statuses', path: '/artwork-statuses' }"></sidebar-item>
          <sidebar-item :link="{ name: 'artwork-themes', path: '/artwork-categories' }"></sidebar-item>
          <sidebar-item :link="{ name: 'artwork-tags', path: '/artwork-tags' }"></sidebar-item>
          <sidebar-item :link="{ name: 'product-statuses', path: '/product-statuses' }"></sidebar-item>
          <sidebar-item :link="{ name: 'order-statuses', path: '/order-statuses' }"></sidebar-item>
          <sidebar-item :link="{ name: 'product-types', path: '/product-types' }"></sidebar-item>
          <sidebar-item :link="{ name: 'social-types', path: '/social-types' }"></sidebar-item>
          <sidebar-item :link="{ name: 'tags', path: '/tags' }"></sidebar-item>
          <sidebar-item :link="{ name: 'taxes', path: '/taxes' }"></sidebar-item>
          <sidebar-item :link="{ name: 'attributes', path: '/attributes' }"></sidebar-item>
          <sidebar-item :link="{ name: 'attribute-options', path: '/attribute-options' }"></sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'categories', path: '/categories' }"
          ></sidebar-item> -->
          <sidebar-item :link="{ name: 'shippings', path: '/shippings' }"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
