<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5">
      <h1 class="header-1-p mt-5 mb-5">About Us</h1>

      <h2 class="header-2-p">Overview</h2>
      <p class="text-3-s mb-5 mt-3">A place full of creativity – PXL offers a place for those who are looking for the
        right fit that accommodates their personality and lifestyle.
      </p>

      <h2 class="header-2-p">Vision</h2>
      <p class="text-3-s mb-5 mt-3">
        To be widely known for the quality and creativity in our products and services
      </p>

      <h2 class="header-2-p">Mission</h2>
      <p class="text-3-s mb-5 mt-3">
        Offer a variety of creative designs with great quality to those who are looking for something special to wear.
      </p>

      <h2 class="header-2-p">Values</h2>
      <p class="text-3-s mb-5 mt-3">
        PXL is adopting five core values that it expects all stakeholders to embody: Creativity, Openness, Accountability,
        Commitment, and Trust.
      </p>

      <!-- cards of view/mission/values -->
      <!-- <section class="row">
        <div class="card m-3 col">
          <div class="card-body">
            <h5 class="card-title header-2-p">View</h5>

            <p class="text-3-s mt-4">
              To become the most used platform by artists in the MENA region. .
            </p>
          </div>
        </div>

        <div class="card m-3 col">
          <div class="card-body">
            <h5 class="card-title header-2-p">Mission</h5>

            <p class="text-3-s mt-4">
              To allow artist to become more successful by simply displaying and
              selling their creative art to the public.
            </p>
          </div>
        </div>
        <div class="card m-3 col">
          <div class="card-body">
            <h5 class="card-title header-2-p">Values</h5>

            <p class="text-3-s mt-4">
              PXL is adopting five core values that it expects all stakeholders
              to embody: Creativity, Openness, Accountability, Commitment, and
              Trust.
            </p>
          </div>
        </div>
      </section> -->

      <!-- why should you join us -->
      <!-- <div class="join-us mt-4 mb-5">
        <h3 class="header-2-p mb-4">Why should you join us?</h3>
        <ul>
          <li class="mb-3 text-3-s">
            PXL offers up to 30% profit share to its artist.
          </li>
          <li class="mb-3 text-3-s">
            Artist can increase their profit share percentage once their account
            becomes approved.
          </li>
          <li class="mb-3 text-3-s">
            PXL offers a high-end user experience that allow artists and
            customers to navigate the website smoothly with no hic-ups or
            issues.
          </li>
          <li class="mb-3 text-3-s">
            The PXL.shop website have many features that can assist the artists
            to display their work in the best possible way, keep up with sales,
            and keep track of what is trending.
          </li>
          <li class="mb-3 text-3-s">
            Here at PXL we use the best printing machinery in the market to
            capture the artist work pixel by pixel 😉
          </li>
          <li class="mb-3 text-3-s">
            All artists will receive their profit share automatically with no
            hustle or delay.
          </li>
        </ul>
      </div> -->

      <!-- contact us cards -->
      <div class="row container mb-3">
        <div class="card mr-3 col p-md-3">
          <div class="card-body">
            <h5 class="card-title header-2-p">Contact us</h5>
            <ul class="contact text-3-s mt-3">
              <li class="mb-1 ">Whatsapp support (+973) <a href="whatsapp://send?phone=+97336348338" target="_blank"

                  rel="noopener noreferrer">
                  <i class="fab fa-whatsapp"></i>
                  36348338
                </a>
                </li>
              <li class="mb-1">Email: info@pxl.shop</li>
            </ul>
          </div>
        </div>

        <!-- <div class="card col p-md-3">
          <div class="card-body">
            <h5 class="card-title header-2-p">Address</h5>
            <ul class="contact text-3-s mt-3">
              <li class="mb-1">PXL</li>
              <li class="mb-1">Flat 19, Floor 2, Building 3000B, Road 1249</li>
              <li class="mb-1">Block 1012 Hamala</li>
              <li class="mb-1">Kingdom of Bahrain</li>
              <li class="mb-1">C.R. No: 129190-1</li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() { },
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
