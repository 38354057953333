import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function usePaymentMethods() {
  const paymentMethods = ref({});
  const paymentMethod = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getPaymentMethods = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/payment-methods?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        paymentMethods.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getPaymentMethod = async (id) => {
    axiosClient.get("/payment-methods/" + id).then((response) => {
      paymentMethod.value = response.data.data;
    });
  };

  const storePaymentMethod = async (paymentMethod) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(paymentMethod, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/payment-methods", params)
      .then((response) => {
        router.push({ name: "payment-methods.index" });
        swal({
          icon: "success",
          title: "payment method saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updatePaymentMethod = async (paymentMethod) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/payment-methods/" + paymentMethod.id, paymentMethod)
      .then((response) => {
        router.push({ name: "payment-methods.index" });
        swal({
          icon: "success",
          title: "Payment method saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deletePaymentMethod = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/payment-methods/" + id)
          .then((response) => {
            getPaymentMethods();
            router.push({ name: "payment-methods.index" });
            swal({
              icon: "success",
              title: "Payment method deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  const addSetting = async (method) => {
    if (!method.settings) method.settings = [];
    await method.settings.push({
      setting_key: "",
      setting_lable: "",
      setting_value: "",
    });
  };

  const deleteSetting = async (settingsArray, index) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        settingsArray.splice(index, 1);
        swal({
          icon: "success",
          title: "Setting deleted successfully",
        });
      }
    });
  };

  return {
    paymentMethods,
    paymentMethod,
    getPaymentMethods,
    getPaymentMethod,
    storePaymentMethod,
    validationErrors,
    isLoading,
    updatePaymentMethod,
    deletePaymentMethod,
    addSetting,
    deleteSetting,
  };
}
