// import axiosClient from "../axiosClient";
// eslint-disable-next-line no-unused-vars
import { ref, inject } from "vue";
// import { useRouter } from "vue-router";
import { fabric } from "fabric";

// import _, { isEmpty, isNull } from "lodash";

export default function useMockupEditor() {
  // var frontCanvas = null;
  // let backCanvas = null;
  // let clipRect1 = null;
  // let background = null;
  let canvasList = {};
  let showCanvas = null;
  let id = null;

  // const router = useRouter();
  // const swal = inject("$swal");
  const setUpEditor = async (artwork, canvasContainer, imageList) => {
    if (artwork && artwork.value?.products.length > 0) {
      for (let pi = 0; pi < artwork.value?.products.length; pi++) {
        const container = canvasContainer.value[pi];
        const product = artwork.value?.products[pi];
        if (artwork.value.initial[product.name_en]) {
          setInitialColor(product.name_en, artwork.value.initial[product.name_en].color, artwork.value.initial, pi, imageList);
          // continue;
        } else {
          artwork.value.initial[product.name_en] = {
            positions: {
              top: 0,
              left: 0,
            },
            scale: {
              x: 0.029,
              y: 0.029,
            },
            color: "",
          };
        }

        canvasList[product.name_en] = new fabric.Canvas(
          product.name_en.toLowerCase().replace(/ /g, "-") + "-canvas",
          {
            hoverCursor: "pointer",
            selection: false,
            id: product.name_en.toLowerCase().replace(/ /g, "-") + "-canvas",
            name: product.name_en,
            width: container.offsetWidth,
            height: container.offsetHeight,
          }
        );
        // Adding the artwork to the canvas
        var imgUrl = artwork.value.image[0].original_url;
        var img = new Image();
        // img.crossOrigin = "Anonymous";
        img.onload = function () {

          var imgTop = artwork.value?.initial[product?.name_en]?.positions?.top ? artwork.value?.initial[product?.name_en]?.positions?.top : 0;
          var imgLeft = artwork.value?.initial[product?.name_en]?.positions?.left ? artwork.value?.initial[product?.name_en]?.positions?.left : 0;

          var imgScaleX = artwork.value?.initial[product?.name_en]?.scale?.x ? artwork.value?.initial[product?.name_en]?.scale?.x : ((container.offsetWidth / img.width) * 0.5);
          var imgScaleY = artwork.value?.initial[product?.name_en]?.scale?.y ? artwork.value?.initial[product?.name_en]?.scale?.y : ((container.offsetWidth / img.width) * 0.5);

          var imgInstance = new fabric.Image(img, {
            top: parseFloat(Number(imgTop).toFixed(5)),
            left: parseFloat(Number(imgLeft).toFixed(5)),
            scaleX: parseFloat(Number(imgScaleX).toFixed(5)),
            scaleY: parseFloat(Number(imgScaleY).toFixed(5)),
            selectable: true,
            centeredScaling: true,
          });
          // imgInstance.filters.push(
          //   new fabric.Image.filters.BlendImage({
          //     mode: "multiply",
          //   })
          // );
          // imgInstance.applyFilters();
          imgInstance.setControlVisible('ml', false);
          imgInstance.setControlVisible('mb', false);
          imgInstance.setControlVisible('mr', false);
          imgInstance.setControlVisible('mt', false);
          canvasList[product.name_en].add(imgInstance).setActiveObject(imgInstance);
          if (imgLeft == 0 && imgTop == 0) {
            canvasList[product.name_en].centerObject(imgInstance);
          }
        };
        img.src = imgUrl;
        img.classList.add('image-blend');
        container.classList.add("canvas-border");

        canvasList[product.name_en].on({
          "object:moving": function (e) {
            let obj = e.target;
            obj.opacity = 0.5;
          },
          "object:modified": function (e) {
            let obj = e.target;
            obj.opacity = 1;

            // deselect all objects
            // this.discardActiveObject().renderAll();

            if (artwork.value && artwork.value.initial && artwork.value.initial[this.name] && obj) {
              artwork.value.initial[this.name]["positions"]["left"] =
                obj?.left || 0;
                artwork.value.initial[this.name]["positions"]["top"] =
                obj?.top || 0;

                artwork.value.initial[this.name]["scale"]["x"] =
                obj?.scaleX || 0;
                artwork.value.initial[this.name]["scale"]["y"] =
                obj?.scaleY || 0;
            }
          },
          "mouse:down": function (e) {
            var obj = e.target;
            obj.opacity = 0.5;
            container.classList.add("canvas-border");
          },
          "mouse:up": function (e) {
            var obj = e.target;
            obj.opacity = 1;
            container.classList.remove("canvas-border");
          },
          // "after:render": function (e) {
          //   var obj = e.target;
          // },
        });
      }
    }
  };

  const setUpShow = async (artwork, options, filterOptions = false, canvasContainer, imageList) => {
    if(!canvasContainer.value || canvasContainer.value.length == 0) return;
    if(!imageList.value || imageList.value.length == 0) return;
    if (filterOptions && !options.value.options.product) {
      options.value.options.product = artwork.value.products[0].name_en;
      options.value.price = artwork.value.products[0].price;
      options.value.discount = artwork.value.products[0].discount;
    }

    var artworkProducts = [];
    if (filterOptions) {
      artworkProducts = artwork.value.products.filter((product) => product.name_en == options.value["options"]["product"]);
    } else if (artwork.value?.product) {
      artworkProducts = [artwork.value.product];
    } else if (artwork.value?.products) {
      artworkProducts = artwork.value.products;
    } else {
      artworkProducts = artwork.value.products;
    }

    for (let pi = 0; pi < artworkProducts.length; pi++) {
      const container = canvasContainer.value[pi] ? canvasContainer.value[pi] : canvasContainer.value;
      const product = artworkProducts[pi];

      if (artwork.value.initial[product.name_en] && filterOptions == false) {
        var color = options.value?.color ? options.value.color : artwork.value.initial[product.name_en].color;
        setInitialColor(product.name_en, color, artwork.value.initial, pi, imageList);
        // continue;
      } else if (artwork.value.initial[product.name_en] && filterOptions == true) {
        var color = options.value.options.color ? options.value.options.color : artwork.value.initial[product.name_en].color;
        changeShowColor(product.name_en, color, pi, imageList);
      } else {
        artwork.value.initial[product.name_en] = {
          positions: {
            top: 0,
            left: 0,
          },
          scale: {
            x: 0,
            y: 0,
          },
          color: "",
        };
      }

      canvasList[product.name_en] = new fabric.Canvas(
        product.name_en.toLowerCase().replace(/ /g, "-") + "-canvas",
        {
          hoverCursor: "pointer",
          selection: false,
          id: product.name_en.toLowerCase().replace(/ /g, "-") + "-canvas",
          name: product.name_en,
          width: container.offsetWidth,
          height: container.offsetHeight,
          selectable: false,
        }
      );
      // Adding the artwork to the canvas
      var imgUrl = artwork.value.image[0].original_url;
      var img = new Image();
      // img.crossOrigin = "Anonymous";
      img.onload = function () {

        var imgTop = artwork.value?.initial[product?.name_en]?.positions?.top ? artwork.value?.initial[product?.name_en]?.positions?.top : 0;
        var imgLeft = artwork.value?.initial[product?.name_en]?.positions?.left ? artwork.value?.initial[product?.name_en]?.positions?.left : 0;

        var imgScaleX = artwork.value?.initial[product?.name_en]?.scale?.x ? artwork.value?.initial[product?.name_en]?.scale?.x : ((container.offsetWidth / img.width) * 0.5);
        var imgScaleY = artwork.value?.initial[product?.name_en]?.scale?.y ? artwork.value?.initial[product?.name_en]?.scale?.y : ((container.offsetWidth / img.width) * 0.5);

        var imgInstance = new fabric.Image(img, {
          top: parseFloat(Number(imgTop).toFixed(5)),
          left: parseFloat(Number(imgLeft).toFixed(5)),
          scaleX: parseFloat(Number(imgScaleX).toFixed(5)),
          scaleY: parseFloat(Number(imgScaleY).toFixed(5)),
          selectable: false,
        });
        // imgInstance.filters.push(
        //   new fabric.Image.filters.BlendImage({
        //     mode: "multiply",
        //   })
        // );
        // imgInstance.applyFilters();
        canvasList[product.name_en].add(imgInstance);
        if (imgLeft == 0 && imgTop == 0) {
          canvasList[product.name_en].centerObject(imgInstance);
        }
      };
      img.src = imgUrl;
      img.classList.add('image-blend');
    }
  };

  const setInitialColor = (productName, color, initial, productIndex, imageList) => {
    if(imageList.value[productIndex]?.id == productName.toLowerCase().replace(/ /g, "-") + "-image") {
      imageList.value[productIndex].style.backgroundColor = color;
      initial[productName].color = color;
    } else if (imageList.value?.id == productName.toLowerCase().replace(/ /g, "-") + "-image") {
      imageList.value.style.backgroundColor = color;
      initial[productName].color = color;
    }
  };

  const changeShowColor = (productName, color, productIndex, imageList) => {
    if(imageList.value[productIndex].id == productName.toLowerCase().replace(/ /g, "-") + "-image") {
      imageList.value[productIndex].style.backgroundColor = color;
    } else if (imageList.value?.id == productName.toLowerCase().replace(/ /g, "-") + "-image") {
      imageList.value.style.backgroundColor = color;
    }
  };

  const setUpListMockupEditor = async (artworkData) => {
    if (artworkData && artworkData.value.products) {
      artworkData.value.products.forEach((product) => {
        let background = new Image();
        // background.crossOrigin = "*";
        background.onload = function () {
          let canvas = new fabric.Canvas(product.name_en, {
            hoverCursor: "pointer",
            selection: true,
            id: product.name_en,
            // width: background.width,
            // height: background.height,
          });
          fabric.Object.prototype.transparentCorners = false;
          canvas.setBackgroundImage(
            product.front_image[0].original_url,
            canvas.renderAll.bind(canvas),
            {
              // Optionally add an opacity lvl to the image
              // backgroundImageOpacity: 0.5,
              // should the image be resized to fit the container?
              backgroundImageStretch: true,
              scaleX: canvas.width / background.width,
              scaleY: canvas.height / background.height,
            }
          );
          let clipRect = new fabric.Rect({
            width: canvas.width * 0.4,
            height: canvas.height * 0.65,
            fill: "rgba(255,255,255,0)",
            selectable: false,
            centeredScaling: true,
            id: "clipRect",
          });

          canvas.add(clipRect);
          canvas.centerObject(clipRect);

          // Adding the artwork to the canvas
          var imgUrl = artworkData.value.image[0].original_url;
          var img = new Image();
          // img.crossOrigin = "*";
          img.onload = function () {
            var imgInstance = new fabric.Image(img, {
              scaleX: (clipRect.width / img.width) * 0.5,
              scaleY: (clipRect.width / img.width) * 0.5,
              selectable: true,
              clipName: imgUrl,
            });
            canvas.getObjects().forEach(function (o, index) {
              if (index === 0) return;
              canvas.remove(o);
            });
            clipRect.set({
              clipFor: imgUrl,
            });
            imgInstance.filters.push(
              new fabric.Image.filters.BlendImage({
                mode: "multiply",
              })
            );
            // imgInstance.applyFilters();
            canvas.add(imgInstance);
            canvas.centerObject(imgInstance);
          };
          img.src = imgUrl;
          canvasList[product.name_en] = canvas;

          canvas.on({
            "object:moving": function (e) {
              e.target.opacity = 0.5;

              let obj = e.target;

              if (obj.left < clipRect.left) {
                canvas.centerObject(obj);
              }
              if (
                obj.left >
                clipRect.left + clipRect.width - obj.width * obj.scaleX
              ) {
                canvas.centerObject(obj);
              }
              if (obj.top < clipRect.top) {
                canvas.centerObject(obj);
              }
              if (
                obj.top >
                clipRect.top + clipRect.height - obj.height * obj.scaleY
              ) {
                canvas.centerObject(obj);
              }
            },
            "object:modified": function (e) {
              e.target.opacity = 1;

              // deselect all objects
              this.discardActiveObject().renderAll();

              if (artworkData.value && artworkData.value.initial && e.target) {
                artworkData.value.initial[this.id]["positions"]["left"] =
                  e.target?.left || 0;
                artworkData.value.initial[this.id]["positions"]["top"] =
                  e.target?.top || 0;

                artworkData.value.initial[this.id]["scale"]["x"] =
                  e.target?.scaleX || 0;
                artworkData.value.initial[this.id]["scale"]["y"] =
                  e.target?.scaleY || 0;
              }

              //remove boarder
              this.getObjects().forEach(function (o) {
                if (o.id === "clipRect") {
                  o.set({
                    stroke: "#EC5D42",
                    strokeWidth: 0,
                  });
                }
              });
            },
            "mouse:down": function (e) {
              this.getObjects().forEach(function (o) {
                if (o.id === "clipRect") {
                  o.set({
                    stroke: "#EC5D42",
                    strokeWidth: 1,
                  });
                }
              });
            },
            "mouse:up": function (e) {
              //
            },
            "after:render": function (e) {
              var obj = e.target;
            },
          });
        };
        background.src = product.front_image[0].original_url;
      });
    }
    // background = new Image();
    // background.src = MockupImg;
    // background.onload = function () {
    //   clipRect1 = new fabric.Rect({
    //     width: 200,
    //     height: 400,
    //     fill: "rgba(255,255,255,0)",
    //     selectable: false,
    //     centeredScaling: true,
    //     id: "clipRect1",
    //   });
    //   frontCanvas = this.__frontCanvas new fabric.Canvas(frontCanvasID, {
    //     hoverCursor: "pointer",
    //     selection: true,
    //   });
    //   fabric.Object.prototype.transparentCorners = false;
    //   frontCanvas.setDimensions({
    //     width: background.width,
    //     height: background.height,
    //   });
    //   frontCanvas.setBackgroundImage(
    //     MockupImg,
    //     frontCanvas.renderAll.bind(frontCanvas),
    //     {
    //       // Optionally add an opacity lvl to the image
    //       backgroundImageOpacity: 0.5,
    //       // should the image be resized to fit the container?
    //       backgroundImageStretch: true,
    //     }
    //   );

    //   frontCanvas.add(clipRect1);
    //   frontCanvas.centerObject(clipRect1);

    //   frontCanvas.on({
    //     "object:moving": function (e) {
    //       e.target.opacity = 0.5;

    //       let obj = e.target;
    //       if (obj.left > 344) {
    //         frontCanvas.centerObject(obj);
    //       }
    //       if (obj.left < 88) {
    //         frontCanvas.centerObject(obj);
    //       }
    //       if (obj.top > 500) {
    //         frontCanvas.centerObject(obj);
    //       }
    //       if (obj.top < 20) {
    //         frontCanvas.centerObject(obj);
    //       }
    //     },
    //     "object:modified": function (e) {
    //       e.target.opacity = 1;
    //     },
    //     "mouse:down": function (e) {
    //       this.getObjects().forEach(function (o) {
    //         if (o.id === "clipRect1") {
    //           o.set({
    //             stroke: "black",
    //             strokeWidth: 1,
    //           });
    //         }
    //       });
    //     },
    //     "mouse:up": function (e) {
    //       this.getObjects().forEach(function (o) {
    //         if (o.id === "clipRect1") {
    //           o.set({
    //             stroke: "black",
    //             strokeWidth: 0,
    //           });
    //         }
    //       });
    //     },
    //     "selection:cleared": function (e) {
    //       this.getObjects().forEach(function (o) {
    //         if (o.id === "clipRect1") {
    //           o.set({
    //             stroke: "black",
    //             strokeWidth: 0,
    //           });
    //         }
    //       });
    //     },
    //     "after:render": function (e) {
    //       var obj = e.target;
    //     },
    //   });
    // };
  };

  const addImage = async (imgUrl) => {
    // var img = new Image();
    // img.src = imgUrl;
    // img.style.mixBlendMode = "multiply";
    // img.onload = function () {
    //   var imgInstance = new fabric.Image(img, {
    //     scaleX: 0.1,
    //     scaleY: 0.1,
    //     selectable: true,
    //     clipName: imgUrl,
    //   });
    //   frontCanvas.getObjects().forEach(function (o, index) {
    //     if (index === 0) return;
    //     frontCanvas.remove(o);
    //   });
    //   clipRect1.set({
    //     clipFor: imgUrl,
    //   });
    //   frontCanvas.add(imgInstance);
    //   imgInstance.filters.push(
    //     new fabric.Image.filters.BlendImage({
    //       mode: "multiply",
    //     })
    //   );
    //   frontCanvas.centerObject(imgInstance);
    // };
    // fabric.Image.fromURL(imgUrl, function (img) {
    //   img.scale(0.1);
    //   img.filters.push(
    //     new fabric.Image.filters.BlendImage({
    //       mode: "multiply",
    //     })
    //   );
    //   img.set({
    //     // clipPath: clipRect1,
    //   });
    //   frontCanvas.add(img);
    // });
  };

  const changeColor = (canvasID, color, initial) => {
    document.getElementById(canvasID).style.backgroundColor = color;
    initial[canvasID].color = color;
  };

  const saveDesgin = (data) => {
    const canvas = document.getElementById(data);
  };

  return {
    // backCanvas,
    // frontCanvas,
    showCanvas,
    setUpEditor,
    setInitialColor,
    setUpListMockupEditor,
    setUpShow,
    addImage,
    changeColor,
    changeShowColor,
    saveDesgin,
    id,
  };
}
