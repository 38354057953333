/* eslint-disable no-unused-vars */
import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";
import { isEmpty, isNull, isUndefined } from "lodash";
import { useLocalStorage } from "@vueuse/core"

export const cartItems = ref([]);
const LoggedIn = useLocalStorage('isLoggedIn', false);
export function useShopping() {
  const router = useRouter();
  const swal = inject("$swal");
  const validationErrors = ref({});
  const checkoutErrors = ref({});
  const isLoading = ref(false);
  LoggedIn.value = JSON.parse(localStorage.getItem('isLoggedIn'));


  const selectedOptions = ref({
    quantity: 1,
    options: {},
  });
  const variations = ref([]);

  const filteredProducts = ref({});

  const variationOptionQty = ref({});

  const cartTotal = ref(0);

  const cartDiscount = ref(0);

  const cartAmount = ref(0);

  const checkoutMeta = ref({});

  const wishlistItems = ref([]);

  const wishlistIDs = ref(JSON.parse(localStorage.getItem("wishlist")) ?? []);

  const selectVariations = async (key, productName, product) => {
    if (key === "product") {
      selectedOptions.value = {
        quantity: 1,
        options: {},
        price: 0,
        discount: 0,
      };
      selectedOptions.value["options"][key.toLowerCase()] = productName;
      selectedOptions.value["price"] = product.price;
      selectedOptions.value["discount"] = product.discount;
      filteredProducts.value = product.children;
      variationOptionQty.value = {};
      product.children.forEach((childProduct) => {
        childProduct.variations.forEach((variation) => {
          variationOptionQty.value[
            variation.variation_options[0].name_en.toLowerCase()
          ] == null || undefined
            ? (variationOptionQty.value[
              variation.variation_options[0].name_en.toLowerCase()
            ] = 0)
            : variationOptionQty.value[
            variation.variation_options[0].name_en.toLowerCase()
            ];
          variationOptionQty.value[
            variation.variation_options[0].name_en.toLowerCase()
          ] += Number(childProduct.quantity);
        });
      });
    } else {
      if (product) {
        selectedOptions.value["options"][key.toLowerCase()] = productName;
        variationOptionQty.value = {};
        filteredProducts.value = [];
        product.children.forEach((childProduct) => {
          let isSame = true;
          childProduct.variations.forEach((variation) => {
            if (
              selectedOptions.value["options"][variation.name_en.toLowerCase()]
            ) {
              variation.variation_options.forEach((option) => {
                if (
                  option.name_en !=
                  selectedOptions.value["options"][
                  variation.name_en.toLowerCase()
                  ]
                ) {
                  isSame = false;
                }
              });
            } else {
              if (!filteredProducts.value.includes(childProduct))
                filteredProducts.value.push(childProduct);
            }
          });
          if (isSame) {
            if (!filteredProducts.value.includes(childProduct))
              filteredProducts.value.push(childProduct);
          }
        });
        filteredProducts.value.forEach((childProduct) => {
          childProduct.variations.forEach((variation) => {
            variationOptionQty.value[
              variation.variation_options[0].name_en.toLowerCase()
            ] == null || undefined
              ? (variationOptionQty.value[
                variation.variation_options[0].name_en.toLowerCase()
              ] = 0)
              : variationOptionQty.value[
              variation.variation_options[0].name_en.toLowerCase()
              ];
            variationOptionQty.value[
              variation.variation_options[0].name_en.toLowerCase()
            ] += Number(childProduct.quantity);
          });
        });
        selectedOptions.value["options"][key.toLowerCase()] = productName;
        selectedOptions.value["price"] = filteredProducts.value[0].price;
        selectedOptions.value["discount"] = filteredProducts.value[0].discount;
        // selectedOptions.value["discount"] = product.discount;
      }
    }
  };

  const increaseQty = () => {
    selectedOptions.value["quantity"] += 1;
  };

  const dcreaseQty = () => {
    if (selectedOptions.value["quantity"] > 1) {
      selectedOptions.value["quantity"] -= 1;
    }
  };

  const setDefualtVariations = async (artwork) => {
    selectedOptions.value["artwork_id"] = artwork.value.id;
    selectedOptions.value["artwork"] = artwork.value;
    artwork.value.products
      .filter(
        (product) =>
          product.name_en == selectedOptions.value["options"]["product"]
      )
      .forEach((product) => {
        variations.value = product.variations;
      });

    return true;
  };

  const addToCart = async (isLoggedIn, userID) => {
    if (!variations.value && variations.value.length == 0) {
      swal({
        icon: "error",
        title: "Please select a product",
      });
      return;
    }
    let isValidProduct = true;
    for (let vi = 0; vi < variations.value.length; vi++) {
      const variation = variations.value[vi];
      if (
        isUndefined(
          selectedOptions.value["options"][variation.name_en.toLowerCase()]
        )
      ) {
        isValidProduct = false;
        swal({
          icon: "error",
          title: "Please select a " + variation.name_en.toLowerCase(),
        });
        return;
      }
      if (
        isNull(
          selectedOptions.value["options"][variation.name_en.toLowerCase()]
        )
      ) {
        isValidProduct = false;
        swal({
          icon: "error",
          title: "Please select a " + variation.name_en.toLowerCase(),
        });
        return;
      }
      if (
        selectedOptions.value["options"][variation.name_en.toLowerCase()] == ""
      ) {
        isValidProduct = false;
        swal({
          icon: "error",
          title: "Please select a " + variation.name_en.toLowerCase(),
        });
        return;
      }
    }
    if (!isValidProduct) {
      return;
    }
    selectedOptions.value["product"] = filteredProducts.value[0];
    selectedOptions.value["product_id"] = filteredProducts.value[0].id;
    let cart = [];
    cart = JSON.parse(localStorage.getItem("cart"));
    if (cart == null || cart.length == 0) {
      cart = [];
      cart.push(selectedOptions.value);
      if (LoggedIn.value == false) {
        localStorage.setItem("cart", JSON.stringify(cart));
        setTimeout(() => {
          getUserCartItems(LoggedIn.value, userID);
        }, 1000);
        swal({
          icon: "success",
          title: "Art added to your cart",
          showCancelButton: true,
          cancelButtonText: 'Continue shopping',
          confirmButtonText: "Proceed to checkout",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({ name: "Cart" });
          } else {

          }
        });
      }
      if (LoggedIn.value == true) {
        storeCart(selectedOptions.value, userID);
        localStorage.setItem("cart", JSON.stringify(cart));
      }
      return;
    } else {
      let isSameProduct = true;
      let isSameArt = true;
      let isSameOptions = true;
      let isAdded = false;
      cart.forEach((item, index) => {
        if (item["product_id"] !== selectedOptions.value["product_id"]) {
          isSameProduct = false;
        } else if (item["product_id"] === selectedOptions.value["product_id"]) {
          isSameProduct = true;
        }
        for (let att in item["options"]) {
          if (item["options"][att] != selectedOptions.value["options"][att]) {
            isSameOptions = false;
            break;
          } else if (
            item["options"][att] == selectedOptions.value["options"][att]
          ) {
            isSameOptions = true;
          }
        }
        if (item["artwork_id"] !== selectedOptions.value["artwork_id"]) {
          isSameArt = false;
        } else if (item["artwork_id"] === selectedOptions.value["artwork_id"]) {
          isSameArt = true;
        }
        if (
          isSameArt == true &&
          isSameProduct == true &&
          isSameOptions == true
        ) {
          item["quantity"] += selectedOptions.value["quantity"];
          if (LoggedIn.value == false) {
            localStorage.setItem("cart", JSON.stringify(cart));
            setTimeout(() => {
              getUserCartItems(LoggedIn.value, userID);
            }, 1000);
            swal({
              icon: "success",
              title: "Art added to your cart",
              showCancelButton: true,
              cancelButtonText: 'Continue shopping',
              confirmButtonText: "Proceed to checkout",
            }).then((result) => {
              if (result.isConfirmed) {
                router.push({ name: "Cart" });
              } else {

              }
            });
          }
          if (LoggedIn.value == true) {
            updateCartQuantity(item, userID);
            localStorage.setItem("cart", JSON.stringify(cart));
          }
          isAdded = true;
          return;
        }
      });
      if (isAdded == false && (isSameProduct == false || isSameArt == false)) {
        cart.push(selectedOptions.value);
        if (LoggedIn.value == false) {
          localStorage.setItem("cart", JSON.stringify(cart));
          swal({
            icon: "success",
            title: "Art added to your cart",
            showCancelButton: true,
            cancelButtonText: 'Continue shopping',
            confirmButtonText: "Proceed to checkout",
          }).then((result) => {
            if (result.isConfirmed) {
              router.push({ name: "Cart" });
            } else {

            }
          });
        }
        if (LoggedIn.value == true) {
          storeCart(selectedOptions.value, userID);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      }
      setTimeout(() => {
        getUserCartItems(LoggedIn.value, userID);
      }, 1000);
      return;
    }
  };

  const storeCart = async (cart, userID) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    cart["user_id"] = userID;

    let params = serialize(cart, {
      indices: true,
      booleansAsIntegers: true,
    });

    await axiosClient
      .post("/carts", params)
      .then((response) => {
        swal({
          icon: "success",
          title: "Art added to your cart",
          showCancelButton: true,
          cancelButtonText: 'Continue shopping',
          confirmButtonText: "Proceed to checkout",
        }).then((result) => {
          setTimeout(() => {
            getUserCartItems(LoggedIn.value, userID);
          }, 1000);
          if (result.isConfirmed) {
            router.push({ name: "Cart" });
          } else {

          }
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateCartQuantity = async (cart, userID) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    cart["user_id"] = userID;

    let params = serialize(cart, {
      indices: true,
      booleansAsIntegers: true,
    });

    await axiosClient
      .post("/carts/update/quantity", params)
      .then((response) => {
        setTimeout(() => {
          getUserCartItems(LoggedIn.value, userID);
        }, 1000);
        swal({
          icon: "success",
          title: "Art added to your cart",
          showCancelButton: true,
          cancelButtonText: 'Continue shopping',
          confirmButtonText: "Proceed to checkout",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({ name: "Cart" });
          } else {

          }
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const getUserCartItems = async (isLoggedIn, userID) => {
    if (LoggedIn.value == true) {
      if (isLoading.value) return;

      isLoading.value = true;
      validationErrors.value = {};

      await axiosClient
        .get("/carts?" + "user_id=" + userID)
        .then((response) => {
          cartItems.value = response.data.data ?? [];
          localStorage.setItem("cart", JSON.stringify(cartItems.value));
          calculateCartTotal();
        })
        .catch((error) => {
          if (error.response?.data) {
            validationErrors.value = error.response.data.errors;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
    if (LoggedIn.value == false) {
      cartItems.value = JSON.parse(localStorage.getItem("cart")) ?? [];
      calculateCartTotal();
    }
  };

  const updateCartItemQuantity = async (isLoggedIn, userID, item, qty) => {
    if (item.quantity + qty <= 0) return;
    item.quantity += qty;
    calculateCartTotal();
    if (LoggedIn.value == true) {
      if (isLoading.value) return;
      isLoading.value = true;
      validationErrors.value = {};
      let params = serialize(item, {
        indices: true,
        booleansAsIntegers: true,
      });
      await axiosClient
        .post("/carts/update/quantity", params)
        .then((response) => {
          cartItems.value = response.data.data;
          calculateCartTotal();
        })
        .catch((error) => {
          if (error.response?.data) {
            validationErrors.value = error.response.data.errors;
          }
        })
        .finally(() => (isLoading.value = false));
    }
    if (LoggedIn.value == false) {
      localStorage.setItem("cart", JSON.stringify(cartItems.value));
    }
  };

  const deleteCartItem = async (id, isLoggedIn, userID, itemIndex) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (LoggedIn.value == true) {
          axiosClient
            .delete("/carts/" + id)
            .then((response) => {
              setTimeout(() => {
                getUserCartItems(LoggedIn.value, userID);
              }, 1000);
              swal({
                icon: "success",
                title: "Item deleted successfully",
              });
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Something went wrong",
              });
            });
        }
        if (LoggedIn.value == false) {
          cartItems.value.splice(itemIndex, 1);
          localStorage.setItem("cart", JSON.stringify(cartItems.value));
          setTimeout(() => {
            getUserCartItems(LoggedIn.value, userID);
          }, 1000);
        }
      }
    });
  };

  const calculateCartTotal = async () => {
    cartTotal.value = 0;
    cartDiscount.value = 0;
    cartAmount.value = 0;
    if (cartItems.value) {
      cartItems.value.forEach((item) => {
        cartDiscount.value += item.product.discount * item.quantity;
        cartAmount.value += item.product.price * item.quantity;
        cartTotal.value +=
          (item.product.price - item.product.discount) * item.quantity;
      });
    }
  };

  const placeOrder = async (orderData, isLoggedIn, userID) => {
    let empty = [];
    for (const key in orderData) {
      if (typeof orderData[key] == "object") {
        for (const key2 in orderData[key]) {
          if (key2 == "name_ar") continue;
          if (key2 == "post_code") continue;
          if (key2 == 'flat') continue;
          if (key == 'billing_address' && !orderData['different_address']) continue;
          if (orderData[key][key2] == "") {
            let error_value = key2.replace("_", " ").replace("en", "");
            empty.push(error_value);
            if (!checkoutErrors.value[key]) {
              checkoutErrors.value[key] = {};
            }
            checkoutErrors.value[key][key2] = 'Please enter the ' + error_value;
          }
        }
      } else {
        if (key == "total") continue;
        if (key == "subtotal") continue;
        if (key == "shipping") continue;
        if (key == "discount") continue;
        if (key == "order_notes") continue;
        if (key == "different_address") continue;
        if (key == "coupon") continue;
        if (key == "coupon_discount") continue;
        if (orderData[key] == "") {
          if (key == "payment_method" && orderData.total < 1) {
            orderData[key] = 'Free Coupon';
          } else {
            let error_value = key.replace("_", " ").replace("en", "");
            checkoutErrors.value[key] = 'Please enter the ' + error_value;
            empty.push(key.replace("_", " "));
          }
        }
      }
    }
    if (!empty.length == 0) {
      swal({
        icon: "warning",
        title: "Please fill the required fields!",
      });
      return;
    }

    if (orderData.total < 1) {
      orderData['payment_method'] = 'Free Coupon';
    }
    if (LoggedIn.value == false) {
      orderData.items = JSON.parse(localStorage.getItem("cart"));
    }
    if (LoggedIn.value == true) {
      orderData.items = cartItems.value;
    }

    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    orderData["user_id"] = userID ? userID : null;

    let params = serialize(orderData, {
      indices: true,
      booleansAsIntegers: true,
    });


    let oldLocal = JSON.parse(localStorage.getItem("cart"));

    await axiosClient
      .post("/orders", params)
      .then((response) => {
        setTimeout(() => {
          localStorage.setItem("cart", JSON.stringify([]));
          getUserCartItems(LoggedIn.value, userID);
        }, 1000);
        if (response?.data?.data?.total == 0) {
          router.push({ name: "Home" });
          swal({
            icon: "success",
            title: "Your order successfully placed",
            //confirmButtonColor: "#DD6B55",
            confirmButtonText: "Return to order history",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              router.push({ name: "Account" });
            }
          });
        } else if (response?.data?.data?.payment_method == "Credit Card") {
          payByCredimax(response?.data?.data?.id);
        } else if (response?.data?.data?.payment_method == "Debit Card") {
          payByBenefit(response?.data?.data?.id);
        } else {
          router.push({ name: "Home" });
          swal({
            icon: "success",
            title: "Your order successfully placed",
            //confirmButtonColor: "#DD6B55",
            confirmButtonText: "Return to order history",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              router.push({ name: "Account" });
            }
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
        swal({
          icon: "error",
          title: error.response.data.message,
        });
      })
      .finally(() => (isLoading.value = false));


    if (validationErrors.value !== null || validationErrors.value !== "") {
      localStorage.setItem("cart", JSON.stringify(oldLocal))
    }



  };

  const payByCredimax = async (order_id) => {
    window.open(
      process.env.VUE_APP_API_BASE_URL + "/CrediMax?id=" + order_id,
      "_self"
    );
  };

  const payByBenefit = async (order_id) => {
    window.open(process.env.VUE_APP_API_BASE_URL + "/Benefit?id=" + order_id, "_self");
  };

  const getCheckoutMeta = async () => {
    if (isLoading.value) return;
    isLoading.value = true;
    validationErrors.value = {};
    await axiosClient
      .get("/checkout-meta")
      .then((response) => {
        checkoutMeta.value = response.data.meta;
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const cancelOrder = async (orderId, statusId) => {
    const canCancel = [1, 2];
    var res = null;
    if (!canCancel.includes(statusId)) {
      swal({
        icon: "error",
        title: "You can not cancel the order now!",
      });
      return;
    }
    await swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let orderData = {};
        orderData["id"] = orderId;
        let params = serialize(orderData, {
          indices: true,
          booleansAsIntegers: true,
        });
        await axiosClient
          .post("/orders/cancel", params)
          .then((response) => {
            swal({
              icon: "success",
              title: "Order canceled successfully",
            });
            res = response;
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
            res = Promise.reject(error);
          });
      }
    });
    return res;
  };

  const payOrder = async (orderId, method, statusId) => {
    const canPay = [1];
    if (!canPay.includes(statusId)) {
      swal({
        icon: "error",
        title: "You already pay for this order",
      });
      return;
    }
    let orderData = {};
    orderData["id"] = orderId;
    orderData["payment_method"] = method;
    let params = serialize(orderData, {
      indices: true,
      booleansAsIntegers: true,
    });
    axiosClient
      .post("/orders/updateMethod", params)
      .then((response) => {
        if (response?.data?.data?.payment_method == "Credit Card") {
          payByCredimax(response?.data?.data?.id);
        } else if (response?.data?.data?.payment_method == "Debit Card") {
          payByBenefit(response?.data?.data?.id);
        } else {
          swal({
            icon: "success",
            title: "Payment changed successfully",
          });
          router.push({ name: "Home" });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  const addToWishlist = async (artworkId, isLoggedIn) => {
    wishlistIDs.value = JSON.parse(localStorage.getItem("wishlist")) ?? [];
    if (!wishlistIDs.value.includes(artworkId)) {
      wishlistIDs.value.push(artworkId);
      localStorage.setItem("wishlist", JSON.stringify(wishlistIDs.value));
    } else {
      var index = wishlistIDs.value.indexOf(artworkId);
      if (index !== -1) {
        wishlistIDs.value.splice(index, 1);
        localStorage.setItem("wishlist", JSON.stringify(wishlistIDs.value));
      }
    }
    if (LoggedIn.value) {
      let data = {};
      data["wishlistIDs"] = wishlistIDs.value;
      let params = serialize(data, {
        indices: true,
        booleansAsIntegers: true,
      });
      axiosClient
        .post("/addToWishlist", params)
        .then((response) => { })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Something went wrong",
          });
        });
    }
  };

  const getWishlistItems = async (page, isLoggedIn) => {
    wishlistIDs.value = JSON.parse(localStorage.getItem("wishlist")) ?? [];
    let data = {};
    data["wishlistIDs"] = wishlistIDs.value;
    data["page"] = page;
    let params = serialize(data, {
      indices: true,
      booleansAsIntegers: true,
    });
    axiosClient
      .post("/getWishlistItems", params)
      .then((response) => {
        wishlistItems.value = response.data;
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  const deleteWishlistItem = async (artworkId, isLoggedIn) => {
    wishlistIDs.value = JSON.parse(localStorage.getItem("wishlist")) ?? [];
    var index = wishlistIDs.value.indexOf(artworkId);
    if (index !== -1) {
      wishlistIDs.value.splice(index, 1);
      localStorage.setItem("wishlist", JSON.stringify(wishlistIDs.value));
    }
    if (LoggedIn.value) {
      let data = {};
      data["wishlistIDs"] = wishlistIDs.value;
      let params = serialize(data, {
        indices: true,
        booleansAsIntegers: true,
      });
      axiosClient
        .post("/addToWishlist", params)
        .then((response) => { })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Something went wrong",
          });
        });
    }
    getWishlistItems(1, LoggedIn.value);
  };

  return {
    isLoading,
    selectedOptions,
    setDefualtVariations,
    selectVariations,
    increaseQty,
    dcreaseQty,
    addToCart,
    updateCartQuantity,
    getUserCartItems,
    cartTotal,
    cartDiscount,
    cartAmount,
    updateCartItemQuantity,
    deleteCartItem,
    calculateCartTotal,
    placeOrder,
    getCheckoutMeta,
    checkoutMeta,
    checkoutErrors,
    payByCredimax,
    cancelOrder,
    payOrder,
    addToWishlist,
    getWishlistItems,
    wishlistItems,
    wishlistIDs,
    deleteWishlistItem,
  };
}
