<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form name="information" id="information" @submit.prevent="storeProduct(product)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Front Image
                        <md />
                      </label>
                      <FileUpload name="product_front_image[]" :auto="true" :customUpload="true"
                        @uploader="storeProductFiles($event, false, 'product_front_image', 30, 0, product.front_image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                                                      class="p-button-rounded p-button-success"
                                                                      :disabled="!files || files.length === 0"></Button>
                                                                    <Button @click="clearCallback()" icon="pi pi-times"
                                                                      class="p-button-rounded p-button-danger"
                                                                      :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!productProcessingFiles['product_front_image'] && product?.front_image && product?.front_image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in product?.front_image" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(product?.front_image, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="productProcessingFiles['product_front_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column pointer">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['front_image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Back Image
                        <md />
                      </label>
                      <FileUpload name="product_back_image[]" :auto="true" :customUpload="true"
                        @uploader="storeProductFiles($event, false, 'product_back_image', 30, 0, product.back_image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                                                      class="p-button-rounded p-button-success"
                                                                      :disabled="!files || files.length === 0"></Button>
                                                                    <Button @click="clearCallback()" icon="pi pi-times"
                                                                      class="p-button-rounded p-button-danger"
                                                                      :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!productProcessingFiles['product_back_image'] && product?.back_image && product?.back_image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in product?.back_image" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(product?.back_image, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="productProcessingFiles['product_back_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column pointer">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['back_image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <!-- new gallery -->
                    <div class="col-md-12">
                      <label class="form-control-label">Product Gallery
                        <md />
                      </label>
                      <FileUpload name="product_product_gallery[]" :auto="true" :customUpload="true"
                        @uploader="storeProductFiles($event, true, 'product_product_gallery', 30, 0, product.product_gallery, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!productProcessingFiles['product_product_gallery[]'] && product?.product_gallery && product?.product_gallery.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in product?.product_gallery" :key="index"
                              class="p-2 position-relative" style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
                                  removeFromArray(product?.product_gallery, index)
                                  ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="productProcessingFiles['product_product_gallery']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column pointer">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                            {{ product.product_gallery.length }}
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['product_gallery']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <!-- end of new gallery -->
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name
                        <md />
                      </label>
                      <input v-model="product.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                      <div class="row" v-for="message in validationErrors['name_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">price
                        <md />
                      </label>
                      <input v-model="product.price" class="form-control" id="price" type="number" name="price"
                        step="0.01" />
                      <div class="row" v-for="message in validationErrors['price']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">quantity
                        <md />
                      </label>
                      <input v-model="product.quantity" class="form-control" id="quantity" type="text" name="quantity" />
                      <div class="row" v-for="message in validationErrors['quantity']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Discount</label>
                      <input v-model="product.discount" class="form-control" id="discount" type="number" name="discount"
                        step="0.01" />
                      <div class="row" v-for="message in validationErrors['discount']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Description</label>
                      <textarea v-model="product.description_en" class="form-control" id="description_en"
                        name="description_en" />
                      <div class="row" v-for="message in validationErrors['description_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">height</label>
                      <input v-model="product.height" class="form-control" id="height" type="number" name="height" />
                      <div class="row" v-for="message in validationErrors['height']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">width</label>
                      <input v-model="product.width" class="form-control" id="width" type="number" name="width" />
                      <div class="row" v-for="message in validationErrors['width']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">weight</label>
                      <input v-model="product.weight" class="form-control" id="weight" type="number" name="weight" />
                      <div class="row" v-for="message in validationErrors['weight']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">length</label>
                      <input v-model="product.length" class="form-control" id="length" type="number" name="length" />
                      <div class="row" v-for="message in validationErrors['length']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">sku</label>
                      <input v-model="product.sku" class="form-control" id="sku" type="text" name="sku" />
                      <div class="row" v-for="message in validationErrors['sku']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="backorders">Backorders</label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="product.backorders"
                          :options="meta.backorders" optionLabel="label" optionValue="value" />
                        <div class="row" v-for="message in validationErrors['backorders']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="status_id">Status
                          <md />
                        </label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="product.status_id"
                          :options="meta.status" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['status_id']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="type_id">Type
                          <md />
                        </label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="product.type_id"
                          :options="meta.type" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['type_id']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between"></div>
              </div>
              <div v-if="product?.type_id == 2" class="card">
                <div class="card-header">
                  <h4>Variations</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="roles">Variations</label>
                        <MultiSelect v-model="variations" :filter="true" :showClear="true" :options="meta.attribute"
                          optionLabel="name_en" display="chip" />
                      </div>
                    </div>
                    <div v-if="variations === undefined ||
                      variations.length != 0
                      " class="col-md-12 mt-4">
                      <table class="w-100">
                        <tr v-for="(variation, iv) in variations" :key="variation">
                          <td>
                            <div class="form-group d-flex flex-column justify-content-start">
                              <label for="attributeOptions">{{ variation.name_en }}</label>
                              <MultiSelect v-model="variations[iv].variation_options" :filter="true" :showClear="true"
                                :options="meta?.attributeOption?.filter(
                                  (attributeOption) =>
                                    variation.id == attributeOption.attribute_id
                                )" optionLabel="name_en" display="chip" class="w-100">



                                <template v-slot:option="{ option }">
                                  <div v-if="showColor(option.name_en)">
                                    <span class="dot" :style="{ background: option.name_en }"></span>
                                  </div>
                                  <div v-else>
                                    {{ option.name_en }}
                                  </div>
                                </template>
                              </MultiSelect>


                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="product?.children === undefined ||
                    product?.children.length != 0
                    " class="col-md-12 mt-4">
                    <table class="table">
                      <tr>
                        <th>No.</th>
                        <!-- <th>Front Image</th>
                                                              <th>Back Image</th> -->
                        <th>Name</th>
                        <th v-for="variation in product.variations" :key="variation">
                          {{ variation.name_en }}
                        </th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Quantity</th>
                      </tr>
                      <tr v-for="(child, index) in product?.children" :key="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ child.name_en }}
                        </td>
                        <td v-for="(variation, index) in child.variations" :key="index">
                          <div v-for="(option, index) in variation.variation_options">
                            <div v-if="variation.name_en == 'Color'" :class="{
                              dot: variation.name_en == 'Color',
                            }" :style="[{ 'background-color': option.name_en }]">
                              &nbsp;
                            </div>
                            <span class="w-100" v-else>
                              {{ option.name_en }}
                            </span>
                          </div>
                        </td>
                        <td>
                          <input v-model="child.price" class="form-control" id="price" type="number" name="price"
                            step="0.01" />
                        </td>
                        <td>
                          <input v-model="child.discount" class="form-control" type="number" name="discount"
                            step="0.01" />
                        </td>
                        <td>
                          <input v-model="child.quantity" class="form-control" id="quantity" type="number"
                            name="quantity" />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button @click.prevent="
              router.push({
                name: 'products.index',
              })
              " label="Cancel" class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import useProducts from "@/composables/Products";
import { logedinUser } from "@/composables/auth";
import { removeFromArray } from "@/helpers/Functions";


export default {
  components: {
  },
  setup() {
    const product = reactive({
      front_image: [],
      back_image: [],
      product_gallery: [],
      backorders: "",
      description_ar: "",
      description_en: "",
      discount: "",
      downloadable: 0,
      height: "",
      length: "",
      low_stock_threshold: "",
      manage_stock: 0,
      name_ar: "",
      name_en: "",
      parent_id: "",
      price: "",
      quantity: "",
      sku: "",
      sold_individually: 0,
      status_id: "",
      type_id: "",
      vendor_id: "",
      user_id: "",
      virtual: 0,
      weight: "",
      width: "",
      children: [],
      variations: [],
    });
    const variations = ref([]);

    const {
      getProductMeta,
      meta,
      storeProduct,
      validationErrors,
      isLoading,
      productProcessingFiles,
      storeProductFiles,
    } = useProducts();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(() => {
      getProductMeta();
      product.user_id = logedinUser.id;
    });

    const generateProductVariations = async (options) => {
      // const options = {
      //   color: ["Red"],
      //   size: []
      // };
      // const optionNames = Object.keys(options);
      // const optionValues = Object.values(options);
      const optionNames = options.map(option => option.name_en);
      const optionValues = options.map(option => option.variation_options);
      const outputVariations = [];

      const generateVariations = (index, variation) => {
        if (index === optionNames.length) {
          outputVariations.push(variation);
          return;
        }

        if (optionValues[index]?.length) {
          for (let i = 0; i < optionValues[index].length; i++) {
            const newVariation = { ...variation };
            newVariation[index] = {
              name_en: optionNames[index],
              variation_options: [optionValues[index][i]]
            };
            generateVariations(index + 1, newVariation);
          }
        };
      }

      generateVariations(0, {});
      return outputVariations;
    }

    watch(product, async (current, previous) => {
      product.value?.children.forEach((item, index) => {
        item.price = product.value?.price;
        item.discount = product.value?.discount;
      })
    }, { deep: true });

    function showColor(color) {
      return color.startsWith("#");
    }

    watch(variations, async (current, previous) => {
      let productVariations = []
      variations.value = variations.value.sort((a, b) => a.name_en.localeCompare(b.name_en));
      if (variations.value[0]?.variation_options) {
        productVariations = await generateProductVariations(variations.value);
      }
      product.children = [];
      productVariations.forEach((element, index) => {
        let quantity = Math.floor(product.quantity / productVariations.length);
        let quantity_rem = product.quantity % productVariations.length;
        if (index == 0) {
          quantity = quantity + quantity_rem;
        }
        product.children.push({
          front_image: [],
          back_image: [],
          product_gallery: [],
          backorders: product.backorders,
          description_ar: product.description_ar,
          description_en: product.description_en,
          discount: product.discount,
          downloadable: product.downloadable,
          height: product.height,
          length: product.length,
          low_stock_threshold: product.low_stock_threshold,
          manage_stock: product.manage_stock,
          name_ar: product.name_ar,
          name_en: product.name_en,
          parent_id: product.parent_id,
          price: product.price,
          quantity: quantity,
          sku: product.sku + index,
          sold_individually: product.sold_individually,
          status_id: product.status_id,
          type_id: product.type_id,
          user_id: product.user_id,
          vendor_id: product.vendor_id,
          virtual: product.virtual,
          weight: product.weight,
          width: product.width,
          variations: Object.values(element),
        });
      });
      product.variations = variations.value
    },
      {
        deep: true
      });
    return {
      router,
      meta,
      product,
      storeProduct,
      validationErrors,
      isLoading,
      can,
      variations,
      productProcessingFiles,
      storeProductFiles,
      removeFromArray,
      showColor,
    };
  },
};
</script>

<style scoped>
.dot {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
</style>
