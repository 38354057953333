<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="storeRole(role)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name</label>
                      <input v-model="role.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.name_en" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name Ar</label>
                      <input
                        v-model="role.name_ar"
                        class="form-control"
                        id="name_ar"
                        type="text"
                        name="name_ar"
                      />
                    </div> -->
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label class="form-control-label" for="permissions">Permissions</label>
                        <MultiSelect v-model="role.permissions" :filter="true" :showClear="true"
                          :options="allPermissions.data" optionLabel="name_en" optionValue="id" display="chip" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button @click.prevent="
              router.push({
                name: 'roles.index',
              })
            " label="Cancel" class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import useRoles from "@/composables/Roles";
import usePermissions from "@/composables/Permissions";



export default {
  components: {
  },
  setup() {
    const role = reactive({
      name_en: "",
      name_ar: "",
      permissions: [],
    });

    const { storeRole, validationErrors, isLoading } = useRoles();
    const { allPermissions, getAllPermissions } = usePermissions();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(() => {
      getAllPermissions();
    });

    return {
      router,
      role,
      allPermissions,
      storeRole,
      validationErrors,
      isLoading,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>

