<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div>
                      <label class="form-control-label">Image</label>
                    </div>
                    <div>
                      <img id="artwork-image" class="img-fluid" style="object-fit: cover" :src="
                        artwork.image && artwork?.image[0]?.url
                          ? artwork?.image[0]?.url
                          : '/img/placeholders/block.png'
                      " alt="" loading="lazy" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-control-label">Name</label>
                        <input v-model="artwork.name_en" class="form-control" id="name_en" type="text" name="name_en"
                          readonly />
                      </div>
                      <div class="col-md-12 mt-4">
                        <label class="form-control-label">Description</label>
                        <textarea v-model="artwork.description_en" class="form-control" id="description_en"
                          name="description_en" readonly />
                      </div>
                      <div class="col-md-12 mt-4">
                        <label class="form-control-label">Categories</label>
                        <div>
                          <span v-for="category in artwork?.categoriesData" :key="category.id"
                            class="badge badge-lg badge-info mr-2">
                            {{ category.name_en }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <label class="form-control-label">Tags</label>
                        <div>
                          <span v-for="tag in artwork?.tagsData" :key="tag.id" class="badge badge-lg badge-info mr-2">
                            {{ tag.name_en }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <label class="form-control-label">Product</label>
                        <div>
                          <span v-for="product in artwork?.productData" :key="product.id"
                            class="badge badge-lg badge-info mr-2">
                            {{ product.name_en }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <label class="form-control-label">User</label>
                        <div>
                          <span class="badge badge-lg badge-info mr-2">
                            {{ artwork?.user?.name_en }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div v-if="artwork.products" class="row">
                  <div v-for="(product, productIndex) in artwork.products" :key="productIndex" class="col-12 col-lg-6">
                    <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-mockup'" class="mockup">
                      <img ref="imageList" :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-image'"
                        class="mockup-image" :src="product?.front_image[0]?.webp" alt="">
                      <div :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-drawing-area'"
                        class="drawing-area">
                        <div ref="canvasContainer"
                          :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas-container'"
                          class="canvas-container">
                          <canvas :id="product?.name_en.toLowerCase().replace(/ /g, '-') + '-canvas'"
                            class="mockup-canvas"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="form-control-label">{{ product?.name_en }}</p>
                        </div>
                        <div class="col-md-12" v-for="(setting, index) in artwork?.initial[product?.name_en]" :key="setting?.index">
                          <div v-if="index == 'color'" class="row">
                            <div class="col-md-3">
                              <p class="form-control-label text-capitalize">
                                {{ index }}
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="d-flex justify-content-start align-items-center">
                                <span class="color" :style="[{ 'background-color': setting }]"></span>
                                <span class="ml-3 form-control-label">
                                  {{ setting }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div v-else class="row">
                            <div class="col-md-3">
                              <p class="form-control-label text-capitalize">
                                {{ index }}
                              </p>
                            </div>
                            <div class="col-md-9">
                              <span class="form-control-label">
                                {{ setting }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label for="status_id">Status</label>
                      <Dropdown class="w-100" :filter="true" :showClear="true" v-model="artwork.status_id"
                        :options="artworkMeta?.status" optionLabel="name_en" optionValue="id" />
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="binary">Is Active</label>
                        <Checkbox name="Is Active" v-model="artwork.is_active" :binary="true"/>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="
                  card-header
                  d-flex
                  justify-content-between
                  align-items-center
                ">
                <div>Notes</div>
                <div v-if="artwork && artwork.notes">
                  <Button label="Add" @click.prevent="addNote()" class="p-button-secondary" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <th class="col-2">Title</th>
                    <th class="col-9">Content</th>
                    <th class="col-1">Actions</th>
                  </thead>
                  <tbody v-if="artwork && artwork.notes && artwork.notes.length > 0">
                    <tr v-for="(note, index) in artwork.notes" :key="note.index">
                      <td class="col-2">
                        <input v-model="note.title_en" :readonly="note.id ? true : false" class="form-control"
                          type="text" />
                      </td>
                      <td class="col-9">
                        <input v-model="note.content_en" :readonly="note.id ? true : false" class="form-control"
                          type="text" />
                      </td>
                      <td class="col-1">
                        <Button icon="far fa-trash-alt" @click.prevent="
                          deleteNote(
                            note.id,
                            index,
                            artwork
                          )
                        " class="p-button-danger" />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="col-12 text-center">No Notes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button label="Back" @click.prevent="
            router.push({
              name: 'artworks.index',
            })
          " class="p-button-secondary" />
          <Button label="Save" @click.prevent="updateArtwork(artwork, 'admin')" class="p-button-success" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";
import useArtworks from "@/composables/Artworks";
import useMockupEditor from "@/composables/MockupEditor";

const {
  artwork,
  artworkMeta,
  getArtworkMeta,
  getArtwork,
  updateArtwork,
  deleteNote,
} = useArtworks();

const {
  setUpShow,
} = useMockupEditor();

const router = useRouter();
const route = useRoute();
const { can } = useAbility();

const canvasContainer = ref([]);
const imageList = ref([]);

onMounted(async () => {
  await getArtwork(route.params.id);
  await getArtworkMeta();
  setTimeout(() => {
    setUpShow(artwork, {}, false, canvasContainer, imageList);
  }, 1000);
});

const addNote = () => {
  var note = {
    title_en: '',
    title_ar: '',
    content_en: '',
    content_ar: '',
  }
  artwork.value.notes.push(note);
}
</script>

<style>

</style>
