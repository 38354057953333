<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form name="information" id="information" @submit.prevent="updateOrder(order)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Order #{{ order.reference }} details</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="
                            col-md-6
                            d-flex
                            justify-content-between
                            align-items-cente
                          ">
                          <label class="form-control-label">Date</label>
                          <span class="badge badge-lg badge-info">
                            {{ order.date }}
                          </span>
                        </div>
                        <div class="
                            col-md-6
                            d-flex
                            justify-content-between
                            align-items-cente
                          ">
                          <label class="form-control-label">Payment Method</label>
                          <span class="badge badge-lg badge-info">
                            {{ order?.payment_method }}
                          </span>
                        </div>
                      </div>
                      <div class="w-100 mt-3">
                        <div class="form-group d-flex flex-column justify-content-start">
                          <label class="form-control-label" for="status_id">Status</label>
                          <Dropdown class="w-100" :filter="true" :showClear="true" v-model="order.status_id"
                            :options="meta.status" optionLabel="name_en" optionValue="id" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label">Delivery address</label>
                      <table v-if="order && order.delivery_address" class="w-100">
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Name
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.name_en }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Mobile
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.mobile }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Email
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.email }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Country
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.country }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              City
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.city }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Block
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.block }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Road
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.road }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Villa
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.villa }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Post Code
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.delivery_address?.post_code }}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label">Billing address</label>
                      <table v-if="order && order.billing_address" class="w-100">
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Name
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.name_en }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Mobile
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.mobile }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Email
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.email }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Country
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.country }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              City
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.city }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Block
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.block }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Road
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.road }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Villa
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.villa }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="form-control-label text-gray">
                              Post Code
                            </span>
                          </td>
                          <td>
                            <span class="form-control-label text-light">
                              {{ order?.billing_address?.post_code }}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-footer d-flex justify-content-between"></div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Items</h4>
                </div>
                <div class="card-body">
                  <div v-if="order.children" class="row">
                    <div v-for="child in order.children" :key="child.index" class="col-md-12">
                      From {{ child?.vendor?.name_en }} #{{ child.reference }}

                      <div v-if="child && child.items" class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td class="col-1 pl-0 font-weight-bold">
                                Artwork
                              </td>
                              <td class="col-3 align-middle font-weight-bold"></td>
                              <td class="col-2 align-middle font-weight-bold">
                                Scale
                              </td>
                              <td class="col-2 align-middle font-weight-bold">
                                Positions
                              </td>
                              <td class="col-2 align-middle font-weight-bold">
                                Quantity
                              </td>
                              <td class="col-2 align-middle font-weight-bold">
                                Discount
                              </td>
                              <td class="col-2 align-middle font-weight-bold">
                                Price
                              </td>
                            </tr>
                            <tr v-for="item in child.items" :key="item.index">
                              <td class="col-1 pl-0">
                                <img class="img-fluid" style="object-fit: cover" :src="
                                  item && item.item_image
                                    ? item.item_image
                                    : '/img/placeholders/block.png'
                                " alt="" loading="lazy" />
                              </td>
                              <td class="col-3 align-middle">
                                <div class="art-name">
                                  <h4>
                                    <b>
                                      {{ item?.name_en }}
                                    </b>
                                  </h4>
                                </div>
                                <div v-if="item.options" class="
                                    art-details
                                    d-flex
                                    justify-content-start
                                  ">
                                  <div v-for="(option, index) in item.options" :key="option.index" class="
                                      pr-2
                                      text-gray text-capitalize
                                      d-flex
                                      align-items-center
                                    ">
                                    <span>{{ index }}: &nbsp;</span>
                                    <span v-if="index == 'color'" class="dot" :style="[{ 'background-color': option }]">
                                    </span>
                                    <span v-else class="text-light">
                                      {{ option }}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td class="col-2 align-middle">
                                <div class="art-price">
                                  <span class="text-gray">
                                    {{ item?.initial?.scale }}
                                  </span>
                                </div>
                              </td>
                              <td class="col-2 align-middle">
                                <div class="art-positions">
                                  <span class="text-gray">
                                    {{ item?.initial?.positions }}
                                  </span>
                                </div>
                              </td>
                              <td class="col-2 align-middle">
                                <div class="art-quantity">
                                  <span class="font-weight-bold">
                                    {{ item?.quantity }}
                                  </span>
                                </div>
                              </td>
                              <td class="col-2 align-middle">
                                <div class="art-scale">
                                  <span class="font-weight-bold">
                                    - {{ item?.discount }}
                                  </span>
                                </div>
                              </td>
                              <td class="col-2 align-middle">
                                <div class="art-scale">
                                  <span class="font-weight-bold">
                                    {{ item?.price }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="col-1 pl-0 font-weight-bold"></td>
                              <td class="col-3 align-middle font-weight-bold"></td>
                              <td class="col-2 align-middle font-weight-bold"></td>
                              <td class="col-2 align-middle font-weight-bold"></td>
                              <td class="col-2 align-middle font-weight-bold"></td>
                              <td class="col-2 align-middle font-weight-bold">
                                Total
                              </td>
                              <td class="col-2 align-middle font-weight-bold">
                                {{ child?.total }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Summary</h4>
                </div>
                <div class="card-body">
                  <div class="d-flex flex-column">
                    <label class="form-control-label">Total discount {{ order.discount }}</label>
                    <label class="form-control-label">Total shipping {{ order.shipping }}</label>
                    <label class="form-control-label">Subtotal {{ order.subtotal }}</label>
                    <label class="form-control-label">Total {{ order.total }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
  router.push({
    name: 'orders.index',
  })
            " class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useOrders from "@/composables/Orders";



export default {
  components: {
  },
  setup() {
    const swal = inject("$swal");

    const { order, getOrder, meta, updateOrder, validationErrors, isLoading } =
      useOrders();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getOrder(route.params.id);
    });
    return {
      router,
      meta,
      order,
      updateOrder,
      validationErrors,
      isLoading,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>

<style scoped>
.btn-quantity {
  border: 1px solid var(--light) !important;
  padding: 0 !important;
}

.btn-quantity span {
  padding: 0.625rem 1.25rem;
}

.btn-quantity span.btn-q:hover {
  cursor: pointer;
  color: var(--primary);
}

.btn-custom-light {
  background-color: white;
  border: 1px solid var(--light) !important;
  padding: 0.625rem 1.25rem;
  color: var(--gray);
  font-weight: 600;
}

.btn-custom-light:hover {
  border: 1px solid var(--primary) !important;
  color: var(--secondary);
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
</style>
