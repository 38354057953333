<template>
    <div class="col-md-12 p-0">
        <div class="row">
            <div v-for="number in 9" class="col-md-4 p-0 pl-2 pr-2 pb-3">
                <div class="card theme-card bg-dark text-white border-0 m-0 m-pointer">
                    <img class="tst-img" style="object-fit: cover" src="/img/brand/45logo-dark.png" alt="Card image" />
                </div>
            </div>
        </div>
    </div>
</template>
  

<style scoped>
@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
        background-color: #f7f7f7;
    }


    50% {
        background-position: 100% 0;
        background-color: #f7f7f7;
    }

    100% {
        background-position: 200% 0;
        background-color: #f7f7f7;
    }
}

@keyframes cardImageOpacity {
    0% {
        background-position: 0% 0;
        opacity: 20%;
    }

    50% {
        background-position: 100% 0;
        opacity: 40%;
    }

    75% {
        background-position: 100% 0;
        opacity: 70%;
    }


    100% {
        background-position: 200% 0;
        opacity: 90%;
    }
}

.theme-card {
    width: 100%;
    height: 223px;
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border: 2px solid #ffffff;
}

.theme-card .card-img {
    height: 100%;
}

.tst-img {
    height: 100%;
    animation: cardImageOpacity 2s infinite !important;
}

.theme-card .card-title {
    font-family: "Poppins-Bold";
}

.breadcrumb-custom {
    font-family: "Poppins";
    font-size: 12px;
    color: var(--grayer);
}
</style>