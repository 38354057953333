import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useShippingMethods() {
  const shippingMethods = ref({});
  const shippingMethod = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getShippingMethods = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/shipping-methods?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        shippingMethods.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getShippingMethod = async (id) => {
    axiosClient.get("/shipping-methods/" + id).then((response) => {
      shippingMethod.value = response.data.data;
    });
  };

  const storeShippingMethod = async (shippingMethod) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedShippingMethod = new FormData();
    for (let item in shippingMethod) {
      if (shippingMethod.hasOwnProperty(item)) {
        serializedShippingMethod.append(item, shippingMethod[item]);
      }
    }

    axiosClient
      .post("/shipping-methods", serializedShippingMethod)
      .then((response) => {
        router.push({ name: "shipping-methods.index" });
        swal({
          icon: "success",
          title: "shipping method saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateShippingMethod = async (shippingMethod) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/shipping-methods/" + shippingMethod.id, shippingMethod)
      .then((response) => {
        router.push({ name: "shipping-methods.index" });
        swal({
          icon: "success",
          title: "Shipping method saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteShippingMethod = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/shipping-methods/" + id)
          .then((response) => {
            getShippingMethods();
            router.push({ name: "shipping-methods.index" });
            swal({
              icon: "success",
              title: "Shipping method deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    shippingMethods,
    shippingMethod,
    getShippingMethods,
    getShippingMethod,
    storeShippingMethod,
    validationErrors,
    isLoading,
    updateShippingMethod,
    deleteShippingMethod,
  };
}
