<template>
    <div class="container">
        <div class="col-md-12 mb-4 mt-6 p-0">
            <div class="d-flex flex-row justify-content-start align-items-center"></div>
            <div class="breadcrumb-custom">Home

                <svg xmlns="http://www.w3.org/2000/svg" width="4.811" height="8.121" viewBox="0 0 4.811 8.121"
                    class="ml-1 mr-1">
                    <path id="Path_258" data-name="Path 258" d="M10,14l3-3L10,8" transform="translate(-8.939 -6.939)"
                        fill="none" stroke="#837f7a" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" />
                </svg>
                Artists
            </div>
            <div class="current-page text-capitalize" style="font-size: 1.2rem;">Artists</div>
        </div>

        <div class="col-md-3 mb-5">
            <input v-model="search_name_en" class="form-control" placeholder="Search Artist Name" type="text" />
        </div>
        <div class="col-md-12 p-0">
            <hr class="m-0" />
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3 mt-3">
            <div v-if="!isLoading && artistUsers?.data?.length > 0" class="text-lable-gray">
                {{ artistUsers?.data?.length }} Results
            </div>
            <div class="d-flex align-items-center">
                <label for="perPage" class="per-page-label">Per page</label>
                <select id="perPage" v-model="per_page" class="form-select form-select-sm">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>

        <!-- Skeleton Loading -->
        <div v-if="isLoading" class="container py-5">
            <div class="row g-3">
                <div v-for="n in 5" :key="n" class="col-5-custom">
                    <div class="card artist-card shadow-sm">
                        <div class="card-body d-flex flex-column align-items-center">
                            <div class="avatar-container mb-4">
                                <div class="skeleton-avatar"></div>
                            </div>
                            <div class="skeleton-text-title mb-1"></div>
                            <div class="skeleton-text-body">
                                <div class="skeleton-line"></div>
                                <div class="skeleton-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Actual Content -->
        <div v-else-if="artistUsers?.data?.length > 0" class="container py-5">
            <div class="row g-3">
                <div v-for="item in artistUsers.data" :key="item?.id" class="col-5-custom">
                    <div class="card artist-card shadow-sm pointer text-center" @click="goToArtistProfile(item)">
                        <div class="card-body d-flex flex-column align-items-center">
                            <div class="mb-4" style="width: 80px; height: 80px;">
                                <img id="artwork-image" class="artist-avatar" :src="item.avatar && item?.avatar[0]?.url
                                    ? item?.avatar[0]?.url
                                    : 'img/user-defualt.svg'
                                    " alt="" loading="lazy" />
                            </div>
                            <h5 class="card-title mb-1">
                                {{ item?.name_en }}
                                <i v-if="item?.type_id == 1" class="primary-pxl fas fa-certificate ml-1"></i>
                            </h5>
                            <p class="card-text text-muted">
                                {{ item?.about_en }}
                            </p>
                            <div class="social-media mt-lg-3">
                                <div class="d-flex justify-content-start" v-if="item?.socials">
                                    <div v-for="social in item.socials" :key="social.index"
                                        style="width: 30px; height: 30px;">
                                        <a :href="social.link" target="_blank" style="width: 30px; height: 30px;">
                                            <img style="object-fit: contain; width: 100%; height: 100%; padding-right: 0.5rem;"
                                                :src="social.type.icon && social?.type?.icon[0]?.url
                                                    ? social?.type?.icon[0]?.url
                                                    : '/img/placeholders/block.png'
                                                    " alt="" loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mb-4 mt-4 p-0">
                    <div class="d-flex justify-content-end">
                        <pagination :data="artistUsers" :limit="1" @pagination-change-page="(page) =>
                            getArtistUsers(
                                page,
                                per_page
                            )
                            " />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import useUsers from "@/composables/Users";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
const route = useRoute();
const router = useRouter();
const { getArtistUsers, artistUsers } = useUsers();
const isLoading = ref(true);
const LOADING_DURATION = 1500;
const page = ref(1);
const per_page = ref(10);
const perPageOptions = [10, 20, 30, 40, 50];
const search_name_en = ref("");

const getAvatarUrl = (item) => {
    return item?.avatar?.[0]?.url || 'img/user-default.svg';
};

const Pagination = LaravelVuePagination;

const goToArtistProfile = (item) => {
    if (item?.username) {
        router.push(`/artist/${item.username}`);
    }
};

watch(per_page, async (newValue) => {
    isLoading.value = true;
    page.value = 1;
    try {
        await getArtistUsers(page.value, newValue);
        setTimeout(() => {
            isLoading.value = false;
        }, LOADING_DURATION);
    } catch (error) {
        console.error("Error fetching featured users", error);
        isLoading.value = false;
    }
});


watch(search_name_en, (current) => {
    getArtistUsers(page.value, per_page.value, current);
});




watch(() => route.query.page, (current) => {
    const pageNumber = current ? parseInt(current, 10) : 1;
    page.value = pageNumber;
});


watch(() => page.value, (current) => {
    getArtistUsers(current, per_page.value);
})

onMounted(async () => {
    try {
        const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
        page.value = pageNumber;
        await getArtistUsers(
            page.value,
            per_page.value
        );
        setTimeout(() => {
            isLoading.value = false;
        }, LOADING_DURATION);
        console.log("Featured users", artistUsers.value);
    } catch (error) {
        console.error("Error fetching featured users", error);
        isLoading.value = false;
    }
});
</script>

<style scoped>
/* Custom 5-column grid */
.col-5-custom {
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding: 6px;
}

@media (max-width: 1200px) {
    .col-5-custom {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 992px) {
    .col-5-custom {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 768px) {
    .col-5-custom {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 576px) {
    .col-5-custom {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.pointer {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.pointer:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.artist-card {
    height: 220px;
    width: 100%;
    border-radius: 0;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.avatar-container {
    width: 100px;
    height: 100px;
    margin-top: 0.5rem;
}

.artist-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.card-body {
    padding: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.25rem;
}

.card-title {
    font-size: 1rem;
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.8rem;
    margin-bottom: 0;
    line-height: 1.2;
}

.row.g-3 {
    margin-right: -6px;
    margin-left: -6px;
    row-gap: 0 !important;
}

.container.py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.skeleton-avatar {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
}

.skeleton-text-title {
    width: 50%;
    height: 20px;
    margin: 0 auto;
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border-radius: 4px;
}

.skeleton-text-body {
    width: 100%;
    margin-top: 0.5rem;
}

.skeleton-line {
    height: 14px;
    margin: 8px 0;
    animation: backgroundColorChange 2s infinite;
    background: linear-gradient(to right, #f7f7f7, #e9e9e9, #f7f7f7);
    background-size: 200% 100%;
    border-radius: 4px;
}

.per-page-label {
    color: #ec5d42;
    font-size: 0.75rem;
    font-weight: bold;
    margin-right: 0.5rem;
    margin-bottom: 0;
}

.form-select {
    padding: 0.375rem 1.75rem 0.375rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0;
    border: 1px solid #dee2e6;
    background-color: #fff;
    cursor: pointer;
    width: 120px;
    height: 35px;
    text-align: left;
}

.form-select:hover {
    border-color: #ec5d42;
}

.form-select:focus {
    border-color: #ec5d42;
    box-shadow: none;
    outline: 0;
}

.form-select option {
    padding: 8px;
    text-align: left;
}

.form-select option:hover {
    background-color: rgba(236, 93, 66, 0.2) !important;
    color: #000;
}

.form-select option:checked {
    background-color: rgba(236, 93, 66, 0.2) !important;
    color: #000;
}

/* Style for when hovering over options */
select.form-select option:hover {
    background-color: rgba(236, 93, 66, 0.2) !important;
}

/* Firefox specific styles for option hover */
@-moz-document url-prefix() {
    .form-select option:hover {
        background-color: rgba(236, 93, 66, 0.2) !important;
        color: #000;
    }
}

@keyframes backgroundColorChange {
    0% {
        background-position: 0% 0;
    }

    50% {
        background-position: 100% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.breadcrumb-custom {
    font-size: 0.75rem;
    color: #837f7a;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.current-page {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 0.25rem;
}

.col-md-12.mb-4.mt-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
</style>