import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";
import moment from 'moment';


export function useCoupons() {
  const coupons = ref({});
  const coupon = ref({});
  const couponMeta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const couponProcessing = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const getCoupons = async (
    page = 1,
    search_id = "",
    search_name = "",
    search_code = "",
    search_type = "",
    search_value = "",
    search_maximum_usage = "",
    search_is_active = "",
    search_expires_at = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/coupons?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name=" +
          search_name +
          "&search_code=" +
          search_code +
          "&search_type=" +
          search_type +
          "&search_value=" +
          search_value +
          "&search_maximum_usage=" +
          search_maximum_usage +
          "&search_is_active=" +
          search_is_active +
          "&search_expires_at=" +
          search_expires_at +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        coupons.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getCoupon = async (id) => {
    await axiosClient.get("/coupons/" + id).then((response) => {
      coupon.value = response.data.data;
      coupon.value.expires_at = new Date(coupon.value.expires_at)
      coupon.value.is_active = coupon.value.is_active ? true : false
      couponMeta.value = response.data.meta;
    });
  };

  const findCoupon = async (data) => {
    if (couponProcessing.value["findCoupon"]) return;
    let responseData = null;
    couponProcessing.value["findCoupon"] = true;
    await axiosClient.post("/coupon-find", data)
      .then((response) => {
        responseData = response;
      })
      .catch((error) => {
        responseData = Promise.reject(error);
      })
      .finally(() => (couponProcessing.value["findCoupon"] = false));
    return responseData;
  };

  const getCouponMeta = async () => {
    await axiosClient.get("/coupons/meta").then((response) => {
      couponMeta.value = response.data.meta;
    });
  };

  const storeCoupon = async (coupon) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let expires_at = coupon.expires_at
    coupon.expires_at = moment(expires_at).format('YYYY-MM-DD HH:mm:ss')
    let params = serialize(coupon, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/coupons", params)
      .then((response) => {
        router.push({ name: "coupons.index" });
        swal({
          icon: "success",
          title: "Coupon saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateCoupon = async (coupon) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let expires_at = coupon.expires_at
    coupon.expires_at = moment(expires_at).format('YYYY-MM-DD HH:mm:ss')

    axiosClient
      .put("/coupons/" + coupon.id, coupon)
      .then((response) => {
        router.push({ name: "coupons.index" });
        swal({
          icon: "success",
          title: "Coupon saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteCoupon = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/coupons/" + id)
          .then((response) => {
            getCoupons();
            router.push({ name: "coupons.index" });
            swal({
              icon: "success",
              title: "Coupon deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    coupons,
    coupon,
    getCoupons,
    couponMeta,
    getCouponMeta,
    getCoupon,
    storeCoupon,
    validationErrors,
    isLoading,
    updateCoupon,
    deleteCoupon,
    findCoupon,
  };
}
