<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5 mb-5">
      <h1 class="mt-5 header-1-p">Privacy Policy</h1>

      <p class="text-3-s mt-4 mb-4">
        PXL has created this privacy statement in order to demonstrate our firm
        commitment to your privacy. The following discloses our information
        gathering and dissemination practices for PXL official website:
        www.pxl.shop. Our Service might require you to provide your contact
        details i.e., email address and demographic information if applicable.
        Your contact information is used to notify you when you buy/sell
        products, availability of new products, special offers and news or to
        communicate with you.
      </p>
      <p class="text-3-s mt-2 mb-4">
        Our website may use browser cookies to maintain information about your
        site session; most notably, the 'state' of your current web session.
        Cookies help us identify you; this in-turn makes your experience at
        www.pxl.shop a smoother and more enjoyable. These cookies contain
        absolutely no personal and confidential information.
      </p>
      <p class="text-3-s mt-2 mb-4">
        Our website has a security measure in place to protect the loss, misuse,
        and alteration of the information under our control. All information you
        send to PXL via the www.pxl.shop such as your Username, password, and
        other transaction details are encrypted using the Secure Socket Layer
        (SSL) Certificate. The security of your information is vital to us.
      </p>
      <p class="text-3-s mt-2 mb-4">
        Please check this text-3-s often as we retain the right to change it at
        any moment. Changes and clarifications will be effective right away once
        they are posted online. If we make any significant changes to this
        text-3-s, we will let you know on the PXL website and by sending an email
        so that you are aware of what data we collect, how we use it, and, if
        applicable, under what conditions we use and/or disclose it.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
