<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Front Image</label>
                    <div v-if="product?.front_image && product?.front_image.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in product?.front_image" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                          alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Back Image</label>
                    <div v-if="product?.back_image && product?.back_image.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in product?.back_image" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                          alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Name</label>
                    <input v-model="product.name_en" class="form-control" id="name_en" type="text" name="name_en"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">price</label>
                    <input v-model="product.price" class="form-control" id="price" type="number" name="price"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">quantity</label>
                    <input v-model="product.quantity" class="form-control" id="quantity" type="text" name="quantity"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Discount</label>
                    <input v-model="product.discount" class="form-control" id="discount" type="number" name="discount"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Description</label>
                    <textarea v-model="product.description_en" class="form-control" id="description_en"
                      name="description_en" readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">height</label>
                    <input v-model="product.height" class="form-control" id="height" type="number" name="height"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">width</label>
                    <input v-model="product.width" class="form-control" id="width" type="number" name="width"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">weight</label>
                    <input v-model="product.weight" class="form-control" id="weight" type="number" name="weight"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">length</label>
                    <input v-model="product.length" class="form-control" id="length" type="number" name="length"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">sku</label>
                    <input v-model="product.sku" class="form-control" id="sku" type="text" name="sku" readonly />
                  </div>
                  <!-- <div class="col-md-12 mt-4">
                    <base-checkbox
                      :disabled="true"
                      v-model="product.manage_stock"
                      >manage stock</base-checkbox
                    >
                  </div> -->
                  <!-- <div class="col-md-12 mt-4">
                    <base-checkbox
                      :disabled="true"
                      v-model="product.downloadable"
                      >downloadable</base-checkbox
                    >
                  </div> -->
                  <!-- <div class="col-md-12 mt-4">
                    <base-checkbox :disabled="true" v-model="product.virtual"
                      >virtual</base-checkbox
                    >
                  </div> -->
                  <!-- <div class="col-md-12 mt-4">
                    <base-checkbox
                      :disabled="true"
                      v-model="product.sold_individually"
                      >sold individually</base-checkbox
                    >
                  </div> -->
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Backorders</label>
                    <br />
                    {{ product.backorders }}
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">User</label>
                    <br />
                    {{ product?.user?.name_en }}
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Status</label>
                    <br />
                    {{ product?.status?.name_en }}
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Type</label>
                    <br />
                    {{ product?.type?.name_en }}
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
            <div v-if="product?.type_id == 2" class="card">
              <div class="card-header">
                <h4>Variations</h4>
              </div>
              <div class="row">
                <div v-if="
                  product?.children === undefined ||
                  product?.children.length != 0
                " class="col-md-12">
                  <table class="table">
                    <tr>
                      <th>No.</th>
                      <!-- <th>Front Image</th>
                      <th>Back Image</th> -->
                      <th>Name</th>
                      <th v-for="variation in product.variations" :key="variation">
                        {{ variation.name_en }}
                      </th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>
                    <tr v-for="(childe, index) in product?.children" :key="childe.index">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <!-- <td>
                        <div v-if="childe?.front_image && childe?.front_image.length > 0"
                          class="d-flex justify-content-start align-items-center flex-wrap">
                          <div v-for="(image, index) in childe?.front_image" :key="index" class="p-2 position-relative"
                            style="height:150px; width: 150px;">
                            <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                              alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div v-if="childe?.back_image && childe?.back_image.length > 0"
                          class="d-flex justify-content-start align-items-center flex-wrap">
                          <div v-for="(image, index) in childe?.back_image" :key="index" class="p-2 position-relative"
                            style="height:150px; width: 150px;">
                            <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                              alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                          </div>
                        </div>
                      </td> -->
                      <td>
                        {{ childe.name_en }}
                      </td>
                      <td v-for="options in childe.variations" :key="options.index">
                        <div v-for="option in options.variation_options" :key="option.index">
                          <span v-if="options.name_en == 'Color'" :class="{
                            dot: options.name_en == 'Color',
                          }" :style="[{ 'background-color': option.name_en }]">
                            &nbsp;
                          </span>
                          <span v-else>
                            {{ option.name_en }}
                          </span>
                        </div>
                      </td>
                      <td>
                        {{ childe.price }}
                      </td>
                      <td>
                        {{ childe.quantity }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button @click.prevent="
  router.push({
    name: 'products.index',
  })
          " label="Cancel" class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useProducts from "@/composables/Products";

import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import { ElSelect, ElOption } from "element-plus";

export default {
  components: {
    DropzoneFileUpload,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  setup() {
    const { product, getProduct, meta } = useProducts();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getProduct(route.params.id);
    });

    return {
      router,
      meta,
      product,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>

<style scoped>
.dot {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
</style>
