import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";
import { useAuth } from "@/composables/auth";

export default function useUsers() {
  const users = ref({});
  const user = ref({});
  const featuredUsers = ref({});
  const artistUsers = ref({});
  const userMeta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const addMessageFlag = ref(false);
  const userProcessingFiles = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const { getLogedinUser } = useAuth();

  const getUsers = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_username = "",
    search_email = "",
    search_mobile = "",
    search_mobilecode = "",
    search_role = "",
    search_global = "",
    order_column = "id",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/users?" +
        "page=" +
        page +
        "&search_id=" +
        search_id +
        "&search_name_en=" +
        search_name_en +
        "&search_name_ar=" +
        search_name_ar +
        "&search_username=" +
        search_username +
        "&search_email=" +
        search_email +
        "&search_mobile=" +
        search_mobile +
        "&search_mobilecode" +
        search_mobilecode +
        "&search_role=" +
        search_role +
        "&search_global=" +
        search_global +
        "&order_column=" +
        order_column +
        "&order_direction=" +
        order_direction +
        "&per_page=" +
        per_page
      )
      .then((response) => {
        users.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getUser = async (
    id,
    page = 1,
    search_global = "",
    orderColumn = "created_at",
    orderDirection = "desc",
    per_page = "10"
  ) => {
    await axiosClient
      .get(
        "/users/" +
        id +
        "?" +
        "page=" +
        page +
        "&search_global=" +
        search_global +
        "&orderColumn=" +
        orderColumn +
        "&orderDirection=" +
        orderDirection +
        "&per_page=" +
        per_page
      )
      .then((response) => {
        response.data.data.rolesData = response.data.data.roles;
        response.data.data.roles = response.data.roles;
        user.value = response.data.data;
        userMeta.value = response.data.meta;
      });
  };

  const getArtistProfile = async (username, filters) => {
    let artist = {
      username: username,
      filters: filters
    };
    let params = serialize(artist, {
      indices: true,
    });
    axiosClient
      .post("/artist/profile", params)
      .then((response) => {
        user.value = response.data;
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getFeaturedUsers = async (page = 1, per_page = 6) => {
    await axiosClient.get("/users/featured" +
      "?" +
      "page=" +
      page +
      "&per_page=" +
      per_page
    ).then((response) => {
      featuredUsers.value = response.data.data;
    });
  };

  const getArtistUsers = async (page = 1, per_page = 6, search_name_en = "") => {
    let url = `/users/artists?page=${page}&per_page=${per_page}`;

    if (search_name_en) {
      url += `&search_name_en=${encodeURIComponent(search_name_en)}`;
    }
    try {
      const response = await axiosClient.get(url);
      artistUsers.value = response.data.data;
    } catch (error) {
      console.error("Error fetching artist users:", error);
    }
  };

  const getUserMeta = async () => {
    axiosClient.get("/users/meta").then((response) => {
      userMeta.value = response.data.meta;
    });
  };

  const storeUser = async (user) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    // let serializedUser = new FormData();
    // for (let item in user) {
    //   if (user.hasOwnProperty(item)) {
    //     if (typeof user[item] == "object") {
    //       for (let i = 0; i < user[item].length; i++) {
    //         let file = user[item][i];
    //         serializedUser.append(item + "[" + i + "]", file);
    //       }
    //     } else {
    //       serializedUser.append(item, user[item]);
    //     }
    //   }
    // }

    let params = serialize(user, {
      indices: true,
    });

    axiosClient
      .post("/users", params)
      .then((response) => {
        router.push({ name: "users.index" });
        swal({
          icon: "success",
          title: "User saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };
  const storeUserMessage = async (userMessage) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedMessage = new FormData();
    for (let item in userMessage) {
      if (userMessage.hasOwnProperty(item)) {
        serializedMessage.append(item, userMessage[item]);
      }
    }

    axiosClient
      .post("/messages", serializedMessage)
      .then((response) => {
        getUser(userMessage.user_id);
        addMessageFlag.value = false;
        swal({
          icon: "success",
          title: "Message saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateUser = async (user) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/users/" + user.id, user)
      .then((response) => {
        router.push({ name: "users.index" });
        swal({
          icon: "success",
          title: "User saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateMyAccount = async (user) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/users/" + user.id, user)
      .then((response) => {
        getLogedinUser();
        swal({
          icon: "success",
          title: "saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteUser = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/users/" + id)
          .then((response) => {
            getUsers();
            router.push({ name: "users.index" });
            swal({
              icon: "success",
              title: "User deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const deleteUserMessage = async (id, user_id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/messages/" + id)
          .then((response) => {
            getUser(user_id);
            swal({
              icon: "success",
              title: "Message deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const deleteUserAddress = async (id, index, userid) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id) {
          axiosClient
            .delete("/user-addresses/" + id)
            .then((response) => {
              getUser(userid);
              swal({
                icon: "success",
                title: "User address deleted successfully",
              });
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Something went wrong",
                text: error,
              });
            });
        } else {
          try {
            user.value.address.splice(index, 1);

            swal({
              icon: "success",
              title: "User address deleted successfully",
            });
          } catch (error) {
            swal({
              icon: "error",
              title: "Something went wrong",
              text: error,
            });
          }
        }
      }
    });
  };

  const deleteUserSocial = async (id, index, userid) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id) {
          axiosClient
            .delete("/user-socials/" + id)
            .then((response) => {
              getUser(userid);
              swal({
                icon: "success",
                title: "User social deleted successfully",
              });
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Something went wrong",
                text: error,
              });
            });
        } else {
          try {
            user.value.socials.splice(index, 1);

            swal({
              icon: "success",
              title: "User social deleted successfully",
            });
          } catch (error) {
            swal({
              icon: "error",
              title: "Something went wrong",
              text: error,
            });
          }
        }
      }
    });
  };

  const storeUserFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (userProcessingFiles.value[collection_name]) return;

    userProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedUser = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedUser.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/users/media", serializedUser)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (userProcessingFiles.value[collection_name] = false));
    });
  };
  const readNotifications = async (notifications) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(notifications, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/users/readNotifications", params)
      .then((response) => { })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  return {
    users,
    userMeta,
    user,
    getUserMeta,
    getUsers,
    getUser,
    storeUser,
    validationErrors,
    isLoading,
    updateUser,
    updateMyAccount,
    deleteUser,
    deleteUserAddress,
    deleteUserSocial,
    storeUserMessage,
    deleteUserMessage,
    addMessageFlag,
    getFeaturedUsers,
    featuredUsers,
    getArtistUsers,
    artistUsers,
    userProcessingFiles,
    storeUserFiles,
    readNotifications,
    getArtistProfile,
  };
}
