import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useArtworks() {
  const artworks = ref({});
  const artwork = ref({});
  const artworkMeta = ref({});
  const files = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const artworkProcessingFiles = ref({});

  const router = useRouter();
  const swal = inject("$swal");

  const getArtworks = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_categories = "",
    search_products = "",
    search_tags = "",
    search_variations = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10,
    inactive = 0,
    is_direct = 0,
    is_featured = null,
  ) => {
    axiosClient
      .get(
        "/artworks" +
        "?" +
        "page=" +
        page +
        "&search_id=" +
        search_id +
        "&search_name_en=" +
        search_name_en +
        "&search_name_ar=" +
        search_name_ar +
        "&search_categories=" +
        search_categories +
        "&search_products=" +
        search_products +
        "&search_tags=" +
        search_tags +
        "&search_variations=" +
        encodeURIComponent(search_variations) +
        // encodeURIComponent(JSON.stringify(search_variations)) +
        "&search_global=" +
        search_global +
        "&order_column=" +
        order_column +
        "&order_direction=" +
        order_direction +
        "&per_page=" +
        per_page +
        "&inactive=" +
        inactive +
        "&is_direct=" +
        is_direct +
        "&is_featured=" +
        is_featured
      )
      .then((response) => {
        artworks.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getArtworkMeta = async () => {
    axiosClient.get("/artworks/meta").then((response) => {
      artworkMeta.value = response.data.meta;
    });
  };

  const getArtwork = async (id) => {
    await axiosClient.get("/artworks/" + id).then((response) => {
      // response.data.data.productsData = response.data.data.products;
      // response.data.data.products = response.data.products;

      // response.data.data.categoriesData = response.data.data.categories;
      // response.data.data.categories = response.data.categories;

      // response.data.data.tagsData = response.data.data.tags;
      // response.data.data.tags = response.data.tags;

      artwork.value = response.data.data;
      artworkMeta.value = response.data.meta;
    });
  };

  const getArtworkBySlug = async (slug) => {
    var data = {
      slug: slug,
    };

    let params = serialize(data, {
      indices: true,
      booleansAsIntegers: true,
    });
    await axiosClient.post("/artworks/slug", params).then((response) => {
      // response.data.data.productsData = response.data.data.products;
      // response.data.data.products = response.data.products;

      // response.data.data.categoriesData = response.data.data.categories;
      // response.data.data.categories = response.data.categories;

      // response.data.data.tagsData = response.data.data.tags;
      // response.data.data.tags = response.data.tags;

      artwork.value = response.data.data;
      artworkMeta.value = response.data.meta;
    });
  };

  const storeArtwork = async (artwork, type) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(artwork, {
      indices: true,
      booleansAsIntegers: true,
    });

    var res = null;

    await axiosClient
      .post("/artworks", params)
      .then(async (response) => {
        if (artwork?.direct == 1) {
          res = response.data.data;
        } else if (type === "admin") {
          router.push({ name: "artworks.index" });
          swal({
            icon: "success",
            title: "Artwork saved successfully",
          });
        } else if (type === "verifyUser") {
          router.push({ name: "Account" });
          swal({
            icon: "success",
            title: "Artwork added successfully",
          });
        } else if (type === "user") {
          router.push({ name: "Account" });
          swal({
            icon: "success",
            title: "Artwork added successfully",
            text: "The desgin will go under an approval proccess before beign added to the profile"
          });
        }
        else {
          res = response.data.data;
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Artwork error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
    return res;
  };

  const addNote = async (notes, artworkID) => {
    notes.push({
      artwork_id: artworkID,
      content_en: "",
      content_ar: "",
      title_en: "",
      title_ar: "",
    });
  };

  const deleteNote = async (noteID, index, artworkData) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (noteID && artworkData?.id) {
          axiosClient
            .delete("/artwork-notes/" + noteID)
            .then(() => {
              getArtwork(artworkData?.id);
              swal({
                icon: "success",
                title: "Note deleted successfully",
              });
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Something went wrong",
              });
            });
        } else {
          artworkData.notes.splice(index, 1);
          swal({
            icon: "success",
            title: "Note deleted successfully",
          });
        }
      }
    });
  };

  const updateArtworkStatus = async () => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};


    let params = serialize(artwork.value, {
      indices: true,
      booleansAsIntegers: true,
    });


    axiosClient
      .post("/artworks/updateStatus", params)
      .then((response) => {
        swal({
          icon: "success",
          title: "Status updated successfully",
        });
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;
          swal({
            icon: "error",
            title: "Status update error",
          });
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeArtworkNotes = async (notes, artworkID) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let ob = {};
    ob.notes = notes;

    const params = serialize(ob, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/artworks/storeNotes", params)
      .then((response) => {
        getArtwork(artworkID);
        swal({
          icon: "success",
          title: "Notes saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeArtworkInitial = async (artworkID, initial) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let data = {
      id: artworkID,
      initial: initial,
    };

    let params = serialize(data, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/artworks/storeInitial", params)
      .then((response) => {
        router.push({ name: "Account" });
        swal({
          icon: "success",
          title: "Artwork saved successfully",
        });
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Artwork error",
        });
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeArtworkMedia = async (files) => {
    let serializedArtwork = new FormData();
    for (let item in files) {
      if (files.hasOwnProperty(item)) {
        serializedArtwork.append(item, files[item]);
      }
    }

    axiosClient
      .post("/artworks/media", serializedArtwork)
      .then((response) => { })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateArtwork = async (artwork, type) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};
    if (type === "admin") {
      artwork.is_admin = true;
    } else {
      artwork.is_admin = false;
    }

    axiosClient
      .put("/artworks/" + artwork.id, artwork)
      .then((response) => {
        if (type === "admin") {
          router.push({ name: "artworks.index" });
          swal({
            icon: "success",
            title: "Artwork saved successfully",
          });
        } else if (type === "verifyUser") {
          router.push({ name: "Account" });
          swal({
            icon: "success",
            title: "Artwork added successfully",
          });
        } else {
          router.push({ name: "Account" });
          swal({
            icon: "success",
            title: "Artwork added successfully",
            text: "The desgin will go under an approval proccess before beign added to the profile"
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteArtwork = async (id, type) => {
    await swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosClient
          .delete("/artworks/" + id)
          .then((response) => {
            if (type === "admin") {
              getArtworks();
              router.push({ name: "artworks.index" });
              swal({
                icon: "success",
                title: "Artwork deleted successfully",
              });
            } else {
              router.push({ name: "Account" });
              swal({
                icon: "success",
                title: "Artwork deleted successfully",
              });
            }
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const updateIsActive = async (artwork_id, is_active, artworkData) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let ob = {};
    ob.id = artwork_id;
    ob.is_active = is_active;

    let params = serialize(ob, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/artworks/updateIsActive", params)
      .then((response) => {
        const artworkDataResponse = response.data.data;
        let msg = "";
        if (artworkDataResponse.is_active == true) {
          msg = artworkDataResponse.name_en + " activated successfully";
        }
        if (artworkDataResponse.is_active == false) {
          msg = artworkDataResponse.name_en + " deactivated successfully";
        }
        artworkData.is_active = artworkDataResponse.is_active;
        swal({
          icon: "success",
          title: msg,
        });
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;
          swal({
            icon: "error",
            title: "Status update error",
          });
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const storeArtworkFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (artworkProcessingFiles.value[collection_name]) return;

    artworkProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedUser = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedUser.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/artworks/media", serializedUser)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (artworkProcessingFiles.value[collection_name] = false));
    });
  };

  return {
    artworks,
    artwork,
    artworkMeta,
    files,
    getArtworkMeta,
    getArtworks,
    getArtwork,
    storeArtwork,
    addNote,
    deleteNote,
    updateArtworkStatus,
    storeArtworkNotes,
    storeArtworkInitial,
    validationErrors,
    isLoading,
    updateArtwork,
    deleteArtwork,
    storeArtworkMedia,
    updateIsActive,
    getArtworkBySlug,
    artworkProcessingFiles,
    storeArtworkFiles,
  };
}
