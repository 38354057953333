import { createApp, onMounted } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";


import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability";

import "./assets/themes/saga-blue/theme.css";
import "./assets/css/custom.css";

import GlobalComponents from "./plugins/globalComponents";

const options = { containerClassName: "ct-notification" };

const app = createApp(App, {
  setup() {
    onMounted();
  },
});

app.use(router);
app.use(VueSweetalert2);
app.use(abilitiesPlugin, ability);
app.use(Toast, options);
app.use(ArgonDashboard);
app.use(PrimeVue);
app.use(GlobalComponents);
app.mount("#app");
