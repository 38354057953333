import { createRouter, createWebHashHistory } from "vue-router";
import { isLoggedIn } from "@/composables/auth";

function authAdmin(to, from, next) {
  const list = ["/login", "/register", "/lock"];
  const roles = localStorage.getItem("ROLES").split(",");

  if (isLoggedIn.value == true && list.includes(to.fullPath)) {
    next("/");
  } else if (isLoggedIn.value && roles.includes("Admin")) {
    next();
  }
}

function authUser(to, from, next) {
  const list = [
    "/account",
    "/add-artwork",
    "/add-artwork/select-product",
    "/edit-artwork",
    "/edit-artwork/select-product",
  ];
  if (isLoggedIn.value == false && list.includes(to.fullPath)) {
    next("/");
  } else {
    next();
  }
}

function logedin(to, from, next) {
  const list = ["/login", "/register", "/lock"];
  if (isLoggedIn.value == true && list.includes(to.fullPath)) {
    next("/");
  } else {
    next();
  }
}

// Auth Layout
import AuthLayout from "@/views/Layout/Auth/AuthLayout";
// Auth Pages
import Login from "../views/Layout/Auth/Login.vue";
import Register from "../views/Layout/Auth/Register.vue";
import ForgotPassword from "../views/Layout/Auth/ForgotPassword.vue";
import ResetPassword from "../views/Layout/Auth/ResetPassword.vue";
import Lock from "../views/Layout/Auth/Lock.vue";
import Pricing from "../views/Layout/Auth/Pricing.vue";

// Main Layout
import MainLayout from "@/views/Layout/Main/MainLayout";
// Main Pages
import Home from "@/views/Pages/Home";
import Themes from "@/views/Pages/Themes";
import Artists from "@/views/Pages/Artists";
import Theme from "@/views/Pages/Theme";
import ThemeArtworks from "@/views/Pages/ThemeArtworks";
import Shop from "@/views/Pages/Shop";
import Artwork from "@/views/Pages/Artwork";
import PrintYourArt from "@/views/Pages/PrintYourArt";
import AddArtwork from "@/views/Pages/AddArtwork";
import EditArtwork from "@/views/Pages/EditArtwork";
import SelectProduct from "@/views/Pages/SelectProduct";
import Cart from "@/views/Pages/Cart";
import Checkout from "@/views/Pages/Checkout";
import Contact from "@/views/Pages/Contact";
import About from "@/views/Pages/About";
import PrivacyPolicy from "@/views/Pages/PrivacyPolicy";
import ReturnPolicy from "@/views/Pages/ReturnPolicy";
import TermsAndConditions from "@/views/Pages/TermsAndConditions"
import HowItWorks from "@/views/Pages/HowItWorks";;
import ArtistProfile from "@/views/Pages/ArtistProfile";
import Account from "@/views/Pages/Account";
import Product from "@/views/Pages/Product";
import Wishlist from "@/views/Pages/Wishlist";
import Message from "@/views/Pages/Message";

// Dashboard Layout
import DashboardLayout from "@/views/Layout/Dashboard/DashboardLayout";
// Dashboard Pages
import Settings from "../views/Pages/Settings.vue";
import Dashboard from "../views/Pages/Dashboard.vue";
import Alternative from "../views/Dashboard/AlternativeDashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";
// GlobalSettings CRUD
import GlobalSettingsIndex from "../views/Pages/Settings/GlobalSettings/Index";
import GlobalSettingsCreate from "../views/Pages/Settings/GlobalSettings/Create";
import GlobalSettingsEdit from "../views/Pages/Settings/GlobalSettings/Edit";
import GlobalSettingsShow from "../views/Pages/Settings/GlobalSettings/Show";

// Users CRUD
import UsersIndex from "../views/Pages/Users/Index";
import UsersCreate from "../views/Pages/Users/Create";
import UsersEdit from "../views/Pages/Users/Edit";
import UsersShow from "../views/Pages/Users/Show";

// Products CRUD
import ProductsIndex from "../views/Pages/Products/Index";
import ProductsCreate from "../views/Pages/Products/Create";
import ProductsEdit from "../views/Pages/Products/Edit";
import ProductsShow from "../views/Pages/Products/Show";

// Artworks CRUD
import ArtworksIndex from "../views/Pages/Artworks/Index";
import ArtworksCreate from "../views/Pages/Artworks/Create";
import ArtworksEdit from "../views/Pages/Artworks/Edit";
import ArtworksShow from "../views/Pages/Artworks/Show";

// ArtworkNotes CRUD
// import ArtworkNotesIndex from "../views/Pages/Artworks/ArtworkNotes/Index";
// import ArtworkNotesCreate from "../views/Pages/Artworks/ArtworkNotes/Create";
// import ArtworkNotesEdit from "../views/Pages/Artworks/ArtworkNotes/Edit";
// import ArtworkNotesShow from "../views/Pages/Artworks/ArtworkNotes/Show";

// Notifications CRUD
import NotificationsIndex from "../views/Pages/Notifications/Index";
import NotificationsCreate from "../views/Pages/Notifications/Create";
import NotificationsEdit from "../views/Pages/Notifications/Edit";
import NotificationsShow from "../views/Pages/Notifications/Show";

// Coupons CRUD
import CouponsIndex from "../views/Pages/Coupons/Index";
import CouponsCreate from "../views/Pages/Coupons/Create";
import CouponsEdit from "../views/Pages/Coupons/Edit";
import CouponsShow from "../views/Pages/Coupons/Show";

// Orders CRUD
import OrdersIndex from "../views/Pages/Orders/Index";
import OrdersShow from "../views/Pages/Orders/Show";
// import OrdersCreate from "../views/Pages/Orders/Create";
// import OrdersEdit from "../views/Pages/Orders/Edit";

// PaymentDue CRUD
import PaymentDueIndex from "../views/Pages/PaymentDue/Index";
import PaymentDueShow from "../views/Pages/PaymentDue/Show";
import CustomArtworksIndex from "../views/Pages/CustomArtworks/Index.vue"
import FeaturedArtworksIndex from "../views/Pages/FeaturedArtworks/Index.vue"

// Settings CRUDs
import CountriesIndex from "@/views/Pages/Settings/Countries/Index";
import CountriesCreate from "@/views/Pages/Settings/Countries/Create";
import CountriesEdit from "@/views/Pages/Settings/Countries/Edit";
import CountriesShow from "@/views/Pages/Settings/Countries/Show";

import CitiesIndex from "@/views/Pages/Settings/Cities/Index";
import CitiesCreate from "@/views/Pages/Settings/Cities/Create";
import CitiesEdit from "@/views/Pages/Settings/Cities/Edit";
import CitiesShow from "@/views/Pages/Settings/Cities/Show";

import CurrenciesIndex from "@/views/Pages/Settings/Currencies/Index";
import CurrenciesCreate from "@/views/Pages/Settings/Currencies/Create";
import CurrenciesEdit from "@/views/Pages/Settings/Currencies/Edit";
import CurrenciesShow from "@/views/Pages/Settings/Currencies/Show";

import PermissionsIndex from "@/views/Pages/Settings/Permissions/Index";
import PermissionsCreate from "@/views/Pages/Settings/Permissions/Create";
import PermissionsEdit from "@/views/Pages/Settings/Permissions/Edit";
import PermissionsShow from "@/views/Pages/Settings/Permissions/Show";

import ShippingsIndex from "@/views/Pages/Settings/Shippings/Index";
import ShippingsCreate from "@/views/Pages/Settings/Shippings/Create";
import ShippingsEdit from "@/views/Pages/Settings/Shippings/Edit";
import ShippingsShow from "@/views/Pages/Settings/Shippings/Show";

import ShippingMethodsIndex from "@/views/Pages/Settings/ShippingMethods/Index";
import ShippingMethodsCreate from "@/views/Pages/Settings/ShippingMethods/Create";
import ShippingMethodsEdit from "@/views/Pages/Settings/ShippingMethods/Edit";
import ShippingMethodsShow from "@/views/Pages/Settings/ShippingMethods/Show";

import RolesIndex from "@/views/Pages/Settings/Roles/Index";
import RolesCreate from "@/views/Pages/Settings/Roles/Create";
import RolesEdit from "@/views/Pages/Settings/Roles/Edit";
import RolesShow from "@/views/Pages/Settings/Roles/Show";

import UserStatusesIndex from "@/views/Pages/Settings/UserStatuses/Index";
import UserStatusesCreate from "@/views/Pages/Settings/UserStatuses/Create";
import UserStatusesEdit from "@/views/Pages/Settings/UserStatuses/Edit";
import UserStatusesShow from "@/views/Pages/Settings/UserStatuses/Show";

import UserTypesIndex from "@/views/Pages/Settings/UserTypes/Index";
import UserTypesCreate from "@/views/Pages/Settings/UserTypes/Create";
import UserTypesEdit from "@/views/Pages/Settings/UserTypes/Edit";
import UserTypesShow from "@/views/Pages/Settings/UserTypes/Show";

import MessageTypesIndex from "@/views/Pages/Settings/MessageTypes/Index";
import MessageTypesCreate from "@/views/Pages/Settings/MessageTypes/Create";
import MessageTypesEdit from "@/views/Pages/Settings/MessageTypes/Edit";
import MessageTypesShow from "@/views/Pages/Settings/MessageTypes/Show";

import NotificationTypesIndex from "@/views/Pages/Settings/NotificationTypes/Index";
import NotificationTypesCreate from "@/views/Pages/Settings/NotificationTypes/Create";
import NotificationTypesEdit from "@/views/Pages/Settings/NotificationTypes/Edit";
import NotificationTypesShow from "@/views/Pages/Settings/NotificationTypes/Show";

import PaymentMethodsIndex from "@/views/Pages/Settings/PaymentMethods/Index";
import PaymentMethodsCreate from "@/views/Pages/Settings/PaymentMethods/Create";
import PaymentMethodsEdit from "@/views/Pages/Settings/PaymentMethods/Edit";
import PaymentMethodsShow from "@/views/Pages/Settings/PaymentMethods/Show";

import ArtworkStatusesIndex from "@/views/Pages/Settings/ArtworkStatuses/Index";
import ArtworkStatusesCreate from "@/views/Pages/Settings/ArtworkStatuses/Create";
import ArtworkStatusesEdit from "@/views/Pages/Settings/ArtworkStatuses/Edit";
import ArtworkStatusesShow from "@/views/Pages/Settings/ArtworkStatuses/Show";

import ArtworkCategoriesIndex from "@/views/Pages/Settings/ArtworkCategories/Index";
import ArtworkCategoriesCreate from "@/views/Pages/Settings/ArtworkCategories/Create";
import ArtworkCategoriesEdit from "@/views/Pages/Settings/ArtworkCategories/Edit";
import ArtworkCategoriesShow from "@/views/Pages/Settings/ArtworkCategories/Show";

import ArtworkTagsIndex from "@/views/Pages/Settings/ArtworkTags/Index";
import ArtworkTagsCreate from "@/views/Pages/Settings/ArtworkTags/Create";
import ArtworkTagsEdit from "@/views/Pages/Settings/ArtworkTags/Edit";
import ArtworkTagsShow from "@/views/Pages/Settings/ArtworkTags/Show";

import OrderStatusesIndex from "@/views/Pages/Settings/OrderStatuses/Index";
import OrderStatusesCreate from "@/views/Pages/Settings/OrderStatuses/Create";
import OrderStatusesEdit from "@/views/Pages/Settings/OrderStatuses/Edit";
import OrderStatusesShow from "@/views/Pages/Settings/OrderStatuses/Show";

import ProductStatusesIndex from "@/views/Pages/Settings/ProductStatuses/Index";
import ProductStatusesCreate from "@/views/Pages/Settings/ProductStatuses/Create";
import ProductStatusesEdit from "@/views/Pages/Settings/ProductStatuses/Edit";
import ProductStatusesShow from "@/views/Pages/Settings/ProductStatuses/Show";

import ProductTypesIndex from "@/views/Pages/Settings/ProductTypes/Index";
import ProductTypesCreate from "@/views/Pages/Settings/ProductTypes/Create";
import ProductTypesEdit from "@/views/Pages/Settings/ProductTypes/Edit";
import ProductTypesShow from "@/views/Pages/Settings/ProductTypes/Show";

import SocialTypesIndex from "@/views/Pages/Settings/SocialTypes/Index";
import SocialTypesCreate from "@/views/Pages/Settings/SocialTypes/Create";
import SocialTypesEdit from "@/views/Pages/Settings/SocialTypes/Edit";
import SocialTypesShow from "@/views/Pages/Settings/SocialTypes/Show";

import TagsIndex from "@/views/Pages/Settings/Tags/Index";
import TagsCreate from "@/views/Pages/Settings/Tags/Create";
import TagsEdit from "@/views/Pages/Settings/Tags/Edit";
import TagsShow from "@/views/Pages/Settings/Tags/Show";

import TaxesIndex from "@/views/Pages/Settings/Taxes/Index";
import TaxesCreate from "@/views/Pages/Settings/Taxes/Create";
import TaxesEdit from "@/views/Pages/Settings/Taxes/Edit";
import TaxesShow from "@/views/Pages/Settings/Taxes/Show";

import AttributesIndex from "@/views/Pages/Settings/Attributes/Index";
import AttributesCreate from "@/views/Pages/Settings/Attributes/Create";
import AttributesEdit from "@/views/Pages/Settings/Attributes/Edit";
import AttributesShow from "@/views/Pages/Settings/Attributes/Show";

import AttributeOptionsIndex from "@/views/Pages/Settings/AttributeOptions/Index";
import AttributeOptionsCreate from "@/views/Pages/Settings/AttributeOptions/Create";
import AttributeOptionsEdit from "@/views/Pages/Settings/AttributeOptions/Edit";
import AttributeOptionsShow from "@/views/Pages/Settings/AttributeOptions/Show";

import CategoriesIndex from "@/views/Pages/Settings/Categories/Index";
import CategoriesCreate from "@/views/Pages/Settings/Categories/Create";
import CategoriesEdit from "@/views/Pages/Settings/Categories/Edit";
import CategoriesShow from "@/views/Pages/Settings/Categories/Show";

import SlidersIndex from "@/views/Pages/Sliders/index";
import SlidersCreate from "@/views/Pages/Sliders/Create";
import SlidersEdit from "@/views/Pages/Sliders/Edit";
import SlidersShow from "@/views/Pages/Sliders/Show";

const routes = [
  {
    path: "/",
    redirect: "/",
    component: MainLayout,
    beforeEnter: authUser,
    children: [
      {
        path: "/",
        name: "Home",
        components: { default: Home },
        meta: { title: "Home" },
      },
      {
        path: "/themes",
        name: "Themes",
        components: { default: Themes },
        props: { default: true },
        meta: { title: "Themes" },
      },
      {
        path: "/artists",
        name: "Artists",
        components: { default: Artists },
        props: { default: true },
        meta: { title: "Artists" },
      },
      // {
      //   path: "/themes/:slug",
      //   name: "Theme",
      //   components: { default: Theme },
      //   props: { default: true },
      //   meta: { title: "Theme" },
      // },
      {
        path: "/themes/:slug",
        name: "ThemeArtworks",
        components: { default: ThemeArtworks },
        props: { default: true },
        meta: { title: "Theme Artworks" },
      },
      {
        path: "/shop",
        name: "Shop",
        components: { default: Shop },
        props: { default: true, search_value: true },
        meta: { title: "Shop" },
      },
      {
        path: "/artwork/:id",
        name: "Artwork",
        components: { default: Artwork },
        meta: { title: "Artwork" },
      },
      {
        path: "/print-your-art",
        name: "PrintYourArt",
        components: { default: PrintYourArt },
        meta: { title: "Print Your Art" },
      },
      {
        path: "/add-artwork",
        name: "AddArtwork",
        components: { default: AddArtwork },
        meta: { title: "Add Artwork" },
      },
      {
        path: "/add-artwork/select-product",
        name: "SelectProduct",
        components: { default: SelectProduct },
        props: { default: true },
        meta: { title: "Select Product" },
      },
      {
        path: "/edit-artwork/:slug",
        name: "EditArtwork",
        components: { default: EditArtwork },
        meta: { title: "Edit Artwork" },
      },
      {
        path: "/edit-artwork/select-product",
        name: "SelectProduct",
        components: { default: SelectProduct },
        props: { default: true },
        meta: { title: "Select Product" },
      },
      {
        path: "/cart",
        name: "Cart",
        components: { default: Cart },
        meta: { title: "Cart" },
      },
      {
        path: "/checkout",
        name: "Checkout",
        components: { default: Checkout },
        meta: { title: "Checkout" },
      },
      {
        path: "/contact",
        name: "Contact",
        components: { default: Contact },
        meta: { title: "Contact" },
      },
      {
        path: "/about",
        name: "About",
        components: { default: About },
        meta: { title: "About" },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        components: { default: PrivacyPolicy },
        meta: { title: "Privacy policy" },
      },
      {
        path: "/return-policy",
        name: "ReturnPolicy",
        components: { default: ReturnPolicy },
        meta: { title: "Return policy" },
      },
      {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        components: { default: TermsAndConditions },
        meta: { title: "Terms and conditions" },
      },
      {
        path: "/how-it-works",
        name: "HowItWorks",
        components: { default: HowItWorks },
        meta: { title: "How it works?" },
      },
      {
        path: "/product/:id",
        name: "Product",
        components: { default: Product },
        meta: { title: "Product" },
      },
      {
        path: "/artist/:slug",
        name: "Artist",
        component: ArtistProfile,
        meta: { title: "Artist" },
      },
      {
        path: "/Message",
        name: "Message",
        components: { default: Message },
        props: { default: true },
        meta: { title: "Message" },
      },
      {
        path: "/account",
        name: "Account",
        components: { default: Account },
        props: { default: true },
        meta: { title: "Account" },
      },
      {
        path: "/favorite",
        name: "Wishlist",
        components: { default: Wishlist },
        meta: { title: "Wishlist" },
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    beforeEnter: logedin,
    children: [
      {
        path: "/pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        components: { default: ForgotPassword },
      },
      {
        path: "/reset-password/:token",
        name: "ResetPassword",
        components: { default: ResetPassword },
      },
      {
        path: "/lock",
        name: "Lock",
        components: { default: Lock },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    beforeEnter: authAdmin,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/settings",
        name: "Settings",
        components: { default: Settings },
      },
      // Global Settings CRUD
      {
        path: "/global-settings",
        name: "globalSettings.index",
        component: GlobalSettingsIndex,
        meta: { title: "Global Settings" },
      },
      {
        path: "/global-settings/create",
        name: "globalSettings.create",
        component: GlobalSettingsCreate,
        meta: { title: "Add new Global Settings" },
      },
      {
        path: "/global-settings/edit/:id",
        name: "globalSettings.edit",
        component: GlobalSettingsEdit,
        meta: { title: "Edit Global Settings" },
      },
      {
        path: "/global-settings/show/:id",
        name: "globalSettings.show",
        component: GlobalSettingsShow,
        meta: { title: "Global Settings" },
      },
      // Users CRUD
      {
        path: "/users",
        name: "users.index",
        component: UsersIndex,
        meta: { title: "Users" },
      },
      {
        path: "/users/create",
        name: "users.create",
        component: UsersCreate,
        meta: { title: "Add new post" },
      },
      {
        path: "/users/edit/:id",
        name: "users.edit",
        component: UsersEdit,
        meta: { title: "Edit post" },
      },
      {
        path: "/users/show/:id",
        name: "users.show",
        component: UsersShow,
        meta: { title: "User" },
      },
      // Products CRUD
      {
        path: "/products",
        name: "products.index",
        component: ProductsIndex,
        meta: { title: "Products" },
      },
      {
        path: "/products/create",
        name: "products.create",
        component: ProductsCreate,
        meta: { title: "Add new post" },
      },
      {
        path: "/products/edit/:id",
        name: "products.edit",
        component: ProductsEdit,
        meta: { title: "Edit post" },
      },
      {
        path: "/products/show/:id",
        name: "products.show",
        component: ProductsShow,
        meta: { title: "Product" },
      },
      // Artworks CRUD
      {
        path: "/artworks",
        name: "artworks.index",
        component: ArtworksIndex,
        meta: { title: "Artworks" },
      },
      {
        path: "/custom-artworks",
        name: "custom-artworks.index",
        component: CustomArtworksIndex,
        meta: { title: "Custom Artworks" },
      },
      {
        path: "/featured-artworks",
        name: "featured-artworks.index",
        component: FeaturedArtworksIndex,
        meta: { title: "Featured Artworks" },
      },
      {
        path: "/artworks/create",
        name: "artworks.create",
        component: ArtworksCreate,
        meta: { title: "Add new artwork" },
      },
      {
        path: "/artworks/edit/:id",
        name: "artworks.edit",
        component: ArtworksEdit,
        meta: { title: "Edit artwork" },
      },
      {
        path: "/artworks/show/:id",
        name: "artworks.show",
        component: ArtworksShow,
        meta: { title: "Artwork" },
      },
      // Notifications CRUD
      {
        path: "/notifications",
        name: "notifications.index",
        component: NotificationsIndex,
        meta: { title: "Notifications" },
      },
      {
        path: "/notifications/create",
        name: "notifications.create",
        component: NotificationsCreate,
        meta: { title: "Add new notification" },
      },
      {
        path: "/notifications/edit/:id",
        name: "notifications.edit",
        component: NotificationsEdit,
        meta: { title: "Edit notification" },
      },
      {
        path: "/notifications/show/:id",
        name: "notifications.show",
        component: NotificationsShow,
        meta: { title: "Notification" },
      },
      // Coupons CRUD
      {
        path: "/coupons",
        name: "coupons.index",
        component: CouponsIndex,
        meta: { title: "Coupons" },
      },
      {
        path: "/coupons/create",
        name: "coupons.create",
        component: CouponsCreate,
        meta: { title: "Add new coupon" },
      },
      {
        path: "/coupons/edit/:id",
        name: "coupons.edit",
        component: CouponsEdit,
        meta: { title: "Edit coupon" },
      },
      {
        path: "/coupons/show/:id",
        name: "coupons.show",
        component: CouponsShow,
        meta: { title: "Coupon" },
      },
      // Orders CRUD
      {
        path: "/orders",
        name: "orders.index",
        component: OrdersIndex,
        meta: { title: "Orders" },
      },
      {
        path: "/orders/show/:id",
        name: "orders.show",
        component: OrdersShow,
        meta: { title: "Order" },
      },
      // PaymentDue CRUD
      {
        path: "/due-payments",
        name: "due-payments.index",
        component: PaymentDueIndex,
        meta: { title: "Due payments" },
      },
      {
        path: "/due-payments/show/:id",
        name: "due-payments.show",
        component: PaymentDueShow,
        meta: { title: "Due payments" },
      },
      //countries
      {
        path: "/countries",
        name: "countries.index",
        component: CountriesIndex,
        meta: { title: "Countries list" },
      },
      {
        path: "/countries/create",
        name: "countries.create",
        component: CountriesCreate,
        meta: { title: "Add new Country" },
      },
      {
        path: "/countries/edit/:id",
        name: "countries.edit",
        component: CountriesEdit,
        meta: { title: "Edit Country" },
      },
      {
        path: "/countries/show/:id",
        name: "countries.show",
        component: CountriesShow,
        meta: { title: "Country info" },
      },
      // cities
      {
        path: "/cities",
        name: "cities.index",
        component: CitiesIndex,
        meta: { title: "Cities list" },
      },
      {
        path: "/cities/create",
        name: "cities.create",
        component: CitiesCreate,
        meta: { title: "Add new City" },
      },
      {
        path: "/cities/edit/:id",
        name: "cities.edit",
        component: CitiesEdit,
        meta: { title: "Edit City" },
      },
      {
        path: "/cities/show/:id",
        name: "cities.show",
        component: CitiesShow,
        meta: { title: "City info" },
      },
      // Currencies
      {
        path: "/currencies",
        name: "currencies.index",
        component: CurrenciesIndex,
        meta: { title: "Currencies list" },
      },
      {
        path: "/currencies/create",
        name: "currencies.create",
        component: CurrenciesCreate,
        meta: { title: "Add new Currency" },
      },
      {
        path: "/currencies/edit/:id",
        name: "currencies.edit",
        component: CurrenciesEdit,
        meta: { title: "Edit Currency" },
      },
      {
        path: "/currencies/show/:id",
        name: "currencies.show",
        component: CurrenciesShow,
        meta: { title: "Currency info" },
      },
      // permissions
      {
        path: "/permissions",
        name: "permissions.index",
        component: PermissionsIndex,
        meta: { title: "Permissions list" },
      },
      {
        path: "/permissions/create",
        name: "permissions.create",
        component: PermissionsCreate,
        meta: { title: "Add new Permission" },
      },
      {
        path: "/permissions/edit/:id",
        name: "permissions.edit",
        component: PermissionsEdit,
        meta: { title: "Edit Permission" },
      },
      {
        path: "/permissions/show/:id",
        name: "permissions.show",
        component: PermissionsShow,
        meta: { title: "Permission info" },
      },
      // shippings
      {
        path: "/shippings",
        name: "shippings.index",
        component: ShippingsIndex,
        meta: { title: "Shippings list" },
      },
      {
        path: "/shippings/create",
        name: "shippings.create",
        component: ShippingsCreate,
        meta: { title: "Add new Shipping" },
      },
      {
        path: "/shippings/edit/:id",
        name: "shippings.edit",
        component: ShippingsEdit,
        meta: { title: "Edit Shipping" },
      },
      {
        path: "/shippings/show/:id",
        name: "shippings.show",
        component: ShippingsShow,
        meta: { title: "Shipping info" },
      },
      // shipping methods
      {
        path: "/shipping-methods",
        name: "shipping-methods.index",
        component: ShippingMethodsIndex,
        meta: { title: "Shipping Methods list" },
      },
      {
        path: "/shipping-methods/create",
        name: "shipping-methods.create",
        component: ShippingMethodsCreate,
        meta: { title: "Add new Shipping Method" },
      },
      {
        path: "/shipping-methods/edit/:id",
        name: "shipping-methods.edit",
        component: ShippingMethodsEdit,
        meta: { title: "Edit Shipping Method" },
      },
      {
        path: "/shipping-methods/show/:id",
        name: "shipping-methods.show",
        component: ShippingMethodsShow,
        meta: { title: "Shipping Method info" },
      },
      // roles
      {
        path: "/roles",
        name: "roles.index",
        component: RolesIndex,
        meta: { title: "Roles list" },
      },
      {
        path: "/roles/create",
        name: "roles.create",
        component: RolesCreate,
        meta: { title: "Add new Role" },
      },
      {
        path: "/roles/edit/:id",
        name: "roles.edit",
        component: RolesEdit,
        meta: { title: "Edit Role" },
      },
      {
        path: "/roles/show/:id",
        name: "roles.show",
        component: RolesShow,
        meta: { title: "Role info" },
      },
      // User Statuses
      {
        path: "/user-statuses",
        name: "user-statuses.index",
        component: UserStatusesIndex,
        meta: { title: "User Statuses list" },
      },
      {
        path: "/user-statuses/create",
        name: "user-statuses.create",
        component: UserStatusesCreate,
        meta: { title: "Add new User Statuses" },
      },
      {
        path: "/user-statuses/edit/:id",
        name: "user-statuses.edit",
        component: UserStatusesEdit,
        meta: { title: "Edit User Statuses" },
      },
      {
        path: "/user-statuses/show/:id",
        name: "user-statuses.show",
        component: UserStatusesShow,
        meta: { title: "User Statuses info" },
      },
      // User Types
      {
        path: "/user-types",
        name: "user-types.index",
        component: UserTypesIndex,
        meta: { title: "User Types list" },
      },
      {
        path: "/user-types/create",
        name: "user-types.create",
        component: UserTypesCreate,
        meta: { title: "Add new User Types" },
      },
      {
        path: "/user-types/edit/:id",
        name: "user-types.edit",
        component: UserTypesEdit,
        meta: { title: "Edit User Types" },
      },
      {
        path: "/user-types/show/:id",
        name: "user-types.show",
        component: UserTypesShow,
        meta: { title: "User Types info" },
      },
      // Message Types
      {
        path: "/message-types",
        name: "message-types.index",
        component: MessageTypesIndex,
        meta: { title: "Message Types list" },
      },
      {
        path: "/message-types/create",
        name: "message-types.create",
        component: MessageTypesCreate,
        meta: { title: "Add new Message Types" },
      },
      {
        path: "/message-types/edit/:id",
        name: "message-types.edit",
        component: MessageTypesEdit,
        meta: { title: "Edit Message Types" },
      },
      {
        path: "/message-types/show/:id",
        name: "message-types.show",
        component: MessageTypesShow,
        meta: { title: "Message Types info" },
      },
      // Notification Types
      {
        path: "/notification-types",
        name: "notification-types.index",
        component: NotificationTypesIndex,
        meta: { title: "Notification Types list" },
      },
      {
        path: "/notification-types/create",
        name: "notification-types.create",
        component: NotificationTypesCreate,
        meta: { title: "Add new Notification Types" },
      },
      {
        path: "/notification-types/edit/:id",
        name: "notification-types.edit",
        component: NotificationTypesEdit,
        meta: { title: "Edit Notification Types" },
      },
      {
        path: "/notification-types/show/:id",
        name: "notification-types.show",
        component: NotificationTypesShow,
        meta: { title: "Notification Types info" },
      },
      // Payment Methods
      {
        path: "/payment-methods",
        name: "payment-methods.index",
        component: PaymentMethodsIndex,
        meta: { title: "Payment Methods list" },
      },
      {
        path: "/payment-methods/create",
        name: "payment-methods.create",
        component: PaymentMethodsCreate,
        meta: { title: "Add new Payment Methods" },
      },
      {
        path: "/payment-methods/edit/:id",
        name: "payment-methods.edit",
        component: PaymentMethodsEdit,
        meta: { title: "Edit Payment Methods" },
      },
      {
        path: "/payment-methods/show/:id",
        name: "payment-methods.show",
        component: PaymentMethodsShow,
        meta: { title: "Payment Methods info" },
      },
      // Artwork Statuses
      {
        path: "/artwork-statuses",
        name: "artwork-statuses.index",
        component: ArtworkStatusesIndex,
        meta: { title: "Artwork statuses list" },
      },
      {
        path: "/artwork-statuses/create",
        name: "artwork-statuses.create",
        component: ArtworkStatusesCreate,
        meta: { title: "Add new artwork statuses" },
      },
      {
        path: "/artwork-statuses/edit/:id",
        name: "artwork-statuses.edit",
        component: ArtworkStatusesEdit,
        meta: { title: "Edit artwork statuses" },
      },
      {
        path: "/artwork-statuses/show/:id",
        name: "artwork-statuses.show",
        component: ArtworkStatusesShow,
        meta: { title: "Artwork statuses info" },
      },
      // Artwork Categories
      {
        path: "/artwork-categories",
        name: "artwork-categories.index",
        component: ArtworkCategoriesIndex,
        meta: { title: "Artwork themes list" },
      },
      {
        path: "/artwork-categories/create",
        name: "artwork-categories.create",
        component: ArtworkCategoriesCreate,
        meta: { title: "Add new artwork themes" },
      },
      {
        path: "/artwork-categories/edit/:id",
        name: "artwork-categories.edit",
        component: ArtworkCategoriesEdit,
        meta: { title: "Edit artwork themes" },
      },
      {
        path: "/artwork-categories/show/:id",
        name: "artwork-categories.show",
        component: ArtworkCategoriesShow,
        meta: { title: "Artwork theme info" },
      },
      // Artwork Tags
      {
        path: "/artwork-tags",
        name: "artwork-tags.index",
        component: ArtworkTagsIndex,
        meta: { title: "Artwork tags list" },
      },
      {
        path: "/artwork-tags/create",
        name: "artwork-tags.create",
        component: ArtworkTagsCreate,
        meta: { title: "Add new artwork tags" },
      },
      {
        path: "/artwork-tags/edit/:id",
        name: "artwork-tags.edit",
        component: ArtworkTagsEdit,
        meta: { title: "Edit artwork tags" },
      },
      {
        path: "/artwork-tags/show/:id",
        name: "artwork-tags.show",
        component: ArtworkTagsShow,
        meta: { title: "Artwork tags info" },
      },
      // Order Statuses
      {
        path: "/order-statuses",
        name: "order-statuses.index",
        component: OrderStatusesIndex,
        meta: { title: "Order statuses list" },
      },
      {
        path: "/order-statuses/create",
        name: "order-statuses.create",
        component: OrderStatusesCreate,
        meta: { title: "Add new order statuses" },
      },
      {
        path: "/order-statuses/edit/:id",
        name: "order-statuses.edit",
        component: OrderStatusesEdit,
        meta: { title: "Edit order statuses" },
      },
      {
        path: "/order-statuses/show/:id",
        name: "order-statuses.show",
        component: OrderStatusesShow,
        meta: { title: "Order statuses info" },
      },
      // Product Statuses
      {
        path: "/product-statuses",
        name: "product-statuses.index",
        component: ProductStatusesIndex,
        meta: { title: "Product statuses list" },
      },
      {
        path: "/product-statuses/create",
        name: "product-statuses.create",
        component: ProductStatusesCreate,
        meta: { title: "Add new product statuses" },
      },
      {
        path: "/product-statuses/edit/:id",
        name: "product-statuses.edit",
        component: ProductStatusesEdit,
        meta: { title: "Edit product statuses" },
      },
      {
        path: "/product-statuses/show/:id",
        name: "product-statuses.show",
        component: ProductStatusesShow,
        meta: { title: "Product statuses info" },
      },
      // Order Statuses
      {
        path: "/order-statuses",
        name: "order-statuses.index",
        component: OrderStatusesIndex,
        meta: { title: "Order statuses list" },
      },
      {
        path: "/order-statuses/create",
        name: "order-statuses.create",
        component: OrderStatusesCreate,
        meta: { title: "Add new order statuses" },
      },
      {
        path: "/order-statuses/edit/:id",
        name: "order-statuses.edit",
        component: OrderStatusesEdit,
        meta: { title: "Edit order statuses" },
      },
      {
        path: "/order-statuses/show/:id",
        name: "order-statuses.show",
        component: OrderStatusesShow,
        meta: { title: "Order statuses info" },
      },
      // Product Types
      {
        path: "/product-types",
        name: "product-types.index",
        component: ProductTypesIndex,
        meta: { title: "Product Types list" },
      },
      {
        path: "/product-types/create",
        name: "product-types.create",
        component: ProductTypesCreate,
        meta: { title: "Add new Product Types" },
      },
      {
        path: "/product-types/edit/:id",
        name: "product-types.edit",
        component: ProductTypesEdit,
        meta: { title: "Edit Product Types" },
      },
      {
        path: "/product-types/show/:id",
        name: "product-types.show",
        component: ProductTypesShow,
        meta: { title: "Product Types info" },
      },
      // Social Types
      {
        path: "/social-types",
        name: "social-types.index",
        component: SocialTypesIndex,
        meta: { title: "Social Types list" },
      },
      {
        path: "/social-types/create",
        name: "social-types.create",
        component: SocialTypesCreate,
        meta: { title: "Add new Social Types" },
      },
      {
        path: "/social-types/edit/:id",
        name: "social-types.edit",
        component: SocialTypesEdit,
        meta: { title: "Edit Social Types" },
      },
      {
        path: "/social-types/show/:id",
        name: "social-types.show",
        component: SocialTypesShow,
        meta: { title: "Social Types info" },
      },
      // tags
      {
        path: "/tags",
        name: "tags.index",
        component: TagsIndex,
        meta: { title: "Tags list" },
      },
      {
        path: "/tags/create",
        name: "tags.create",
        component: TagsCreate,
        meta: { title: "Add new Tag" },
      },
      {
        path: "/tags/edit/:id",
        name: "tags.edit",
        component: TagsEdit,
        meta: { title: "Edit Tag" },
      },
      {
        path: "/tags/show/:id",
        name: "tags.show",
        component: TagsShow,
        meta: { title: "Tag info" },
      },
      // taxes
      {
        path: "/taxes",
        name: "taxes.index",
        component: TaxesIndex,
        meta: { title: "Taxes list" },
      },
      {
        path: "/taxes/create",
        name: "taxes.create",
        component: TaxesCreate,
        meta: { title: "Add new Tax" },
      },
      {
        path: "/taxes/edit/:id",
        name: "taxes.edit",
        component: TaxesEdit,
        meta: { title: "Edit Tax" },
      },
      {
        path: "/taxes/show/:id",
        name: "taxes.show",
        component: TaxesShow,
        meta: { title: "Tax info" },
      },
      // Attributes
      {
        path: "/attributes",
        name: "attributes.index",
        component: AttributesIndex,
        meta: { title: "Attributes list" },
      },
      {
        path: "/attributes/create",
        name: "attributes.create",
        component: AttributesCreate,
        meta: { title: "Add new Attributes" },
      },
      {
        path: "/attributes/edit/:id",
        name: "attributes.edit",
        component: AttributesEdit,
        meta: { title: "Edit Attributes" },
      },
      {
        path: "/attributes/show/:id",
        name: "attributes.show",
        component: AttributesShow,
        meta: { title: "Attributes info" },
      },
      // Attribute Options
      {
        path: "/attribute-options",
        name: "attribute-options.index",
        component: AttributeOptionsIndex,
        meta: { title: "Attributes list" },
      },
      {
        path: "/attribute-options/create",
        name: "attribute-options.create",
        component: AttributeOptionsCreate,
        meta: { title: "Add new Attributes" },
      },
      {
        path: "/attribute-options/edit/:id",
        name: "attribute-options.edit",
        component: AttributeOptionsEdit,
        meta: { title: "Edit Attributes" },
      },
      {
        path: "/attribute-options/show/:id",
        name: "attribute-options.show",
        component: AttributeOptionsShow,
        meta: { title: "Attribute Options info" },
      },
      // Categories
      {
        path: "/categories",
        name: "categories.index",
        component: CategoriesIndex,
        meta: { title: "Categories list" },
      },
      {
        path: "/categories/create",
        name: "categories.create",
        component: CategoriesCreate,
        meta: { title: "Add new Category" },
      },
      {
        path: "/categories/edit/:id",
        name: "categories.edit",
        component: CategoriesEdit,
        meta: { title: "Edit Category" },
      },
      {
        path: "/categories/show/:id",
        name: "categories.show",
        component: CategoriesShow,
        meta: { title: "Category info" },
      },
      // sliders
      {
        path: "/sliders",
        name: "sliders.index",
        component: SlidersIndex,
        meta: { title: "Sliders list" },
      },
      {
        path: "/sliders/create",
        name: "sliders.create",
        component: SlidersCreate,
        meta: { title: "Add new slider" },
      },
      {
        path: "/sliders/edit/:id",
        name: "sliders.edit",
        component: SlidersEdit,
        meta: { title: "Edit slider" },
      },
      {
        path: "/sliders/show/:id",
        name: "sliders.show",
        component: SlidersShow,
        meta: { title: "slider info" },
      },
      {
        path: "/alternative",
        name: "Alternative",
        components: { default: Alternative },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "/pages/user",
        name: "Profile",
        components: { default: Profile },
      },
      {
        path: "/pages/timeline",
        name: "Timeline",
        components: { default: Timeline },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: 'smooth' }), 200)
      });
    }
  }
});

export default router;
