<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5 mb-5">
      <p class="mt-5 mb-3 header-1-p">Terms and Conditions (User & Service Agreement)</p>
      <p class="mt-5 mb-3 header-2-p">Our services</p>
      <p class="text-3-s">
        PXL provides a range of services which enable you to publish, sell, and purchase art; and receive the benefits of product fulfilment, including payment processing, customer services, and product manufacturing. In addition, PXL will arrange for the delivery of the physical product to your customers.
      </p>

      <p class="mt-5 mb-3 header-2-p">Login</p>
      <p class="text-3-s">
        To become an active member, you will be required to select a username and password when registering. In its sole discretion, PXL may refuse any username that it decides is inappropriate and / or refuse any person from becoming a member.
      </p>

      <p class="mt-5 mb-3 header-2-p">Passwords</p>
      <p class="text-3-s">
        You are responsible for actions made on the website using your password, including any products purchased or sold and any content displayed, even if these actions were not approved or contemplated by you. You are solely responsible for any loss caused by any use of your password by you, or any other person.
You agree that you will not disclose your password to any other person, and you will not keep your password where it can be copied or used by anyone other than you. If you suspect someone else knows your password, you must change it immediately.
      </p>

      <p class="mt-5 mb-3 header-2-p">Purchasing a Product on PXL</p>
      <p class="text-3-s">
        <ul>
          <li class="text-3-s">
            Users can purchase products on the PXL website using a valid payment method.
          </li>
          <li class="text-3-s">
            You do not have to be a member to purchase a product.
          </li>
          <li class="text-3-s">
            The price you pay is fixed at the time of ordering.
          </li>
          <li class="text-3-s">
            You may not cancel an order once the Item is processing.
          </li>
        </ul>
        It is the customer’s responsibility to ensure the product delivery address is correct. PXL takes no responsibility for any product a customer does not receive because of errors in the delivery address given to us.
      </p>

      <p class="mt-5 mb-3 header-2-p">Limitation of liability</p>
      <p class="text-3-s">
        In no case will PXL be liable for any consequential loss or damage suffered by you arising from this Services Agreement. To the extent permitted by law, all warranties and conditions implied by law are hereby expressly excluded.
      </p>

      <p class="mt-5 mb-3 header-2-p">Amendment to this agreement</p>
      <p class="text-3-s">
        We may amend the terms of this agreement from time to time, and we will let you know about these changes either by sending you an email to the email address you have registered with PXL or by displaying information about the changes on our home page, or both. Either way, your continuing use of the website will be taken to be acceptance of the new terms.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
