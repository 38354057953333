import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

import {useAuth} from "./auth";

export default function useGlobalSettings() {
  const siteSettings = ref({});
  const globalSettings = ref({});
  const globalSetting = ref({});
  const globalSettingMeta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const { getSettingsList } = useAuth();

  const SettingTypes = ref([
    {
        'id' : 1,
        'name_en' : 'text',
        'name_ar' : 'text',
    },
    {
        'id' : 2,
        'name_en' : 'textarea',
        'name_ar' : 'textarea',
    },
    {
        'id' : 3,
        'name_en' : 'texteditor',
        'name_ar' : 'texteditor',
    },
    {
        'id' : 4,
        'name_en' : 'number',
        'name_ar' : 'number',
    },
    {
        'id' : 5,
        'name_en' : 'percentage',
        'name_ar' : 'percentage',
    },
    {
        'id' : 6,
        'name_en' : 'currency',
        'name_ar' : 'currency',
    },
    {
        'id' : 7,
        'name_en' : 'image',
        'name_ar' : 'image',
    },
    {
        'id' : 8,
        'name_en' : 'gallery',
        'name_ar' : 'gallery',
    },
  ]);

  const router = useRouter();
  const swal = inject("$swal");

  const getGlobalSettings = async (
    page = 1,
    search_id = "",
    search_s_key = "",
    search_s_label = "",
    search_s_type = "",
    search_s_value = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/global-settings?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_s_key=" +
          search_s_key +
          "&search_s_label=" +
          search_s_label +
          "&search_s_type=" +
          search_s_type +
          "&search_s_value=" +
          search_s_value +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        globalSettings.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getGlobalSetting = async (id) => {
    axiosClient.get("/global-settings/" + id).then((response) => {
      globalSetting.value = response.data.data;
      globalSettingMeta.value = response.data.meta;
    });
  };

  const storeGlobalSetting = async (globalSetting) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedGlobalSetting = new FormData();
    for (let item in globalSetting) {
      if (globalSetting.hasOwnProperty(item)) {
        serializedGlobalSetting.append(item, globalSetting[item]);
      }
    }

    axiosClient
      .post("/global-settings", serializedGlobalSetting)
      .then((response) => {
        router.push({ name: "globalSettings.index" });
        swal({
          icon: "success",
          title: "GlobalSetting saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateGlobalSetting = async (globalSetting) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/global-settings/" + globalSetting.id, globalSetting)
      .then((response) => {
        router.push({ name: "globalSettings.index" });
        swal({
          icon: "success",
          title: "GlobalSetting saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteGlobalSetting = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/global-settings/" + id)
          .then((response) => {
            getGlobalSettings();
            router.push({ name: "globalSettings.index" });
            swal({
              icon: "success",
              title: "GlobalSetting deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  const getGlobalSettingMeta = async () => {
    await axiosClient.get("/global-settings/meta").then((response) => {
      globalSettingMeta.value = response.data.meta;
    });
  };

  const getSiteSettings = async () => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    await axiosClient
      .get("/global-settings-list")
      .then((response) => {
        response?.data?.data?.forEach((siteSetting) => {
          if (siteSetting.s_type == 4) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 5) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 6) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 8) {
            siteSetting.s_value = JSON.parse(siteSetting.s_value ?? "[]");
          }
        });
        siteSettings.value = response.data.data;
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          validationErrors.value = error.response.data.errors;

          swal({
            toast: false,
            position: "center",
            showConfirmButton: false,
            showCancelButton: true,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal.stopTimer);
              toast.addEventListener("mouseleave", swal.resumeTimer);
            },
            icon: "error",
            title: "Validation error, please try again.",
          });
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateSiteSettings = async (settingData) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    var data = settingData;

    data.forEach((siteSetting) => {
      if (siteSetting.s_type == 4) {
        siteSetting.s_value = String(siteSetting.s_value ?? 0);
      }
      if (siteSetting.s_type == 5) {
        siteSetting.s_value = String(siteSetting.s_value ?? 0);
      }
      if (siteSetting.s_type == 6) {
        siteSetting.s_value = String(siteSetting.s_value ?? 0);
      }
      if (siteSetting.s_type == 8) {
        siteSetting.s_value = JSON.stringify(siteSetting.s_value ?? []);
      }
    });

    var serializeData = {
      settings: data,
    };

    let params = serialize(serializeData, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/site-settings/update", params)
      .then((response) => {
        swal({
          toast: false,
          position: "center",
          showConfirmButton: false,
          showCancelButton: true,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", swal.stopTimer);
            toast.addEventListener("mouseleave", swal.resumeTimer);
          },
          icon: "success",
          title: "Setting saved successfully",
        });
        setTimeout(() => {
          getSiteSettings();
          getSettingsList();
        }, 1000);
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  return {
    globalSettings,
    globalSetting,
    getGlobalSettings,
    getGlobalSetting,
    storeGlobalSetting,
    validationErrors,
    isLoading,
    updateGlobalSetting,
    deleteGlobalSetting,
    SettingTypes,
    globalSettingMeta,
    getGlobalSettingMeta,
    getSiteSettings,
    updateSiteSettings,
    siteSettings,
  };
}
