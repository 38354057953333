import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { serialize } from "object-to-formdata";

export default function useNotifications() {
  const notifications = ref({});
  const notification = ref({});
  const notificationMeta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getNotifications = async (
    page = 1,
    search_id = "",
    search_title_en = "",
    search_title_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/notifications?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_title_en=" +
          search_title_en +
          "&search_title_ar=" +
          search_title_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        notifications.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getNotification = async (id) => {
    await axiosClient.get("/notifications/" + id).then((response) => {
      notification.value = response.data.data;
      notification.value.usersData = response.data.data.users;
      notification.value.users = response.data.users;
      notificationMeta.value = response.data.meta;
    });
  };

  const getNotificationMeta = async () => {
    await axiosClient.get("/notifications/meta").then((response) => {
      notificationMeta.value = response.data.meta;
    });
  };

  const storeNotification = async (notification) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(notification, {
      indices: true,
      booleansAsIntegers: true,
    });

    axiosClient
      .post("/notifications", params)
      .then((response) => {
        router.push({ name: "notifications.index" });
        swal({
          icon: "success",
          title: "Notification saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateNotification = async (notification) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/notifications/" + notification.id, notification)
      .then((response) => {
        router.push({ name: "notifications.index" });
        swal({
          icon: "success",
          title: "Notification saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteNotification = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/notifications/" + id)
          .then((response) => {
            getNotifications();
            router.push({ name: "notifications.index" });
            swal({
              icon: "success",
              title: "Notification deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    notifications,
    notification,
    getNotifications,
    notificationMeta,
    getNotificationMeta,
    getNotification,
    storeNotification,
    validationErrors,
    isLoading,
    updateNotification,
    deleteNotification,
  };
}
