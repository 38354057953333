<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_name" class="form-control" placeholder="Search name" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_code" class="form-control" placeholder="Search code" type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page" :options="per_page_options"
                    optionLabel="text" optionValue="value" />
                </div>
                <div>
                  <Button v-if="can('coupon_create')" label="New" @click.prevent="
                    router.push({
                      name: 'coupons.create',
                    })
                  " class="p-button-success" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
                              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('name')">
                          <div :class="{
                            '': orderColumn === 'name',
                          }">
                            Name
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'name' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'name' },
                              { 'pi pi-sort-alt': orderColumn != 'name' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('code')">
                          <div :class="{
                            '': orderColumn === 'code',
                          }">
                            Code
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'code' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'code' },
                              { 'pi pi-sort-alt': orderColumn != 'code' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('type')">
                          <div :class="{
                            '': orderColumn === 'type',
                          }">
                            Type
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'type' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'type' },
                              { 'pi pi-sort-alt': orderColumn != 'type' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('value')">
                          <div :class="{
                            '': orderColumn === 'value',
                          }">
                            Value
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'value' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'value' },
                              { 'pi pi-sort-alt': orderColumn != 'value' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('expires_at')">
                          <div :class="{
                            '': orderColumn === 'expires_at',
                          }">
                            Expires At
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'expires_at' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'expires_at' },
                              { 'pi pi-sort-alt': orderColumn != 'expires_at' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('is_active')">
                          <div :class="{
                            '': orderColumn === 'is_active',
                          }">
                            Is Active
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'is_active' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'is_active' },
                              { 'pi pi-sort-alt': orderColumn != 'is_active' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="coupon in coupons.data" :key="coupon.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.id }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.name }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.code }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.type }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.value }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.expires_at }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ coupon.is_active ? 'Active' : 'Not Active' }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button class="p-button-info" icon="fas fa-eye" v-if="can('coupon_show')" @click.prevent="
                          router.push({
                            name: 'coupons.show',
                            params: { id: coupon.id },
                          })
                        " />
                        <Button class="p-button-warning" icon="fas fa-pen-fancy" v-if="can('coupon_edit')" @click.prevent="
                          router.push({
                            name: 'coupons.edit',
                            params: { id: coupon.id },
                          })
                        " />
                        <Button class="p-button-danger" icon="fas fa-trash" v-if="can('coupon_delete')"
                          @click.prevent="deleteCoupon(coupon.id)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ coupons?.meta?.from }} to
                    {{ coupons?.meta?.to }} of
                    {{ coupons?.meta?.total }}
                  </p>
                </div>
                <div>
                  <pagination :data="coupons" :limit="1" @pagination-change-page="
                    (page) =>
                      getCoupons(
                        page,
                        search_id,
                        search_name,
                        search_code,
                        search_type,
                        search_value,
                        search_maximum_usage,
                        search_is_active,
                        search_expires_at,
                        search_global,
                        orderColumn,
                        orderDirection,
                        per_page
                      )
                  " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import {useCoupons} from "@/composables/Coupons";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";


export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = 1;
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name = ref(null)
    const search_code = ref(null)
    const search_type = ref(null)
    const search_value = ref(null)
    const search_maximum_usage = ref(null)
    const search_is_active = ref(null)
    const search_expires_at = ref(null)
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");

    const router = useRouter();
    const {
      coupons,
      getCoupons,
      deleteCoupon,
    } = useCoupons();
    const { can } = useAbility();
    onMounted(async () => {
      await getCoupons();
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(search_id, (current) => {
      getCoupons(
        1,
        current,
        search_name.value,
        search_code.value,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name, (current) => {
      getCoupons(
        1,
        search_id.value,
        current,
        search_code.value,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_code, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        current,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_type, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        current,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_value, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        search_type.value,
        current,
        search_maximum_usage.value,
        search_is_active.value,
        search_expires_at.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_maximum_usage, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        search_type.value,
        search_value.value,
        current,
        search_is_active.value,
        search_expires_at.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_is_active, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        current,
        search_expires_at.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_expires_at, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_code.value,
        search_type.value,
        search_value.value,
        search_maximum_usage.value,
        search_is_active.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getCoupons(
        1,
        search_id.value,
        search_name.value,
        search_title_ar.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });

    return {
      router,
      coupons,
      getCoupons,
      deleteCoupon,
      search_id,
      search_name,
      search_code,
      search_type,
      search_value,
      search_maximum_usage,
      search_is_active,
      search_expires_at,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
