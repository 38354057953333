<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Desktop Image</label>
                    <div v-if="slider?.desktop_image && slider?.desktop_image.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in slider?.desktop_image" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'" alt="upload"
                          class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Mobile Image</label>
                    <div v-if="slider?.mobile_image && slider?.mobile_image.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in slider?.mobile_image" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'" alt="upload"
                          class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>
                  <!-- Tablet image -->
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Tablet Image</label>
                    <div v-if="slider?.tablet_image && slider?.tablet_image.length > 0"
                      class="d-flex justify-content-start align-items-center flex-wrap">
                      <div v-for="(image, index) in slider?.tablet_image" :key="index" class="p-2 position-relative"
                        style="height:150px; width: 150px;">
                        <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'" alt="upload"
                          class="w-100 h-100" imageClass="image-cover" preview />
                      </div>
                    </div>
                  </div>

                  <!-- End of tablet image -->
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Title</label>
                    <input v-model="slider.title_en" class="form-control" id="title_en" type="text" name="title_en"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Location</label>
                    <input v-model="slider.location" class="form-control" id="location" type="text" name="location"
                      readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">link</label>
                    <input v-model="slider.link" class="form-control" id="link" type="text" name="link" readonly />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Order</label>
                    <input v-model="slider.order" class="form-control" id="order" type="number" name="order" readonly />
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button label="Cancel" @click.prevent="
            router.push({
              name: 'sliders.index',
            })
            " class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useSliders from "@/composables/Sliders";



export default {
  components: {
  },
  setup() {
    const { slider, getSlider, validationErrors, isLoading } = useSliders();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getSlider(route.params.id);
    });

    return {
      router,
      slider,
      validationErrors,
      isLoading,
      can,
    };
  },
};
</script>

<style></style>
