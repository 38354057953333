<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="updatePaymentMethod(paymentMethod)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-control-label">Name</label>
                      <input v-model="paymentMethod.name_en" class="form-control" id="name_en" type="text"
                        name="name_en" />
                    </div>
                    <!-- <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name Ar</label>
                      <input
                        v-model="paymentMethod.name_ar"
                        class="form-control"
                        id="name_ar"
                        type="text"
                        name="name_ar"
                      />
                    </div> -->
                    <div class="col-md-12 mt-4">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="is_active"
                          v-model="paymentMethod.is_active" />
                        <label class="form-control-label form-check-label" for="is_active">Active</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-footer d-flex justify-content-between"></div> -->
              </div>
              <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <div>Settings</div>
                  <div>
                    <Button @click.prevent="addSetting(paymentMethod)" label="Add" class="p-button-secondary" />
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-flush table-hover">
                    <thead class="thead-light">
                      <th>Key</th>
                      <th>Lable</th>
                      <th>Value</th>
                      <th>Actions</th>
                    </thead>
                    <tbody v-if="paymentMethod && paymentMethod.settings">
                      <tr v-for="(setting, index) in paymentMethod.settings" :key="setting.index">
                        <td>
                          <input v-model="setting.setting_key" class="form-control" type="text" />
                        </td>
                        <td>
                          <input v-model="setting.setting_lable" class="form-control" type="text" />
                        </td>
                        <td>
                          <input v-model="setting.setting_value" class="form-control" type="text" />
                        </td>
                        <td>
                          <Button @click.prevent="deleteSetting(paymentMethod.settings, index)" icon="far fa-trash-alt"
                            class="p-button-danger" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button @click.prevent="
              router.push({
                name: 'payment-methods.index',
              })
            " label="Cancel" class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import usePaymentMethods from "@/composables/PaymentMethods";



export default {
  components: {
  },
  setup() {
    const {
      paymentMethod,
      getPaymentMethod,
      updatePaymentMethod,
      addSetting,
      deleteSetting,
      validationErrors,
      isLoading,
    } = usePaymentMethods();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getPaymentMethod(route.params.id);
    });

    return {
      router,
      paymentMethod,
      addSetting,
      deleteSetting,
      validationErrors,
      isLoading,
      updatePaymentMethod,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>

<style>

</style>
