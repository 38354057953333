<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_reference" class="form-control" placeholder="Search reference" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_vendor" class="form-control" placeholder="Search artist name" type="text" />
                  </div>
                  <div class="col-md-3">
                    <Dropdown v-model="search_status" class="w-100 h-100 fs-6" :options="statusOptions"
                      optionLabel="text" optionValue="value" placeholder="Select Status" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page"
                    :options="per_page_options" optionLabel="text" optionValue="value" />
                </div>
                <!-- <Button v-if="can('order_create')" label="New" @click.prevent="
                    router.push({
                      name: 'orders.create',
                    })
                  " class="p-button-success" /> -->
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
                            '': orderColumn === 'id',
                          }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
                              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('date')">
                          <div :class="{
                            '': orderColumn === 'date',
                          }">
                            Date
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'date' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'date' },
                              { 'pi pi-sort-alt': orderColumn != 'date' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('reference')">
                          <div :class="{
                            '': orderColumn === 'reference',
                          }">
                            Reference
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'reference' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'reference' },
                              { 'pi pi-sort-alt': orderColumn != 'reference' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between">
                          <div>
                            Artist
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between">
                          <div>
                            IBAN
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('commission')">
                          <div :class="{
                            '': orderColumn === 'commission',
                          }">
                            Amount
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'commission' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'commission' },
                              { 'pi pi-sort-alt': orderColumn != 'commission' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <!-- TODO: only put order status if id == 2 or == 5 -->
                        <div class="d-flex justify-content-between" @click="updateOrdering('status_id')">
                          <div :class="{
                            '': orderColumn === 'status_id',
                          }">
                            Order Status
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'status_id' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'status_id' },
                              { 'pi pi-sort-alt': orderColumn != 'status_id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('is_paid')">
                          <div :class="{
                            '': orderColumn === 'is_paid',
                          }">
                            Piad
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
                              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'is_paid' },
                              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'is_paid' },
                              { 'pi pi-sort-alt': orderColumn != 'is_paid' }]"></i>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="orders.data">
                    <tr v-for="order in orders.data" :key="order.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.id }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.date }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.reference }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="order.vendor">{{
                          order.vendor?.name_en
                        }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="order.vendor">{{
                          order.vendor?.iban
                        }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ order.commission }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="order.status" class="badge badge-primary">{{
                          order.status.name_en
                        }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <div class="d-flex justify-content-start align-items-center">
                          <Checkbox v-model="order.is_paid" :disabled="payArtistLoading[order.id]"
                            @click.prevent="payArtist({ id: order.id, is_paid: !order.is_paid })" :binary="true" />
                          <label v-if="order.is_paid" class="m-0 pl-1">Recived</label>
                          <label v-else class="m-0 pl-1">Pending</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-sm font-weight-normal">
                        Total for this page
                      </td>
                      <td colspan="3" class="text-sm font-weight-normal">
                        <span>
                          {{ Number(totalCommission).toFixed(3) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ orders?.meta?.from }} to
                    {{ orders?.meta?.to }} of
                    {{ orders?.meta?.total }}
                  </p>
                </div>
                <div>
                  <!-- <pagination :data="orders" :limit="1" @pagination-change-page="(page) =>
                    getOrders(
                      page,
                      search_id,
                      search_date,
                      search_reference,
                      search_name_en,
                      search_name_ar,
                      search_vendor,
                      search_status,
                      search_parent,
                      search_children,
                      search_noguest,
                      search_global,
                      orderColumn,
                      orderDirection,
                      per_page
                    )
                    " /> -->
                  <pagination :data="orders" :limit="1" @pagination-change-page="(page) => { }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import useOrders from "@/composables/Orders";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";


export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = ref(1);
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_date = ref("");
    const search_reference = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_vendor = ref("");
    const search_status = ref(5);
    const search_parent = ref(0);
    const search_children = ref(1);
    const search_noguest = ref(1);
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");
    const route = useRoute();
    const statusOptions = ref([
      { text: "ALL", value: "" },
      { text: "Completed", value: 5 },
      { text: "Pending Payment", value: 1 },
      { text: "Paid", value: 2 },
      { text: "Cancelled", value: 6 },
      { text: "Delivering", value: 4 },
      { text: "Printing", value: 3 },
      { text: "Failed", value: 9 },
      { text: "On Hold", value: 7 },
      { text: "Refunded", value: 8 },
    ]);
    const totalCommission = ref(0);

    const payArtistLoading = ref({});

    const router = useRouter();
    const { orders, getOrders, updatePaid } = useOrders();
    const { can } = useAbility();
    onMounted(async () => {
      const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
      page.value = pageNumber;
      await getOrders(
        page.value,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const payArtist = async (order) => {
      if (payArtistLoading.value[order.id] === true) return

      try {
        payArtistLoading.value[order.id] = true
        // Wait for updatePaid to complete
        await updatePaid(order)

        // Only after updatePaid is complete, call getOrders
        await getOrders(
          1,
          search_id.value,
          search_date.value,
          search_reference.value,
          search_name_en.value,
          search_name_ar.value,
          search_vendor.value,
          search_status.value,
          search_parent.value,
          search_children.value,
          search_noguest.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value
        )
      } catch (error) {
        console.error('Error updating payment status:', error)
      } finally {
        payArtistLoading.value[order.id] = false
      }
    }



    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(orders, (current) => {
      if (orders.value?.data) {
        totalCommission.value = 0
        orders.value?.data.forEach(order => {
          totalCommission.value += Number(order.commission)
        });
      }
    });
    watch(search_id, (current) => {
      getOrders(
        1,
        current,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_date, (current) => {
      getOrders(
        1,
        search_id.value,
        current,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_reference, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_en, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        current,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_ar, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        current,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_vendor, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_status, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        current,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_global, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getOrders(
        1,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });
    watch(() => route.query.page, (current) => {
      const pageNumber = current ? parseInt(current, 10) : 1;
      page.value = pageNumber;
    });

    watch(() => page.value, (current) => {
      getOrders(
        current,
        search_id.value,
        search_date.value,
        search_reference.value,
        search_name_en.value,
        search_name_ar.value,
        search_vendor.value,
        search_status.value,
        search_parent.value,
        search_children.value,
        search_noguest.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    })

    return {
      route,
      router,
      orders,
      getOrders,
      payArtist,
      search_id,
      search_date,
      search_reference,
      search_name_en,
      search_name_ar,
      search_vendor,
      search_status,
      search_parent,
      search_children,
      search_noguest,
      search_global,
      orderColumn,
      orderDirection,
      statusOptions,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
      totalCommission,
      payArtistLoading,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}

.custom-dropdown .p-dropdown-label {
  font-size: 0.8rem;
  /* Adjust font size */
  color: #a0c5e8;
  /* Set color */
  width: 5px;
  /* Set width */
}

.custom-dropdown .p-dropdown-item {
  font-size: 0.8rem;
  /* Adjust font size for options */
  color: #a0c5e8;
  /* Set color for options */
}
</style>
