<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5">

    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
