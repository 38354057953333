import { ref, defineComponent } from "vue";
import imageCompression from "browser-image-compression";
// import { useWindowSize, useWindowScroll } from "@vueuse/core";
// import { useHead } from "@vueuse/head";
// import moment from "moment";

export function dateFormat(date, format) {
  var formatedDate = "";
  // if(format == 'utc') {
  //   formatedDate = moment(date).format("DD-MM-YYYY");
  // } else {
  //   formatedDate = moment(date).format(format);
  // }

  if (!date) return "";
  if (format == "utc") {
    if (
      date.includes("-") &&
      date.includes(":") &&
      (date.includes("am") || date.includes("pm"))
    ) {
      var dateTimeArray = date.split(" ");
      var dateArray = dateTimeArray[0].split("-");
      var timeArray = dateTimeArray[1].split(":");
      if (dateTimeArray[2] == "pm" && Number(timeArray[0]) < 12) {
        timeArray[0] = String(Number(timeArray[0]) + 12);
      }
      formatedDate = new Date(
        new Date(
          dateArray[0],
          dateArray[1] - 1,
          dateArray[2],
          timeArray[0],
          timeArray[1],
          timeArray[2]
        ).toUTCString()
      );
    } else if (date.includes("-") && date.includes(":")) {
      var dateTimeArray = date.split(" ");
      var dateArray = dateTimeArray[0].split("-");
      var timeArray = dateTimeArray[1].split(":");
      formatedDate = new Date(
        new Date(
          dateArray[0],
          dateArray[1] - 1,
          dateArray[2],
          timeArray[0],
          timeArray[1],
          timeArray[2]
        ).toUTCString()
      );
    } else if (date.includes("-")) {
      date = String(date);
      var dateArray = date.split("-");
      if (dateArray) {
        formatedDate = new Date(
          Date.UTC(dateArray[2], dateArray[1], dateArray[0])
        );
      }
    } else if (
      date.includes(":") &&
      (date.includes("am") || date.includes("pm"))
    ) {
      var newDate = new Date();
      var year = newDate.getFullYear();
      var month = newDate.getMonth();
      var day = newDate.getDate();
      var timeAmPmArray = date.split(" ");
      var timeArray = timeAmPmArray[0].split(":");
      if (timeAmPmArray[1] == "pm" && Number(timeArray[0]) < 12) {
        timeArray[0] = String(Number(timeArray[0]) + 12);
      }
      formatedDate = new Date(
        new Date(
          year,
          month,
          day,
          timeArray[0],
          timeArray[1],
          timeArray[2]
        ).toUTCString()
      );
    } else if (date.includes(":")) {
      var newDate = new Date();
      var year = newDate.getFullYear();
      var month = newDate.getMonth();
      var day = newDate.getDate();
      var timeArray = date.split(":");
      formatedDate = new Date(
        new Date(
          year,
          month,
          day,
          timeArray[0],
          timeArray[1],
          timeArray[2]
        ).toUTCString()
      );
    }
  } else {
    date = new Date(date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Months start at 0!
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? "pm" : "am";
    var hours24 = hours;
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours;
    hours24 = hours24 < 10 ? "0" + hours24 : hours24;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    formatedDate = format
      .replace("yy", year)
      .replace("mm", month)
      .replace("dd", day)
      .replace("H", hours24)
      .replace("h", hours)
      .replace("m", minutes)
      .replace("s", seconds)
      .replace("a", ampm);
  }
  return formatedDate;
}

export const getArrayOfField = (inputArray, field) => {
  var outputArray = [];
  if (!inputArray.length) return outputArray;
  for (var i = 0; i < inputArray.length; ++i)
    outputArray.push(inputArray[i][field]);
  return outputArray;
};

export const removeFromArray = (inputArray, itemIndex) => {
  if (!inputArray.length) return;
  inputArray.splice(itemIndex, 1);
};

export const BlobToFile = async (inputData) => {
  return new File([inputData], inputData.name, {
    type: inputData.type,
  });
};

export const compressFiles = async (inputData) => {
  var outputData = [];
  for (var i = 0; i < inputData.length; i++) {
    await imageCompression(inputData[i], {
      maxSizeMB: 4,
    }).then(async (compressedBlob) => {
      var compressedFile = await BlobToFile(compressedBlob);
      outputData.push(compressedFile);
    });
  }
  return outputData;
};

// export const screenBreakPoint = (inputData) => {
//   const { width, height } = useWindowSize();
//   const outputData = ref(false);
//   if (width.value <= inputData) {
//     outputData.value = true;
//   } else {
//     outputData.value = false;
//   }
//   return outputData;
// };

// export const scrollToTop = () => {
//   const { x, y } = useWindowScroll();
//   x.value = 0;
//   y.value = 0;
// };

// export const setMeta = (title = '', pageTitle = '', pageDesc = '', pageImage = '', pagePath = '', pageKeywords = '') => {
//   let language = 'English';
//   let locale = 'en_US';

//   useHead({
//     title: title,
//     meta: [
//       { name: 'description', content: pageDesc },
//       { name: 'keywords', content: pageKeywords },
//       { name: 'language', content: language },
//       { name: 'twitter:card', content: 'summary_large_image' },
//       { name: 'twitter:description', content: pageDesc },
//       { name: 'twitter:title', content: pageTitle },
//       { name: 'twitter:image', content: pageImage },
//       { name: 'og:site_name', content: title },
//       { name: 'og:locale', content: locale },
//       { name: 'og:title', content: pageTitle },
//       { name: 'og:description', content: pageDesc },
//       { name: 'og:url', content: pagePath },
//       { name: 'og:image', content: pageImage },
//       { name: 'og:image:width', content: '1240' },
//       { name: 'og:image:height', content: '900' },
//       { name: 'og:image:type', content: 'image/jpeg' },
//       { name: 'og:type', content: 'product' },
//       { name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' },
//       { name: 'revisit-after', content: '10 days' },
//     ],
//   })
// };

// export const setMeta = (
//   title = "",
//   metaData = {},
//   pagePath = "",
//   pageKeywords = ""
// ) => {
//   // defineComponent({
//   // setup() {
//   let language = "English";
//   let locale = "en_US";

//   let imageUrl =
//     metaData.image.length > 0
//       ? metaData.image[0].thumbnail
//       : "/imgs/placeholder1x1.png";

//   useHead({
//     title: title,
//     meta: [
//       { name: "description", content: metaData.description_en },
//       { name: "keywords", content: pageKeywords },
//       { name: "language", content: language },
//       { name: "twitter:card", content: "summary_large_image" },
//       { name: "twitter:description", content: metaData.description_en },
//       { name: "twitter:title", content: metaData.name_en },
//       { name: "twitter:image", content: imageUrl },
//       { name: "og:site_name", content: title },
//       { name: "og:locale", content: locale },
//       { name: "og:title", content: metaData.name_en },
//       { name: "og:description", content: metaData.description_en },
//       { name: "og:url", content: pagePath },
//       { name: "og:image", content: imageUrl },
//       { name: "og:image:width", content: "1240" },
//       { name: "og:image:height", content: "900" },
//       { name: "og:image:type", content: "image/jpeg" },
//       { name: "og:type", content: "product" },
//       {
//         name: "robots",
//         content:
//           "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
//       },
//       { name: "revisit-after", content: "10 days" },
//     ],
//   });
//   // },
//   // });
// };
