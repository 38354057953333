import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useProductTypes() {
  const productTypes = ref({});
  const productType = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getProductTypes = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/product-types?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        productTypes.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getProductType = async (id) => {
    axiosClient.get("/product-types/" + id).then((response) => {
      productType.value = response.data.data;
    });
  };

  const storeProductType = async (productType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedProductType = new FormData();
    for (let item in productType) {
      if (productType.hasOwnProperty(item)) {
        serializedProductType.append(item, productType[item]);
      }
    }

    axiosClient
      .post("/product-types", serializedProductType)
      .then((response) => {
        router.push({ name: "product-types.index" });
        swal({
          icon: "success",
          title: "product type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateProductType = async (productType) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/product-types/" + productType.id, productType)
      .then((response) => {
        router.push({ name: "product-types.index" });
        swal({
          icon: "success",
          title: "Product type saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteProductType = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/product-types/" + id)
          .then((response) => {
            getProductTypes();
            router.push({ name: "product-types.index" });
            swal({
              icon: "success",
              title: "Product type deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    productTypes,
    productType,
    getProductTypes,
    getProductType,
    storeProductType,
    validationErrors,
    isLoading,
    updateProductType,
    deleteProductType,
  };
}
