import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();

export const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,

  withCredentials: true,
});

axiosClient.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${localStorage.getItem("TOKEN")}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error?.response?.status == 401) {
      localStorage.removeItem('isLoggedIn')
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('ROLES')
      location.reload()
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
