<template>
  <div>
    <Sidebar v-model:visible="isOpen" :baseZIndex="10000" :showCloseIcon="false">
      <div class="w-100">
        <Button v-if="isLoggedIn == true" style="width: 100%; height: 46px; color: var(--default)"
          @click.prevent="addArtwork(), isOpen=!isOpen">
          <div class="d-flex justify-content-between w-100 px-2">
            <span class="btn-inner--text">
              <small style="font-size: 70%; font-weight: 700;">YOUR ART</small>
            </span>
            <span class="btn-inner--icon">
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24">
                <path id="Path_270" data-name="Path 270" d="M5.636,18.364a9,9,0,1,1,12.728,0" fill="none" stroke="#fcf3e2"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <line id="Line_19" data-name="Line 19" y1="9" transform="translate(12 11)" fill="none" stroke="#fcf3e2"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_271" data-name="Path 271" d="M15,14l-3-3L9,14" fill="none" stroke="#fcf3e2"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <rect id="Rectangle_81" data-name="Rectangle 81" width="24" height="24" fill="none" />
              </svg>
            </span>
          </div>
        </Button>

        <Button class="p-button-outlined mt-3" style="width: 100%; height: 46px; text-align: left;"
          @click.prevent="navTo('PrintYourArt', '')">
          <div class="d-flex justify-content-between w-100 px-2">
            <span class="btn-inner--text d-flex align-items-center">
              <small style="font-size: 65%; font-weight: 700;" class="text-uppercase">Custom Print</small>
            </span>
            <span class="btn-inner--icon d-flex align-items-center">
              <i class="pi pi-print" style="font-size:21x;"></i>
            </span>
          </div>
        </Button>
        <!-- <base-button icon type="outline" outline=true
          style="width: 100%; height: 46px; color: var(--default)" @click.prevent="
            router.push({
              name: 'PrintYourArt',
            })
          ">
          <div class="d-flex justify-content-between w-100 px-2">
            <span class="btn-inner--text">
              <small>Custom Print</small>
            </span>
            <span class="btn-inner--icon">
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24">
                <path id="Path_270" data-name="Path 270" d="M5.636,18.364a9,9,0,1,1,12.728,0" fill="none"
                  stroke="#fcf3e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <line id="Line_19" data-name="Line 19" y1="9" transform="translate(12 11)" fill="none" stroke="#fcf3e2"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_271" data-name="Path 271" d="M15,14l-3-3L9,14" fill="none" stroke="#fcf3e2"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <rect id="Rectangle_81" data-name="Rectangle 81" width="24" height="24" fill="none" />
              </svg>
            </span>
          </div>
        </base-button>-->
      </div>
      <!-- <div class="w-100 my-4">
        <p class="header-3-p">My store</p>
        <div class="d-flex flex-column pl-2">
          <a id="artworks" @click.prevent="navTo('Account', 'artwork')">
            <span class="text-m-3-s">Artworks</span>
          </a>
          <a id="sales" @click.prevent="navTo('Account', 'sales')">
            <span class="text-m-3-s">Sales</span>
          </a>
        </div>
      </div> -->
      <div class="w-100 my-4">
        <p class="header-3-p">Account</p>
        <div class="d-flex flex-column pl-2">
          <a id="orderhistory" @click.prevent="navTo('Account', 'order-history')">
            <span class="text-m-3-s">Order history</span>
          </a>
          <a id="favorites" @click.prevent="navTo('Account', 'favorites')">
            <span class="text-m-3-s">Favorites</span>
          </a>
          <a id="settings" @click.prevent="navTo('Account', 'settings')">
            <span class="text-m-3-s">Settings</span>
          </a>
        </div>
      </div>
      <p class="border"></p>
      <div class="w-100 my-4">
        <div class="d-flex flex-column pl-2">
          <a id="themes" @click.prevent="navTo('Themes', '')">
            <span class="text-m-3-s">Themes</span>
          </a>
          <a id="artists" @click.prevent="navTo('Artists', '')">
            <span class="text-m-3-s">Artists</span>
          </a>
          <a id="aboutus" @click.prevent="navTo('About', '')">
            <span class="text-m-3-s">About us</span>
          </a>
          <a id="howitworks" @click.prevent="navTo('HowItWorks', '')">
            <span class="text-m-3-s">How it works?</span>
          </a>
        </div>
      </div>
          <CurrencySwitcher></CurrencySwitcher>
    </Sidebar>
    <base-nav v-model="showMenu" type="default" :transparent="route.name == 'Home' ? true : false"
      menu-classes="justify-content-between" class="navbar-horizontal navbar-main" expand="lg">
      <div class="row w-100">
        <div class="col-md-9 d-flex justify-content-start align-items-center p-0">
          <div class="navbar-wrapper">
            <router-link class="navbar-brand" to="/">
              <img style="height: 38.04px; width: 105px" src="img/brand/default-logo.png" />
            </router-link>
          </div>
          <form class="navbar-search form-inline mr-sm-3" :class="{
      'navbar-search-light': type === 'default',
      'navbar-search-dark': type === 'light',
    }" id="navbar-search-main" style="width: 65%" @submit.prevent="
      router.push({
        name: 'Shop',
        params: { search_value: search_g_value },
      })
      ">
            <div class="form-group mb-0 w-100">
              <div class="
                  input-group input-group-alternative input-group-merge
                  w-100
                  rounded-0
                ">
                <input v-model="search_g_value" class="form-control" placeholder="Pokémon, world cup, national day ..."
                  type="text" />
                <div class="input-group-prepend">
                  <span class="input-group-text text-primary m-pointer" @click.prevent="
      router.push({
        name: 'Shop',
        params: { search_value: search_g_value },
      })
      ">
                    <svg id="Search" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Path_6" data-name="Path 6" d="M0,0H24V24H0Z" fill="none" />
                      <path id="Path_7" data-name="Path 7"
                        d="M14.193,5.582a5.971,5.971,0,1,1-8.444,0,5.971,5.971,0,0,1,8.444,0" transform="translate(0 0)"
                        fill="none" stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                      <path id="Path_8" data-name="Path 8" d="M14.15,14.06,20,19.99" transform="translate(0 0)"
                        fill="none" stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main"
              aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </form>
          <Button v-if="isLoggedIn == true"
            style="width:150px; height: 46px; padding:8px; margin-right:10px; color: var(--default)"
            @click.prevent="addArtwork()">
            <div class="d-flex justify-content-between w-100 px-2">
              <span class="btn-inner--text">
                <small style="font-size: 70%; font-weight: 700;">YOUR ART</small>
              </span>
              <span class="btn-inner--icon">
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24">
                  <path id="Path_270" data-name="Path 270" d="M5.636,18.364a9,9,0,1,1,12.728,0" fill="none"
                    stroke="#fcf3e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <line id="Line_19" data-name="Line 19" y1="9" transform="translate(12 11)" fill="none" stroke="#fcf3e2"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_271" data-name="Path 271" d="M15,14l-3-3L9,14" fill="none" stroke="#fcf3e2"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <rect id="Rectangle_81" data-name="Rectangle 81" width="24" height="24" fill="none" />
                </svg>
              </span>
            </div>
          </Button>
          <Button class="p-button-outlined" style="width:150px; padding:8px; height: 46px; text-align: left;"
            @click.prevent="
      router.push({
        name: 'PrintYourArt',
      })
      ">
            <div class="d-flex justify-content-between w-100 px-2">
              <span class="btn-inner--text d-flex align-items-center">
                <small style="font-size: 65%; font-weight: 700;" class="text-uppercase">Custom Print</small>
              </span>
              <span class="btn-inner--icon d-flex align-items-center">
                <i class="pi pi-print" style="font-size:21x;"></i>
              </span>
            </div>
          </Button>
        </div>
        <div class="col-md-3 d-flex justify-content-end align-items-center p-0">
          <ul class="navbar-nav align-items-lg-center ml-lg-auto">
            <li class="nav-item">
              <a href="#/cart" class="text-secondary pl-2" @click.prevent="router.push({ name: 'Cart' })">
                <svg v-if="cartItems?.length == 0" id="cart_dark" xmlns="http://www.w3.org/2000/svg" width="32"
                  height="32" viewBox="0 0 32 32">
                  <path id="Path_11" data-name="Path 11" d="M0,0H32V32H0Z" fill="none" />
                  <path id="Path_12" data-name="Path 12"
                    d="M7.921,16.661,5.874,7H22.709A1.333,1.333,0,0,1,24,8.657l-1.8,7.189a2.666,2.666,0,0,1-2.321,2.007l-9.088.909A2.667,2.667,0,0,1,7.921,16.661Z"
                    transform="translate(1.958 2.333)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_13" data-name="Path 13" d="M6.665,8,5.8,4H3.5" transform="translate(1.167 1.333)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_14" data-name="Path 14"
                    d="M17.23,19.267a.488.488,0,1,0,.491.488.491.491,0,0,0-.491-.488" transform="translate(5.582 6.422)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_15" data-name="Path 15"
                    d="M8.818,19.267a.489.489,0,1,0,.489.489.484.484,0,0,0-.489-.489" transform="translate(2.778 6.422)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
                <svg v-else id="cart_dark_badge" data-name="Group 21" xmlns="http://www.w3.org/2000/svg" width="32"
                  height="32" viewBox="0 0 32 32">
                  <g id="Shopping_Cart" data-name="Shopping Cart">
                    <path id="Path_11" data-name="Path 11" d="M0,0H32V32H0Z" fill="none" />
                    <path id="Path_12" data-name="Path 12"
                      d="M7.921,16.661,5.874,7H22.709A1.333,1.333,0,0,1,24,8.657l-1.8,7.189a2.666,2.666,0,0,1-2.321,2.007l-9.088.909A2.667,2.667,0,0,1,7.921,16.661Z"
                      transform="translate(1.958 2.333)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_13" data-name="Path 13" d="M6.665,8,5.8,4H3.5" transform="translate(1.167 1.333)"
                      fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_14" data-name="Path 14"
                      d="M17.23,19.267a.488.488,0,1,0,.491.488.491.491,0,0,0-.491-.488"
                      transform="translate(5.582 6.422)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_15" data-name="Path 15"
                      d="M8.818,19.267a.489.489,0,1,0,.489.489.484.484,0,0,0-.489-.489"
                      transform="translate(2.778 6.422)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                  </g>
                  <g id="Path_358" data-name="Path 358" transform="translate(17.93 0.425)" fill="#ec5d42">
                    <path
                      d="M 7 13 C 3.691590070724487 13 1 10.30840969085693 1 7 C 1 3.691590070724487 3.691590070724487 1 7 1 C 10.30840969085693 1 13 3.691590070724487 13 7 C 13 10.30840969085693 10.30840969085693 13 7 13 Z"
                      stroke="none" />
                    <path
                      d="M 7 2 C 4.242989540100098 2 2 4.242989540100098 2 7 C 2 9.757009506225586 4.242989540100098 12 7 12 C 9.757009506225586 12 12 9.757009506225586 12 7 C 12 4.242989540100098 9.757009506225586 2 7 2 M 7 0 C 10.86598968505859 0 14 3.134010314941406 14 7 C 14 10.86598968505859 10.86598968505859 14 7 14 C 3.134010314941406 14 0 10.86598968505859 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z"
                      stroke="none" fill="#fcf3e2" />
                  </g>
                </svg>

              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="text-secondary pl-2" @click.prevent="router.push({ name: 'Wishlist' })">
                <svg id="favorite_dark" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <path id="Path_16" data-name="Path 16" d="M0,0H32V32H0Z" fill="none" />
                  <path id="Path_17" data-name="Path 17" d="M23.485,6.515a12,12,0,1,1-16.971,0,12,12,0,0,1,16.971,0"
                    transform="translate(1 1)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_18" data-name="Path 18"
                    d="M15.745,8.75a3.531,3.531,0,0,1,3.339,3.6c0,3.52-5.565,6.4-5.667,6.4s-5.667-2.883-5.667-6.4a3.532,3.532,0,0,1,3.339-3.6,3,3,0,0,1,2.328,1.069A3,3,0,0,1,15.745,8.75Z"
                    transform="translate(2.583 2.917)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </svg>
              </a>
            </li>
            <li class="nav-item" v-if="isLoggedIn == true && logedinUser">
              <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0"
                menuClasses="mt-2 mr-1">
                <template v-slot:title-container>
                  <a href="#" class="nav-link p-0 pl-2 mt-1" @click.prevent>
                    <div class="media align-middle">
                      <span class="avatar avatar-m rounded-circle" style="width: 44px; height: 44px">
                        <img alt="Avatar placeholder" :src="logedinUser?.preview_thumbnail
      ? logedinUser?.preview_thumbnail
      : 'img/user-defualt.svg'
      " />
                      </span>
                    </div>
                  </a>
                </template>

                <div class="dropdown-header noti-title">
                  <h6 class="header-4-p m-0 text-capitalize">
                    Hi {{ logedinUser?.name }}
                  </h6>
                </div>
                <a href="#!" v-if="logedinUser?.roles.includes('Admin')"
                  @click.prevent="router.push({ name: 'Dashboards' })" class="dropdown-item">
                  <i class="fas fa-tachometer-alt"></i>
                  <span class="text-4-s">Dashboard</span>
                </a>
                <a href="#!" @click.prevent="router.push({ name: 'Account' })" class="dropdown-item">
                  <i class="ni ni-single-02"></i>
                  <span class="text-4-s">My Account</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#!" @click.prevent="logout()" class="dropdown-item">
                  <i class="ni ni-user-run"></i>
                  <span class="text-4-s">Logout</span>
                </a>
              </base-dropdown>
            </li>
            <li class="nav-item" v-if="isLoggedIn == false">
              <!-- <router-link :to="{ name: 'Login' }" class="nav-link">
                <span class="nav-link-inner--text">Login</span>
              </router-link> -->
              <a href="#" class="nav-link p-0 pl-2 mt-1" @click.prevent="router.push({ name: 'Login' })">
                <div class="media align-middle" title="Login">
                  <span class="avatar avatar-m rounded-circle bg-white" style="width: 44px; height: 44px">
                    <img alt="Avatar" :src="'img/user-defualt.svg'" />
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-12 p-0" :class="{
      'bb-g': router.currentRoute.value.path == '/',
    }">
          <div class="d-flex justify-content-between">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link :to="{ name: 'Themes' }" class="nav-link text-secondary font-weight-bolder p-0 py-3">
                  <span class="nav-link-inner--text">Themes</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Artists' }" class="nav-link text-secondary font-weight-bolder p-0 py-3">
                  <span class="nav-link-inner--text">Artists</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'About' }" class="nav-link text-secondary font-weight-bolder p-0 py-3">
                  <span class="nav-link-inner--text">About us</span>
                </router-link>
              </li>
              <li class="nav-item">
          <router-link :to="{ name: 'HowItWorks' }" class="nav-link text-secondary font-weight-bolder p-0 py-3">
            <span class="nav-link-inner--text">How it works?</span>
          </router-link>
        </li>
            </ul>
            <CurrencySwitcher></CurrencySwitcher>
          </div>
        </div>
      </div>

      <template v-slot:toggle-button>
        <div class="d-flex justify-content-between w-100 mobile-nav">
          <div class="d-flex justify-content-start align-items-center pb-3">
            <button class="navbar-toggler collapsed c-secondary p-0 pr-3" type="button"
              @click.prevent="isOpen = !isOpen">
              <i class="fas fa-bars"></i>
            </button>
            <router-link class="navbar-brand" to="/">
              <img style="height: 38.04px; width: 105px" src="img/brand/default-logo.png" />
            </router-link>
          </div>
          <div class="d-flex justify-content-end align-items-center pb-3">
            <OverlayPanel ref="mobileSearchPanel">
              <div class="
                  input-group
                  w-100
                  rounded-0
                ">
                <input v-model="search_g_value" class="form-control" placeholder="Pokémon, world cup, national day ..."
                  type="text" />
                <div class="input-group-prepend pr-1">
                  <span class="input-group-text text-primary m-pointer pr-1" @click.prevent="
      router.push({
        name: 'Shop',
        params: { search_value: search_g_value },
      })
      ">
                    Search
                  </span>
                </div>
              </div>
            </OverlayPanel>
            <div class="nav-item">
              <span class="text-secondary m-pointer" @click.prevent="toggleMobileSearchPanel">
                <svg id="Search" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                  <path id="Path_6" data-name="Path 6" d="M0,0H32V32H0Z" fill="none" />
                  <path id="Path_7" data-name="Path 7"
                    d="M14.193,5.582a5.971,5.971,0,1,1-8.444,0,5.971,5.971,0,0,1,8.444,0" transform="translate(0 0)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_8" data-name="Path 8" d="M14.15,14.06,20,19.99" transform="translate(0 0)" fill="none"
                    stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
              </span>
              <a href="#/cart" class="text-secondary pl-2" @click.prevent="router.push({ name: 'Cart' })">
                <svg id="cart_dark" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <path id="Path_11" data-name="Path 11" d="M0,0H32V32H0Z" fill="none" />
                  <path id="Path_12" data-name="Path 12"
                    d="M7.921,16.661,5.874,7H22.709A1.333,1.333,0,0,1,24,8.657l-1.8,7.189a2.666,2.666,0,0,1-2.321,2.007l-9.088.909A2.667,2.667,0,0,1,7.921,16.661Z"
                    transform="translate(1.958 2.333)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_13" data-name="Path 13" d="M6.665,8,5.8,4H3.5" transform="translate(1.167 1.333)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_14" data-name="Path 14"
                    d="M17.23,19.267a.488.488,0,1,0,.491.488.491.491,0,0,0-.491-.488" transform="translate(5.582 6.422)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_15" data-name="Path 15"
                    d="M8.818,19.267a.489.489,0,1,0,.489.489.484.484,0,0,0-.489-.489" transform="translate(2.778 6.422)"
                    fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
              </a>
            </div>
            <div class="nav-item">
              <a href="#" class="text-secondary pl-2" @click.prevent="router.push({ name: 'Wishlist' })">
                <svg id="favorite_dark" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <path id="Path_16" data-name="Path 16" d="M0,0H32V32H0Z" fill="none" />
                  <path id="Path_17" data-name="Path 17" d="M23.485,6.515a12,12,0,1,1-16.971,0,12,12,0,0,1,16.971,0"
                    transform="translate(1 1)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                  <path id="Path_18" data-name="Path 18"
                    d="M15.745,8.75a3.531,3.531,0,0,1,3.339,3.6c0,3.52-5.565,6.4-5.667,6.4s-5.667-2.883-5.667-6.4a3.532,3.532,0,0,1,3.339-3.6,3,3,0,0,1,2.328,1.069A3,3,0,0,1,15.745,8.75Z"
                    transform="translate(2.583 2.917)" fill="none" stroke="#1a1e1d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </svg>
              </a>
            </div>
            <div class="nav-item" v-if="isLoggedIn == true">
              <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0"
                menuClasses="mt-2 mr-1">
                <template v-slot:title-container>
                  <a href="#" class="nav-link p-0 pl-2 mt-1" @click.prevent="">
                    <div class="media align-middle">
                      <span class="avatar avatar-m rounded-circle" style="width: 44px; height: 44px">
                        <img alt="Avatar placeholder" :src="logedinUser?.preview_thumbnail
      ? logedinUser?.preview_thumbnail
      : 'img/user-defualt.svg'
      " />
                      </span>
                    </div>
                  </a>
                </template>

                <div class="dropdown-header noti-title">
                  <h6 class="header-4-p m-0 text-capitalize">
                    Hi {{ logedinUser?.name }}
                  </h6>
                </div>
                <a href="#!" v-if="logedinUser?.roles.includes('Admin')"
                  @click.prevent="router.push({ name: 'Dashboards' })" class="dropdown-item">
                  <i class="fas fa-tachometer-alt"></i>
                  <span class="text-4-s">Dashboard</span>
                </a>
                <a href="#!" @click.prevent="router.push({ name: 'Account' })" class="dropdown-item">
                  <i class="ni ni-single-02"></i>
                  <span class="text-4-s">My Account</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#!" @click.prevent="logout()" class="dropdown-item">
                  <i class="ni ni-user-run"></i>
                  <span class="text-4-s">Logout</span>
                </a>
              </base-dropdown>
            </div>
            <div class="nav-item" v-if="isLoggedIn == false">
              <!-- <router-link :to="{ name: 'Login' }" class="nav-link">
                  <span class="nav-link-inner--text">Login</span>
                </router-link> -->
              <a href="#" class="nav-link p-0 pl-2 mt-1" @click.prevent="router.push({ name: 'Login' })">
                <div class="media align-middle" data-toggle="tooltip" data-placement="top" title="Login">
                  <span class="avatar avatar-m rounded-circle bg-white" style="width: 44px; height: 44px">
                    <img alt="Avatar" :src="'img/user-defualt.svg'" />
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:navbar-toggler>
      </template>
    </base-nav>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer class="py-5 bg-gradient-white border-top border-primary" style="border-width: 3px !important">
      <div class="container">
        <div class="row">
          <div class="col-xl-3">
            <h2>PXL</h2>
            <p>
              A place full of creativity – PXL offers a place for those who are looking for the right fit that
              accommodates their personality and lifestyle.
            </p>
            <ul class="
                nav nav-footer
                justify-content-center justify-content-xl-start
              ">
              <li class="nav-item">
                <a class="nav-link nav-link-icon" style="padding-left: 0;" href="https://www.instagram.com/pxlmena/"
                  target="_blank" data-toggle="tooltip" data-original-title="Follow us on Instagram">
                  <i class="fab fa-instagram"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-icon" href="https://twitter.com/pxlmena" target="_blank"
                  data-toggle="tooltip" data-original-title="Follow us on Twitter">
                  <i class="fab fa-twitter-square"></i>
                  <span class="nav-link-inner--text d-lg-none">Twitter</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-xl-3">
            <h2>About</h2>
            <router-link :to="{ name: 'About' }" class="nav-link">
              <span class="nav-link-inner--text">About us</span>
            </router-link>
            <router-link :to="{ name: 'HowItWorks' }" class="nav-link">
              <span class="nav-link-inner--text">How it works?</span>
            </router-link>
            <router-link :to="{ name: 'PrivacyPolicy' }" class="nav-link">
              <span class="nav-link-inner--text">Privacy policy</span>
            </router-link>
            <router-link :to="{ name: 'ReturnPolicy' }" class="nav-link">
              <span class="nav-link-inner--text">Return policy</span>
            </router-link>
            <router-link :to="{ name: 'TermsAndConditions' }" class="nav-link">
              <span class="nav-link-inner--text">Terms and conditions</span>
            </router-link>
          </div>
          <div class="col-xl-3">
            <h2>Shop</h2>
            <router-link :to="{ name: 'Shop', params: { search_value: '' } }" class="nav-link">
              <span class="nav-link-inner--text">All</span>
            </router-link>
            <router-link :to="{
      name: 'Shop',
      params: { search_value: 'Movies & TV Shows' },
    }" class="nav-link">
              <span class="nav-link-inner--text">Movies & TV Shows</span>
            </router-link>
            <router-link :to="{ name: 'Shop', params: { search_value: 'Anime' } }" class="nav-link">
              <span class="nav-link-inner--text">Anime</span>
            </router-link>
            <router-link :to="{ name: 'Shop', params: { search_value: 'Sport' } }" class="nav-link">
              <span class="nav-link-inner--text">Sport</span>
            </router-link>
          </div>
          <div class="col-xl-3">
            <!-- <h2>Settings</h2> -->
          </div>
        </div>
      </div>
    </footer>

    <footer class="py-0 bg-gradient-white" id="footer-main">
      <div class="container-fluid py-4 border-top">
        <div class="row align-items-center justify-content-xl-center">
          <div class="col-xl-12 d-flex align-items-center justify-content-xl-center">
            <div class="copyright text-center text-xl-left text-muted">
              <small>{{ year }} © by PXL Bahrain. All Rights Reserved</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
/* eslint-disable no-new */
import { ref, watch, onMounted } from "vue";
import Sidebar from 'primevue/sidebar';

import { logedinUser, isLoggedIn, useAuth } from "@/composables/auth";
import { cartItems, useShopping } from "@/composables/Shopping";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    Sidebar,
  },
  setup() {
    const currency = ref("BHD");
    const country = ref("Bahrain");
    const search_g_value = ref("");
    const isOpen = ref(false);

    const { logout, addArtwork } = useAuth();
    const { getUserCartItems } = useShopping();
    const router = useRouter();
    const route = useRoute();
    const mobileSearchPanel = ref();

    onMounted(async () => {
      await getUserCartItems(isLoggedIn.value, logedinUser.id);
    });

    const navTo = async (name, tabName) => {
      isOpen.value = false;
      if (tabName) {
        localStorage.setItem("account-tab", tabName);
      }
      router.push({
        name: name,
        params: { toTab: tabName },
      })
    };

    const toggleMobileSearchPanel = (event) => {
      mobileSearchPanel.value?.toggle(event);
    }

    return {
      logedinUser,
      isLoggedIn,
      logout,
      router,
      route,
      currency,
      country,
      addArtwork,
      search_g_value,
      isOpen,
      navTo,
      cartItems,
      mobileSearchPanel,
      toggleMobileSearchPanel,
    };
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.8;

@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  100% {
    opacity: 1;
  }
}

.main-content {
  background-color: #fff;
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.mobile-nav {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .mobile-nav {
    display: flex !important;
  }
}
</style>

<style scoped></style>
