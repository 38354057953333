<template>
  <div class="container-fluid">
    <div class="mt-5 d-flex justify-content-center flex-wrap" style="width: 100%;">
      <div class=" col-lg-4 col-md-4  mb-3">
        <div class="card">
          <h2 class="mt-3 mb-3 text-white pl-3"> <i class="fas fa-shopping-cart"></i> Total Orders :</h2>
          <h1 class="text-center text-white mb-5" v-if="total_orders">{{ total_orders }}</h1>
          <h1 class="text-center text-white mb-5" v-else>0</h1>
        </div>
      </div>

      <div class=" col-lg-4 col-md-4  mb-3">
        <div class="card">
          <h2 class="mt-3 mb-3 text-white pl-3"> <i class="fas fa-check-circle"></i> Total Paid Orders :</h2>
          <h1 class="text-center text-white mb-5" v-if="total_paid_orders">{{ total_paid_orders }}</h1>
          <h1 class="text-center text-white mb-5" v-else>0</h1>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mb-3 ">
        <div class="card">
          <h2 class="mt-3 mb-3 text-white pl-3"> <i class="fas fa-dollar-sign"></i> Total Revenue :</h2>
          <h1 class="text-center text-white mb-5" v-if="total_paid_order_sum">{{ total_paid_order_sum }} BD</h1>
          <h1 class="text-center text-white mb-5" v-else>0</h1>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- <div class="mt-5 d-flex justify-content-center flex-wrap" style="width: 100%;"> -->
      <!-- no. of items sold -->
      <!-- <div class="col-lg-4 col-md-4 mb-3 ">
        <div class="card">
          <h2 class="mt-3 mb-3 text-white pl-3"> <i class="fas fa-dollar-sign"></i> Total Sold Items :</h2>
          <h1 class="text-center text-white mb-5" v-if="total_items_sold">{{ total_items_sold }}</h1>
          <h1 class="text-center text-white mb-5" v-else>0 items</h1>
        </div>
      </div> -->
      <!-- no. of direct print sold -->
      <!-- <div class="col-lg-4 col-md-4 mb-3 ">
        <div class="card">
          <h2 class="mt-3 mb-3 text-white pl-3"> <i class="fas fa-dollar-sign"></i> Total Ordered Custom Prints :</h2>
          <h1 class="text-center text-white mb-5" v-if="total_direct_prints">{{ total_direct_prints }}</h1>
          <h1 class="text-center text-white mb-5" v-else>0 Prints</h1>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue';
import axiosClient from '../../axiosClient';

export default {
  setup() {
    const result = ref(null);
    const total_orders = ref(null);
    const total_paid_orders = ref(null);
    const total_paid_order_sum = ref(null);
    const total_items_sold = ref(null);
    const total_direct_prints = ref(null);


    const getDashboardOrder = async () => {
      try {
        const response = await axiosClient.get('dashboard-orders');
        result.value = response;
      } catch (error) {
        result.value = "error := ";
      }
    };

    onMounted(async () => {
      await getDashboardOrder();

    });


    watch(() => {
      if (result.value?.data) {
        total_orders.value = result.value?.data?.total_orders;
        total_paid_orders.value = result.value?.data?.total_paid_orders;
        total_paid_order_sum.value = result.value?.data?.total_paid_order_sum;
        total_items_sold.value = result.value?.data?.total_items_sold;
        total_direct_prints.value = result.value?.data?.total_direct_prints;
      }
    })



    return {
      result,
      total_paid_order_sum,
      total_paid_orders,
      total_orders,
      total_items_sold,
      total_direct_prints,
    };
  },
};
</script>

<style scoped>

.card {
  background: #ec5d42;
}

.newContainer {
  width: 80%;
  justify-content: center !important;
  align-items: center !important;
}

h1 {
  font-size: 2.5em;
  /* Increase the font size here */
}

@media screen and (min-width: 768px) {}
</style>
