<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Title</label>
                    <input
                      v-model="notification.title_en"
                      class="form-control"
                      id="title_en"
                      type="text"
                      name="title_en"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Content</label>
                    <textarea
                      v-model="notification.content_en"
                      class="form-control"
                      id="content_en"
                      name="content_en"
                      readonly
                    ></textarea>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Type</label>
                    <div>
                      <span class="badge badge-lg badge-info m-2">
                        {{ notification?.type?.name_en }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Users</label>
                    <div>
                      <span v-for="user in notification?.usersData" :key="user.indx" class="badge badge-lg badge-info m-2">
                        {{ user?.name_en }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
  router.push({
    name: 'notifications.index',
  })
            " class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useNotifications from "@/composables/Notifications";
import useNotificationTypes from "@/composables/NotificationTypes";



export default {
  components: {
  },
  setup() {
    const { notification, getNotification } = useNotifications();

    const { notificationTypes, getNotificationTypes } = useNotificationTypes();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(async () => {
      await getNotificationTypes();
      await getNotification(route.params.id);
    });

    return {
      router,
      notificationTypes,
      notification,
      can,
    };
  },
  methods: {},
  mounted() {},
};
</script>

