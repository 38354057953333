<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="updateNotification(notification)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Title</label>
                      <input v-model="notification.title_en" class="form-control" id="title_en" type="text"
                        name="title_en" />
                      <div class="row" v-for="message in validationErrors['title_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Content <md /></label>
                      <textarea v-model="notification.content_en" class="form-control" id="content_en"
                        name="content_en"></textarea>
                      <div class="row" v-for="message in validationErrors['content_en']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="type_id">Type <md /></label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="notification.type_id"
                          :options="notificationMeta?.types" optionLabel="name_en" optionValue="id" />
                        <div class="row" v-for="message in validationErrors['type_id']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label for="users">Users <md /></label>
                        <MultiSelect class="w-100" :filter="true" :showClear="true" v-model="notification.users"
                          :options="notificationMeta?.users" optionLabel="name_en" optionValue="id" display="chip" />
                        <div class="row" v-for="message in validationErrors['users']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button label="Cancel" @click.prevent="
  router.push({
    name: 'notifications.index',
  })
            " class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useNotifications from "@/composables/Notifications";



export default {
  components: {
  },
  setup() {

    const {
      notificationMeta,
      notification,
      getNotification,
      updateNotification,
      validationErrors,
      isLoading,
    } = useNotifications();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(async () => {
      getNotification(route.params.id);
    });

    return {
      router,
      notification,
      validationErrors,
      isLoading,
      updateNotification,
      can,
      notificationMeta,
    };
  },
  methods: {},
  mounted() {},
};
</script>

