<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <form @submit.prevent="storeCurrency(currency)">
        <div class="row">
          <div class="col-md-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h4>Information</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-control-label">Image
                        <md />
                      </label>
                      <FileUpload name="image[]" :auto="true" :customUpload="true"
                        @uploader="storeCurrencyFiles($event, false, 'currency_image', 30, 0, currency.image, false)"
                        :multiple="true" accept="image/*">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                          <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                            <div class="d-flex gap-2">
                              <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                              <!-- <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                class="p-button-rounded p-button-success"
                                :disabled="!files || files.length === 0"></Button>
                              <Button @click="clearCallback()" icon="pi pi-times"
                                class="p-button-rounded p-button-danger"
                                :disabled="!files || files.length === 0"></Button> -->
                            </div>
                          </div>
                        </template>
                        <template #empty>
                          <div
                            v-if="!currencyProcessingFiles['currency_image'] && currency?.image && currency?.image.length > 0"
                            class="d-flex justify-content-start align-items-center flex-wrap">
                            <div v-for="(image, index) in currency?.image" :key="index" class="p-2 position-relative"
                              style="height:150px; width: 150px;">
                              <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                                alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                              <div
                                class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                                style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                                @click.prevent="
              removeFromArray(currency?.image, index)
              ">
                                <i class="pi pi-times"></i>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="currencyProcessingFiles['currency_image']"
                            class="d-flex justify-content-center align-items-center">
                            <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center flex-column">
                            <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                            <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                          </div>
                        </template>
                      </FileUpload>
                      <div class="row" v-for="message in validationErrors['image']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Code</label>
                      <input v-model="currency.code" class="form-control" id="code" type="text" name="code" />
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.code" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name</label>
                      <input v-model="currency.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.name_en" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name Ar</label>
                      <input
                        v-model="currency.name_ar"
                        class="form-control"
                        id="name_ar"
                        type="text"
                        name="name_ar"
                      />
                    </div> -->
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Exchange Rate</label>
                      <input v-model="currency.exchange_rate" class="form-control" id="exchange_rate" type="text"
                        name="exchange_rate" />
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.exchange_rate" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="form-group d-flex flex-column justify-content-start">
                        <label class="form-control-label" for="country_id">Country</label>
                        <Dropdown class="w-100" :filter="true" :showClear="true" v-model="currency.country_id"
                          :options="countries.data" optionLabel="name_en" optionValue="id" />
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <input v-model="currency.is_default" id="is_default" type="checkbox" name="is_default" />
                      <label class="form-control-label pl-2">Is Default</label>
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.is_default" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <input v-model="currency.is_active" id="is_active" type="checkbox" name="is_active" />
                      <label class="form-control-label pl-2">Is Active</label>
                      <div class="text-red-600 mt-1">
                        <div v-for="message in validationErrors?.is_active" :key="message.id">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <Button @click.prevent="
              router.push({
                name: 'currencies.index',
              })
              " label="Cancel" class="p-button-secondary" />
            <Button type="submit" label="Save" class="p-button-success" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

import useCurrencies from "@/composables/Currencies";
import useCountries from "@/composables/Countries";
import { removeFromArray, getArrayOfField } from "@/helpers/Functions";



export default {
  components: {
  },
  setup() {
    const currency = reactive({
      image: [],
      name_en: "",
      name_ar: "",
      code: "",
      country_id: null,
      is_default: false,
      is_active: true,
    });

    const { storeCurrency, validationErrors, isLoading, currencyProcessingFiles, storeCurrencyFiles } = useCurrencies();
    const { countries, getCountries } = useCountries();

    const router = useRouter();
    const { can } = useAbility();
    onMounted(() => {
      getCountries();
    });

    return {
      router,
      currency,
      countries,
      storeCurrency,
      currencyProcessingFiles,
      storeCurrencyFiles,
      removeFromArray,
      validationErrors,
      isLoading,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>
