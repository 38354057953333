<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between"></div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
