<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div style="min-height: 428px" class="container mt-5">
      <div v-if="route?.query" class="row my-5">
        <div class="col-md-12">
          <div class="d-flex justify-content-center">
            <h1>
              {{ route?.query?.title_en }}
            </h1>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-center">
            <p v-html="route.query.msg_en"></p>
          </div>
        </div>
        <!-- <div v-if="route.query.order_id" class="col-md-12">
          <div class="d-flex justify-content-center">
            <base-button
              @click="payByCredimax(route.query.order_id)"
              type="primary"
              >Pay by Credimax</base-button
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import {useShopping} from "@/composables/Shopping";
export default {
  components: {},
  setup() {
    const route = useRoute();
    const { payByCredimax } = useShopping();

    return {
      route,
      payByCredimax,
    };
  },
};
</script>
<style>
.carousel-item {
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.4);
  padding: 15px 10px;
}
</style>
