<template>
  <div class="currency-switcher">
    <Dropdown class="select" v-model="currency" :options="currencies.data" optionLabel="code">
      <template #value="slotProps">
        <div class="w-100 d-flex justify-content-start align-items-center" v-if="slotProps.value">
          <span class="header-3-s">Currency</span>
          <img :src="slotProps.value?.image && slotProps.value?.image[0]?.webp ? slotProps.value?.image[0]?.webp : ''"
            style="width: 30px; height: 30px; object-fit: contain" />
          <span class="header-3-s">{{ slotProps.value.code }}</span>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="w-100 d-flex justify-content-start align-items-center" v-if="slotProps.option">
          <img
            :src="slotProps.option?.image && slotProps.option?.image[0]?.webp ? slotProps.option?.image[0]?.webp : ''"
            style="width: 30px; height: 30px; object-fit: contain" />
          <span class="header-3-s pl-2">{{ slotProps.option.code }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import useCurrencies from "@/composables/Currencies";
import { useCurrency } from "@/composables/GlobalState";

export default {
  name: "currency-switcher",
  setup() {
    const page = 1;
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_code = ref("");
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");

    const { currency, getCurrency } = useCurrency()

    const { currencies, getCurrencies, deleteCurrency } = useCurrencies();

    onMounted(async () => {
      await getCurrencies()
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    watch(
      () => currencies.value,
      (current) => {
        for (let index = 0; index < currencies.value.data.length; index++) {
          const item = currencies.value.data[index];
          if (item.code == currency.value?.code) {
            currency.value = item;
            localStorage.setItem('pxl-currency', JSON.stringify(currency.value))
            break;
          } else if (item.is_default) {
            currency.value = item;
            localStorage.setItem('pxl-currency', JSON.stringify(currency.value))
          }
        }
      },
      { deep: true }
    )

    watch(
      () => currency.value,
      (current) => {
        localStorage.setItem('pxl-currency', JSON.stringify(currency.value))
      },
      { deep: true }
    )

    return {
      currencies,
      currency,
    };
  }
}
</script>
<style scoped>
.currency-switcher *+* {
  margin-left: 0.5rem;
}

.currency-switcher .select {
  background-color: var(--primary-re);
  border: none;
  color: var(--secondary-re) !important;
}
</style>
