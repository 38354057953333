import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function usePermissions() {
  const allPermissions = ref({});
  const permissions = ref({});
  const permission = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getPermissions = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/permissions?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        permissions.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getAllPermissions = async () => {
    axiosClient
      .get("/permissions?all=true")
      .then((response) => {
        allPermissions.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getPermission = async (id) => {
    axiosClient.get("/permissions/" + id).then((response) => {
      permission.value = response.data.data;
    });
  };

  const storePermission = async (permission) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedPermission = new FormData();
    for (let item in permission) {
      if (permission.hasOwnProperty(item)) {
        serializedPermission.append(item, permission[item]);
      }
    }

    axiosClient
      .post("/permissions", serializedPermission)
      .then((response) => {
        router.push({ name: "permissions.index" });
        swal({
          icon: "success",
          title: "Permission saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updatePermission = async (permission) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/permissions/" + permission.id, permission)
      .then((response) => {
        router.push({ name: "permissions.index" });
        swal({
          icon: "success",
          title: "Permission saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deletePermission = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/permissions/" + id)
          .then((response) => {
            getPermissions();
            router.push({ name: "permissions.index" });
            swal({
              icon: "success",
              title: "Permission deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    allPermissions,
    permissions,
    permission,
    getAllPermissions,
    getPermissions,
    getPermission,
    storePermission,
    validationErrors,
    isLoading,
    updatePermission,
    deletePermission,
  };
}
