import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useRoles() {
  const allRoles = ref({});
  const roles = ref({});
  const role = ref({});
  const meta = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getRoles = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/roles?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        roles.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getAllRoles = async () => {
    axiosClient
      .get("/roles?all=true")
      .then((response) => {
        allRoles.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getRole = async (id) => {
    axiosClient.get("/roles/" + id).then((response) => {
      response.data.data.permissionData = response.data.data.permissions;
      response.data.data.permissions = response.data.permissions;
      role.value = response.data.data;
      meta.value = response.data.meta;
    });
  };

  const storeRole = async (role) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedRole = new FormData();
    // let a = new FormArray();
    // a.value = role.permissions;
    for (let item in role) {
      if (role.hasOwnProperty(item)) {
        if (typeof role[item] == "object") {
          for (let i = 0; i < role[item].length; i++) {
            let file = role[item][i];
            serializedRole.append(item + "[" + i + "]", file);
          }
        } else {
          serializedRole.append(item, role[item]);
        }
      }
    }

    axiosClient
      .post("/roles", serializedRole)
      .then((response) => {
        router.push({ name: "roles.index" });
        swal({
          icon: "success",
          title: "Role saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateRole = async (role) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/roles/" + role.id, role)
      .then((response) => {
        router.push({ name: "roles.index" });
        swal({
          icon: "success",
          title: "Role saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteRole = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/roles/" + id)
          .then((response) => {
            getRoles();
            router.push({ name: "roles.index" });
            swal({
              icon: "success",
              title: "Role deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    allRoles,
    roles,
    role,
    getAllRoles,
    getRoles,
    getRole,
    storeRole,
    validationErrors,
    isLoading,
    updateRole,
    deleteRole,
  };
}
