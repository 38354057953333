import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useAttributes() {
  const attributes = ref({});
  const attribute = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getAttributes = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/attributes?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        attributes.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getAttribute = async (id) => {
    axiosClient.get("/attributes/" + id).then((response) => {
      attribute.value = response.data.data;
    });
  };

  const storeAttribute = async (attribute) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedAttribute = new FormData();
    for (let item in attribute) {
      if (attribute.hasOwnProperty(item)) {
        serializedAttribute.append(item, attribute[item]);
      }
    }

    axiosClient
      .post("/attributes", serializedAttribute)
      .then((response) => {
        router.push({ name: "attributes.index" });
        swal({
          icon: "success",
          title: "Attribute saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateAttribute = async (attribute) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/attributes/" + attribute.id, attribute)
      .then((response) => {
        router.push({ name: "attributes.index" });
        swal({
          icon: "success",
          title: "Attribute saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteAttribute = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/attributes/" + id)
          .then((response) => {
            getAttributes();
            router.push({ name: "attributes.index" });
            swal({
              icon: "success",
              title: "Attribute deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    attributes,
    attribute,
    getAttributes,
    getAttribute,
    storeAttribute,
    validationErrors,
    isLoading,
    updateAttribute,
    deleteAttribute,
  };
}
