<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h4>Information</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Key</label>
                    <input v-model="globalSetting.s_key" class="form-control" id="s_key" type="text" name="s_key" />
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group d-flex flex-column justify-content-start">
                      <label class="form-control-label" for="s_type">Type</label>
                      <Dropdown class="w-100" :filter="true" :showClear="true" v-model="globalSetting.s_type"
                        :options="globalSettingMeta?.s_types" optionLabel="name_en" optionValue="id" />
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Label</label>
                    <input v-model="globalSetting.s_label" class="form-control" id="s_label" type="text"
                      name="s_label" />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Value</label>
                    <input v-model="globalSetting.s_value" class="form-control" id="s_value" type="text"
                      name="s_value" />
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Order</label>
                    <input v-model="globalSetting.s_order" class="form-control" id="s_order" type="text"
                      name="s_order" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <Button @click.prevent="
  router.push({
    name: 'globalSettings.index',
  })
          " label="Cancel" class="p-button-secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import useGlobalSettings from "@/composables/GlobalSettings";



export default {
  components: {
  },
  setup() {
    const { globalSetting, getGlobalSetting, globalSettingMeta } = useGlobalSettings();

    const router = useRouter();
    const route = useRoute();
    const { can } = useAbility();
    onMounted(() => {
      getGlobalSetting(route.params.id);
    });

    return {
      router,
      globalSetting,
      globalSettingMeta,
      can,
    };
  },
  methods: {},
  mounted() { },
};
</script>
