import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useArtworkStatuses() {
  const artworkStatuses = ref({});
  const artworkStatus = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getArtworkStatuses = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/artwork-statuses?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        artworkStatuses.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });
  };

  const getArtworkStatus = async (id) => {
    axiosClient.get("/artwork-statuses/" + id).then((response) => {
      artworkStatus.value = response.data.data;
    });
  };

  const storeArtworkStatus = async (artworkStatus) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedArtworkStatus = new FormData();
    for (let item in artworkStatus) {
      if (artworkStatus.hasOwnProperty(item)) {
        serializedArtworkStatus.append(item, artworkStatus[item]);
      }
    }

    axiosClient
      .post("/artwork-statuses", serializedArtworkStatus)
      .then((response) => {
        router.push({ name: "artwork-statuses.index" });
        swal({
          icon: "success",
          title: "artwork status saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateArtworkStatus = async (artworkStatus) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/artwork-statuses/" + artworkStatus.id, artworkStatus)
      .then((response) => {
        router.push({ name: "artwork-statuses.index" });
        swal({
          icon: "success",
          title: "Artwork status saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteArtworkStatus = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/artwork-statuses/" + id)
          .then((response) => {
            getArtworkStatuses();
            router.push({ name: "artwork-statuses.index" });
            swal({
              icon: "success",
              title: "Artwork status deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: error,
            });
          });
      }
    });
  };

  return {
    artworkStatuses,
    artworkStatus,
    getArtworkStatuses,
    getArtworkStatus,
    storeArtworkStatus,
    validationErrors,
    isLoading,
    updateArtworkStatus,
    deleteArtworkStatus,
  };
}
