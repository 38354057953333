import axiosClient from "../axiosClient";
import axios from "axios";
import { ref, reactive, inject } from "vue";
import { useRouter } from "vue-router";
import { AbilityBuilder, Ability } from "@casl/ability";
import { ABILITY_TOKEN } from "@casl/vue";
import { serialize } from "object-to-formdata";
import { useLocalStorage } from "@vueuse/core"

export const logedinUser = reactive({
  id: "",
  name: "",
  email: "",
  iban: "",
  avatar: "",
  preview_thumbnail: "",
  token: "",
  roles: "",
  data: [],
});

export const isLoggedIn = useLocalStorage('isLoggedIn', false);
export const roles = ref(null);
export const token = ref(null);
export const settingsList = ref({});

export function useAuth() {
  const processing = ref(false);
  const authProcessingFiles = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);
  const meta = ref({});
  const router = useRouter();
  const swal = inject("$swal");
  const ability = inject(ABILITY_TOKEN);
  const loginForm = reactive({
    email: "",
    password: "",
    remember: false,
  });

  const getPublicToken = async () => {
    if (isLoggedIn.value == false && !localStorage.getItem("TOKEN")) {
      localStorage.setItem("isLoggedIn", false);
      await axiosClient.get("/publicToken").then((response) => {
        localStorage.setItem("TOKEN", response.data.token);
        localStorage.setItem("ROLES", response.data.roles);
        token.value = response.data.token;
        roles.value = response.data.roles;
        getAbilities();
      });
    }
    if (isLoggedIn.value == true && localStorage.getItem("TOKEN")) {
      getLogedinUser();
    }
  };

  const submitLogin = async () => {
    if (processing.value) return;

    processing.value = true;
    validationErrors.value = {};

    axiosClient
      .post("/login", loginForm)
      .then(async (response) => {
        loginUser(response);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        } else if (error.response.data.error) {
          validationErrors.value = { error: [error.response.data.error]};
        }
      })
      .finally(() => (processing.value = false));
  };

  const loginUser = async (response) => {
    isLoggedIn.value = true
    logedinUser.id = response.data.user.id;
    logedinUser.name = response.data.user.name_en;
    logedinUser.email = response.data.user.email;
    logedinUser.iban = response.data.user.iban;
    localStorage.setItem(
      "wishlist",
      JSON.stringify(response.data.user.wishlist_ids)
    );

    response?.data?.user?.avatar.forEach((element) => {
      logedinUser.avatar = element["url"];
      logedinUser.preview_thumbnail = element["preview_thumbnail"];
    });

    logedinUser.data = response.data.user;
    logedinUser.token = response.data.token;
    token.value = response.data.token;
    logedinUser.roles = response.data.roles;
    roles.value = response.data.roles;
    localStorage.setItem("TOKEN", logedinUser.token);
    localStorage.setItem("ROLES", logedinUser.roles);
    await getAbilities();
    router.push({ name: "Home" });
  };
  const getUser = () => {
    axiosClient.get("/user").then((response) => {
      loginUser(response);
    });
  };

  const getLogedinUser = () => {
    axiosClient.get("/user").then((response) => {
      localStorage.setItem("isLoggedIn", true);
      logedinUser.id = response.data.id;
      logedinUser.name = response.data.name_en;
      logedinUser.email = response.data.email;
      logedinUser.iban = response.data.iban;
      localStorage.setItem(
        "wishlist",
        JSON.stringify(response.data.wishlist_ids)
      );

      response?.data?.avatar.forEach((element) => {
        logedinUser.avatar = element["url"];
        logedinUser.preview_thumbnail = element["preview_thumbnail"];
      });

      logedinUser.data = response.data;
      logedinUser.token = localStorage.getItem("TOKEN");
      token.value = localStorage.getItem("TOKEN");
      logedinUser.roles = localStorage.getItem("ROLES");
      roles.value = localStorage.getItem("ROLES");
      getAbilities();
    });

    return logedinUser;
  };

  const getAuthMeta = async () => {
    axiosClient.get("/register/meta").then((response) => {
      meta.value = response.data.meta;
    });
  };

  const registerUser = async (user) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let params = serialize(user, {
      indices: true,
    });

    axiosClient
      .post("/register", params)
      .then((response) => {
        loginUser(response);
        swal({
          icon: "success",
          title: "Successfully registered",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const logout = async () => {
    if (processing.value) return;

    processing.value = true;

    axiosClient
      .post("/logout")
      .then((response) => {
        localStorage.setItem("isLoggedIn", false);
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ROLES");
        localStorage.setItem(
          "wishlist",
          JSON.stringify([])
        );
        router.push({ name: "Home" });
        setTimeout(() => {
          // getPublicToken();
          window.location.reload();
        }, 100);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.response.status,
          text: error.response.statusText,
        });
      })
      .finally(() => {
        processing.value = false;
      });
  };

  const forgotPassword = async (email) => {
    if (processing.value) return;

    processing.value = true;

    axiosClient
      .post("/forgot-password", { email })
      .then((response) => {
        swal({
          icon: "success",
          title: "Successfully sent",
        });
        router.push({ name: "Login" });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (processing.value = false));
  };

  const resetPassword = async (password, password_confirmation, token) => {
    if (processing.value) return;

    processing.value = true;

    const headers = {
      Authorization: "Bearer " + token,
    };
    axios
      .post(
        process.env.VUE_APP_API_BASE_URL+"/reset-password",
        {
          password: password,
          password_confirmation: password_confirmation,
          token: token,
        },
        { headers }
      )
      .then((response) => {
        swal({
          icon: "success",
          title: "Successfully reset",
        });
        router.push({ name: "Login" });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (processing.value = false));
  };

  const getAbilities = async () => {
    axiosClient
      .get("/abilities")
      .then((response) => {
        const permissions = response.data;
        const { can, rules } = new AbilityBuilder(Ability);

        can(permissions);

        ability.update(rules);
      })
      .catch((error) => {
        localStorage.removeItem("TOKEN");
        // router.push({ name: "Login" });
      });
  };

  const addArtwork = async () => {
    // if (!logedinUser.iban) {
    //   swal({
    //     icon: "warning",
    //     title: "Please add your IBAN before adding an artwork",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       localStorage.setItem("account-tab", "settings");
    //       router.push({
    //         name: "Account",
    //       });
    //     }
    //   });
    // } else {
    //   router.push({
    //     name: "AddArtwork",
    //   });
    // }
    if(!logedinUser){
      swal({
            icon: "warning",
            title: "Please Sign in before adding and artwork",
          })
    }else {
      router.push({
        name: "AddArtwork",
      });
    }
  };

  const storeAuthFiles = async (
    event,
    multi,
    collection_name,
    size,
    model_id,
    itemFile,
    compress
  ) => {
    if (authProcessingFiles.value[collection_name]) return;

    authProcessingFiles.value[collection_name] = true;
    validationErrors.value = {};

    if (compress) {
      // var uploadFiles = await compressFiles(event.files);
      var uploadFiles = event.files;
    } else {
      var uploadFiles = event.files;
    }

    uploadFiles.forEach((file) => {
      let uploadData = {
        file: file,
        collection_name: collection_name,
        size: size,
        model_id: model_id,
      };
      let serializedUser = new FormData();
      for (let item in uploadData) {
        if (uploadData.hasOwnProperty(item)) {
          serializedUser.append(item, uploadData[item]);
        }
      }
      axiosClient
        .post("/register/media", serializedUser)
        .then((response) => {
          if (!multi) {
            itemFile[0] = response.data;
          } else {
            itemFile.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            var message = error.response.data.message
              ? error.response.data.message
              : "";
            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              // timer: 5000,
              // timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title: "Error",
              html: '<pre class="text-break text-wrap">' + message + "</pre>",
            });
          } else if (error?.response?.data?.errors) {
            validationErrors.value = error.response.data.errors;

            swal({
              toast: false,
              position: "center",
              showConfirmButton: false,
              showCancelButton: true,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", swal.stopTimer);
                toast.addEventListener("mouseleave", swal.resumeTimer);
              },
              icon: "error",
              title:
                "Validation error, we can not upload the files, please try again.",
            });
          }
        })
        .finally(() => (authProcessingFiles.value[collection_name] = false));
    });
  };

  const getSettingsList = async () => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    await axiosClient
      .get("/global-settings-list")
      .then((response) => {
        response?.data?.data?.forEach((siteSetting) => {
          if (siteSetting.s_type == 4) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 5) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 6) {
            siteSetting.s_value = Number(siteSetting.s_value ?? "0");
          }
          if (siteSetting.s_type == 8) {
            siteSetting.s_value = JSON.parse(siteSetting.s_value ?? "[]");
          }
          settingsList.value[siteSetting.s_key] = siteSetting.s_value;
        });
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          validationErrors.value = error.response.data.errors;

          swal({
            toast: false,
            position: "center",
            showConfirmButton: false,
            showCancelButton: true,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal.stopTimer);
              toast.addEventListener("mouseleave", swal.resumeTimer);
            },
            icon: "error",
            title: "Validation error, please try again.",
          });
        }
      })
      .finally(() => (isLoading.value = false));
  };

  return {
    loginForm,
    validationErrors,
    processing,
    submitLogin,
    getUser,
    getLogedinUser,
    getAuthMeta,
    meta,
    registerUser,
    logout,
    forgotPassword,
    resetPassword,
    getAbilities,
    addArtwork,
    getPublicToken,
    storeAuthFiles,
    authProcessingFiles,
    getSettingsList,
    settingsList,
  };
}
