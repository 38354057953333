<template>
    <div>
        <!-- <div class="bg-default py-5">&nbsp;</div> -->
        <!-- Page content -->
        <div class="container mt-5 mb-5">
            <h1 class="mt-5 header-1-p">Return Policy</h1>

            <h2 class="mt-5 header-2-p ">Order cancelation</h2>
            <p class="mb-4 mt-2 text-2-s">
                A customer is able to cancel an order only if the order is not yet processed to
                printing. Once the product is in the printing stage, cancelation will not be allowed,
                and returns will not be accepted.
            </p>
            <h2 class="mt-5 header-2-p">Damaged orders</h2>
            <p class=" mt-2 mb-4 text-2-s">
                We make sure that all of our products are sent with extra cation and quality check
                but if you receive a damaged product in any form, please make sure to take a
                picture or a video highlighting the damage and send it to info@pxl.shop and we
                will make sure that you receive a replacement product immediately.
            </p>
            <h2 class="mt-5 header-2-p">Wrong orders</h2>
            <p class=" mb-4 text-2-s mt-2">
                If the order that you received does not match what you placed such as receiving
                wrong color, wrong size, or wrong design please take a picture or a video of the
                product and send it to info@pxl.shop and we will make sure that you receive a
                correct replacement product immediately.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    setup() { },
};
</script>
<style>
.carousel-item {
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.4);
    padding: 15px 10px;
}

</style>