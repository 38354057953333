<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <Sidebar v-model:visible="isOpen" :baseZIndex="10000" :showCloseIcon="false">
      <div v-if="artworks.data" class="d-flex justify-content-between align-items-center flex-wrap"
        style="height: 43px">
        <div class="text-lable-semi-bold-primary">Filters</div>
      </div>
      <div class="d-flex align-items-start flex-column" v-if="artworks.data">
        <span class="text-lable-p mt-2 mb-2"> Products </span>
        <div class="d-flex align-items-start flex-column">
          <div class="mr-2 mb-2" v-for="product in artworkMeta.products" :key="product.index">
            <input class="checkbox-custom-primary" :id="product.name_en" type="checkbox" :name="product.name_en"
              v-model="selectedProducts[product.name_en]" @change="selectFilter(search_products, product.id)" />
            <span :for="product.name_en" class="text-lable-gray ml-2">
              {{ product.name_en }}
            </span>
          </div>
        </div>
        <div v-if="artworkMeta.attributes">
          <div v-for="variation in artworkMeta.attributes" :key="variation.index" class="">
            <span class="text-lable-p mt-2 mb-2">
              {{ variation.name_en }}
            </span>
            <div class="d-flex justify-content-start flex-wrap">
              <div v-for="option in variation?.options" :key="option.index" class="mr-2 mb-2">
                <div v-if="variation.name_en.toLowerCase() == 'color'">
                  <span
                    class="
                                                                                                                                                                                              dot
                                                                                                                                                                                              d-flex
                                                                                                                                                                                              align-items-center
                                                                                                                                                                                              justify-content-center
                                                                                                                                                                                            "
                    :style="[{ 'background-color': option.name_en }]" @click.prevent="
                      selectFilter(search_variations, option.name_en)
                      ">
                    <b v-if="search_variations &&
                      search_variations.includes(option.name_en)
                    " style="color: white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="7.411" viewBox="0 0 10.828 7.411">
                        <path id="Path_281" data-name="Path 281" d="M17.486,10.707l-5,5-3-2.994"
                          transform="translate(-8.072 -9.293)" fill="none" stroke="#fff" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2" />
                      </svg>
                    </b>
                  </span>
                </div>
                <div v-else>
                  <button class="btn-custom-light" :class="[
                    {
                      active: search_variations.includes(option.name_en),
                    },
                  ]" @click.prevent="
                    selectFilter(search_variations, option.name_en)
                    ">
                    <span class="text-lable-gray pl-2 pr-2">
                      {{ option.name_en }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="text-lable-p mt-2 mb-2"> Tags </span>
        <div class="d-flex justify-content-start flex-wrap">
          <div class="mr-2 mb-2" v-for="tag in artworkMeta?.tags?.filter(
            (tag, index) => index < 11
          )" :key="tag.index">
            <button class="btn-custom-light" :class="[
              {
                active: search_tags.includes(tag.id),
              },
            ]" @click.prevent="selectFilter(search_tags, tag.id)">
              <span class="text-lable-gray">
                {{ tag.name_en }}
              </span>
            </button>
          </div>
        </div>
        <div class="w-100 mt-4">
          <Button class="w-100" @click.prevent="isOpen = !isOpen">
            <p class="text-bold-3-s w-100 text-white text-center">APPLY</p>
          </Button>
        </div>
      </div>
    </Sidebar>
    <div class="container">
      <div class="row w-100 m-0">
        <div class="col-md-12 mb-4 mt-6 p-0">
          <div class="d-flex flex-row justify-content-start align-items-center"></div>
          <div class="breadcrumb-custom">
            Home
            <svg xmlns="http://www.w3.org/2000/svg" width="4.811" height="8.121" viewBox="0 0 4.811 8.121"
              class="ml-1 mr-1">
              <path id="Path_258" data-name="Path 258" d="M10,14l3-3L10,8" transform="translate(-8.939 -6.939)"
                fill="none" stroke="#837f7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
            Theme
            <svg xmlns="http://www.w3.org/2000/svg" width="4.811" height="8.121" viewBox="0 0 4.811 8.121"
              class="ml-1 mr-1">
              <path id="Path_258" data-name="Path 258" d="M10,14l3-3L10,8" transform="translate(-8.939 -6.939)"
                fill="none" stroke="#837f7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
            <!-- {{ parent_name_en }} -->
            {{ category_name_en }}
          </div>
          <div class="current-page text-capitalize">
            {{ category_name_en }}
          </div>
          <!-- <div class="current-page-text">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh
          </div> -->
        </div>
        <div class="col-12 p-0">
          <hr class="m-0" />
        </div>
        <div class="col-lg-2 mb-4 mt-4 p-0 d-none d-lg-block">
          <div v-if="artworks.data" class="d-flex justify-content-between align-items-center flex-wrap"
            style="height: 43px">
            <div class="text-lable-semi-bold-primary">Filters</div>
          </div>
          <div class="d-flex align-items-start flex-column" v-if="artworks.data">
            <span class="text-lable-p mt-2 mb-2"> Products </span>
            <div class="d-flex align-items-start flex-column">
              <div class="mr-2 mb-2" v-for="product in artworkMeta.products" :key="product.index">
                <input class="checkbox-custom-primary" :id="product.name_en" type="checkbox" :name="product.name_en"
                  v-model="selectedProducts[product.name_en]" @change="selectFilter(search_products, product.id)" />
                <span :for="product.name_en" class="text-lable-gray ml-2">
                  {{ product.name_en }}
                </span>
              </div>
            </div>
            <div v-if="artworkMeta.attributes">
              <div v-for="variation in artworkMeta.attributes" :key="variation.index" class="">
                <span class="text-lable-p mt-2 mb-2">
                  {{ variation.name_en }}
                </span>
                <div class="d-flex justify-content-start flex-wrap">
                  <div v-for="option in variation?.options" :key="option.index" class="mr-2 mb-2">
                    <div v-if="variation.name_en.toLowerCase() == 'color'">
                      <span
                        class="
                                                                                                                                                                                              dot
                                                                                                                                                                                              d-flex
                                                                                                                                                                                              align-items-center
                                                                                                                                                                                              justify-content-center
                                                                                                                                                                                            "
                        :style="[{ 'background-color': option.name_en }]" @click.prevent="
                          selectFilter(search_variations, option.name_en)
                          ">
                        <b v-if="search_variations &&
                          search_variations.includes(option.name_en)
                        " style="color: white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="7.411"
                            viewBox="0 0 10.828 7.411">
                            <path id="Path_281" data-name="Path 281" d="M17.486,10.707l-5,5-3-2.994"
                              transform="translate(-8.072 -9.293)" fill="none" stroke="#fff" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                          </svg>
                        </b>
                      </span>
                    </div>
                    <div v-else>
                      <button class="btn-custom-light" :class="[
                        {
                          active: search_variations.includes(option.name_en),
                        },
                      ]" @click.prevent="
                        selectFilter(search_variations, option.name_en)
                        ">
                        <span class="text-lable-gray pl-2 pr-2">
                          {{ option.name_en }}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="text-lable-p mt-2 mb-2"> Tags </span>
            <div class="d-flex justify-content-start flex-wrap">
              <div class="mr-2 mb-2" v-for="tag in artworkMeta?.tags?.filter(
                (tag, index) => index < 11
              )" :key="tag.index">
                <button class="btn-custom-light" :class="[
                  {
                    active: search_tags.includes(tag.id),
                  },
                ]" @click.prevent="selectFilter(search_tags, tag.id)">
                  <span class="text-lable-gray">
                    {{ tag.name_en }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-10 mb-4 mt-4 p-0">
          <div class="mb-4 d-flex justify-content-between flex-wrap">
            <div class="d-flex justify-content-start align-items-center">
              <div v-if="artworks.data" class="text-lable-gray d-none d-lg-block">
                {{ artworks.meta.total }} Results
              </div>
              <button class="btn-custom-light d-block d-lg-none" style="height: calc(1.5em + 1.25rem + 2px)"
                @click.prevent="isOpen = !isOpen">
                <span class="text-bold-3-s">FILTERS</span>
                <svg id="settings-filter.3" xmlns="http://www.w3.org/2000/svg" class="ml-3" width="24" height="24"
                  viewBox="0 0 24 24">
                  <path id="Path_325" data-name="Path 325"
                    d="M14,14h2a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V15A1,1,0,0,1,14,14Z" fill="none"
                    stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    fill-rule="evenodd" />
                  <path id="Path_326" data-name="Path 326" d="M21,17H17" fill="none" stroke="#ec5d42"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  <g id="Group_511" data-name="Group 511" transform="translate(3 4)">
                    <path id="Path_327" data-name="Path 327" d="M13,17H3" transform="translate(-3 -4)" fill="none"
                      stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_328" data-name="Path 328"
                      d="M10,10H8A1,1,0,0,1,7,9V5A1,1,0,0,1,8,4h2a1,1,0,0,1,1,1V9A1,1,0,0,1,10,10Z"
                      transform="translate(-3 -4)" fill="none" stroke="#ec5d42" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" fill-rule="evenodd" />
                    <path id="Path_329" data-name="Path 329" d="M3,7H7" transform="translate(-3 -4)" fill="none"
                      stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_330" data-name="Path 330" d="M11,7H21" transform="translate(-3 -4)" fill="none"
                      stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                  <path id="Path_331" data-name="Path 331" d="M0,0H24V24H0Z" fill="none" />
                </svg>

              </button>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <div v-if="artworks.data" class="text-lable-semi-bold-primary mr-3 d-none d-lg-block">
                Sort by
              </div>
              <div v-if="artworks.data">
                <Dropdown v-model="orderColumn" :options="orderColumnOptions" optionLabel="text" optionValue="value"
                  placeholder="Sort by" />
              </div>
            </div>
          </div>
          <div v-if="isLoading">
            <ThemeArtworkSkeleton />
          </div>
          <div v-else class="row mb-5 mx-2 mx-md-0 justify-content-start">
            <div v-for="artwork in artworks.data" :key="artwork.index"
              class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-0 mb-3">
              <div>
                <div class="card card-custom pointer bg-dark text-white border-0 m-0 mb-2 mx-auto text-left">
                  <img class="card-img" style="object-fit: contain"
                    :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                    :src="getArtworkImage(artwork)" alt="Card image" @click.prevent="
                      router.push({
                        name: 'Artwork',
                        params: { id: artwork.slug },
                      })
                      " />
                  <div class="btn-fav">
                    <img @click.prevent="addToWishlist(artwork.id, isLoggedIn)" :src="wishlistIDs.includes(artwork.id)
                      ? 'img/favorite.svg'
                      : 'img/non-favorite.svg'
                      " />
                  </div>
                  <div class="img-footer">
                    <span v-if="formatDateYYYYMMDD(artwork.created_at) ==
                      formatDateYYYYMMDD(Date.now())
                    " class="badge badge-secondary p-2">
                      New
                    </span>
                  </div>
                </div>
                <div @click.prevent="
                  router.push({
                    name: 'Artwork',
                    params: { id: artwork.slug },
                  })
                  " class="d-flex align-items-start flex-column mx-auto pointer card-custom-info">
                  <span class="text-lable-semi-bold-secondary">
                    {{ artwork.name_en }}
                  </span>
                  <span class="text-lable-span pointer">
                    {{ artwork.user.name_en }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="artworks" class="d-flex justify-content-end">
            <pagination :data="artworks" :limit="1" @pagination-change-page="(page) => { }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import useArtworks from "@/composables/Artworks";
import { isLoggedIn } from "@/composables/auth";
import { useShopping } from "@/composables/Shopping";

import { useRouter, useRoute } from "vue-router";
import Sidebar from 'primevue/sidebar';

import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
import ThemeArtworkSkeleton from "@/components/ThemeArtworkSkeleton.vue";
export default {
  components: {
    Pagination: LaravelVuePagination,
    Sidebar,
    ThemeArtworkSkeleton,
  },
  props: {
    slug: {
      required: true,
    },
    // subslug: {
    //   required: true,
    // },
    // parent_name_en: {
    //   required: true,
    // },
    category_id: {
      required: true,
    },
    category_name_en: {
      required: true,
    },
  },
  setup(props) {
    const page = ref(1);
    const per_page = ref(24);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_categories = ref([]);
    const search_products = ref([]);
    const search_tags = ref([]);
    const search_variations = ref([]);
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderColumnOptions = ref([]);
    const orderDirection = ref("desc");
    const orderDirectionOptions = ref([]);
    const inactive = ref(1);
    const is_direct = ref(0);
    const isOpen = ref(false);
    const isLoading = ref(true);

    const selectedProducts = ref({});

    const router = useRouter();
    const route = useRoute();

    const { artworks, getArtworks, artworkMeta, getArtworkMeta } =
      useArtworks();
    const { addToWishlist, wishlistIDs } = useShopping();

    onMounted(async () => {
      if (props.category_id) {
        search_categories.value.push(props.category_id);
      } else {
        const themeArtworkData = JSON.parse(localStorage.getItem('ThemeArtwork'));
        search_categories.value.push(themeArtworkData.id)
      }

      const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
      page.value = pageNumber;
      getArtworks(
        pageNumber,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
      );
      getArtworkMeta();
      per_page_options.value = [
        { text: "15", value: 15 },
        { text: "25", value: 25 },
        { text: "40", value: 40 },
        { text: "100", value: 100 },
      ];
      orderDirectionOptions.value = [
        { text: "ASC", value: "asc" },
        { text: "DESC", value: "desc" },
      ];
      orderColumnOptions.value = [
        { text: "Default", value: "id" },
        { text: "Date", value: "created_at" },
        { text: "Name", value: "name_en" },
      ];
      setTimeout(() => {
        isLoading.value = false;
      }, 1500);
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
      );
    };

    watch(orderColumn, () => {
      getArtworks(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value,
      );
    });

    watch(
      () => search_products.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
        );
      },
      { deep: true }
    );

    watch(
      () => search_tags.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
        );
      },
      { deep: true }
    );

    watch(
      () => search_variations.value,
      async () => {
        getArtworks(
          1,
          search_id.value,
          search_name_en.value,
          search_name_ar.value,
          search_categories.value,
          search_products.value,
          search_tags.value,
          search_variations.value,
          search_global.value,
          orderColumn.value,
          orderDirection.value,
          per_page.value,
          inactive.value,
          is_direct.value,
        );
      },
      { deep: true }
    );

    watch(() => route.query.page, (current) => {
      const pageNumber = current ? parseInt(current, 10) : 1;
      page.value = pageNumber;
    });


    watch(() => page.value, (current) => {
      getArtworks(
        current,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_categories.value,
        search_products.value,
        search_tags.value,
        search_variations.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value,
        inactive.value,
        is_direct.value
      );
    })

    const selectFilter = async (filters, value) => {
      page.value = 1;
      router.push({ query: { ...route.query, page: 1 } });
      if (!filters.includes(value)) {
        filters.push(value);
      } else if (filters.includes(value)) {
        let itemIndex = filters.indexOf(value);
        filters.splice(itemIndex, 1);
      }
    };

    const formatDateYYYYMMDD = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    };


    function getArtworkImage(artwork) {
      if (artwork?.cover_image && artwork?.cover_image[0]?.webp) {
        return artwork.cover_image[0].webp;
      } else if (artwork?.image[0]?.webp) {
        return artwork.image[0].webp;
      } else if (artwork?.image[0]?.original_url) {
        return artwork.image[0].original_url;
      } else {
        return '/img/placeholders/block.png';
      }
    }


    if (!props.category_id) {
      const data = JSON.parse(localStorage.getItem('ThemeArtwork'));
      if (data) {
        router.push({
          name: 'ThemeArtworks',
          params: {
            slug: data.slug,
            category_id: data.id,
            category_name_en: data.name_en,
          },
        });
      } else {
        router.push({ name: 'Themes' });
      }
    }


    return {
      isLoggedIn,
      router,
      route,
      artworks,
      getArtworks,
      artworkMeta,
      search_id,
      search_name_en,
      search_name_ar,
      search_categories,
      search_products,
      search_tags,
      search_variations,
      search_global,
      orderColumn,
      orderColumnOptions,
      orderDirection,
      orderDirectionOptions,
      updateOrdering,
      per_page_options,
      per_page,
      page,
      selectedProducts,
      selectFilter,
      formatDateYYYYMMDD,
      getArtworkImage,
      addToWishlist,
      wishlistIDs,
      isOpen,
      inactive,
      is_direct,
      isLoading,
    };
  },
};
</script>
<style scoped>
.card-custom {
  width: 180px;
  height: 180px;
}

.card-custom .card-img {
  height: 100%;
}

.card-custom .card-title {
  font-family: "Poppins-Bold";
}

.breadcrumb-custom {
  font-family: "Poppins";
  font-size: 12px;
  color: var(--grayer);
}

.btn-custom-light {
  font-family: "Poppins";
  font-size: 14px;
  color: var(--grayer);
}

.dot {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  /* border: 3px solid #000; */
  display: inline-block;
  cursor: pointer;
}

.dot .slected {
  border: 3px solid #ec5d42;
}

.filters {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .filters {
    display: block !important;
  }
}
</style>
